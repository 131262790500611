import React from 'react';
import { NavLink } from 'react-router-dom';

class Pagination extends React.Component {
    constructor() {
        super();
        this.state = {
            pageList: [],
            selected: 1,
            isPrew: false,
            isNext: true
        }
        this.selectPage = this.selectPage.bind(this);
    }
    componentDidMount() {
        const page = window.location.pathname.split('/page-')[1];
        if (page) {
            this.setState({
                selected: parseInt(page.slice(0, -1))
            }, () => {
                this.props.functionPage(this.state.selected);
            })
        } else {
            this.setState({
                selected: 1
            })
        }
    }

    componentDidUpdate() {
        const page = window.location.pathname.split('/page-')[1];
        if (page) {
            if (parseInt(page.slice(0, -1)) !== this.state.selected) {
                this.setState({
                    selected: parseInt(page.slice(0, -1))
                })
            }
        }
    }
    changePage() {
        this.setState({
            isPrew: (this.state.selected > 1),
            isNext: (this.state.selected < this.props.totalCount / 20)
        }, () => {
            this.props.functionPage(this.state.selected);
        });
    }

    selectPage(page, event) {
        this.setState({
            selected: page
        }, () => {
            this.changePage();
        })
    }

    pageNumbers(c, m) {
        let current = c,
            last = Math.ceil(m / 20),
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    generatePageButton() {
        const { totalCount } = this.props;
        const { currentPage } = this.props;

        if (totalCount && totalCount > 20) {
            const arr = this.pageNumbers(currentPage, totalCount);
            return arr.map((el, key) => {
                return <li className={`c-pagination-list__item item${currentPage === el ? '--active' : ''} ${el === '...' ? 'no_click' : ''}`} key={key} onClick={this.selectPage.bind(this, el)}>
                    {el === '...' ? <div key={key} >{el}</div > : <NavLink to={this.psrseLink(el)} key={key} >{el}</NavLink >}
                </li>
            })
        } else {
            return <li className="c-pagination-list__item item--active">
                <a href="#">1</a>
            </li>
        }
    }

    prewPage() {
        this.selectPage(this.state.selected - 1, null);
    }

    nextPage() {
        this.selectPage(this.state.selected + 1, null);
    }

    psrseLink(page) {
        const path = window.location.pathname.split('/page-')[0];
        let createUrl = `${path}${(path[path.length - 1] === '/') ? '' : '/'}page-${page}/`;
        return createUrl
    }
    render() {
        const { isPrew, isNext } = this.state;
        return <div className="c-pagination">
            <ul className="c-pagination-list h-reset-list">

                <li className={`c-pagination-list__item c-pagination-list__prev item${isPrew ? '' : '--disabled'}`} onClick={this.prewPage.bind(this)}>
                    <NavLink to={this.psrseLink(this.state.selected - 1)}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                        </svg>
                    </NavLink >
                </li>

                {this.generatePageButton()}

                <li className={`c-pagination-list__item c-pagination-list__next${isNext ? '' : '--disabled'}`} onClick={this.nextPage.bind(this)}>
                    <NavLink to={this.psrseLink(this.state.selected + 1)}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                        </svg>
                    </NavLink >
                </li>

            </ul>
        </div>
    }
}


export default Pagination