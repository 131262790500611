import React from 'react';
import { NavLink } from 'react-router-dom';

import Image1 from '../../assets/img/banner/1.svg';
import Image2 from '../../assets/img/banner/2.svg';
import Image3 from '../../assets/img/banner/3.svg';
import Image4 from '../../assets/img/banner/4.svg';
import Image5 from '../../assets/img/banner/5.svg';
import Image6 from '../../assets/img/banner/6.svg';
import Image7 from '../../assets/img/banner/7.svg';
import Image8 from '../../assets/img/banner/8.svg';
import Image9 from '../../assets/img/banner/9.svg';
import Image10 from '../../assets/img/banner/10.svg';
import LogoBig from '../../assets/img/logo-big.png';
import ImageWebp1 from '../../assets/img/banner/1.webp';
import ImageWebp2 from '../../assets/img/banner/2.webp';
import ImageWebp3 from '../../assets/img/banner/3.webp';
import ImageWebp4 from '../../assets/img/banner/4.webp';
import ImageWebp5 from '../../assets/img/banner/5.webp';
import ImageWebp6 from '../../assets/img/banner/6.webp';
import ImageWebp7 from '../../assets/img/banner/7.webp';
import ImageWebp8 from '../../assets/img/banner/8.webp';
import ImageWebp9 from '../../assets/img/banner/9.webp';
import ImageWebp10 from '../../assets/img/banner/10.webp';

import ImgWithFallback from './../imgWithFallback';

class cBanner extends React.Component {
    searchBTN;
    constructor(props) {
        super(props);
        this.state = {
            searchLine: ''
        }
        this.changeSearch = this.changeSearch.bind(this);
        this.bindEnter = this.bindEnter.bind(this);
    }

    bindEnter() {
        window.addEventListener('keyup', (event) => {
            if (event.keyCode === 13) {
                this.searchBTN.click();
            }
        });
    }

    changeSearch(event) {
        this.setState({
            searchLine: event.target.value
        })
    }

    render() {
        return <section className="c-banner">
            <div className="container">
                <div className="c-banner__inner js-magic-parent">
                    <div className="c-banner__magic-trigger js-magic-trigger"></div>
                    <svg width="639" height="304" viewBox="0 0 639 304" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M240.046 8.89923C240.046 8.89923 -19.5358 -38.1611 3.0184 117.974C65.3217 333.607 497.346 119.198 607.313 254.949C619.664 270.197 630.106 287.355 638.872 306" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 641 308" style={{ enableBackground: 'new 0 0 641 308' }} xmlSpace="preserve" className="animated-line">
                        <path id="c-line__1" fill="none" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M638.9,306c-8.8-18.6-19.2-35.8-31.6-51.1C497.3,119.2,65.3,333.6,3,118C-19.5-38.2,240,8.9,240,8.9"></path>
                    </svg>
                    <div className="c-banner__icons">
                        <div className="icon-el icon-el--1">
                            <ImgWithFallback src={ImageWebp1} type='image/webp' fallback={Image1} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--2">
                            <ImgWithFallback src={ImageWebp2} type='image/webp' fallback={Image2} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--3">
                            <ImgWithFallback src={ImageWebp3} type='image/webp' fallback={Image3} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--4">
                            <ImgWithFallback src={ImageWebp4} type='image/webp' fallback={Image4} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--5">
                            <ImgWithFallback src={ImageWebp5} type='image/webp' fallback={Image5} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--6">
                            <ImgWithFallback src={ImageWebp6} type='image/webp' fallback={Image6} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--7">
                            <ImgWithFallback src={ImageWebp7} type='image/webp' fallback={Image7} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--8">
                            <ImgWithFallback src={ImageWebp8} type='image/webp' fallback={Image8} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--9">
                            <ImgWithFallback src={ImageWebp9} type='image/webp' fallback={Image9} alt='icon'/>
                        </div>
                        <div className="icon-el icon-el--10">
                            <ImgWithFallback src={ImageWebp10} type='image/webp' fallback={Image10} alt='icon'/>
                        </div>
                    </div>
                    <div className="c-banner__content">
                        <div className="c-banner__logo">
                            <img src={LogoBig} alt="logo" />
                        </div>
                        <p className="c-banner__text">
                            Shop, explore channels and chat with <br />
                            businesses and your fellas
                        </p>
                        <div className="search-form">
                            <div className="input-wrap">
                                <input type="search" placeholder="Search products, sellers, channels…" className="form-control" onChange={this.changeSearch.bind(this)} onFocus={this.bindEnter.bind(this)} value={this.state.searchLine} />
                                <NavLink to={`/search/data=${this.state.searchLine}`} className="form-submit" ref={el => this.searchBTN = el}>
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 24.9" style={{ enableBackground: 'new 0 0 23.6 24.9' }} xmlSpace="preserve" className="icon icon-search">
                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10.3,19c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8S5.4,19,10.3,19z M15.9,17.1l6.3,6.3"></path>
                                    </svg>
                                </NavLink>
                            </div>
                        </div>
                        <div className="c-banner__actions">
                            <a href="#" className="c-banner__actions__item">
                                Get the app
                        </a>
                            <a href="#" className="c-banner__actions__item">
                                Become a partner
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default cBanner;
