import React, { useEffect, useState } from 'react';
import Slider from '../slider/slider';
import OffersList from '../c-offers-list/index';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default ({ data }) => {
    const { CompanyId, OfferId } = data,
        [list, setList] = useState(null),
        [selectedItem, setSelectedItem] = useState(null),
        [images, setImages] = useState(null),
        [totalCount, setTotalCount] = useState(null),
        [minPrice, setMinPrice] = useState(null),
        [maxPrice, setMaxPrice] = useState(null),
        [breadchumbs_data, setBreadchumbsData] = useState(null),
        [minimum, setMinimum] = useState(null),
        getData = () => {
            axios.get(`/api/v1/business/getProductsByEANummer?offerId=${OfferId}&companyId=${CompanyId}`).then(({ data }) => {
                setList(data.Data.slice(0,3));
                setSelectedItem(data.SelectedItem);
                setImages(data.SelectedItem.Images);
                setTotalCount(data.TotalCount);
                setMinPrice(data.MinPrice);
                setMaxPrice(data.MaxPrice);
                setBreadchumbsData([
                    {
                        link: '/products',
                        text: 'All products'
                    },
                    {
                        link: '#',
                        text: data.SelectedItem.Title
                    }
                ]);

                const sortble = data.Data.sort((a, b) => a.Price > b.Price ? 1 : -1);
                setMinimum(sortble[0]);
            });
        };
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);
    return (<div className={'c-offer-box flex'}>
        <div className="offer-b-content slider">
            <Slider images={data.Images} pagination={'points'} />
        </div>
        <div className="offer-b-content data">
            <div className="ob-title">{data.Title}</div>

            <div className="ob-offers">
                <span>{data.TotalCount} offers: </span>
                <b>{data.MinPrice} {data.Currency} - {data.MaxPrice} {data.Currency}</b>
            </div>

            <div className="ob-list-data">
                <div className="list">
                    {list && <OffersList list={list} />}
                </div>
                <NavLink className={'all-offers-btn'} to={`/offer/${data.OfferId}/${data.CompanyId}`}>Show all {data.TotalCount} offers</NavLink>
            </div>
        </div>
    </div>)
};