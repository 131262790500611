import React from 'react';
import { NavLink } from 'react-router-dom';
import LottieAnimation from "../../components/lottie-animation";

import ChannelsJson from '../../assets/json/Channels';
import Oval from '../../assets/img/dekor/oval.svg';
import CircleDash from '../../assets/img/dekor/circle-dash.svg';
import Img1 from '../../assets/img/channels/img1.jpg';
import Img2 from '../../assets/img/channels/img2.jpg';
import Img3 from '../../assets/img/channels/img3.jpg';
import ImgWebp1 from '../../assets/img/channels/img1.webp';


import Swiper from 'react-id-swiper';

class cChannels extends React.Component {
    swiper;
    constructor(props) {
        super(props);
        this.state = {
            paginator: false
        }
    }

    params = {
        slidesPerView: 'auto',
        loop: false,
        speed: 800,
        spaceBetween: 15,
        pagination: {
            el: '.c-channels-carousel__pagination',
            type: 'bullets',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                centeredSlides: true
            },
            481: {
                slidesOffsetBefore: 10,
                slidesOffsetAfter: 10,
                centeredSlides: false
            }
        },
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true,
        onInit: (swiper) => {
            this.swiper = swiper
        }
    }

    render() {
        return <section className="c-channels">
            <div className="c-channels__oval">
                <img src={Oval} alt="oval" />
            </div>
            <div className="c-channels__circle-dashed">
                <img src={CircleDash} alt="circle" />
            </div>
            <div className="container-sm">
                <div className="c-channels__inner js-magic-parent">
                    <div className="c-channels__magic-trigger js-magic-trigger"></div>
                    <svg width="348" height="900" viewBox="0 0 348 900" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M2.29492 898.099C82.9007 880.752 253.082 808.441 299.871 587.255C304.385 565.914 320.819 497.02 332.881 410C339.632 361.292 345.013 306.906 346.167 252M346.197 250.5C346.191 250.833 346.184 251.167 346.177 251.5M346.197 250.5L346.177 251.5M346.197 250.5L346.167 252M346.197 250.5C346.201 250.333 346.204 250.167 346.207 250C347.905 161.269 338.477 71.3473 305.872 2M346.177 251.5C346.174 251.667 346.17 251.833 346.167 252" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg width="348" height="900" viewBox="0 0 348 900" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path id="c-line__2" d="M2.29492 898.099C82.9007 880.752 253.082 808.441 299.871 587.255C304.385 565.914 320.819 497.02 332.881 410C339.632 361.292 345.013 306.906 346.167 252M346.197 250.5C346.191 250.833 346.184 251.167 346.177 251.5M346.197 250.5L346.177 251.5M346.197 250.5L346.167 252M346.197 250.5C346.201 250.333 346.204 250.167 346.207 250C347.905 161.269 338.477 71.3473 305.872 2M346.177 251.5C346.174 251.667 346.17 251.833 346.167 252" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <div className="c-channels-info">
                        <div className="section-info-text c-channels-info__text">
                            <h2 className="section-title">Channels</h2>
                            <span className="section-subtitle">Find inspiration and inspire </span>
                            <p>
                                Never miss an authentic story about an inspiring experience of a like-minded, a
                                useful observation of an expert or new product from your loved brand. Do you
                                have your own story or experience to share with people? Create a channel,
                                inspire others and build your own community.
                            </p>
                            <NavLink to={"/channels"}>
                                <div className="btn btn--blue">
                                    Explore channels
                                </div>
                            </NavLink>
                        </div>
                        <div className="c-channels-info__anim">
                            <LottieAnimation data={ChannelsJson} type={'svg'} height={null} />
                        </div>
                    </div>

                    <div className="c-channels-carousel">
                        <Swiper {...this.params}>
                            <div className="c-channels-carousel__card swiper-slide">
                                <div className="c-channels-carousel__card__content">
                                    <a href="#" className="c-channels-carousel__card__img h-object-fit">
                                        <picture>
                                            <source type="image/webp" srcSet={ImgWebp1} alt="img"/>
                                            <img src={Img1} alt="img" />
                                        </picture>                                        
                                    </a>
                                    <a href="#" className="c-channels-carousel__card__title">
                                        Travel Prudently Lug
                                    </a>
                                    <p className="c-channels-carousel__card__text">
                                        Hey guys! We are eyeslovetosee - a group of 6 people who report…
                                    </p>
                                </div>
                                <div className="c-channels-carousel__card__footer">
                                    <a href="#">
                                        <span>Read more</span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-channels-carousel__card swiper-slide">
                                <div className="c-channels-carousel__card__content">
                                    <a href="#" className="c-channels-carousel__card__img h-object-fit">
                                        <img src={Img2} alt="img" />
                                    </a>
                                    <a href="#" className="c-channels-carousel__card__title">
                                        Perfume Tips Tricks
                                    </a>
                                </div>
                                <div className="c-channels-carousel__card__footer">
                                    <a href="#">
                                        <span>Read more</span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-channels-carousel__card swiper-slide">
                                <div className="c-channels-carousel__card__content">
                                    <a href="#" className="c-channels-carousel__card__img h-object-fit">
                                        <img src={Img3} alt="img" />
                                    </a>
                                    <a href="#" className="c-channels-carousel__card__title">
                                        Philosophy As A
                                    </a>
                                    <p className="c-channels-carousel__card__text">
                                        Hey guys! We are eyeslovetosee - a group of 6 people who report…
                                    </p>
                                </div>
                                <div className="c-channels-carousel__card__footer">
                                    <a href="#">
                                        <span>Read more</span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-channels-carousel__card create-channel-card swiper-slide">
                                <div className="c-channels-carousel__card__content">
                                    <span className="c-channels-carousel__card__title">
                                        Inspire others and develop your own community
                                    </span>
                                    <a href="#" className="btn btn--blue btn--fluid">
                                        Create chanel
                                    </a>
                                </div>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section >
    }
}

export default cChannels;