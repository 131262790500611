import React from 'react';
import axios from 'axios';
import $ from "jquery";
import { NavLink } from 'react-router-dom';
import Layout from '../containers/layout';
import IconFire from '../assets/img/icon-fire.png';
import PageBannerImg from '../assets/img/svg/page-banner-img.svg';

class ShoppingPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            menu: false,
            data: []
        }
        this.getChannels = this.getChannels.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getChannels()
        }
    }

    getChannels() {
        axios.get(`/api/v1/blogs/users/10`).then((result) => {
            this.setState({
                data: result.data
            })
        });
    }
    render() {
        const { data } = this.state;
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <div className="c-search">
                    <div className="c-search-top">
                        <div className="container">
                            <div className="c-search-top__inner">
                                <form action="#" className="c-search-form">
                                    <input type="text" placeholder="Search for products, sellers, channels…" className="form-control" />
                                    <button type="submit" className="c-search-form__submit">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 24.9" style={{
                                            enableBackground: 'new 0 0 23.6 24.9'
                                        }} xmlSpace="preserve" className="icon icon-search" >
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10.3,19c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8S5.4,19,10.3,19z M15.9,17.1l6.3,6.3"></path>
                                        </svg>
                                    </button>
                                </form>
                                <button type="button" className="c-search__close">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: 'new 0 0 16 16' }} xmlSpace="preserve" className="icon-close">
                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,1.5l13,13 M14.5,1.5l-13,13"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="c-search-autocomplete">
                        <div className="container">
                            <div className="c-search-autocomplete__inner">
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                                <a href="#" className="c-search-autocomplete__link">camo</a>
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                                <a href="#" className="c-search-autocomplete__link">camo</a>
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                            </div>
                        </div>
                    </div>
                    <div className="c-search-trendy">
                        <div className="container">
                            <div className="c-search-trendy__inner">
                                <strong className="c-search-trendy__title">Trendy searches</strong>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Laptop</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Shoes</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Bathroom Accessories</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Wall Décor</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Kitchen Storage</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Sports Equipment</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Organisers</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Headphones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <main>
                    <div className="c-page-banner">
                        <div className="container">
                            <img src={PageBannerImg} alt="page-banner" className="c-page-banner__img" />
                        </div>
                    </div>
                    <div className="c-channels-content">
                        <div className="c-channels-search">
                            <div className="container">
                                <form action="#" className="search-form">
                                    <div className="input-wrap">
                                        <input type="text" placeholder="Search channels..." className="form-control" />
                                        <button type="submit" className="form-submit">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 24.9" style={{
                                                enableBackground: 'new 0 0 23.6 24.9'
                                            }} xmlSpace="preserve" className="icon icon-search" >
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10.3,19c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8S5.4,19,10.3,19z M15.9,17.1l6.3,6.3"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                                <div className="c-shopping-labels">
                                    <a href="#" className="c-shopping-labels__item">Fashion trends</a>
                                    <a href="#" className="c-shopping-labels__item">News</a>
                                    <a href="#" className="c-shopping-labels__item">Finance</a>
                                    <a href="#" className="c-shopping-labels__item">design</a>
                                    <a href="#" className="c-shopping-labels__item">technology</a>
                                    <a href="#" className="c-shopping-labels__item">social media</a>
                                    <a href="#" className="c-shopping-labels__item">Emergency & first need</a>
                                    <a href="#" className="c-shopping-labels__item">Eat & drink</a>
                                    <a href="#" className="c-shopping-labels__item">Education</a>
                                </div>
                            </div>
                        </div>
                        <div className="c-channels-boxes-wrapper">
                            <div className="container">
                                <div className="c-channels-boxes-inner">
                                    {
                                        data.map((el, key) => {
                                            return <div className="c-channels-boxes-col" key={key}>
                                                <div className="c-channels-carousel__card">
                                                    <div className="c-channels-carousel__card__content">
                                                        <a href="#" className="c-channels-carousel__card__img h-object-fit">
                                                            <img src={el.Image} alt="img" />
                                                        </a>
                                                        <a href="#" className="c-channels-carousel__card__title">{el.Name}</a>
                                                        <p className="c-channels-carousel__card__text">{el.Description}</p>
                                                    </div>
                                                    <div className="c-channels-carousel__card__footer">
                                                        <NavLink to={`/channel-bigger-view/${el.Alias}`}>
                                                            <span>Read more</span>
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                            </svg>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </div >
    }
}

export default ShoppingPage;
