/** @format */

import React from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import Layout from '../containers/layout';

class BlogViewPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            menu: false,
            load: true,
            dataText: '',
            blog: null,
            posts: null,
            pathname: null
        }
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getData();
        }
    }

    componentDidUpdate() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            if (this.state.pathname !== window.location.pathname) {
                this.setState({
                    pathname: window.location.pathname
                }, () => {
                    this.getData();
                });
            }
        }
    }
    async getData() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const blog = await axios.get(`/api/v1${window.location.pathname.replace("/blog/", "/blogs/")}`);
        this.setState({
            pathname: window.location.pathname,
            blog: blog.data
        }, () => {
            this.getOther();
        })
    }
    async getOther() {
        let postData = {
            Page: 0,
            PageSize: 3,
            CategoryId: 0
        }
        const posts = await axios.post(`/api/v1/blogs/posts`, postData);
        this.setState({
            posts: posts.data.Data
        })
    }
    parseDate(date) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da} ${mo} ${ye}`
    }
    parseLink(link) {
        return link.replace("/blog/", "/blog/")
    }
    render() {
        const { blog, posts } = this.state;
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <main>
                    {blog && <div className="c-blog-article">
                        {blog.Images && <div className="c-blog-article__banner h-object-fit">
                            <img src={blog.Images[0]} alt="banner" />
                        </div>}
                        <div className="c-blog-article__content">
                            <h1 className="c-blog-article__content__title">{blog.Title}</h1>
                            <div className="c-blog-article__content__info">
                                <span className="c-blog-article__content__category">{blog.Category}</span>
                                <span className="c-blog-article__content__date">{this.parseDate(blog.PublishingDate)}</span>
                            </div>
                            <div className="c-blog-article__content__text" dangerouslySetInnerHTML={{ __html: blog.Description }}></div>
                        </div>
                    </div>}

                    <div className="c-blog-explore">
                        <div className="container">
                            <h2 className="c-blog-explore__title">Explore more articles</h2>
                            <div className="c-blog-explore-inner">
                                <div className="c-blog-boxes-inner">


                                    {posts && posts.map((post, key) => {
                                        return <div className="c-blog-boxes-col" key={key}>
                                            <div className="c-blog-boxes__card">
                                                <a href="#" className="c-blog-boxes__card__img h-object-fit">
                                                    {post.Images && <img src={post.Images} alt="img" />}
                                                </a>
                                                <div className="c-blog-boxes__card__content">
                                                    <a href="#" className="c-blog-boxes__card__cat">{post.Category}</a>
                                                    <a href="#" className="c-blog-boxes__card__title">{post.Title}</a>
                                                    <div className="c-blog-boxes__card__info">
                                                        <div className="c-blog-boxes__card__info__side">
                                                            <div className="c-blog-boxes__card__info__icon">
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 24 24" }} xmlSpace="preserve" className="icon-calendar">
                                                                    <path id="SVGID_1_" d="M21,3h-1V1c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v2H8V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H3 C1.3,3,0,4.3,0,6v15c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V6C24,4.3,22.7,3,21,3z M21,20c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V10h18 V20z"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="c-blog-boxes__card__info__text">
                                                                <span>{this.parseDate(post.PublishingDate)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="c-blog-boxes__card__info__side">
                                                            <div className="c-blog-boxes__card__info__icon">
                                                                <svg version="1.1" id="Шар_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 23.6" style={{ enableBackground: "new 0 0 23.6 23.6" }} xmlSpace="preserve" className="icon-pensil">
                                                                    <path id="SVGID_3_" d="M11.8,6l5.7,5.7c0.4,0.4,0.4,1,0,1.4L7.4,23.3c-0.2,0.2-0.5,0.3-0.8,0.3l-5.8-0.4c-0.3,0-0.5-0.2-0.5-0.5 L0,16.9c0-0.3,0.1-0.6,0.3-0.8L10.4,6C10.8,5.6,11.4,5.6,11.8,6z M16.1,0.3l-2.5,2.5c-0.4,0.4-0.4,1,0,1.4l5.7,5.7 c0.4,0.4,1,0.4,1.4,0l2.5-2.5c0.4-0.4,0.4-1,0-1.4l-5.7-5.7C17.1-0.1,16.5-0.1,16.1,0.3z"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="c-blog-boxes__card__info__text">
                                                                <span>By {post.Author}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="c-blog-boxes__card__text" dangerouslySetInnerHTML={{ __html: post.Description }}></div>
                                                    <div className="c-blog-boxes__card__footer">
                                                        <NavLink to={post.Link}>
                                                            <span>Read more</span>
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                            </svg>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </div >
    }
}

export default BlogViewPage;


