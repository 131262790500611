import React from 'react';
import $ from "jquery";
import 'swiper/swiper.scss';
/** Components **/
import Layout from '../containers/layout';
import PartnersComponent from '../components/partners/index';
import Banner from "../components/c-banner/index";
import Channels from "../components/c-channels/index";
import Shopping from "../components/c-shopping/index";
import TopStores from "../components/c-top-stores/index";
import Conversation from "../components/c-conversation/index";
import GetApp from "../components/c-get-app/index";
import Journal from "../components/c-journal/index";

class MainPage extends React.Component {
    scrlTop;
    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            require('../scripts/js-scroll-line')
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
        }
    }
    showDialog(scrlTop) {
        var body = document.body;
        var scrollWidthCheck = document.createElement('div');
        scrollWidthCheck.style.overflowY = 'scroll';
        scrollWidthCheck.style.width = '50px';
        scrollWidthCheck.style.height = '50px';
        body.append(scrollWidthCheck);
        var scrollWidth = scrollWidthCheck.offsetWidth - scrollWidthCheck.clientWidth;
        scrollWidthCheck.remove();
        body.style.paddingRight = scrollWidth + 'px';
        body.querySelector('.c-header').style.paddingRight = scrollWidth + 'px';
        body.style.width = '100%';
        body.style.top = `-${scrlTop}`;
    };

    closeDialog() {
        var body = document.body;
        var scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.width = '';
        body.style.overflow = '';
        body.style.padding = '';
        body.querySelector('.c-header').style.padding = '';
    }
   
    render() {
        return <div className="l-wrapper">
            <Layout lg={false} animated={true}>
                <main>
                    <Banner />
                    <Channels />
                    <Shopping />
                    <TopStores />
                    <Conversation />
                    <GetApp />
                    <Journal />
                </main>
                <PartnersComponent />
            </Layout >
        </div >
    }
}

export default MainPage;
