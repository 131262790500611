import React from 'react';
import Lottie from "react-lottie";

class LottieAnimation extends React.Component {
   render() {
      const {
         data,
         type
      } = this.props;

      return <Lottie
         resizeMode='cover'
         options={{
            animationData: data,
            renderer: type,
            loop: true,
            autoplay: true,
            rendererSettings: {
               preserveAspectRatio: 'xMidYMid slice'
            }
         }}
      />
   }
}

export default LottieAnimation