import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";

import 'simplebar/dist/simplebar.min.css';

const parseSearch = (filterParam, a, SelectedParams) => {
    let values;
    if (filterParam.search) {
        let resFilters = [];
        filterParam.Value.forEach(el => {
            if (el.toUpperCase().indexOf(filterParam.search.toUpperCase()) > -1) {
                resFilters.push(el)
            }
        })
        values = resFilters
    } else {
        values = filterParam.Value
    }
    let newArray = [];
    values.forEach(el => {
        let srch = SelectedParams.find(x => x.key === filterParam.Key)
        newArray.push({
            Key: el,
            checked: srch ? srch.value.find(x => x === el) ? true : false : false
        })
    })
    return newArray
}

class ShoppingFilters extends React.Component {
    minValue;
    maxValue;
    rangeSlider;
    blocks;
    constructor(props) {
        super(props);
        this.state = {
            parameters: null,
            loadFilters: false,
            searchs: null,
            srch: ''
        }

        this.setSearchToFilter = this.setSearchToFilter.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.setFilters = this.setFilters.bind(this);
    }
    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        /** Import custom scripts / sliders */
        if (isBrowser) {
            require('ion-rangeslider');
            require('../../scripts/js-filters');
            // range slider 
            $('.js-range-slider').ionRangeSlider({
                skin: 'round',
                type: 'double',
                min: this.props.defMinVal,
                max: this.props.defMaxVal,
                from: this.props.MinValue,
                to: this.props.MaxValue,
                prefix: "$",
                onChange: (data) => {
                    $('.price-from').text(data.from);
                    $('.price-to').text(data.to);
                    $('.c-filters-range__from').val(data.from);
                    $('.c-filters-range__to').val(data.to);
                    this.props.changeMinMaxPrice([data.from, data.to])
                },
            });
            this.rangeSlider = $('.js-range-slider').data("ionRangeSlider");
            this.props.getCategories();
        }
    }

    setFilters() {
        this.props.resetPage(this.props.getProducts);
    }

    componentDidUpdate() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser && this.props.updateFromFilter) {
            if ((parseInt($('.price-from').html()) !== this.props.MinValue) || (parseInt($('.price-to').html()) !== this.props.MaxValue)) {
                let valFrom = this.props.MinValue;
                let valTo = this.props.MaxValue;
                $('.price-from').text(valFrom);
                $('.price-to').text(valTo);
                this.rangeSlider.update({
                    from: valFrom,
                    to: valTo
                });
            }
        }
    }

    setMinMaxValue() {
        let valFrom = this.minValue.value;
        let valTo = this.maxValue.value;
        this.rangeSlider.update({
            from: valFrom,
            to: valTo
        });
        $('.price-from').text(valFrom);
        $('.price-to').text(valTo);
        this.props.changeMinMaxPrice([valFrom, valTo])
    }
    psrseLink(alias) {
        let splitAlias = alias.split('/');
        let createUrl = `/catalog/${splitAlias[splitAlias.length - 2]}/`;
        return createUrl
    }

    setSearchToFilter(id, event) {
        this.setState({
            srch: event.target.value
        })
        this.props.searchCategories(event.target.value, id)
    }

    toggleFilter(id, event) {
        $(id).siblings('.c-filters-collapse__content').slideToggle();
        $(id).toggleClass('is-active');
    }

    parseCategories(categories, parent) {
        return categories.map((el, key) => {
            return <div>
                <NavLink to={this.psrseLink(el.Alias)} className={`${parent && 'c-filters-cats__list__title'} ${(this.props.selectCategories === el.UniqueId) ? 'selected-category' : null}`} onClick={this.props.selectCategoriesMethod.bind(this, el.UniqueId, el.Name)}>{el.Name}</NavLink >
                <ul className="c-filters-cats__list h-reset-list">
                    {el.ChildCategories && this.parseCategories(el.ChildCategories, false)}
                </ul>
            </div>
        })
    }

    render() {
        const { categories, parametrsArray, filterVisible, toggleFiltersShow } = this.props;
        return <div className={`c-filters-wrapper ${filterVisible ? 'is-visible' : null}`}>
            <div className="c-filters__header">
                <span>Filters</span>
                <button type="button" className="c-filters__close" onClick={toggleFiltersShow.bind(this)}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: 'new 0 0 16 16' }} xmlSpace="preserve" className="icon-close">
                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,1.5l13,13 M14.5,1.5l-13,13"></path>
                    </svg>
                </button>
            </div>
            <form action="#" className="c-filters">
                <div className="c-filters-box">
                    <div className="c-filters-collapse">
                        <button type="button" className="c-filters-collapse__trigger is-active" id={`toggle_Category`} onClick={this.toggleFilter.bind(this, `#toggle_Category`)}>
                            <span className="c-filters-collapse__trigger__name">Category</span>
                            <div className="c-filters-collapse__trigger__arr">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </div>
                        </button>
                        <div className="c-filters-collapse__content is-active">
                            <div className="c-filters-collapse__content__inner">
                                <div className="c-filters-cats">
                                    <NavLink to={'/catalog'} className="c-filters-cats__link-back" onClick={this.props.selectCategoriesMethod.bind(this, 0, 'All categories')}>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                        <span>All categories</span>
                                    </NavLink>
                                    <div className="c-filters-cats__list-wrap">
                                        {categories && this.parseCategories(categories, true)}
                                    </div>
                                    {/* <div className="c-filters-cats__list-wrap list-wrap-hidden">
                                        <a href="#" className="c-filters-cats__list__title">Fashion</a>
                                        <ul className="c-filters-cats__list h-reset-list">
                                            <li><a href="#">Truhen</a></li>
                                            <li><a href="#">Martinique</a></li>
                                            <li><a href="#">Korea</a></li>
                                            <li><a href="#">Solomon Islands</a></li>
                                            <li><a href="#">Niue</a></li>
                                        </ul>
                                        <a href="#" className="c-filters-cats__list__title">Fashion</a>
                                        <ul className="c-filters-cats__list h-reset-list">
                                            <li><a href="#">Truhen</a></li>
                                            <li><a href="#">Martinique</a></li>
                                            <li><a href="#">Korea</a></li>
                                            <li><a href="#">Solomon Islands</a></li>
                                            <li><a href="#">Niue</a></li>
                                        </ul>
                                    </div> */}
                                    {/* <button type="button" className="c-filters-cats__show-more"> <span>+ Show more</span></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-filters-box">
                    <div className="c-filters-collapse">
                        <button type="button" className="c-filters-collapse__trigger is-active" id={`toggle_Price`} onClick={this.toggleFilter.bind(this, `#toggle_Price`)}>
                            <span className="c-filters-collapse__trigger__name">Price</span>
                            <div className="c-filters-collapse__trigger__arr">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </div>
                        </button>
                        <div className="c-filters-collapse__content is-active">
                            <div className="c-filters-collapse__content__inner">
                                <div className="c-filters-range">
                                    <p className="c-filters-range__price">
                                        $ <span className="price-from">0 </span> - $ <span className="price-to">10000</span>
                                    </p>
                                    <input type="text" name="my_range" className="js-range-slider" />
                                    <div className="c-filters-range__bottom">
                                        <div className="c-filters-range__bottom__inputs">
                                            <input type="text" className="form-control c-filters-range__from" ref={(el) => this.minValue = el} onChange={this.setMinMaxValue.bind(this)} /><span>-</span>
                                            <input type="text" className="form-control c-filters-range__to" ref={(el) => this.maxValue = el} onChange={this.setMinMaxValue.bind(this)} /><span>$</span>
                                        </div>
                                        <button type="button" className="btn btn--blue" onClick={() => this.props.getProducts()}>ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="c-filters-box">
                    <div className="c-filters-collapse">
                        <button type="button" className="c-filters-collapse__trigger is-active">
                            <span className="c-filters-collapse__trigger__name">Color</span>
                            <div className="c-filters-collapse__trigger__arr">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </div>
                        </button>
                        <div className="c-filters-collapse__content is-active">
                            <div className="c-filters-collapse__content__inner">
                                <div className="c-filters-colors">
                                    <label htmlFor="orange_color" className="c-filters-colors__label">
                                        <input type="checkbox" id="orange_color" className="c-filters-colors__input" /><span style={{ backgroundColor: '#f5a623' }} className="c-filters-colors__square">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg></span>
                                    </label>
                                    <label htmlFor="yellow_color" className="c-filters-colors__label">
                                        <input type="checkbox" id="yellow_color" className="c-filters-colors__input" /><span style={{ backgroundColor: '#f8e71c' }} className="c-filters-colors__square">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg></span>
                                    </label>
                                    <label htmlFor="grey_color" className="c-filters-colors__label">
                                        <input type="checkbox" id="grey_color" className="c-filters-colors__input" /><span style={{ backgroundColor: 'grey' }} className="c-filters-colors__square">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg></span>
                                    </label>
                                    <label htmlhtmlFor="black_color" className="c-filters-colors__label">
                                        <input type="checkbox" id="black_color" className="c-filters-colors__input" /><span style={{ backgroundColor: '#000000' }} className="c-filters-colors__square">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg></span>
                                    </label>
                                    <label htmlhtmlFor="green_color" className="c-filters-colors__label">
                                        <input type="checkbox" id="green_color" className="c-filters-colors__input" /><span style={{ backgroundColor: 'green' }} className="c-filters-colors__square">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg></span>
                                    </label>
                                    <label htmlhtmlFor="different_colors" className="c-filters-colors__label">
                                        <input type="checkbox" id="different_colors" className="c-filters-colors__input" />
                                        <span className="c-filters-colors__square">
                                            <span style={{ backgroundColor: '#20b605' }} className="c-filters-colors__square-sm"></span>
                                            <span style={{ backgroundColor: '#1162f6' }} className="c-filters-colors__square-sm"></span>
                                            <span style={{ backgroundColor: '#ffc300' }} className="c-filters-colors__square-sm"></span>
                                            <span style={{ backgroundColor: '#ff2727' }} className="c-filters-colors__square-sm"></span>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                            </svg>
                                        </span>
                                    </label>
                                    <button type="button" className="c-filters-colors__more">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15" style={{ enableBackground: 'new 0 0 15 15' }} xmlSpace="preserve" className="icon-plus">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M7.5,1.5v12 M13.5,7.5h-12"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div >
                        </div >
                    </div >
                </div > */}


                {

                    parametrsArray && parametrsArray.map((filterParam, key) => {
                        return <div className="c-filters-box" >
                            <div className="c-filters-collapse">
                                <button type="button" className="c-filters-collapse__trigger is-active" id={`toggle_${filterParam.Key}`} onClick={this.toggleFilter.bind(this, `#toggle_${filterParam.Key}`)}>
                                    <span className="c-filters-collapse__trigger__name">{filterParam.Key}</span>

                                    <div className="c-filters-collapse__trigger__arr">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </div>

                                </button>
                                <div className="c-filters-collapse__content is-active">
                                    <div className="c-filters-collapse__content__inner js-scroll-wraper">
                                        <div className="c-filters-search">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19.5 20.5" style={{ enableBackground: 'new 0 0 19.5 20.5' }} xmlSpace="preserve" className="icon-search-bold">
                                                <path fill="none" stroke="#363537" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.5,15.5c3.9,0,7-3.1,7-7s-3.1-7-7-7s-7,3.1-7,7S4.6,15.5,8.5,15.5z M13,14l5,5"></path>
                                            </svg>
                                            <input type="text" id={`search_${filterParam.Key}`} placeholder={`Search ${filterParam.Key}`} className="form-control" onChange={this.setSearchToFilter.bind(this, filterParam.Key)} />
                                        </div>
                                        <div className="c-filters-brands js-scroll scrollbar-outer">
                                            <div className="collapse_loader" id={`collapse_loader_${filterParam.Key}`} ref={el => { this[`collapse_loader_${filterParam.Key}`] = el }}></div>
                                            <SimpleBar style={{ maxHeight: 200, width: '100%' }}>
                                                {
                                                    parseSearch(filterParam, this.state.srch, this.props.SelectedParams).map((el, key) => {
                                                        return <div className="c-filters-brands__col">
                                                            <label htmlFor={`br_${filterParam.Key}_${el.Key}`} className="c-filters-brands__label" >
                                                                <input type="checkbox" checked={el.checked} id={`br_${filterParam.Key}_${el.Key}`} className="c-filters-brands__input" onChange={this.props.addFilterParams.bind(this, filterParam.Key, el.Key, this[`collapse_loader_${filterParam.Key}`])} />
                                                                <span className="c-filters-brands__circle">
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 12" style={{ enableBackground: 'new 0 0 17 12' }} xmlSpace="preserve" className="icon-checkmark">
                                                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,5.5l5,5 M15.5,1.5L7,10"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className="c-filters-brands__num">{el.Key}</span>
                                                            </label>
                                                        </div>
                                                    })
                                                }
                                            </SimpleBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }


                <div className="c-filters__results-block">
                    <span className="c-filters__results-block__text">
                        {this.props.productsFound} products found
                    </span>
                    <a href="#" className="btn btn--blue btn--fluid show-filter-result" onClick={this.setFilters.bind(this)}>
                        Show results
                    </a>
                </div>
            </form >
        </div >
    }
}

export default ShoppingFilters;