import $ from "jquery";
import 'ion-rangeslider';

// show more 
$('.c-filters-cats__show-more').on('click', function () {
    $(this).siblings('.c-filters-cats__list-wrap.list-wrap-hidden').slideDown();
    $(this).remove();
});

$('.c-shopping-content').on('click', function () {
    $('.c-filters__results-block').removeClass('is-active');
});
// поява фильтра при разрешении < 1200
// фиксированая страница при открытых модалках/меню (кросбраузерно)
var scrlTop;
$(window).on('scroll', function () {
    scrlTop = `${window.scrollY}px`;
});

$('.c-top-info__filters__item').on('click', function () {
    $(this).remove();
    if ($('.c-top-info__filters__item').length < 1) {
        $('.c-top-info__filters').remove();
    }
});