import React from 'react';
import LottieAnimation from "../../components/lottie-animation";
import MessagingJson from '../../assets/json/Messaging';
import Square from '../../assets/img/dekor/square.svg';
import Circle from '../../assets/img/dekor/circle.svg';

class cConversation extends React.Component {
    render() {
        return <section className="c-conversation">
            <div className="c-conversation__square">
                <img src={Square} alt="square" />
            </div>
            <div className="c-conversation__circle">
                <img src={Circle} alt="circle" />
            </div>
            <div className="container-sm">
                <div className="c-conversation__inner js-magic-parent">
                    <div className="c-conversation__magic-trigger js-magic-trigger"></div>
                    <svg width="617" height="648" viewBox="0 0 617 648" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M518.829 2C630.593 127.152 659.98 334.608 525.768 461.455C415.548 565.627 187.44 495.95 45.0455 594.119C27.3357 606.328 13.3429 624.121 2.45312 646" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 617 648" style={{ enableBackground: 'new 0 0 617 648' }} xmlSpace="preserve" className="animated-line">
                        <path id="c-line__5" fill="none" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M2.5,646c10.9-21.9,24.9-39.7,42.6-51.9c142.4-98.2,370.5-28.5,480.7-132.7C660,334.6,630.6,127.2,518.8,2"></path>
                    </svg>
                    <div className="c-conversation-info">
                        <div className="section-info-text c-conversation-info__text">
                            <h2 className="section-title">Conversations</h2>
                            <span className="section-subtitle">Stay in touch </span>
                            <p>
                                Send free one-on-one and group messages to your friends and family anytime,
                                anywhere including media! Share your best moments over voice and video calls.
                                Fast, simple and secure. Easily connect with your favourite online and offline
                                businesses to make reservations, buy products, get instant support and more.
                        </p>
                            <a href="#" className="btn btn--blue">
                                Explore messaging
                        </a>
                        </div>
                        <div className="c-conversation-info__anim">
                            <LottieAnimation data={MessagingJson} type={'svg'} height={null} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default cConversation;