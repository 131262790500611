import $ from "jquery";
import gsap, { TimelineMax, TweenMax, Linear } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

ScrollMagicPluginGsap(ScrollMagic, gsap);

function pathPrepare($el) {
    var lineLength = $el[0].getTotalLength();
    $el.css("stroke-dasharray", lineLength);
    $el.css("stroke-dashoffset", 0);
    return lineLength;
}

var controller = new ScrollMagic.Controller();

var $lines =
    $("#c-line__1")
        .add($('#c-line__2'))
        .add($('#c-line__3'))
        .add($('#c-line__4'))
        .add($('#c-line__5'))
        .add($('#c-line__6'))
        .add($('#c-line__7'));


$.each($lines, function (index, item) {
    var th = $(this);

    var lineLength = pathPrepare(th);
    var parent = th.closest('.js-magic-parent');
    var trigger = parent.find('.js-magic-trigger');

    var tween = new TimelineMax()
        .add(TweenMax.to(th, 1, {
            strokeDashoffset: lineLength,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            ease: Linear.easeNone
        }));
    var elem = document.getElementsByClassName(th.closest('svg').attr('class'));
    var scene = new ScrollMagic.Scene(
        {
            tweenChanges: true,
            duration: trigger.outerHeight(),
            triggerElement: trigger[0]
        }
    )
        .setTween(tween)
        .addTo(controller);
});

