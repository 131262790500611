import React from 'react';
import axios from 'axios';
import $ from "jquery";
import { NavLink } from 'react-router-dom';

class Menu extends React.Component {
  scrlTop;
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      categories: [],
      selectCategories: 0,
      ChildCategories: [],
      openMenuEnabled: false
    }
  }

  showDialog(scrlTop) {
    var body = document.body;
    var scrollWidthCheck = document.createElement('div');

    scrollWidthCheck.style.overflowY = 'scroll';
    scrollWidthCheck.style.width = '50px';
    scrollWidthCheck.style.height = '50px';
    body.append(scrollWidthCheck);
    var scrollWidth = scrollWidthCheck.offsetWidth - scrollWidthCheck.clientWidth;
    scrollWidthCheck.remove();

    // body.style.overflow = 'hidden';
    body.style.paddingRight = scrollWidth + 'px';
    body.querySelector('.c-header').style.paddingRight = scrollWidth + 'px';
    // body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.top = `-${scrlTop}`;
  };

  closeDialog() {
    var body = document.body;
    var scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.width = '';
    body.style.overflow = '';
    body.style.padding = '';
    body.querySelector('.c-header').style.padding = '';
    // window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  isLoaded() {
    function fixedStateSwitcher() {
      if ($(window).scrollTop() > 1) {
        $('.c-header').addClass('is-fixed');
      } else {
        $('.c-header').removeClass('is-fixed');
      }

      // if ($(window).width() < 992) {
      //   // if ($(window).scrollTop() > 320) {
      //     // $('.c-header').addClass('search-active');
      //   // } else {
      //   //   $('.c-header').removeClass('search-active');
      //   // }
      // }
    }
    fixedStateSwitcher();
    $(window).on('scroll', fixedStateSwitcher);


    // фиксированая страница при открытых модалках/меню (кросбраузерно)
    $(window).on('scroll', function () {
      this.scrlTop = `${window.scrollY}px`;
    });

    // MOBILE
    $('.c-menu-main-list__categories').on('click', function () {
      $(this).closest('.c-menu-main-list-wrap').addClass('moved-left');
      $(this).closest('.c-menu-inner').find('.c-menu-list-wrap').addClass('is-active');
    });

    $('.c-menu-content__back-to-main').on('click', function () {
      $(this).closest('.c-menu-list-wrap.is-active').removeClass('is-active');
      $(this).closest('.c-menu-inner').find('.c-menu-main-list-wrap.moved-left').removeClass('moved-left');
    });

    // переключение списков в открытой меню -- mobile версия (Переход их категорий в групы)
    $('.c-menu-list__btn').on('click', function () {
      if ($(window).width() < 992) {
        var $this = $(this);
        var $cat = $this.data('cat');
        var $parent = $this.closest('.c-menu-list-wrap');

        $parent.addClass('moved-left');

        $('.c-menu-content').addClass('is-visible');
        $('.c-menu-content-category.is-active').removeClass('is-active');
        $('.c-menu-content-category#' + $cat).addClass('is-active');
      }
    });

    // возвращение на один уровень назад в категории
    $('.c-menu-content-category__back').on('click', function () {
      if ($(window).width() < 992) {
        var $this = $(this);
        $this.closest('.c-menu-content').removeClass('is-visible');
        $this.closest('.c-menu-wrap').find('.c-menu-list-wrap').removeClass('moved-left');
      }
    });

    // переход из груп в подгруппы
    $('.c-menu-content__group__trigger').on('click', function () {
      if ($(window).width() < 992) {
        var $this = $(this);
        $this.toggleClass('is-active');
        $this.siblings('.c-menu-content__group__inner').slideToggle(300);
      }
    });

    // возвращение на один уровень назад c подгруп в группы
    $('.c-menu-content__group__back').on('click', function () {
      if ($(window).width() < 992) {
        $(this).closest('.c-menu-content').removeClass('moved-left');
      }
    });

    // ПОИСК
    $('.js-search-trigger').on('click', () => {
      // $('body').addClass('search-active');
      setTimeout(function () {
        $('.c-search-form').find('.form-control').focus();
      }, 500);
      this.showDialog(this.scrlTop);
    });

    $('.search-overlay, .c-search__close').on('click', () => {
      // $('body').removeClass('search-active');
      this.closeDialog();
    });

    $('.c-search-form').on('input', '.form-control', function () {
      var trendyBlock = $('.c-search-trendy');
      var autocompleteBlock = $('.c-search-autocomplete');
      if ($(this).val().length > 0) {
        trendyBlock.addClass('is-hidden');
        autocompleteBlock.addClass('is-active');
      } else {
        trendyBlock.removeClass('is-hidden');
        autocompleteBlock.removeClass('is-active');
      }
    });
    $('.c-menu-main-list__categories').on('click', function () {
      // $(this).closest('.c-menu-main-list-wrap').addClass('moved-left');
      // $(this).closest('.c-menu-inner').find('.c-menu-list-wrap').addClass('is-active');
    });

    $('.c-menu-content__back-to-main').on('click', function () {
      $(this).closest('.c-menu-list-wrap.is-active').removeClass('is-active');
      $(this).closest('.c-menu-inner').find('.c-menu-main-list-wrap.moved-left').removeClass('moved-left');
    });
  }
  componentDidMount() {
    this.getMenuCategories();
  }

  toggleMenuTrigger(event) {
    if (this.props.menu) {
      $('.c-menu-wrap').removeClass('is-active');
      $('.js-menu-trigger.is-active').removeClass('is-active');
      $('body').removeClass('modal-active');
      this.props.menuClose();
      this.closeDialog();
    } else {
      $(event.target).addClass('is-active');
      $('.c-menu-wrap').addClass('is-active');
      $('body').addClass('modal-active');
      this.showDialog(this.scrlTop);
    }
  }

  mouseOver(catId, event) {
    let th = event.target;
    if ($(window).width() > 991) {
      if ($(th).closest('.c-menu-list__item').hasClass('is-active')) {
        return
      }
      var $this = $(th);
      var $cat = $this.data('cat');
      var $parent = $this.closest('.c-menu-list__item');

      $parent.siblings('.is-active').removeClass('is-active');
      $parent.addClass('is-active');

      $('.c-menu-content-category.is-active').removeClass('is-active');
      $('.c-menu-content-category#' + $cat).addClass('is-active');
    }
  }

  getMenuCategories() {
    axios.get(`/api/v1/business/getHeaderMenu`).then((result) => {
      this.setState({
        categories: result.data
      }, () => {
        this.isLoaded();
      })
    });
  }

  closeMenuFromClick(event) {
    let body = document.body;
    let scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.width = '';
    body.style.overflow = '';
    body.style.padding = '';
    body.querySelector('.c-header').style.padding = '';
    // window.scrollTo(0, parseInt(scrollY || '0') * -1);

    $('.c-menu-wrap').removeClass('is-active');
    $('.js-menu-trigger.is-active').removeClass('is-active');
    $('body').removeClass('modal-active');
    this.closeDialog();
    this.props.menuTrigger();
  }

  render() {
    const { categories } = this.state;
    return <div className={this.props.menu ? 'c-menu-wrap is-active' : 'c-menu-wrap'}>
      <div className="c-menu-inner" id="c-menu-inner">
        <div className="c-menu-main-list-wrap">
          <div className="c-menu-main-list-header">
            <span>Menu</span>
            <button type="button" className="c-header__menu-trigger is-active js-mob-menu-close" onClick={this.closeMenuFromClick.bind(this)}>
              <svg viewBox="0 0 100 100" width="60" className="ham hamRotate ham4">
                <path d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" className="line top"></path>
                <path d="m 70,50 h -40" className="line middle"></path>
                <path d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" className="line bottom"></path>
              </svg>
            </button>
          </div>
          <ul className="c-menu-main-list h-reset-list">
            <li className="c-menu-main-list__item">
              <button type="button" className="c-menu-main-list__categories">
                <span>Shopping</span>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                  <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                </svg>
              </button>
            </li>
            <li className="c-menu-main-list__item">
              <NavLink to={"/channels"} onClick={this.closeMenuFromClick.bind(this)}>
                <span>Channels</span>
              </NavLink>
            </li>
            <li className="c-menu-main-list__item">
              <a href="#">
                <span>Sellers</span>
              </a>
            </li>
            <li className="c-menu-main-list__item">
              <a href="#">
                <span>Local</span>
              </a>
            </li>
            <li className="c-menu-main-list__item">
              <a href="#">
                <span>Journal</span>
              </a>
            </li>
          </ul>
          <a href="#" className="c-header__app">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" className="icon icon-get-app">
              <defs>
                <clippath id="clip1">
                  <path d="M 7 2 L 23 2 L 23 5 L 7 5 Z M 7 23 L 23 23 L 23 28 L 7 28 Z M 7 23 "></path>
                </clippath>
                <clippath id="clip2">
                  <path d="M 5 0 L 25 0 L 25 30 L 5 30 Z M 5 0 "></path>
                </clippath>
                <clippath id="clip3">
                  <path d="M 21.722656 -0.125 C 23.214844 -0.125 24.464844 0.964844 24.652344 2.402344 L 24.667969 2.574219 L 24.675781 2.75 L 24.675781 27.242188 C 24.675781 28.703125 23.550781 29.917969 22.082031 30.101563 L 21.90625 30.117188 L 21.722656 30.125 L 8.328125 30.125 C 6.832031 30.125 5.582031 29.035156 5.398438 27.597656 L 5.378906 27.425781 L 5.375 27.25 L 5.375 2.75 C 5.375 1.289063 6.5 0.0742188 7.96875 -0.101563 L 8.144531 -0.121094 L 8.328125 -0.125 Z M 21.917969 23.269531 L 8.125 23.269531 L 8.125 27.242188 C 8.125 27.320313 8.183594 27.390625 8.273438 27.410156 L 8.328125 27.417969 L 21.714844 27.417969 C 21.8125 27.417969 21.886719 27.359375 21.910156 27.289063 L 21.917969 27.242188 Z M 16.5625 24.132813 C 17.1875 24.132813 17.691406 24.636719 17.691406 25.257813 C 17.691406 25.785156 17.332031 26.234375 16.8125 26.355469 L 16.691406 26.378906 L 16.5625 26.386719 L 13.519531 26.386719 C 12.898438 26.386719 12.394531 25.882813 12.394531 25.257813 C 12.394531 24.734375 12.753906 24.285156 13.273438 24.160156 L 13.394531 24.140625 L 13.519531 24.132813 Z M 21.917969 7.34375 L 8.125 7.34375 L 8.125 20.574219 L 21.917969 20.574219 Z M 21.722656 2.578125 L 8.328125 2.578125 C 8.230469 2.578125 8.15625 2.632813 8.132813 2.707031 L 8.125 2.75 L 8.125 4.640625 L 21.921875 4.640625 L 21.925781 2.75 C 21.925781 2.675781 21.863281 2.605469 21.777344 2.582031 Z M 21.722656 2.578125 "></path>
                </clippath>
              </defs>
              <g id="surface1">
                <g clipPath="url(#clip1)" clipRule="nonzero">
                  <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(33.724976%,68.62793%,92.156982%)', fillOpacity: 1 }} d="M 2 -3 L 28 -3 L 28 33 L 2 33 Z M 2 -3 "></path>
                </g>
                <g clipPath="url(#clip2)" clipRule="nonzero">
                  <g clipPath="url(#clip3)" clipRule="evenodd">
                    <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(21.176147%,20.783997%,21.568298%)', fillOpacity: 1 }} d="M 0.375 -5.125 L 29.675781 -5.125 L 29.675781 35.125 L 0.375 35.125 Z M 0.375 -5.125 "></path>
                  </g>
                </g>
              </g>
            </svg>
            <span>Get the app</span>
          </a>
        </div>
        <nav className="c-menu-list-wrap">
          <div className="c-menu-content-category__header">
            <button type="button" className="c-menu-content__back-to-main">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
              </svg>
              <span>Shopping</span>
            </button>
            <NavLink to={"/catalog"} onClick={this.closeMenuFromClick.bind(this)}>View all</NavLink >
          </div>
          <ul className="c-menu-list h-reset-list">
            {categories && categories.map((el, key) => {
              return <li className="c-menu-list__item">
                <button type="button" data-cat={`cat-${el.UniqueId}`} className="c-menu-list__btn" onMouseOver={this.mouseOver.bind(this, el.UniqueId)}>
                  <NavLink to={'/' + el.Alias} onClick={this.closeMenuFromClick.bind(this)}>
                    <span>{el.Name}</span>
                  </NavLink>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                  </svg>
                </button>
              </li>
            })}
          </ul>
        </nav>
        <div className="c-menu-content">
          {categories && categories.map((el, key) => {
            return <div id={`cat-${el.UniqueId}`} className="c-menu-content-category" key={key}>
              <div className="c-menu-content-category__header">
                <button type="button" className="c-menu-content-category__back">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                  </svg>
                  <span>{el.Name}</span>
                </button>
                <NavLink to={'/' + el.Alias} onClick={this.closeMenuFromClick.bind(this)}>
                  View all
                </NavLink>
              </div>
              <div className="c-menu-content__inner">


                {el.ChildCategories && el.ChildCategories.map((child, key) => {
                  return <div className="c-menu-content__group" key={key}>
                    <button type="button" className="c-menu-content__group__trigger">
                      <NavLink to={'/' + child.Alias} onClick={this.closeMenuFromClick.bind(this)}>
                        <strong className="c-menu-content__group__title">
                          {child.Name}
                        </strong>
                      </NavLink>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                      </svg>
                    </button>
                    <div className="c-menu-content__group__inner">
                      <ul className="c-menu-content__group__list h-reset-list">
                        {child.ChildCategories && child.ChildCategories.slice(0, 3).map((child2, key) => {
                          return <li> <NavLink to={'/' + child2.Alias} onClick={this.closeMenuFromClick.bind(this)}>{child2.Name}</NavLink></li>
                        })}
                        <li className="view-all-item">
                          <NavLink to={'/' + child.Alias} onClick={this.closeMenuFromClick.bind(this)}>
                            <span>View all</span>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                              <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                            </svg>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                })}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  }
}

export default Menu
