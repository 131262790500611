import Img_0 from './messaging-img/img_0.webp';
import Img_1 from './messaging-img/img_1.webp';
import Img_2 from './messaging-img/img_2.webp';
import Img_3 from './messaging-img/img_3.webp';
import Img_4 from './messaging-img/img_4.webp';
import Img_5 from './messaging-img/img_5.webp';
import Img_6 from './messaging-img/img_6.webp';
import Img_7 from './messaging-img/img_7.webp';
import Img_8 from './messaging-img/img_8.webp';
import Img_9 from './messaging-img/img_9.webp';
import Img_10 from './messaging-img/img_10.webp';
import Img_11 from './messaging-img/img_11.webp';

export default {
    "v": "5.5.9",
    "fr": 25,
    "ip": 0,
    "op": 449,
    "w": 800,
    "h": 600,
    "nm": "Messaging Main",
    "ddd": 0,
    "assets": [
        {
            "id": "image_0",
            "w": 320,
            "h": 320,
            "p": Img_0,
            "e": 0
        },
        {
            "id": "image_1",
            "w": 472,
            "h": 320,
            "p": Img_1,
            "e": 0
        },
        {
            "id": "image_2",
            "w": 320,
            "h": 320,
            "p": Img_2,
            "e": 0
        },
        {
            "id": "image_3",
            "w": 600,
            "h": 600,
            "p": Img_3,
            "e": 0
        },
        {
            "id": "image_4",
            "w": 600,
            "h": 600,
            "p": Img_4,
            "e": 0
        },
        {
            "id": "image_5",
            "w": 320,
            "h": 320,
            "p": Img_5,
            "e": 0
        },
        {
            "id": "image_6",
            "w": 320,
            "h": 320,
            "p": Img_6,
            "e": 0
        },
        {
            "id": "image_7",
            "w": 524,
            "h": 448,
            "p": Img_7,
            "e": 0
        },
        {
            "id": "image_8",
            "w": 180,
            "h": 208,
            "p": Img_8,
            "e": 0
        },
        {
            "id": "image_9",
            "w": 180,
            "h": 208,
            "p": Img_9,
            "e": 0
        },
        {
            "id": "image_10",
            "w": 384,
            "h": 604,
            "p": Img_10,
            "e": 0
        },
        {
            "id": "image_11",
            "w": 800,
            "h": 600,
            "p": Img_11,
            "e": 0
        },
        {
            "id": "comp_0",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Photo",
                    "refId": "image_0",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                200,
                                79,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                160,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 5,
                    "nm": "Awesome offer",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                125,
                                157.8,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 20,
                                        "f": "Rubik-Bold",
                                        "t": "Awesome offer",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 24,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/1",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                200,
                                238,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                59,
                                65,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 118,
                    "h": 130,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                200,
                                206.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 4
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 23
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            210,
                                            255
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 4,
                    "nm": "Shadow",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                200,
                                200,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                99.057,
                                99.628,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.202438995242,
                                            0.641436994076,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 230.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 250.417,
                                                "s": [
                                                    3
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 276.25,
                                                "s": [
                                                    11
                                                ]
                                            },
                                            {
                                                "t": 300.833333333333,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 250.417,
                                                "s": [
                                                    50
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 276.25,
                                                "s": [
                                                    122
                                                ]
                                            },
                                            {
                                                "t": 300.833333333333,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            211.48,
                                            255.586
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.95294123888,
                                            0.95294123888,
                                            0.95294123888,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.26,
                                            6.793
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 33
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_1",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                97,
                                16,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                59,
                                40,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                59,
                                105,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                59,
                                65,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.246960997581,
                                            0.250903993845,
                                            0.262993007898,
                                            0.40000000596
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 102
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 17
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118,
                                            130
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_2",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 3,
                    "nm": "▽ Shopping/Price",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                20.5,
                                16,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21.5,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 5,
                    "nm": "€",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                14,
                                22.2,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 20,
                                        "f": "Rubik-Bold",
                                        "t": "€",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 24,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Rectangle",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                21.994,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -5.9,
                                                    0
                                                ],
                                                [
                                                    -2.33,
                                                    -2.09
                                                ],
                                                [
                                                    0,
                                                    -6.06
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    2.52,
                                                    0
                                                ],
                                                [
                                                    4.72,
                                                    3.62
                                                ],
                                                [
                                                    0.11,
                                                    9.09
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    8.58,
                                                    0
                                                ],
                                                [
                                                    2.58,
                                                    2.31
                                                ],
                                                [
                                                    0,
                                                    3.97
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -15.62,
                                                    0
                                                ],
                                                [
                                                    -4.72,
                                                    -3.62
                                                ],
                                                [
                                                    -0.02,
                                                    -2.04
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -20.996,
                                                    -16
                                                ],
                                                [
                                                    -2.396,
                                                    -16
                                                ],
                                                [
                                                    13.314,
                                                    -10.17
                                                ],
                                                [
                                                    21.004,
                                                    5.06
                                                ],
                                                [
                                                    21.004,
                                                    16
                                                ],
                                                [
                                                    11.014,
                                                    16
                                                ],
                                                [
                                                    -12.336,
                                                    11.28
                                                ],
                                                [
                                                    -20.996,
                                                    -8.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.396077990532,
                                            0.431373000145,
                                            0.498039007187,
                                            0.5
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 50
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_3",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Photo",
                    "refId": "image_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                86.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                160,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                434.5,
                                221.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -69.5,
                                                    -48
                                                ],
                                                [
                                                    24.5,
                                                    -48
                                                ],
                                                [
                                                    24.5,
                                                    -33
                                                ],
                                                [
                                                    -69.5,
                                                    -33
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 1",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -69.5,
                                                    -21
                                                ],
                                                [
                                                    63.5,
                                                    -21
                                                ],
                                                [
                                                    63.5,
                                                    -6
                                                ],
                                                [
                                                    -69.5,
                                                    -6
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 2",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 4,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -69.5,
                                                    6
                                                ],
                                                [
                                                    56.5,
                                                    6
                                                ],
                                                [
                                                    56.5,
                                                    21
                                                ],
                                                [
                                                    -69.5,
                                                    21
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 3",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 6,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -69.5,
                                                    33
                                                ],
                                                [
                                                    0.5,
                                                    33
                                                ],
                                                [
                                                    0.5,
                                                    48
                                                ],
                                                [
                                                    -69.5,
                                                    48
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 4",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 8,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -69.5,
                                                    60
                                                ],
                                                [
                                                    -38.5,
                                                    60
                                                ],
                                                [
                                                    -38.5,
                                                    75
                                                ],
                                                [
                                                    -69.5,
                                                    75
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 5",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            20,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            40.5,
                                            -40.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            43,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            40.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            63,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -1,
                                            67.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            38,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            50.5,
                                            -67.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            57,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -41,
                                            -67.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            31,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            9,
                                            -67.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 6",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 3,
                    "nm": "▽ Video 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                270,
                                221.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                75,
                                75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 3,
                    "nm": "▽ play-button-arrowhead 2",
                    "parent": 3,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                75,
                                75,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 4,
                    "nm": "Shape",
                    "parent": 4,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                27.273,
                                25,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.474197000265,
                                            0.469828993082,
                                            0.469828993082,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 9
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.89
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.36,
                                                    0.94
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.37,
                                                    0.94
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.36,
                                                    -0.94
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.89
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.37,
                                                    -0.94
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -4.348,
                                                    -8.691
                                                ],
                                                [
                                                    -6.818,
                                                    -6.971
                                                ],
                                                [
                                                    -6.818,
                                                    6.969
                                                ],
                                                [
                                                    -4.348,
                                                    8.689
                                                ],
                                                [
                                                    5.792,
                                                    1.699
                                                ],
                                                [
                                                    5.792,
                                                    -1.701
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Shape",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "Oval Copy 2",
                    "parent": 4,
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 50
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            50,
                                            50
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Oval Copy 2",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 2,
                    "nm": "Rectangle Copy 16",
                    "parent": 3,
                    "refId": "image_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                75,
                                75,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                300,
                                300,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                206.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 4
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 23
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            370,
                                            240
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 4,
                    "nm": "Shadow",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                351,
                                199.5,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                170.355,
                                94.118,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.202438995242,
                                            0.641436994076,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 230.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 200,
                                                "s": [
                                                    3
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 225.833,
                                                "s": [
                                                    11
                                                ]
                                            },
                                            {
                                                "t": 250.416666666667,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 200,
                                                "s": [
                                                    50
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 225.833,
                                                "s": [
                                                    122
                                                ]
                                            },
                                            {
                                                "t": 250.416666666667,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            211.48,
                                            255.586
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.95294123888,
                                            0.95294123888,
                                            0.95294123888,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.26,
                                            6.793
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 28
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_4",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Photo",
                    "refId": "image_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                246.988,
                                147.424,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                300,
                                300,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                18.824,
                                18.824,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 3,
                    "nm": "▽ Call decline 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                210.094,
                                249.071,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Path",
                    "parent": 2,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.23,
                                                    1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.28,
                                                    -1.27
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.21,
                                                    0.12
                                                ],
                                                [
                                                    2.83,
                                                    2.84
                                                ],
                                                [
                                                    1.09,
                                                    1.97
                                                ],
                                                [
                                                    0.11,
                                                    0.2
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.23,
                                                    1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.28,
                                                    -1.27
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.37,
                                                    -0.93
                                                ],
                                                [
                                                    0.09,
                                                    -0.85
                                                ],
                                                [
                                                    -8.39,
                                                    -8.4
                                                ],
                                                [
                                                    -0.4,
                                                    0.04
                                                ],
                                                [
                                                    -0.86,
                                                    0.33
                                                ],
                                                [
                                                    -0.75,
                                                    0.58
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.23,
                                                    -1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.19,
                                                    -0.11
                                                ],
                                                [
                                                    -1.97,
                                                    -1.09
                                                ],
                                                [
                                                    -2.84,
                                                    -2.84
                                                ],
                                                [
                                                    -0.12,
                                                    -0.21
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.27,
                                                    -1.28
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.22,
                                                    -1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.58,
                                                    0.74
                                                ],
                                                [
                                                    -0.33,
                                                    0.89
                                                ],
                                                [
                                                    -0.82,
                                                    6.77
                                                ],
                                                [
                                                    11.59,
                                                    11.62
                                                ],
                                                [
                                                    0.88,
                                                    -0.11
                                                ],
                                                [
                                                    0.92,
                                                    -0.36
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.27,
                                                    -1.28
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    19.1,
                                                    11.64
                                                ],
                                                [
                                                    12.93,
                                                    5.45
                                                ],
                                                [
                                                    8.38,
                                                    5.53
                                                ],
                                                [
                                                    5.27,
                                                    8.65
                                                ],
                                                [
                                                    4.66,
                                                    8.31
                                                ],
                                                [
                                                    -2.83,
                                                    2.88
                                                ],
                                                [
                                                    -8.25,
                                                    -4.63
                                                ],
                                                [
                                                    -8.59,
                                                    -5.24
                                                ],
                                                [
                                                    -6.5,
                                                    -7.33
                                                ],
                                                [
                                                    -5.47,
                                                    -8.36
                                                ],
                                                [
                                                    -5.39,
                                                    -12.91
                                                ],
                                                [
                                                    -11.57,
                                                    -19.1
                                                ],
                                                [
                                                    -16.11,
                                                    -19.02
                                                ],
                                                [
                                                    -17.85,
                                                    -17.26
                                                ],
                                                [
                                                    -17.8,
                                                    -17.21
                                                ],
                                                [
                                                    -19.24,
                                                    -14.68
                                                ],
                                                [
                                                    -19.87,
                                                    -12.1
                                                ],
                                                [
                                                    -9.21,
                                                    9.27
                                                ],
                                                [
                                                    12.12,
                                                    19.97
                                                ],
                                                [
                                                    14.7,
                                                    19.32
                                                ],
                                                [
                                                    17.22,
                                                    17.89
                                                ],
                                                [
                                                    17.26,
                                                    17.92
                                                ],
                                                [
                                                    19.02,
                                                    16.19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            75.294,
                                            75.294
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 135
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Oval",
                    "parent": 2,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            80,
                                            80
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            0.227450996637,
                                            0.184313997626,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            75.294,
                                            75.294
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Oval",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 3,
                    "nm": "▽ Call accept 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                289.153,
                                249.071,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "Path",
                    "parent": 5,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.23,
                                                    1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.28,
                                                    -1.27
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.21,
                                                    0.12
                                                ],
                                                [
                                                    2.83,
                                                    2.84
                                                ],
                                                [
                                                    1.09,
                                                    1.97
                                                ],
                                                [
                                                    0.11,
                                                    0.2
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.23,
                                                    1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.28,
                                                    -1.27
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.37,
                                                    -0.93
                                                ],
                                                [
                                                    0.09,
                                                    -0.85
                                                ],
                                                [
                                                    -8.39,
                                                    -8.4
                                                ],
                                                [
                                                    -0.4,
                                                    0.04
                                                ],
                                                [
                                                    -0.86,
                                                    0.33
                                                ],
                                                [
                                                    -0.75,
                                                    0.58
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.23,
                                                    -1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.19,
                                                    -0.11
                                                ],
                                                [
                                                    -1.97,
                                                    -1.09
                                                ],
                                                [
                                                    -2.84,
                                                    -2.84
                                                ],
                                                [
                                                    -0.12,
                                                    -0.21
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.27,
                                                    -1.28
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.22,
                                                    -1.23
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.58,
                                                    0.74
                                                ],
                                                [
                                                    -0.33,
                                                    0.89
                                                ],
                                                [
                                                    -0.82,
                                                    6.77
                                                ],
                                                [
                                                    11.59,
                                                    11.62
                                                ],
                                                [
                                                    0.88,
                                                    -0.11
                                                ],
                                                [
                                                    0.92,
                                                    -0.36
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.27,
                                                    -1.28
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    19.1,
                                                    11.64
                                                ],
                                                [
                                                    12.93,
                                                    5.45
                                                ],
                                                [
                                                    8.38,
                                                    5.53
                                                ],
                                                [
                                                    5.27,
                                                    8.65
                                                ],
                                                [
                                                    4.66,
                                                    8.31
                                                ],
                                                [
                                                    -2.83,
                                                    2.88
                                                ],
                                                [
                                                    -8.25,
                                                    -4.63
                                                ],
                                                [
                                                    -8.59,
                                                    -5.24
                                                ],
                                                [
                                                    -6.5,
                                                    -7.33
                                                ],
                                                [
                                                    -5.47,
                                                    -8.36
                                                ],
                                                [
                                                    -5.39,
                                                    -12.91
                                                ],
                                                [
                                                    -11.57,
                                                    -19.1
                                                ],
                                                [
                                                    -16.11,
                                                    -19.02
                                                ],
                                                [
                                                    -17.85,
                                                    -17.26
                                                ],
                                                [
                                                    -17.8,
                                                    -17.21
                                                ],
                                                [
                                                    -19.24,
                                                    -14.68
                                                ],
                                                [
                                                    -19.87,
                                                    -12.1
                                                ],
                                                [
                                                    -9.21,
                                                    9.27
                                                ],
                                                [
                                                    12.12,
                                                    19.97
                                                ],
                                                [
                                                    14.7,
                                                    19.32
                                                ],
                                                [
                                                    17.22,
                                                    17.89
                                                ],
                                                [
                                                    17.26,
                                                    17.92
                                                ],
                                                [
                                                    19.02,
                                                    16.19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            75.294,
                                            75.294
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 4,
                    "nm": "Oval",
                    "parent": 5,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                30.118,
                                30.118,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            80,
                                            80
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.294117987156,
                                            0.850979983807,
                                            0.388235002756,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            75.294,
                                            75.294
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Oval",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                250,
                                185.071,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 3.012
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 17.318
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            254,
                                            310
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            75.294,
                                            75.294
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 4,
                    "nm": "Shadow",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                248,
                                177,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                84,
                                87.373,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.202438995242,
                                            0.641436994076,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 230.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 149.583,
                                                "s": [
                                                    3
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 175.417,
                                                "s": [
                                                    11
                                                ]
                                            },
                                            {
                                                "t": 200,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 149.583,
                                                "s": [
                                                    50
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 175.417,
                                                "s": [
                                                    122
                                                ]
                                            },
                                            {
                                                "t": 200,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            211.48,
                                            255.586
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.95294123888,
                                            0.95294123888,
                                            0.95294123888,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.26,
                                            6.793
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 33
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_5",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Photo",
                    "refId": "image_5",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                126.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                160,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                221,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -155,
                                                    -7.5
                                                ],
                                                [
                                                    54.35,
                                                    -7.5
                                                ],
                                                [
                                                    54.35,
                                                    7.5
                                                ],
                                                [
                                                    -155,
                                                    7.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 1",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -155,
                                                    19.5
                                                ],
                                                [
                                                    -69,
                                                    19.5
                                                ],
                                                [
                                                    -69,
                                                    34.5
                                                ],
                                                [
                                                    -155,
                                                    34.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 2",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ind": 4,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -56,
                                                    19.5
                                                ],
                                                [
                                                    30,
                                                    19.5
                                                ],
                                                [
                                                    30,
                                                    34.5
                                                ],
                                                [
                                                    -56,
                                                    34.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 3",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            44.286,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            90.584,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            84.545,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            112.727,
                                            -27
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            126.818,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -91.591,
                                            -27
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            69.448,
                                            15
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            19.627,
                                            -27
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                206.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 4
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 23
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            370,
                                            160
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Shadow 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                350,
                                202.5,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                171.355,
                                62.745,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.202438995242,
                                            0.641436994076,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 230.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 100.833,
                                                "s": [
                                                    3
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 126.667,
                                                "s": [
                                                    11
                                                ]
                                            },
                                            {
                                                "t": 151.25,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 100.833,
                                                "s": [
                                                    50
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 126.667,
                                                "s": [
                                                    122
                                                ]
                                            },
                                            {
                                                "t": 151.25,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            211.48,
                                            255.586
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.95294123888,
                                            0.95294123888,
                                            0.95294123888,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.26,
                                            6.793
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 38
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_6",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Photo",
                    "refId": "image_6",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                300,
                                101.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                160,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 5,
                    "nm": "Check out this post!",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                199,
                                180.24,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 20,
                                        "f": "Rubik-Bold",
                                        "t": "Check out this post!",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 24,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 3,
                    "nm": "▽ Card Preview 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                300,
                                287,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pics + Text",
                    "parent": 3,
                    "refId": "comp_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                76.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 153,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 4,
                    "nm": "BG",
                    "parent": 3,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            183
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                300,
                                256.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 4
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 23
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            274,
                                            310
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 4,
                    "nm": "Shadow 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                300.257,
                                248.5,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                128.057,
                                119.796,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.202438995242,
                                            0.641436994076,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 230.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 50.417,
                                                "s": [
                                                    3
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 76.25,
                                                "s": [
                                                    11
                                                ]
                                            },
                                            {
                                                "t": 100.833333333333,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 50.417,
                                                "s": [
                                                    50
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 76.25,
                                                "s": [
                                                    122
                                                ]
                                            },
                                            {
                                                "t": 100.833333333333,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            211.48,
                                            255.586
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.95294123888,
                                            0.95294123888,
                                            0.95294123888,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.26,
                                            6.793
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 33
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_7",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Pic 1",
                    "refId": "image_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                65.5,
                                56,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                262,
                                224,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic 2",
                    "refId": "image_8",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                26,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 2,
                    "nm": "Pic 3",
                    "refId": "image_9",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                86,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                86.5,
                                140,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            152,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -10.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            17,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            66,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -53.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            84,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            44.5,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            85,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -44,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_8",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Icon",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 39.167,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 50,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "r": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 3.333,
                                    "s": [
                                        10
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 6.667,
                                    "s": [
                                        -14
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 10.833,
                                    "s": [
                                        10
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 14.583,
                                    "s": [
                                        -14
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 18.75,
                                    "s": [
                                        10
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 22.917,
                                    "s": [
                                        -14
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 27.5,
                                    "s": [
                                        10
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 32.917,
                                    "s": [
                                        -14
                                    ]
                                },
                                {
                                    "t": 39.1666666666667,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                32.121,
                                31,
                                0
                            ]
                        },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        75,
                                        75,
                                        100
                                    ]
                                },
                                {
                                    "t": 39.1666666666667,
                                    "s": [
                                        100,
                                        100,
                                        100
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.36,
                                                    0.31
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.03,
                                                    8.03
                                                ],
                                                [
                                                    1.77,
                                                    1.73
                                                ],
                                                [
                                                    2.36,
                                                    0
                                                ],
                                                [
                                                    0.03,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -4.92
                                                ],
                                                [
                                                    1.07,
                                                    -2.76
                                                ],
                                                [
                                                    2.34,
                                                    -1.99
                                                ],
                                                [
                                                    -0.16,
                                                    -0.44
                                                ],
                                                [
                                                    -0.47,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.16,
                                                    0.44
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -4.61,
                                                    -3.95
                                                ],
                                                [
                                                    0,
                                                    -2.44
                                                ],
                                                [
                                                    -1.71,
                                                    -1.67
                                                ],
                                                [
                                                    -0.03,
                                                    0
                                                ],
                                                [
                                                    -4.98,
                                                    0.04
                                                ],
                                                [
                                                    0,
                                                    4.14
                                                ],
                                                [
                                                    -1.05,
                                                    2.71
                                                ],
                                                [
                                                    -0.36,
                                                    0.31
                                                ],
                                                [
                                                    0.17,
                                                    0.44
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.47,
                                                    0
                                                ],
                                                [
                                                    0.17,
                                                    -0.44
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    15.729,
                                                    11.836
                                                ],
                                                [
                                                    15.729,
                                                    11.836
                                                ],
                                                [
                                                    9.109,
                                                    -5.254
                                                ],
                                                [
                                                    6.319,
                                                    -11.804
                                                ],
                                                [
                                                    -0.001,
                                                    -14.394
                                                ],
                                                [
                                                    -0.081,
                                                    -14.394
                                                ],
                                                [
                                                    -9.111,
                                                    -5.404
                                                ],
                                                [
                                                    -10.701,
                                                    4.846
                                                ],
                                                [
                                                    -15.731,
                                                    11.836
                                                ],
                                                [
                                                    -16.051,
                                                    13.086
                                                ],
                                                [
                                                    -14.981,
                                                    13.816
                                                ],
                                                [
                                                    14.979,
                                                    13.816
                                                ],
                                                [
                                                    16.049,
                                                    13.086
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ind": 1,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.91,
                                                    1.72
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.98,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    1.97,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.91,
                                                    1.72
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -0.572,
                                                    19.001
                                                ],
                                                [
                                                    4.028,
                                                    16.121
                                                ],
                                                [
                                                    -5.182,
                                                    16.121
                                                ],
                                                [
                                                    -0.572,
                                                    19.001
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.12,
                                                    -0.56
                                                ],
                                                [
                                                    1.87,
                                                    0
                                                ],
                                                [
                                                    0.27,
                                                    -1.95
                                                ],
                                                [
                                                    -1.35,
                                                    0.01
                                                ],
                                                [
                                                    -0.03,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    1.26,
                                                    0
                                                ],
                                                [
                                                    -0.31,
                                                    -1.9
                                                ],
                                                [
                                                    -1.9,
                                                    0
                                                ],
                                                [
                                                    1.15,
                                                    -0.6
                                                ],
                                                [
                                                    0.03,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -0.16,
                                                    -16.5
                                                ],
                                                [
                                                    3.45,
                                                    -15.65
                                                ],
                                                [
                                                    -0.28,
                                                    -19
                                                ],
                                                [
                                                    -4.03,
                                                    -15.55
                                                ],
                                                [
                                                    -0.24,
                                                    -16.5
                                                ],
                                                [
                                                    -0.16,
                                                    -16.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Icon",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 5,
                    "nm": "1",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 49.583,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 74.583,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 99.5833333333333,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                22,
                                42,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 30,
                                        "f": "Rubik-Bold",
                                        "t": "1",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 36,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 5,
                    "nm": "2",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 99.583,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 124.583,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 149.583333333333,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                22,
                                42,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 30,
                                        "f": "Rubik-Bold",
                                        "t": "2",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 36,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 5,
                    "nm": "3",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 149.583,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 174.583,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 199.583333333333,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                22,
                                42,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 30,
                                        "f": "Rubik-Bold",
                                        "t": "3",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 36,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 5,
                    "nm": "4",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 199.583,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 224.583,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 249.583333333333,
                                    "s": [
                                        2
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                22,
                                42,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 30,
                                        "f": "Rubik-Bold",
                                        "t": "4",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 36,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 5,
                    "nm": "5",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 250,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 275,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                22,
                                42,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 30,
                                        "f": "Rubik-Bold",
                                        "t": "5",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 36,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        32,
                                        32,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 10.4166666666667,
                                    "s": [
                                        32,
                                        32,
                                        0
                                    ]
                                }
                            ]
                        },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        79,
                                        79,
                                        100
                                    ]
                                },
                                {
                                    "t": 10.4166666666667,
                                    "s": [
                                        100,
                                        100,
                                        100
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            64
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.337255001068,
                                            0.686275005341,
                                            0.921568989754,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 4,
                    "nm": "Shadow",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 1.667,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 10.417,
                                    "s": [
                                        98
                                    ]
                                },
                                {
                                    "t": 13.75,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                32,
                                32,
                                0
                            ]
                        },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        79,
                                        79,
                                        100
                                    ]
                                },
                                {
                                    "t": 10.4166666666667,
                                    "s": [
                                        100,
                                        100,
                                        100
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.234698995948,
                                            0.6313560009,
                                            1,
                                            0.5
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 127.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 14
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            64
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Shadow",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1500,
                    "st": 0,
                    "bm": 0
                }
            ]
        }
    ],
    "fonts": {
        "list": [
            {
                "fName": "Rubik-Bold",
                "fFamily": "Rubik",
                "fStyle": "Bold",
                "ascent": 71.8994140625
            }
        ]
    },
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 0,
            "nm": "Offer Post",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 250.251,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 300.301,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 358.692,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 375.375,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 358.692,
                            "s": [
                                492,
                                462,
                                0
                            ],
                            "to": [
                                -3.667,
                                -1.833,
                                0
                            ],
                            "ti": [
                                3.667,
                                1.833,
                                0
                            ]
                        },
                        {
                            "t": 375.375,
                            "s": [
                                470,
                                451,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        200,
                        200,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 250.251,
                            "s": [
                                81,
                                81,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 300.301,
                            "s": [
                                85,
                                85,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 358.692,
                            "s": [
                                85,
                                85,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 367.034,
                            "s": [
                                99,
                                99,
                                100
                            ]
                        },
                        {
                            "t": 375.375,
                            "s": [
                                85,
                                85,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 400,
            "h": 400,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 0,
            "nm": "Video Post",
            "refId": "comp_3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 200.2,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 250.251,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 350.351,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 367.0341796875,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 350.351,
                            "s": [
                                186,
                                438,
                                0
                            ],
                            "to": [
                                14,
                                -8.167,
                                0
                            ],
                            "ti": [
                                -14,
                                8.167,
                                0
                            ]
                        },
                        {
                            "t": 367.0341796875,
                            "s": [
                                270,
                                389,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        350,
                        200,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 200.2,
                            "s": [
                                75,
                                75,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 250.251,
                            "s": [
                                79,
                                79,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 350.351,
                            "s": [
                                79,
                                79,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 358.692,
                            "s": [
                                99,
                                99,
                                100
                            ]
                        },
                        {
                            "t": 367.0341796875,
                            "s": [
                                46,
                                46,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 700,
            "h": 400,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 0,
            "nm": "Call",
            "refId": "comp_4",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 150.15,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 200.2,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 342.009,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 358.6923828125,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 342.009,
                            "s": [
                                612,
                                364,
                                0
                            ],
                            "to": [
                                -20,
                                -11.167,
                                0
                            ],
                            "ti": [
                                20,
                                11.167,
                                0
                            ]
                        },
                        {
                            "t": 358.6923828125,
                            "s": [
                                492,
                                297,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        250,
                        200,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 150.15,
                            "s": [
                                83,
                                83,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 200.2,
                            "s": [
                                86,
                                86,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 342.009,
                            "s": [
                                86,
                                86,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 350.351,
                            "s": [
                                100,
                                100,
                                100
                            ]
                        },
                        {
                            "t": 358.6923828125,
                            "s": [
                                44,
                                44,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 500,
            "h": 400,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 4,
            "ty": 0,
            "nm": "Text Post",
            "refId": "comp_5",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 100.101,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 150.15,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 333.667,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 350.3505859375,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 333.667,
                            "s": [
                                564,
                                116,
                                0
                            ],
                            "to": [
                                -14,
                                9.667,
                                0
                            ],
                            "ti": [
                                14,
                                -9.667,
                                0
                            ]
                        },
                        {
                            "t": 350.3505859375,
                            "s": [
                                480,
                                174,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        350,
                        200,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 100.101,
                            "s": [
                                78,
                                78,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 150.15,
                            "s": [
                                81,
                                81,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 333.667,
                            "s": [
                                81,
                                81,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 342.009,
                            "s": [
                                100,
                                100,
                                100
                            ]
                        },
                        {
                            "t": 350.3505859375,
                            "s": [
                                52,
                                52,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 700,
            "h": 400,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 5,
            "ty": 0,
            "nm": "Channel Post",
            "refId": "comp_6",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 0,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 50.051,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 100.101,
                            "s": [
                                99
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 325.325,
                            "s": [
                                99
                            ]
                        },
                        {
                            "t": 342.0087890625,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 325.325,
                            "s": [
                                228,
                                238,
                                0
                            ],
                            "to": [
                                7.667,
                                4.167,
                                0
                            ],
                            "ti": [
                                -7.667,
                                -4.167,
                                0
                            ]
                        },
                        {
                            "t": 342.0087890625,
                            "s": [
                                274,
                                263,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        300,
                        250,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 50.051,
                            "s": [
                                82,
                                82,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 100.101,
                            "s": [
                                84,
                                84,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 325.325,
                            "s": [
                                84,
                                84,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 333.667,
                            "s": [
                                99,
                                99,
                                100
                            ]
                        },
                        {
                            "t": 342.0087890625,
                            "s": [
                                49,
                                49,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 600,
            "h": 500,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 6,
            "ty": 0,
            "nm": "Notification",
            "refId": "comp_8",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 316.984,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 333.6669921875,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 316.984,
                            "s": [
                                484,
                                237,
                                0
                            ],
                            "to": [
                                -5.167,
                                0,
                                0
                            ],
                            "ti": [
                                5.167,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 333.6669921875,
                            "s": [
                                453,
                                237,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        32,
                        32,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 316.984,
                            "s": [
                                100,
                                100,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 325.325,
                            "s": [
                                112,
                                112,
                                100
                            ]
                        },
                        {
                            "t": 333.6669921875,
                            "s": [
                                61,
                                61,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "w": 64,
            "h": 64,
            "ip": 0,
            "op": 1500,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "Messaging icon",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 0,
                            "s": [
                                -204,
                                204,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 328.662,
                            "s": [
                                -204,
                                204,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 342.009,
                            "s": [
                                -148.787,
                                148.787,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 369.536,
                            "s": [
                                -214.948,
                                214.948,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 383.718,
                            "s": [
                                -174,
                                174,
                                100
                            ]
                        },
                        {
                            "t": 395.3955078125,
                            "s": [
                                -204,
                                204,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            10.97,
                                            1.46
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            25.99,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -12.43,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.24,
                                            0
                                        ],
                                        [
                                            -0.6,
                                            0.25
                                        ],
                                        [
                                            0,
                                            1.91
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            11.72
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            25.44
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            12.16
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0.92,
                                            0.89
                                        ],
                                        [
                                            0.63,
                                            0
                                        ],
                                        [
                                            1.8,
                                            -0.74
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            11.8,
                                            -0.68
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -11.14
                                        ]
                                    ],
                                    "v": [
                                        [
                                            103.57,
                                            -7.5
                                        ],
                                        [
                                            103.57,
                                            -4.71
                                        ],
                                        [
                                            56.44,
                                            41.42
                                        ],
                                        [
                                            -12,
                                            41.42
                                        ],
                                        [
                                            -12,
                                            47.17
                                        ],
                                        [
                                            10.5,
                                            69.19
                                        ],
                                        [
                                            67.71,
                                            69.19
                                        ],
                                        [
                                            93.62,
                                            94.14
                                        ],
                                        [
                                            97.01,
                                            95.5
                                        ],
                                        [
                                            98.88,
                                            95.13
                                        ],
                                        [
                                            101.85,
                                            90.76
                                        ],
                                        [
                                            101.85,
                                            69.15
                                        ],
                                        [
                                            123,
                                            47.17
                                        ],
                                        [
                                            123,
                                            14.31
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "hd": false
                        },
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    19.8
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    19.69,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -19.8
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -18.7,
                                                    -1.11
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -2.85,
                                                    -1.2
                                                ],
                                                [
                                                    -0.99,
                                                    0
                                                ],
                                                [
                                                    -1.46,
                                                    1.44
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -19.8
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -19.69,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    19.08
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    3.11
                                                ],
                                                [
                                                    0.96,
                                                    0.4
                                                ],
                                                [
                                                    1.98,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    19.69,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    107,
                                                    5.33
                                                ],
                                                [
                                                    107,
                                                    -48.15
                                                ],
                                                [
                                                    71.34,
                                                    -84
                                                ],
                                                [
                                                    -71.34,
                                                    -84
                                                ],
                                                [
                                                    -107,
                                                    -48.15
                                                ],
                                                [
                                                    -107,
                                                    5.33
                                                ],
                                                [
                                                    -73.47,
                                                    41.11
                                                ],
                                                [
                                                    -73.47,
                                                    76.29
                                                ],
                                                [
                                                    -68.77,
                                                    83.4
                                                ],
                                                [
                                                    -65.81,
                                                    84
                                                ],
                                                [
                                                    -60.43,
                                                    81.79
                                                ],
                                                [
                                                    -19.36,
                                                    41.18
                                                ],
                                                [
                                                    71.34,
                                                    41.18
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            28
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            48,
                                            -21
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            28
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            -21
                                        ]
                                    },
                                    "nm": "Ellipse Path 2",
                                    "hd": false
                                },
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            28
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -48,
                                            -21
                                        ]
                                    },
                                    "nm": "Ellipse Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 3,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -16,
                                            -11.5
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Combined Shape",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "mm",
                            "mm": 2,
                            "nm": "Merge Paths 1",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.850979983807,
                                    0.850979983807,
                                    0.850979983807,
                                    1
                                ]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    45.74,
                                    45.74
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 0
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "sk": {
                                "a": 0,
                                "k": 0
                            },
                            "sa": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Преобразовать"
                        }
                    ],
                    "nm": "Messaging icon",
                    "bm": 0,
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 503,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 8,
            "ty": 2,
            "nm": "Channel phone bg.png",
            "cl": "png",
            "refId": "image_10",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        192,
                        302,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 503,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 9,
            "ty": 2,
            "nm": "bg.png",
            "cl": "png",
            "refId": "image_11",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 503,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": [],
    "chars": [
        {
            "ch": "5",
            "size": 30,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.379,
                                                -3.206
                                            ],
                                            [
                                                -5.697,
                                                0
                                            ],
                                            [
                                                -4.086,
                                                5.046
                                            ],
                                            [
                                                0,
                                                6.609
                                            ],
                                            [
                                                4.459,
                                                4.134
                                            ],
                                            [
                                                6.413,
                                                0
                                            ],
                                            [
                                                2.604,
                                                -1.074
                                            ],
                                            [
                                                1.627,
                                                -1.237
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.165,
                                                1.172
                                            ],
                                            [
                                                -3.027,
                                                0
                                            ],
                                            [
                                                -2.979,
                                                -2.669
                                            ],
                                            [
                                                0,
                                                -4.883
                                            ],
                                            [
                                                2.506,
                                                -3.32
                                            ],
                                            [
                                                5.208,
                                                0
                                            ],
                                            [
                                                2.083,
                                                0.977
                                            ],
                                            [
                                                0.586,
                                                5.013
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0.52,
                                                6.641
                                            ],
                                            [
                                                4.378,
                                                3.206
                                            ],
                                            [
                                                8.952,
                                                0
                                            ],
                                            [
                                                4.085,
                                                -5.045
                                            ],
                                            [
                                                0,
                                                -6.348
                                            ],
                                            [
                                                -4.46,
                                                -4.134
                                            ],
                                            [
                                                -3.418,
                                                0
                                            ],
                                            [
                                                -1.465,
                                                0.619
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.53,
                                                -2.083
                                            ],
                                            [
                                                2.164,
                                                -1.172
                                            ],
                                            [
                                                4.166,
                                                0
                                            ],
                                            [
                                                2.979,
                                                2.67
                                            ],
                                            [
                                                0,
                                                4.037
                                            ],
                                            [
                                                -2.507,
                                                3.32
                                            ],
                                            [
                                                -2.734,
                                                0
                                            ],
                                            [
                                                -4.07,
                                                -1.92
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                3.223,
                                                -17.822
                                            ],
                                            [
                                                10.571,
                                                -3.052
                                            ],
                                            [
                                                25.684,
                                                1.758
                                            ],
                                            [
                                                45.239,
                                                -5.811
                                            ],
                                            [
                                                51.367,
                                                -23.291
                                            ],
                                            [
                                                44.678,
                                                -39.014
                                            ],
                                            [
                                                28.369,
                                                -45.215
                                            ],
                                            [
                                                19.336,
                                                -43.604
                                            ],
                                            [
                                                14.697,
                                                -40.82
                                            ],
                                            [
                                                17.676,
                                                -60.254
                                            ],
                                            [
                                                47.412,
                                                -60.254
                                            ],
                                            [
                                                47.412,
                                                -68.75
                                            ],
                                            [
                                                11.086,
                                                -68.75
                                            ],
                                            [
                                                5.762,
                                                -31.104
                                            ],
                                            [
                                                13.379,
                                                -30.664
                                            ],
                                            [
                                                18.921,
                                                -35.547
                                            ],
                                            [
                                                26.709,
                                                -37.305
                                            ],
                                            [
                                                37.427,
                                                -33.301
                                            ],
                                            [
                                                41.895,
                                                -21.973
                                            ],
                                            [
                                                38.135,
                                                -10.938
                                            ],
                                            [
                                                26.562,
                                                -5.957
                                            ],
                                            [
                                                19.336,
                                                -7.422
                                            ],
                                            [
                                                12.354,
                                                -17.822
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "5",
                                "hd": false
                            }
                        ],
                        "nm": "5",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "4",
            "size": 30,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                10.645,
                                                -24.756
                                            ],
                                            [
                                                33.057,
                                                -56.515
                                            ],
                                            [
                                                33.057,
                                                -24.756
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "4",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                41.992,
                                                0
                                            ],
                                            [
                                                41.992,
                                                -17.09
                                            ],
                                            [
                                                52.295,
                                                -17.09
                                            ],
                                            [
                                                52.295,
                                                -24.756
                                            ],
                                            [
                                                41.992,
                                                -24.756
                                            ],
                                            [
                                                41.992,
                                                -70.117
                                            ],
                                            [
                                                34.57,
                                                -70.117
                                            ],
                                            [
                                                2.539,
                                                -25.684
                                            ],
                                            [
                                                2.539,
                                                -17.09
                                            ],
                                            [
                                                33.203,
                                                -17.09
                                            ],
                                            [
                                                33.203,
                                                0
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "4",
                                "hd": false
                            }
                        ],
                        "nm": "4",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "3",
            "size": 30,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -8.269,
                                                0
                                            ],
                                            [
                                                -4.395,
                                                4.199
                                            ],
                                            [
                                                0,
                                                6.673
                                            ],
                                            [
                                                2.067,
                                                2.881
                                            ],
                                            [
                                                3.678,
                                                1.009
                                            ],
                                            [
                                                -1.433,
                                                1.66
                                            ],
                                            [
                                                0,
                                                4.297
                                            ],
                                            [
                                                3.906,
                                                3.174
                                            ],
                                            [
                                                7.129,
                                                0
                                            ],
                                            [
                                                3.58,
                                                -6.51
                                            ],
                                            [
                                                0,
                                                -5.208
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                2.148
                                            ],
                                            [
                                                -5.859,
                                                0
                                            ],
                                            [
                                                -2.474,
                                                -1.888
                                            ],
                                            [
                                                0,
                                                -3.483
                                            ],
                                            [
                                                3.711,
                                                -1.823
                                            ],
                                            [
                                                3.288,
                                                0
                                            ],
                                            [
                                                0.618,
                                                0.033
                                            ],
                                            [
                                                0.911,
                                                0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.603,
                                                0.017
                                            ],
                                            [
                                                -0.586,
                                                0
                                            ],
                                            [
                                                -2.946,
                                                -1.92
                                            ],
                                            [
                                                0,
                                                -4.817
                                            ],
                                            [
                                                2.799,
                                                -2.473
                                            ],
                                            [
                                                4.622,
                                                0
                                            ],
                                            [
                                                2.278,
                                                3.679
                                            ],
                                            [
                                                0.391,
                                                4.525
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -3.728,
                                                -4.541
                                            ]
                                        ],
                                        "o": [
                                            [
                                                8.073,
                                                0
                                            ],
                                            [
                                                4.395,
                                                -4.199
                                            ],
                                            [
                                                0,
                                                -4.166
                                            ],
                                            [
                                                -2.068,
                                                -2.881
                                            ],
                                            [
                                                2.278,
                                                -0.944
                                            ],
                                            [
                                                2.311,
                                                -2.669
                                            ],
                                            [
                                                0,
                                                -6.022
                                            ],
                                            [
                                                -3.906,
                                                -3.174
                                            ],
                                            [
                                                -9.017,
                                                0
                                            ],
                                            [
                                                -2.084,
                                                3.646
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.163,
                                                -3.971
                                            ],
                                            [
                                                2.246,
                                                -3.906
                                            ],
                                            [
                                                3.288,
                                                0
                                            ],
                                            [
                                                2.473,
                                                1.888
                                            ],
                                            [
                                                0,
                                                4.688
                                            ],
                                            [
                                                -2.116,
                                                1.042
                                            ],
                                            [
                                                -0.716,
                                                0
                                            ],
                                            [
                                                -0.619,
                                                -0.032
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.618,
                                                -0.032
                                            ],
                                            [
                                                0.602,
                                                -0.016
                                            ],
                                            [
                                                5.241,
                                                0
                                            ],
                                            [
                                                2.946,
                                                1.921
                                            ],
                                            [
                                                0,
                                                3.906
                                            ],
                                            [
                                                -2.8,
                                                2.474
                                            ],
                                            [
                                                -5.957,
                                                0
                                            ],
                                            [
                                                -1.302,
                                                -2.051
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                6.511
                                            ],
                                            [
                                                3.727,
                                                4.541
                                            ]
                                        ],
                                        "v": [
                                            [
                                                25.977,
                                                1.904
                                            ],
                                            [
                                                44.678,
                                                -4.395
                                            ],
                                            [
                                                51.27,
                                                -20.703
                                            ],
                                            [
                                                48.169,
                                                -31.274
                                            ],
                                            [
                                                39.551,
                                                -37.109
                                            ],
                                            [
                                                45.117,
                                                -41.016
                                            ],
                                            [
                                                48.584,
                                                -51.465
                                            ],
                                            [
                                                42.725,
                                                -65.259
                                            ],
                                            [
                                                26.172,
                                                -70.02
                                            ],
                                            [
                                                7.275,
                                                -60.254
                                            ],
                                            [
                                                4.15,
                                                -46.973
                                            ],
                                            [
                                                12.842,
                                                -46.973
                                            ],
                                            [
                                                14.941,
                                                -56.152
                                            ],
                                            [
                                                27.1,
                                                -62.012
                                            ],
                                            [
                                                35.742,
                                                -59.18
                                            ],
                                            [
                                                39.453,
                                                -51.123
                                            ],
                                            [
                                                33.887,
                                                -41.357
                                            ],
                                            [
                                                25.781,
                                                -39.795
                                            ],
                                            [
                                                23.779,
                                                -39.844
                                            ],
                                            [
                                                21.484,
                                                -40.039
                                            ],
                                            [
                                                21.484,
                                                -32.275
                                            ],
                                            [
                                                23.315,
                                                -32.349
                                            ],
                                            [
                                                25.098,
                                                -32.373
                                            ],
                                            [
                                                37.378,
                                                -29.492
                                            ],
                                            [
                                                41.797,
                                                -19.385
                                            ],
                                            [
                                                37.598,
                                                -9.814
                                            ],
                                            [
                                                26.465,
                                                -6.104
                                            ],
                                            [
                                                14.111,
                                                -11.621
                                            ],
                                            [
                                                11.572,
                                                -21.484
                                            ],
                                            [
                                                2.393,
                                                -21.484
                                            ],
                                            [
                                                7.983,
                                                -4.907
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "3",
                                "hd": false
                            }
                        ],
                        "nm": "3",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "2",
            "size": 30,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.325,
                                                -6.022
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -3.255,
                                                2.832
                                            ],
                                            [
                                                -3.191,
                                                1.758
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.279,
                                                2.312
                                            ],
                                            [
                                                0,
                                                5.599
                                            ],
                                            [
                                                3.694,
                                                4.33
                                            ],
                                            [
                                                8.008,
                                                0
                                            ],
                                            [
                                                3.809,
                                                -6.77
                                            ],
                                            [
                                                0.098,
                                                -6.087
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                2.449
                                            ],
                                            [
                                                -6.023,
                                                0
                                            ],
                                            [
                                                -2.441,
                                                -2.397
                                            ],
                                            [
                                                0,
                                                -4.077
                                            ],
                                            [
                                                2.669,
                                                -2.707
                                            ],
                                            [
                                                4.199,
                                                -2.441
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.164,
                                                -4.459
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.846,
                                                -3.45
                                            ],
                                            [
                                                1.823,
                                                -1.595
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                5.891,
                                                -3.288
                                            ],
                                            [
                                                3.938,
                                                -3.971
                                            ],
                                            [
                                                0,
                                                -5.305
                                            ],
                                            [
                                                -3.695,
                                                -4.329
                                            ],
                                            [
                                                -9.636,
                                                0
                                            ],
                                            [
                                                -2.148,
                                                3.841
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.13,
                                                -4.341
                                            ],
                                            [
                                                2.311,
                                                -4.57
                                            ],
                                            [
                                                4.069,
                                                0
                                            ],
                                            [
                                                2.441,
                                                2.397
                                            ],
                                            [
                                                0,
                                                3.49
                                            ],
                                            [
                                                -1.693,
                                                1.729
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -6.283,
                                                3.646
                                            ],
                                            [
                                                -2.165,
                                                4.46
                                            ]
                                        ],
                                        "v": [
                                            [
                                                3.125,
                                                0
                                            ],
                                            [
                                                51.123,
                                                0
                                            ],
                                            [
                                                51.123,
                                                -8.301
                                            ],
                                            [
                                                12.939,
                                                -8.301
                                            ],
                                            [
                                                19.092,
                                                -17.725
                                            ],
                                            [
                                                26.611,
                                                -22.754
                                            ],
                                            [
                                                33.301,
                                                -26.465
                                            ],
                                            [
                                                45.557,
                                                -34.863
                                            ],
                                            [
                                                51.465,
                                                -49.219
                                            ],
                                            [
                                                45.923,
                                                -63.672
                                            ],
                                            [
                                                28.369,
                                                -70.166
                                            ],
                                            [
                                                8.203,
                                                -60.01
                                            ],
                                            [
                                                4.834,
                                                -45.117
                                            ],
                                            [
                                                13.77,
                                                -45.117
                                            ],
                                            [
                                                15.82,
                                                -55.302
                                            ],
                                            [
                                                28.32,
                                                -62.158
                                            ],
                                            [
                                                38.086,
                                                -58.562
                                            ],
                                            [
                                                41.748,
                                                -48.851
                                            ],
                                            [
                                                37.744,
                                                -39.556
                                            ],
                                            [
                                                28.906,
                                                -33.301
                                            ],
                                            [
                                                19.531,
                                                -27.881
                                            ],
                                            [
                                                6.86,
                                                -15.723
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "2",
                                "hd": false
                            }
                        ],
                        "nm": "2",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "1",
            "size": 30,
            "style": "Bold",
            "w": 48.24,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.506,
                                                -1.448
                                            ],
                                            [
                                                6.348,
                                                -0.618
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                5.404
                                            ],
                                            [
                                                -2.507,
                                                1.449
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                26.025,
                                                -49.512
                                            ],
                                            [
                                                26.025,
                                                0
                                            ],
                                            [
                                                35.4,
                                                0
                                            ],
                                            [
                                                35.4,
                                                -69.629
                                            ],
                                            [
                                                28.467,
                                                -69.629
                                            ],
                                            [
                                                22.852,
                                                -59.351
                                            ],
                                            [
                                                9.57,
                                                -56.25
                                            ],
                                            [
                                                9.57,
                                                -49.512
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "1",
                                "hd": false
                            }
                        ],
                        "nm": "1",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "C",
            "size": 20,
            "style": "Bold",
            "w": 72.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                8.976,
                                                0
                                            ],
                                            [
                                                5.984,
                                                -7.361
                                            ],
                                            [
                                                0,
                                                -10.586
                                            ],
                                            [
                                                -7.368,
                                                -6.677
                                            ],
                                            [
                                                -8.237,
                                                0
                                            ],
                                            [
                                                -5.631,
                                                6.152
                                            ],
                                            [
                                                -0.837,
                                                7.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.95,
                                                -2.897
                                            ],
                                            [
                                                7.035,
                                                0
                                            ],
                                            [
                                                3.853,
                                                5.229
                                            ],
                                            [
                                                0,
                                                8.406
                                            ],
                                            [
                                                -4.268,
                                                4.968
                                            ],
                                            [
                                                -6.937,
                                                0
                                            ],
                                            [
                                                -3.149,
                                                -2.702
                                            ],
                                            [
                                                -1.055,
                                                -4.622
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.955,
                                                4.785
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -10.521,
                                                0
                                            ],
                                            [
                                                -5.534,
                                                6.776
                                            ],
                                            [
                                                0,
                                                13.942
                                            ],
                                            [
                                                5.598,
                                                5.049
                                            ],
                                            [
                                                9.557,
                                                0
                                            ],
                                            [
                                                4.697,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.96,
                                                4.72
                                            ],
                                            [
                                                -3.677,
                                                5.502
                                            ],
                                            [
                                                -7.642,
                                                0
                                            ],
                                            [
                                                -3.854,
                                                -5.229
                                            ],
                                            [
                                                0,
                                                -10.262
                                            ],
                                            [
                                                4.268,
                                                -4.968
                                            ],
                                            [
                                                5.69,
                                                0
                                            ],
                                            [
                                                3.149,
                                                2.702
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.547,
                                                -6.087
                                            ],
                                            [
                                                -4.955,
                                                -4.785
                                            ]
                                        ],
                                        "v": [
                                            [
                                                37.453,
                                                -73.682
                                            ],
                                            [
                                                12.695,
                                                -62.64
                                            ],
                                            [
                                                4.395,
                                                -36.597
                                            ],
                                            [
                                                15.447,
                                                -5.669
                                            ],
                                            [
                                                36.201,
                                                1.904
                                            ],
                                            [
                                                58.984,
                                                -7.324
                                            ],
                                            [
                                                67.285,
                                                -26.318
                                            ],
                                            [
                                                57.812,
                                                -26.318
                                            ],
                                            [
                                                53.448,
                                                -14.893
                                            ],
                                            [
                                                37.379,
                                                -6.641
                                            ],
                                            [
                                                20.135,
                                                -14.484
                                            ],
                                            [
                                                14.355,
                                                -34.936
                                            ],
                                            [
                                                20.757,
                                                -57.782
                                            ],
                                            [
                                                37.564,
                                                -65.234
                                            ],
                                            [
                                                50.823,
                                                -61.182
                                            ],
                                            [
                                                57.129,
                                                -50.195
                                            ],
                                            [
                                                66.602,
                                                -50.195
                                            ],
                                            [
                                                58.349,
                                                -66.504
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "C",
                                "hd": false
                            }
                        ],
                        "nm": "C",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "h",
            "size": 20,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.962,
                                                2.507
                                            ],
                                            [
                                                -3.646,
                                                0
                                            ],
                                            [
                                                -1.595,
                                                -2.864
                                            ],
                                            [
                                                0,
                                                -3.841
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.465,
                                                2.898
                                            ],
                                            [
                                                7.584,
                                                0
                                            ],
                                            [
                                                2.832,
                                                -1.855
                                            ],
                                            [
                                                2.083,
                                                -2.637
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -6.966
                                            ],
                                            [
                                                2.962,
                                                -2.506
                                            ],
                                            [
                                                4.395,
                                                0
                                            ],
                                            [
                                                0.977,
                                                1.791
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.143
                                            ],
                                            [
                                                -2.702,
                                                -5.305
                                            ],
                                            [
                                                -4.232,
                                                0
                                            ],
                                            [
                                                -1.66,
                                                1.074
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.234,
                                                0
                                            ],
                                            [
                                                15.234,
                                                -27.734
                                            ],
                                            [
                                                19.678,
                                                -41.943
                                            ],
                                            [
                                                29.59,
                                                -45.703
                                            ],
                                            [
                                                38.574,
                                                -41.406
                                            ],
                                            [
                                                40.039,
                                                -32.959
                                            ],
                                            [
                                                40.039,
                                                0
                                            ],
                                            [
                                                49.072,
                                                0
                                            ],
                                            [
                                                49.072,
                                                -33.545
                                            ],
                                            [
                                                46.875,
                                                -45.605
                                            ],
                                            [
                                                31.445,
                                                -53.564
                                            ],
                                            [
                                                20.85,
                                                -50.781
                                            ],
                                            [
                                                15.234,
                                                -45.215
                                            ],
                                            [
                                                15.234,
                                                -71.973
                                            ],
                                            [
                                                6.445,
                                                -71.973
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "h",
                                "hd": false
                            }
                        ],
                        "nm": "h",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "e",
            "size": 20,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                3.688,
                                                0
                                            ],
                                            [
                                                4.529,
                                                -5.241
                                            ],
                                            [
                                                0,
                                                -8.398
                                            ],
                                            [
                                                -4.497,
                                                -4.736
                                            ],
                                            [
                                                -6.47,
                                                0
                                            ],
                                            [
                                                -2.1,
                                                0.52
                                            ],
                                            [
                                                -2.65,
                                                2.605
                                            ],
                                            [
                                                -1.286,
                                                2.361
                                            ],
                                            [
                                                -0.228,
                                                1.921
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.518,
                                                -1.765
                                            ],
                                            [
                                                4.554,
                                                0
                                            ],
                                            [
                                                2.325,
                                                3.215
                                            ],
                                            [
                                                0.162,
                                                5.321
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.517,
                                                2.409
                                            ],
                                            [
                                                1.747,
                                                2.637
                                            ],
                                            [
                                                3.461,
                                                1.742
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.312,
                                                0
                                            ],
                                            [
                                                -4.53,
                                                5.241
                                            ],
                                            [
                                                0,
                                                8.529
                                            ],
                                            [
                                                4.497,
                                                4.736
                                            ],
                                            [
                                                2.649,
                                                0
                                            ],
                                            [
                                                3.909,
                                                -0.912
                                            ],
                                            [
                                                1.582,
                                                -1.497
                                            ],
                                            [
                                                1.286,
                                                -2.36
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.633,
                                                2.322
                                            ],
                                            [
                                                -2.713,
                                                3.041
                                            ],
                                            [
                                                -4.877,
                                                0
                                            ],
                                            [
                                                -2.325,
                                                -3.215
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.273
                                            ],
                                            [
                                                -0.583,
                                                -3.516
                                            ],
                                            [
                                                -1.812,
                                                -2.766
                                            ],
                                            [
                                                -3.462,
                                                -1.741
                                            ]
                                        ],
                                        "v": [
                                            [
                                                28.022,
                                                -53.467
                                            ],
                                            [
                                                10.261,
                                                -45.605
                                            ],
                                            [
                                                3.467,
                                                -25.146
                                            ],
                                            [
                                                10.211,
                                                -5.249
                                            ],
                                            [
                                                26.661,
                                                1.855
                                            ],
                                            [
                                                33.784,
                                                1.074
                                            ],
                                            [
                                                43.622,
                                                -4.199
                                            ],
                                            [
                                                47.925,
                                                -9.985
                                            ],
                                            [
                                                50.195,
                                                -16.406
                                            ],
                                            [
                                                41.553,
                                                -16.406
                                            ],
                                            [
                                                38.326,
                                                -10.275
                                            ],
                                            [
                                                27.425,
                                                -5.713
                                            ],
                                            [
                                                16.621,
                                                -10.535
                                            ],
                                            [
                                                12.891,
                                                -23.34
                                            ],
                                            [
                                                50.928,
                                                -23.34
                                            ],
                                            [
                                                50.151,
                                                -34.863
                                            ],
                                            [
                                                46.657,
                                                -44.092
                                            ],
                                            [
                                                38.747,
                                                -50.854
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "e",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.711,
                                                2.914
                                            ],
                                            [
                                                -4.002,
                                                0
                                            ],
                                            [
                                                -2.389,
                                                -4.231
                                            ],
                                            [
                                                -0.356,
                                                -3.809
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0.161,
                                                -4.492
                                            ],
                                            [
                                                2.711,
                                                -2.913
                                            ],
                                            [
                                                5.584,
                                                0
                                            ],
                                            [
                                                1.291,
                                                2.279
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                13.086,
                                                -30.322
                                            ],
                                            [
                                                17.395,
                                                -41.431
                                            ],
                                            [
                                                27.466,
                                                -45.801
                                            ],
                                            [
                                                39.425,
                                                -39.453
                                            ],
                                            [
                                                41.895,
                                                -30.322
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "e",
                                "hd": false
                            }
                        ],
                        "nm": "e",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "c",
            "size": 20,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.891,
                                                0
                                            ],
                                            [
                                                4.459,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                -9.18
                                            ],
                                            [
                                                -4.07,
                                                -4.996
                                            ],
                                            [
                                                -6.836,
                                                0
                                            ],
                                            [
                                                -3.906,
                                                3.207
                                            ],
                                            [
                                                -0.977,
                                                6.934
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.132,
                                                -2.221
                                            ],
                                            [
                                                3.645,
                                                0
                                            ],
                                            [
                                                2.213,
                                                3.577
                                            ],
                                            [
                                                0,
                                                5.235
                                            ],
                                            [
                                                -1.562,
                                                3.544
                                            ],
                                            [
                                                -5.599,
                                                0
                                            ],
                                            [
                                                -1.855,
                                                -2.132
                                            ],
                                            [
                                                -0.521,
                                                -3.223
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.694,
                                                2.865
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -6.902,
                                                0
                                            ],
                                            [
                                                -4.46,
                                                5.111
                                            ],
                                            [
                                                0,
                                                7.487
                                            ],
                                            [
                                                4.069,
                                                4.997
                                            ],
                                            [
                                                6.087,
                                                0
                                            ],
                                            [
                                                3.906,
                                                -3.206
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.814,
                                                3.859
                                            ],
                                            [
                                                -2.132,
                                                2.222
                                            ],
                                            [
                                                -4.753,
                                                0
                                            ],
                                            [
                                                -2.214,
                                                -3.577
                                            ],
                                            [
                                                0,
                                                -5.202
                                            ],
                                            [
                                                2.409,
                                                -5.462
                                            ],
                                            [
                                                4.102,
                                                0
                                            ],
                                            [
                                                1.855,
                                                2.132
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.749,
                                                -6.998
                                            ],
                                            [
                                                -3.695,
                                                -2.864
                                            ]
                                        ],
                                        "v": [
                                            [
                                                26.611,
                                                -53.809
                                            ],
                                            [
                                                9.57,
                                                -46.143
                                            ],
                                            [
                                                2.881,
                                                -24.707
                                            ],
                                            [
                                                8.984,
                                                -5.981
                                            ],
                                            [
                                                25.342,
                                                1.514
                                            ],
                                            [
                                                40.332,
                                                -3.296
                                            ],
                                            [
                                                47.656,
                                                -18.506
                                            ],
                                            [
                                                39.111,
                                                -18.506
                                            ],
                                            [
                                                34.692,
                                                -9.386
                                            ],
                                            [
                                                26.025,
                                                -6.055
                                            ],
                                            [
                                                15.576,
                                                -11.419
                                            ],
                                            [
                                                12.256,
                                                -24.635
                                            ],
                                            [
                                                14.6,
                                                -37.754
                                            ],
                                            [
                                                26.611,
                                                -45.947
                                            ],
                                            [
                                                35.547,
                                                -42.749
                                            ],
                                            [
                                                39.111,
                                                -34.717
                                            ],
                                            [
                                                47.656,
                                                -34.717
                                            ],
                                            [
                                                40.991,
                                                -49.512
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "c",
                                "hd": false
                            }
                        ],
                        "nm": "c",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "k",
            "size": 20,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.25,
                                                0
                                            ],
                                            [
                                                14.697,
                                                0
                                            ],
                                            [
                                                14.697,
                                                -19.629
                                            ],
                                            [
                                                21.914,
                                                -26.611
                                            ],
                                            [
                                                38.362,
                                                0
                                            ],
                                            [
                                                49.59,
                                                0
                                            ],
                                            [
                                                28.364,
                                                -32.851
                                            ],
                                            [
                                                48.463,
                                                -52.295
                                            ],
                                            [
                                                37.235,
                                                -52.295
                                            ],
                                            [
                                                14.697,
                                                -30.083
                                            ],
                                            [
                                                14.697,
                                                -71.729
                                            ],
                                            [
                                                6.25,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "k",
                                "hd": false
                            }
                        ],
                        "nm": "k",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": " ",
            "size": 20,
            "style": "Bold",
            "w": 27.78,
            "data": {},
            "fFamily": "Rubik"
        },
        {
            "ch": "o",
            "size": 20,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.761,
                                                0
                                            ],
                                            [
                                                2.414,
                                                3.706
                                            ],
                                            [
                                                0,
                                                5.56
                                            ],
                                            [
                                                -2.414,
                                                4.097
                                            ],
                                            [
                                                -5.312,
                                                0
                                            ],
                                            [
                                                -2.446,
                                                -4.812
                                            ],
                                            [
                                                0,
                                                -4.877
                                            ],
                                            [
                                                2.141,
                                                -4.405
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -5.247,
                                                0
                                            ],
                                            [
                                                -2.414,
                                                -3.706
                                            ],
                                            [
                                                0,
                                                -5.787
                                            ],
                                            [
                                                2.414,
                                                -4.097
                                            ],
                                            [
                                                5.987,
                                                0
                                            ],
                                            [
                                                1.545,
                                                3.056
                                            ],
                                            [
                                                0,
                                                5.397
                                            ],
                                            [
                                                -2.141,
                                                4.405
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.026,
                                                -5.713
                                            ],
                                            [
                                                15.535,
                                                -11.272
                                            ],
                                            [
                                                11.914,
                                                -25.172
                                            ],
                                            [
                                                15.535,
                                                -39.997
                                            ],
                                            [
                                                27.122,
                                                -46.143
                                            ],
                                            [
                                                39.772,
                                                -38.924
                                            ],
                                            [
                                                42.09,
                                                -27.025
                                            ],
                                            [
                                                38.879,
                                                -12.321
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                6.691,
                                                0
                                            ],
                                            [
                                                4.428,
                                                -5.203
                                            ],
                                            [
                                                0,
                                                -8.781
                                            ],
                                            [
                                                -4.202,
                                                -4.862
                                            ],
                                            [
                                                -7.144,
                                                0
                                            ],
                                            [
                                                -4.073,
                                                5.496
                                            ],
                                            [
                                                0,
                                                8.424
                                            ],
                                            [
                                                4.622,
                                                4.488
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.467,
                                                0
                                            ],
                                            [
                                                -4.428,
                                                5.204
                                            ],
                                            [
                                                0,
                                                8.196
                                            ],
                                            [
                                                4.202,
                                                4.862
                                            ],
                                            [
                                                8.566,
                                                0
                                            ],
                                            [
                                                4.073,
                                                -5.496
                                            ],
                                            [
                                                0,
                                                -8.716
                                            ],
                                            [
                                                -4.623,
                                                -4.488
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.366,
                                                -53.809
                                            ],
                                            [
                                                9.523,
                                                -46.003
                                            ],
                                            [
                                                2.881,
                                                -25.025
                                            ],
                                            [
                                                9.184,
                                                -5.437
                                            ],
                                            [
                                                26.202,
                                                1.855
                                            ],
                                            [
                                                45.16,
                                                -6.389
                                            ],
                                            [
                                                51.27,
                                                -27.27
                                            ],
                                            [
                                                44.336,
                                                -47.076
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            }
                        ],
                        "nm": "o",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "u",
            "size": 20,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.437,
                                                -2.669
                                            ],
                                            [
                                                -7.315,
                                                0
                                            ],
                                            [
                                                -3.29,
                                                2.734
                                            ],
                                            [
                                                -1.119,
                                                1.986
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.173,
                                                -2.93
                                            ],
                                            [
                                                5.959,
                                                0
                                            ],
                                            [
                                                1.521,
                                                3.125
                                            ],
                                            [
                                                0,
                                                2.67
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                4.46
                                            ],
                                            [
                                                2.65,
                                                4.98
                                            ],
                                            [
                                                4.695,
                                                0
                                            ],
                                            [
                                                1.661,
                                                -1.367
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.111
                                            ],
                                            [
                                                -2.156,
                                                5.469
                                            ],
                                            [
                                                -4.153,
                                                0
                                            ],
                                            [
                                                -0.825,
                                                -1.692
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.25,
                                                -52.295
                                            ],
                                            [
                                                6.25,
                                                -16.748
                                            ],
                                            [
                                                8.406,
                                                -6.055
                                            ],
                                            [
                                                23.354,
                                                1.416
                                            ],
                                            [
                                                35.332,
                                                -2.686
                                            ],
                                            [
                                                39.502,
                                                -7.715
                                            ],
                                            [
                                                39.404,
                                                0
                                            ],
                                            [
                                                47.705,
                                                0
                                            ],
                                            [
                                                47.705,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -26.611
                                            ],
                                            [
                                                37.157,
                                                -14.551
                                            ],
                                            [
                                                24.983,
                                                -6.348
                                            ],
                                            [
                                                16.471,
                                                -11.035
                                            ],
                                            [
                                                15.234,
                                                -17.578
                                            ],
                                            [
                                                15.234,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "u",
                                "hd": false
                            }
                        ],
                        "nm": "u",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "t",
            "size": 20,
            "style": "Bold",
            "w": 27.78,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.465,
                                                -2.1
                                            ],
                                            [
                                                -4.102,
                                                0
                                            ],
                                            [
                                                -1.091,
                                                0.13
                                            ],
                                            [
                                                -1.009,
                                                0.293
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.488,
                                                -0.021
                                            ],
                                            [
                                                0.423,
                                                0
                                            ],
                                            [
                                                0.684,
                                                0.359
                                            ],
                                            [
                                                0,
                                                1.823
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                3.353
                                            ],
                                            [
                                                1.465,
                                                2.1
                                            ],
                                            [
                                                1.27,
                                                0
                                            ],
                                            [
                                                1.09,
                                                -0.13
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.652,
                                                0.087
                                            ],
                                            [
                                                -0.488,
                                                0.021
                                            ],
                                            [
                                                -1.595,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                -0.618
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.203,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -10.498
                                            ],
                                            [
                                                10.4,
                                                -2.319
                                            ],
                                            [
                                                18.75,
                                                0.83
                                            ],
                                            [
                                                22.29,
                                                0.635
                                            ],
                                            [
                                                25.439,
                                                0
                                            ],
                                            [
                                                25.439,
                                                -6.982
                                            ],
                                            [
                                                23.73,
                                                -6.819
                                            ],
                                            [
                                                22.363,
                                                -6.787
                                            ],
                                            [
                                                18.945,
                                                -7.324
                                            ],
                                            [
                                                17.09,
                                                -10.986
                                            ],
                                            [
                                                17.09,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -66.895
                                            ],
                                            [
                                                8.203,
                                                -66.895
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "t",
                                "hd": false
                            }
                        ],
                        "nm": "t",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "i",
            "size": 20,
            "style": "Bold",
            "w": 22.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.381,
                                                0
                                            ],
                                            [
                                                15.381,
                                                -52.051
                                            ],
                                            [
                                                6.445,
                                                -52.051
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "i",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                -61.768
                                            ],
                                            [
                                                15.381,
                                                -61.768
                                            ],
                                            [
                                                15.381,
                                                -71.729
                                            ],
                                            [
                                                6.445,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "i",
                                "hd": false
                            }
                        ],
                        "nm": "i",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "s",
            "size": 20,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -3.279,
                                                -3.532
                                            ],
                                            [
                                                -7.877,
                                                0
                                            ],
                                            [
                                                -3.576,
                                                3.385
                                            ],
                                            [
                                                0,
                                                4.655
                                            ],
                                            [
                                                3.418,
                                                2.246
                                            ],
                                            [
                                                6.434,
                                                1.53
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.326,
                                                0.811
                                            ],
                                            [
                                                0,
                                                2.271
                                            ],
                                            [
                                                -1.749,
                                                1.265
                                            ],
                                            [
                                                -3.399,
                                                0
                                            ],
                                            [
                                                -2.066,
                                                -2.298
                                            ],
                                            [
                                                -0.167,
                                                -1.974
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.21,
                                                2.93
                                            ],
                                            [
                                                7.983,
                                                0
                                            ],
                                            [
                                                3.612,
                                                -3.059
                                            ],
                                            [
                                                0,
                                                -4.948
                                            ],
                                            [
                                                -3.838,
                                                -2.376
                                            ],
                                            [
                                                -4.532,
                                                -1.106
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.291,
                                                -0.716
                                            ],
                                            [
                                                0,
                                                -2.246
                                            ],
                                            [
                                                2.525,
                                                -1.416
                                            ],
                                            [
                                                3.322,
                                                0
                                            ],
                                            [
                                                2.259,
                                                2.832
                                            ],
                                            [
                                                0.266,
                                                2.93
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0.23,
                                                5.209
                                            ],
                                            [
                                                3.279,
                                                3.532
                                            ],
                                            [
                                                7.317,
                                                0
                                            ],
                                            [
                                                3.576,
                                                -3.385
                                            ],
                                            [
                                                0,
                                                -4.817
                                            ],
                                            [
                                                -2.19,
                                                -1.432
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.587,
                                                -0.618
                                            ],
                                            [
                                                -2.299,
                                                -1.363
                                            ],
                                            [
                                                0,
                                                -2.075
                                            ],
                                            [
                                                1.749,
                                                -1.265
                                            ],
                                            [
                                                5.098,
                                                0
                                            ],
                                            [
                                                1.266,
                                                1.619
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.065,
                                                -3.385
                                            ],
                                            [
                                                -3.529,
                                                -4.622
                                            ],
                                            [
                                                -6.104,
                                                0
                                            ],
                                            [
                                                -3.613,
                                                3.06
                                            ],
                                            [
                                                0,
                                                4.199
                                            ],
                                            [
                                                2.15,
                                                1.367
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.804,
                                                0.945
                                            ],
                                            [
                                                2.018,
                                                1.172
                                            ],
                                            [
                                                0,
                                                2.962
                                            ],
                                            [
                                                -2.525,
                                                1.416
                                            ],
                                            [
                                                -5.582,
                                                0
                                            ],
                                            [
                                                -1.23,
                                                -1.562
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                3.223,
                                                -16.406
                                            ],
                                            [
                                                8.488,
                                                -3.296
                                            ],
                                            [
                                                25.221,
                                                2.002
                                            ],
                                            [
                                                41.56,
                                                -3.076
                                            ],
                                            [
                                                46.924,
                                                -15.137
                                            ],
                                            [
                                                41.797,
                                                -25.732
                                            ],
                                            [
                                                28.862,
                                                -30.176
                                            ],
                                            [
                                                22.942,
                                                -31.592
                                            ],
                                            [
                                                17.072,
                                                -33.736
                                            ],
                                            [
                                                13.623,
                                                -39.185
                                            ],
                                            [
                                                16.247,
                                                -44.196
                                            ],
                                            [
                                                23.969,
                                                -46.094
                                            ],
                                            [
                                                34.716,
                                                -42.646
                                            ],
                                            [
                                                36.865,
                                                -37.256
                                            ],
                                            [
                                                45.166,
                                                -37.256
                                            ],
                                            [
                                                41.948,
                                                -46.729
                                            ],
                                            [
                                                24.679,
                                                -53.662
                                            ],
                                            [
                                                10.106,
                                                -49.072
                                            ],
                                            [
                                                4.688,
                                                -37.061
                                            ],
                                            [
                                                10.444,
                                                -27.197
                                            ],
                                            [
                                                20.467,
                                                -23.486
                                            ],
                                            [
                                                27.563,
                                                -21.729
                                            ],
                                            [
                                                35.205,
                                                -19.238
                                            ],
                                            [
                                                38.232,
                                                -14.111
                                            ],
                                            [
                                                34.444,
                                                -7.544
                                            ],
                                            [
                                                25.674,
                                                -5.42
                                            ],
                                            [
                                                13.912,
                                                -9.668
                                            ],
                                            [
                                                11.67,
                                                -16.406
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "s",
                                "hd": false
                            }
                        ],
                        "nm": "s",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "p",
            "size": 20,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                4.115,
                                                0
                                            ],
                                            [
                                                2.286,
                                                5.404
                                            ],
                                            [
                                                0,
                                                4.037
                                            ],
                                            [
                                                -1.208,
                                                3.255
                                            ],
                                            [
                                                -6.108,
                                                0
                                            ],
                                            [
                                                -2.287,
                                                -5.762
                                            ],
                                            [
                                                0,
                                                -4.166
                                            ],
                                            [
                                                2.727,
                                                -3.434
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -6.076,
                                                0
                                            ],
                                            [
                                                -1.208,
                                                -2.832
                                            ],
                                            [
                                                0,
                                                -5.013
                                            ],
                                            [
                                                2.254,
                                                -6.087
                                            ],
                                            [
                                                6.075,
                                                0
                                            ],
                                            [
                                                1.208,
                                                2.995
                                            ],
                                            [
                                                0,
                                                6.836
                                            ],
                                            [
                                                -2.728,
                                                3.435
                                            ]
                                        ],
                                        "v": [
                                            [
                                                28.516,
                                                -5.908
                                            ],
                                            [
                                                15.973,
                                                -14.014
                                            ],
                                            [
                                                14.16,
                                                -24.316
                                            ],
                                            [
                                                15.973,
                                                -36.719
                                            ],
                                            [
                                                28.516,
                                                -45.85
                                            ],
                                            [
                                                41.058,
                                                -37.207
                                            ],
                                            [
                                                42.871,
                                                -26.465
                                            ],
                                            [
                                                38.78,
                                                -11.06
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "p",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.569,
                                                -0.977
                                            ],
                                            [
                                                -3.954,
                                                0
                                            ],
                                            [
                                                -3.791,
                                                3.092
                                            ],
                                            [
                                                0,
                                                11.394
                                            ],
                                            [
                                                4.144,
                                                4.541
                                            ],
                                            [
                                                5.94,
                                                0
                                            ],
                                            [
                                                2.969,
                                                -1.953
                                            ],
                                            [
                                                1.762,
                                                -2.376
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.928,
                                                2.377
                                            ],
                                            [
                                                2.679,
                                                1.725
                                            ],
                                            [
                                                5.032,
                                                0
                                            ],
                                            [
                                                5.98,
                                                -4.883
                                            ],
                                            [
                                                0,
                                                -8.43
                                            ],
                                            [
                                                -4.145,
                                                -4.541
                                            ],
                                            [
                                                -4.015,
                                                0
                                            ],
                                            [
                                                -2.089,
                                                1.302
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                5.713,
                                                20.85
                                            ],
                                            [
                                                14.502,
                                                20.85
                                            ],
                                            [
                                                14.502,
                                                -5.859
                                            ],
                                            [
                                                19.747,
                                                -0.83
                                            ],
                                            [
                                                29.698,
                                                1.758
                                            ],
                                            [
                                                42.934,
                                                -2.881
                                            ],
                                            [
                                                51.904,
                                                -27.295
                                            ],
                                            [
                                                45.687,
                                                -46.753
                                            ],
                                            [
                                                30.56,
                                                -53.564
                                            ],
                                            [
                                                20.084,
                                                -50.635
                                            ],
                                            [
                                                14.307,
                                                -45.117
                                            ],
                                            [
                                                14.307,
                                                -52.051
                                            ],
                                            [
                                                5.713,
                                                -52.051
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "p",
                                "hd": false
                            }
                        ],
                        "nm": "p",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "!",
            "size": 20,
            "style": "Bold",
            "w": 27.78,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                11.572,
                                                -48.145
                                            ],
                                            [
                                                14.111,
                                                -17.09
                                            ],
                                            [
                                                19.092,
                                                -17.09
                                            ],
                                            [
                                                21.484,
                                                -48.145
                                            ],
                                            [
                                                21.484,
                                                -71.729
                                            ],
                                            [
                                                11.572,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "!",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                11.572,
                                                0
                                            ],
                                            [
                                                21.289,
                                                0
                                            ],
                                            [
                                                21.289,
                                                -10.156
                                            ],
                                            [
                                                11.572,
                                                -10.156
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "!",
                                "hd": false
                            }
                        ],
                        "nm": "!",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "€",
            "size": 20,
            "style": "Bold",
            "w": 74.41,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.492,
                                                -4.752
                                            ],
                                            [
                                                -6.999,
                                                0
                                            ],
                                            [
                                                -4.785,
                                                6.152
                                            ],
                                            [
                                                -0.716,
                                                7.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.66,
                                                -2.897
                                            ],
                                            [
                                                6.022,
                                                0
                                            ],
                                            [
                                                3.288,
                                                5.209
                                            ],
                                            [
                                                0.781,
                                                4.265
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.847
                                            ],
                                            [
                                                -0.098,
                                                1.66
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.019,
                                                2.734
                                            ],
                                            [
                                                -5.957,
                                                0
                                            ],
                                            [
                                                -2.702,
                                                -2.702
                                            ],
                                            [
                                                -0.879,
                                                -4.622
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.215,
                                                4.785
                                            ],
                                            [
                                                7.617,
                                                0
                                            ],
                                            [
                                                5.078,
                                                -7.356
                                            ],
                                            [
                                                1.106,
                                                -5.957
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -1.204
                                            ],
                                            [
                                                -0.066,
                                                -1.334
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                1.27,
                                                8.496
                                            ],
                                            [
                                                4.752,
                                                5.046
                                            ],
                                            [
                                                8.105,
                                                0
                                            ],
                                            [
                                                4.004,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.814,
                                                4.72
                                            ],
                                            [
                                                -3.158,
                                                5.502
                                            ],
                                            [
                                                -6.543,
                                                0
                                            ],
                                            [
                                                -1.986,
                                                -3.125
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.033,
                                                -0.781
                                            ],
                                            [
                                                0,
                                                -1.855
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.879,
                                                -4.329
                                            ],
                                            [
                                                3.645,
                                                -4.98
                                            ],
                                            [
                                                4.85,
                                                0
                                            ],
                                            [
                                                2.702,
                                                2.702
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.456,
                                                -6.087
                                            ],
                                            [
                                                -4.216,
                                                -4.785
                                            ],
                                            [
                                                -8.952,
                                                0
                                            ],
                                            [
                                                -3.027,
                                                4.362
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.066,
                                                1.205
                                            ],
                                            [
                                                0,
                                                1.4
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                16.992,
                                                -25.586
                                            ],
                                            [
                                                25.635,
                                                -5.713
                                            ],
                                            [
                                                43.262,
                                                1.855
                                            ],
                                            [
                                                62.598,
                                                -7.373
                                            ],
                                            [
                                                69.678,
                                                -26.367
                                            ],
                                            [
                                                61.719,
                                                -26.367
                                            ],
                                            [
                                                58.008,
                                                -14.941
                                            ],
                                            [
                                                44.238,
                                                -6.689
                                            ],
                                            [
                                                29.492,
                                                -14.502
                                            ],
                                            [
                                                25.342,
                                                -25.586
                                            ],
                                            [
                                                50.879,
                                                -25.586
                                            ],
                                            [
                                                52.93,
                                                -32.52
                                            ],
                                            [
                                                24.609,
                                                -32.52
                                            ],
                                            [
                                                24.561,
                                                -34.961
                                            ],
                                            [
                                                24.707,
                                                -40.234
                                            ],
                                            [
                                                50.879,
                                                -40.234
                                            ],
                                            [
                                                52.93,
                                                -47.168
                                            ],
                                            [
                                                25.684,
                                                -47.168
                                            ],
                                            [
                                                30.029,
                                                -57.764
                                            ],
                                            [
                                                44.434,
                                                -65.234
                                            ],
                                            [
                                                55.762,
                                                -61.182
                                            ],
                                            [
                                                61.133,
                                                -50.195
                                            ],
                                            [
                                                69.092,
                                                -50.195
                                            ],
                                            [
                                                62.085,
                                                -66.504
                                            ],
                                            [
                                                44.336,
                                                -73.682
                                            ],
                                            [
                                                23.291,
                                                -62.646
                                            ],
                                            [
                                                17.09,
                                                -47.168
                                            ],
                                            [
                                                6.738,
                                                -47.168
                                            ],
                                            [
                                                4.688,
                                                -40.234
                                            ],
                                            [
                                                16.309,
                                                -40.234
                                            ],
                                            [
                                                16.211,
                                                -36.621
                                            ],
                                            [
                                                16.309,
                                                -32.52
                                            ],
                                            [
                                                6.738,
                                                -32.52
                                            ],
                                            [
                                                4.688,
                                                -25.586
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "€",
                                "hd": false
                            }
                        ],
                        "nm": "€",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "A",
            "size": 20,
            "style": "Bold",
            "w": 66.7,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                22.143,
                                                -29.395
                                            ],
                                            [
                                                33.618,
                                                -61.084
                                            ],
                                            [
                                                44.662,
                                                -29.395
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "A",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                1.538,
                                                0
                                            ],
                                            [
                                                11.499,
                                                0
                                            ],
                                            [
                                                19.278,
                                                -21.484
                                            ],
                                            [
                                                47.419,
                                                -21.484
                                            ],
                                            [
                                                54.907,
                                                0
                                            ],
                                            [
                                                65.552,
                                                0
                                            ],
                                            [
                                                39.518,
                                                -71.729
                                            ],
                                            [
                                                28.549,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "A",
                                "hd": false
                            }
                        ],
                        "nm": "A",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "w",
            "size": 20,
            "style": "Bold",
            "w": 72.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                0.903,
                                                -52.295
                                            ],
                                            [
                                                15.991,
                                                0
                                            ],
                                            [
                                                25.122,
                                                0
                                            ],
                                            [
                                                35.425,
                                                -40.479
                                            ],
                                            [
                                                46.069,
                                                0
                                            ],
                                            [
                                                55.2,
                                                0
                                            ],
                                            [
                                                70.386,
                                                -52.295
                                            ],
                                            [
                                                61.597,
                                                -52.295
                                            ],
                                            [
                                                50.903,
                                                -11.328
                                            ],
                                            [
                                                40.649,
                                                -52.295
                                            ],
                                            [
                                                30.786,
                                                -52.295
                                            ],
                                            [
                                                20.581,
                                                -11.084
                                            ],
                                            [
                                                10.522,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "w",
                                "hd": false
                            }
                        ],
                        "nm": "w",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "m",
            "size": 20,
            "style": "Bold",
            "w": 83.3,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.718,
                                                2.572
                                            ],
                                            [
                                                -3.32,
                                                0
                                            ],
                                            [
                                                -1.335,
                                                -2.441
                                            ],
                                            [
                                                0,
                                                -3.548
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.49,
                                                2.312
                                            ],
                                            [
                                                -3.451,
                                                0
                                            ],
                                            [
                                                -1.742,
                                                -1.302
                                            ],
                                            [
                                                0,
                                                -3.483
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.367,
                                                2.734
                                            ],
                                            [
                                                7.031,
                                                0
                                            ],
                                            [
                                                2.637,
                                                -1.35
                                            ],
                                            [
                                                1.953,
                                                -2.799
                                            ],
                                            [
                                                1.432,
                                                1.172
                                            ],
                                            [
                                                4.166,
                                                0
                                            ],
                                            [
                                                2.897,
                                                -1.985
                                            ],
                                            [
                                                2.083,
                                                -2.571
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -6.738
                                            ],
                                            [
                                                2.718,
                                                -2.571
                                            ],
                                            [
                                                3.645,
                                                0
                                            ],
                                            [
                                                0.846,
                                                1.628
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.403
                                            ],
                                            [
                                                2.49,
                                                -2.311
                                            ],
                                            [
                                                2.506,
                                                0
                                            ],
                                            [
                                                1.741,
                                                1.302
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -4.622
                                            ],
                                            [
                                                -2.539,
                                                -5.078
                                            ],
                                            [
                                                -3.288,
                                                0
                                            ],
                                            [
                                                -2.637,
                                                1.351
                                            ],
                                            [
                                                -1.172,
                                                -2.278
                                            ],
                                            [
                                                -2.539,
                                                -2.051
                                            ],
                                            [
                                                -3.679,
                                                0
                                            ],
                                            [
                                                -1.693,
                                                1.172
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.234,
                                                0
                                            ],
                                            [
                                                15.234,
                                                -27.734
                                            ],
                                            [
                                                19.312,
                                                -41.699
                                            ],
                                            [
                                                28.369,
                                                -45.557
                                            ],
                                            [
                                                35.84,
                                                -41.895
                                            ],
                                            [
                                                37.109,
                                                -34.131
                                            ],
                                            [
                                                37.109,
                                                0
                                            ],
                                            [
                                                46.045,
                                                0
                                            ],
                                            [
                                                46.045,
                                                -30.42
                                            ],
                                            [
                                                49.78,
                                                -41.992
                                            ],
                                            [
                                                58.691,
                                                -45.459
                                            ],
                                            [
                                                65.063,
                                                -43.506
                                            ],
                                            [
                                                67.676,
                                                -36.328
                                            ],
                                            [
                                                67.676,
                                                0
                                            ],
                                            [
                                                76.807,
                                                0
                                            ],
                                            [
                                                76.807,
                                                -34.814
                                            ],
                                            [
                                                74.756,
                                                -45.85
                                            ],
                                            [
                                                60.4,
                                                -53.467
                                            ],
                                            [
                                                51.514,
                                                -51.44
                                            ],
                                            [
                                                44.629,
                                                -45.215
                                            ],
                                            [
                                                40.723,
                                                -50.391
                                            ],
                                            [
                                                30.664,
                                                -53.467
                                            ],
                                            [
                                                20.801,
                                                -50.488
                                            ],
                                            [
                                                15.137,
                                                -44.873
                                            ],
                                            [
                                                15.137,
                                                -52.295
                                            ],
                                            [
                                                6.445,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "m",
                                "hd": false
                            }
                        ],
                        "nm": "m",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "f",
            "size": 20,
            "style": "Bold",
            "w": 26.03,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.13,
                                                -3.645
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.526,
                                                1.384
                                            ],
                                            [
                                                -2.699,
                                                0
                                            ],
                                            [
                                                -0.412,
                                                -0.016
                                            ],
                                            [
                                                -0.922,
                                                -0.065
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.586,
                                                0.033
                                            ],
                                            [
                                                0.553,
                                                0
                                            ],
                                            [
                                                2.051,
                                                -2.995
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.664
                                            ],
                                            [
                                                0.526,
                                                -1.383
                                            ],
                                            [
                                                0.362,
                                                0
                                            ],
                                            [
                                                0.411,
                                                0.017
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.749,
                                                -0.065
                                            ],
                                            [
                                                -0.586,
                                                -0.032
                                            ],
                                            [
                                                -5.859,
                                                0
                                            ],
                                            [
                                                -1.14,
                                                1.693
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.643,
                                                -60.254
                                            ],
                                            [
                                                8.643,
                                                -52.051
                                            ],
                                            [
                                                1.367,
                                                -52.051
                                            ],
                                            [
                                                1.367,
                                                -45.117
                                            ],
                                            [
                                                8.643,
                                                -45.117
                                            ],
                                            [
                                                8.643,
                                                0
                                            ],
                                            [
                                                17.334,
                                                0
                                            ],
                                            [
                                                17.334,
                                                -45.117
                                            ],
                                            [
                                                26.123,
                                                -45.117
                                            ],
                                            [
                                                26.123,
                                                -52.051
                                            ],
                                            [
                                                17.334,
                                                -52.051
                                            ],
                                            [
                                                18.124,
                                                -62.622
                                            ],
                                            [
                                                22.963,
                                                -64.697
                                            ],
                                            [
                                                24.123,
                                                -64.673
                                            ],
                                            [
                                                26.123,
                                                -64.551
                                            ],
                                            [
                                                26.123,
                                                -72.559
                                            ],
                                            [
                                                24.121,
                                                -72.705
                                            ],
                                            [
                                                22.412,
                                                -72.754
                                            ],
                                            [
                                                10.547,
                                                -68.262
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "f",
                                "hd": false
                            }
                        ],
                        "nm": "f",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "r",
            "size": 20,
            "style": "Bold",
            "w": 33.3,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.377,
                                                2.849
                                            ],
                                            [
                                                -4.427,
                                                0
                                            ],
                                            [
                                                -0.439,
                                                -0.032
                                            ],
                                            [
                                                -0.521,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.391,
                                                0.033
                                            ],
                                            [
                                                0.163,
                                                0
                                            ],
                                            [
                                                2.669,
                                                -2.522
                                            ],
                                            [
                                                0.684,
                                                -1.758
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -3.711
                                            ],
                                            [
                                                2.376,
                                                -2.848
                                            ],
                                            [
                                                0.52,
                                                0
                                            ],
                                            [
                                                0.439,
                                                0.033
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.945,
                                                -0.098
                                            ],
                                            [
                                                -0.391,
                                                -0.032
                                            ],
                                            [
                                                -3.484,
                                                0
                                            ],
                                            [
                                                -2.67,
                                                2.523
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.689,
                                                0
                                            ],
                                            [
                                                15.479,
                                                0
                                            ],
                                            [
                                                15.479,
                                                -30.078
                                            ],
                                            [
                                                19.043,
                                                -39.917
                                            ],
                                            [
                                                29.248,
                                                -44.189
                                            ],
                                            [
                                                30.688,
                                                -44.141
                                            ],
                                            [
                                                32.129,
                                                -43.945
                                            ],
                                            [
                                                32.129,
                                                -53.223
                                            ],
                                            [
                                                30.127,
                                                -53.418
                                            ],
                                            [
                                                29.297,
                                                -53.467
                                            ],
                                            [
                                                20.068,
                                                -49.683
                                            ],
                                            [
                                                15.039,
                                                -43.262
                                            ],
                                            [
                                                15.039,
                                                -52.295
                                            ],
                                            [
                                                6.689,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "r",
                                "hd": false
                            }
                        ],
                        "nm": "r",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        }
    ]
}