import Img_0 from './channels-img/img_0.webp';
import Img_1 from './channels-img/img_1.webp';
import Img_2 from './channels-img/img_2.webp';
import Img_3 from './channels-img/img_3.webp';
import Img_4 from './channels-img/img_4.webp';
import Img_5 from './channels-img/img_5.webp';
import Img_6 from './channels-img/img_6.webp';
import Img_7 from './channels-img/img_7.webp';
import Img_8 from './channels-img/img_8.webp';
import Img_9 from './channels-img/img_9.webp';
import Img_10 from './channels-img/img_10.webp';
import Img_11 from './channels-img/img_11.webp';
import Img_12 from './channels-img/img_12.webp';
import Img_13 from './channels-img/img_13.webp';
import Img_14 from './channels-img/img_14.webp';
import Img_15 from './channels-img/img_15.webp';

export default {
    "v": "5.5.9",
    "fr": 29.9700012207031,
    "ip": 5.00000020365417,
    "op": 265.000010793671,
    "w": 800,
    "h": 600,
    "nm": "Channels",
    "ddd": 0,
    "assets": [
        {
            "id": "image_0",
            "w": 281,
            "h": 52,
            "p": Img_0,
            "e": 0
        },
        {
            "id": "image_1",
            "w": 524,
            "h": 448,
            "p": Img_1,
            "e": 0
        },
        {
            "id": "image_2",
            "w": 524,
            "h": 448,
            "p": Img_2,
            "e": 0
        },
        {
            "id": "image_3",
            "w": 524,
            "h": 448,
            "p": Img_3,
            "e": 0
        },
        {
            "id": "image_4",
            "w": 180,
            "h": 208,
            "p": Img_4,
            "e": 0
        },
        {
            "id": "image_5",
            "w": 524,
            "h": 448,
            "p": Img_5,
            "e": 0
        },
        {
            "id": "image_6",
            "w": 180,
            "h": 208,
            "p": Img_6,
            "e": 0
        },
        {
            "id": "image_7",
            "w": 524,
            "h": 448,
            "p": Img_7,
            "e": 0
        },
        {
            "id": "image_8",
            "w": 524,
            "h": 448,
            "p": Img_8,
            "e": 0
        },
        {
            "id": "image_9",
            "w": 524,
            "h": 448,
            "p": Img_9,
            "e": 0
        },
        {
            "id": "image_10",
            "w": 524,
            "h": 448,
            "p": Img_10,
            "e": 0
        },
        {
            "id": "image_11",
            "w": 180,
            "h": 208,
            "p": Img_11,
            "e": 0
        },
        {
            "id": "image_12",
            "w": 180,
            "h": 208,
            "p": Img_12,
            "e": 0
        },
        {
            "id": "image_13",
            "w": 736,
            "h": 448,
            "p": Img_13,
            "e": 0
        },
        {
            "id": "image_14",
            "w": 384,
            "h": 604,
            "p": Img_14,
            "e": 0
        },
        {
            "id": "image_15",
            "w": 800,
            "h": 600,
            "p": Img_15,
            "e": 0
        },
        {
            "id": "comp_0",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 1,
                    "nm": "Pale Gray-Royal Blue Solid 1",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 50
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                98,
                                100,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "hasMask": true,
                    "masksProperties": [
                        {
                            "inv": false,
                            "mode": "a",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ],
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ],
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ]
                                    ],
                                    "v": [
                                        [
                                            100,
                                            0
                                        ],
                                        [
                                            0,
                                            100
                                        ],
                                        [
                                            100,
                                            200
                                        ],
                                        [
                                            200,
                                            100
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0
                                            ],
                                            "y": [
                                                0.999
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 0,
                                        "s": [
                                            -100
                                        ]
                                    },
                                    {
                                        "t": 6.25000025456772,
                                        "s": [
                                            0
                                        ]
                                    }
                                ]
                            },
                            "nm": "Mask 1"
                        },
                        {
                            "inv": false,
                            "mode": "s",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ],
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ],
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ]
                                    ],
                                    "v": [
                                        [
                                            100,
                                            0
                                        ],
                                        [
                                            0,
                                            100
                                        ],
                                        [
                                            100,
                                            200
                                        ],
                                        [
                                            200,
                                            100
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0
                                            ],
                                            "y": [
                                                0.985
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 6.25,
                                        "s": [
                                            -100
                                        ]
                                    },
                                    {
                                        "t": 12.5000005091354,
                                        "s": [
                                            2
                                        ]
                                    }
                                ]
                            },
                            "nm": "Mask 2"
                        }
                    ],
                    "sw": 200,
                    "sh": 200,
                    "sc": "#98a1a4",
                    "ip": 0,
                    "op": 62.5000025456772,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_1",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Channels/Forward Btn",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.36,
                                                    0.35
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.34,
                                                    -0.14
                                                ],
                                                [
                                                    0,
                                                    -0.36
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.47,
                                                    -5.03
                                                ],
                                                [
                                                    -0.02,
                                                    -0.67
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.4,
                                                    -0.11
                                                ],
                                                [
                                                    -0.08,
                                                    0
                                                ],
                                                [
                                                    -0.17,
                                                    0.28
                                                ],
                                                [
                                                    -1.77,
                                                    -0.17
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.34,
                                                    -0.14
                                                ],
                                                [
                                                    -0.27,
                                                    0.26
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.27,
                                                    -0.26
                                                ],
                                                [
                                                    -0.34,
                                                    0.14
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -5.27,
                                                    0.21
                                                ],
                                                [
                                                    -0.64,
                                                    2.19
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.4
                                                ],
                                                [
                                                    0.08,
                                                    0.02
                                                ],
                                                [
                                                    0.32,
                                                    0
                                                ],
                                                [
                                                    3.62,
                                                    -6.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.37
                                                ],
                                                [
                                                    0.34,
                                                    0.14
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.36,
                                                    -0.36
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    10.73,
                                                    -1.09
                                                ],
                                                [
                                                    2.49,
                                                    -9.23
                                                ],
                                                [
                                                    1.49,
                                                    -9.43
                                                ],
                                                [
                                                    0.92,
                                                    -8.6
                                                ],
                                                [
                                                    0.92,
                                                    -4.97
                                                ],
                                                [
                                                    -10.42,
                                                    3.79
                                                ],
                                                [
                                                    -10.99,
                                                    8.42
                                                ],
                                                [
                                                    -10.99,
                                                    8.6
                                                ],
                                                [
                                                    -10.31,
                                                    9.47
                                                ],
                                                [
                                                    -10.07,
                                                    9.5
                                                ],
                                                [
                                                    -9.28,
                                                    9.06
                                                ],
                                                [
                                                    0.92,
                                                    3.03
                                                ],
                                                [
                                                    0.92,
                                                    7.69
                                                ],
                                                [
                                                    1.49,
                                                    8.53
                                                ],
                                                [
                                                    2.49,
                                                    8.33
                                                ],
                                                [
                                                    10.73,
                                                    0.19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.850979983807,
                                            0.850979983807,
                                            0.850979983807,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Channels/Forward Btn",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_2",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 3,
                    "nm": "▽ Channels/Bookmark Btn",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Path",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.19,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.15
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.17,
                                                    -0.24
                                                ],
                                                [
                                                    -0.36,
                                                    0
                                                ],
                                                [
                                                    -0.33,
                                                    0.28
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.3,
                                                    0
                                                ],
                                                [
                                                    -0.2,
                                                    -0.17
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.34,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.98
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.19,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.38
                                                ],
                                                [
                                                    0.21,
                                                    0.3
                                                ],
                                                [
                                                    0.34,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.2,
                                                    -0.17
                                                ],
                                                [
                                                    0.3,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.33,
                                                    0.28
                                                ],
                                                [
                                                    0.57,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.15
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    7.34,
                                                    -12.5
                                                ],
                                                [
                                                    -7.27,
                                                    -12.5
                                                ],
                                                [
                                                    -9.5,
                                                    -10.38
                                                ],
                                                [
                                                    -9.5,
                                                    11.08
                                                ],
                                                [
                                                    -9.22,
                                                    12.03
                                                ],
                                                [
                                                    -8.32,
                                                    12.5
                                                ],
                                                [
                                                    -7.28,
                                                    12.07
                                                ],
                                                [
                                                    -0.74,
                                                    6.56
                                                ],
                                                [
                                                    0.05,
                                                    6.29
                                                ],
                                                [
                                                    0.84,
                                                    6.56
                                                ],
                                                [
                                                    7.36,
                                                    12.07
                                                ],
                                                [
                                                    8.37,
                                                    12.5
                                                ],
                                                [
                                                    9.5,
                                                    11.08
                                                ],
                                                [
                                                    9.5,
                                                    -10.38
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.698039233685,
                                            0.698039233685,
                                            0.698039233685,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Oval",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.631372988224,
                                            0.643136978149,
                                            0.717647016048,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 1
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 8
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            45,
                                            45
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Oval",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_3",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 3,
                    "nm": "▽ Channels/Like Btn",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Path",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.26,
                                                    0.44
                                                ],
                                                [
                                                    3.14,
                                                    0.74
                                                ],
                                                [
                                                    1.07,
                                                    2.35
                                                ],
                                                [
                                                    3.31,
                                                    1.85
                                                ],
                                                [
                                                    -0.12,
                                                    -1.85
                                                ],
                                                [
                                                    1.68,
                                                    -3.49
                                                ],
                                                [
                                                    1.61,
                                                    -0.96
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -2.19,
                                                    0
                                                ],
                                                [
                                                    -0.86,
                                                    0.69
                                                ],
                                                [
                                                    -0.09,
                                                    0.52
                                                ],
                                                [
                                                    0.63,
                                                    1.54
                                                ],
                                                [
                                                    -0.17,
                                                    0.66
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    1.03,
                                                    -0.8
                                                ],
                                                [
                                                    -2.26,
                                                    -0.53
                                                ],
                                                [
                                                    -0.99,
                                                    -2.15
                                                ],
                                                [
                                                    -1.22,
                                                    -0.68
                                                ],
                                                [
                                                    0.22,
                                                    3.73
                                                ],
                                                [
                                                    -1.61,
                                                    0.93
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    2.55,
                                                    1.32
                                                ],
                                                [
                                                    1.31,
                                                    0
                                                ],
                                                [
                                                    0.98,
                                                    -0.78
                                                ],
                                                [
                                                    1.7,
                                                    -0.68
                                                ],
                                                [
                                                    0.74,
                                                    -0.4
                                                ],
                                                [
                                                    0.34,
                                                    -1.35
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    9.46,
                                                    1.08
                                                ],
                                                [
                                                    8.41,
                                                    -3.01
                                                ],
                                                [
                                                    3.56,
                                                    -4.29
                                                ],
                                                [
                                                    2.3,
                                                    -12.2
                                                ],
                                                [
                                                    0.08,
                                                    -10.59
                                                ],
                                                [
                                                    -3.65,
                                                    -2.84
                                                ],
                                                [
                                                    -8.18,
                                                    -0.44
                                                ],
                                                [
                                                    -10.5,
                                                    -0.42
                                                ],
                                                [
                                                    -10.5,
                                                    10.42
                                                ],
                                                [
                                                    -8.35,
                                                    10.42
                                                ],
                                                [
                                                    -6.69,
                                                    10.9
                                                ],
                                                [
                                                    1.19,
                                                    12.5
                                                ],
                                                [
                                                    6.02,
                                                    11.69
                                                ],
                                                [
                                                    7.38,
                                                    9.14
                                                ],
                                                [
                                                    8.91,
                                                    5.5
                                                ],
                                                [
                                                    10.28,
                                                    3.9
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.698038995266,
                                            0.698038995266,
                                            0.698038995266,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Oval",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                22.5,
                                22.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.631372988224,
                                            0.643136978149,
                                            0.717647016048,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 1
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 8
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            45,
                                            45
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "nm": "Ellipse Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Oval",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_4",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "Pre-comp 1",
                    "refId": "comp_5",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                250,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                230,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "hasMask": true,
                    "masksProperties": [
                        {
                            "inv": false,
                            "mode": "a",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            -0.173,
                                            12.065
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -58.8,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            51.515
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            1.925
                                        ],
                                        [
                                            0,
                                            50.988
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            61.176,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            289.873,
                                            0
                                        ],
                                        [
                                            10.075,
                                            0
                                        ],
                                        [
                                            9.85,
                                            400.512
                                        ],
                                        [
                                            69.8,
                                            458.808
                                        ],
                                        [
                                            225.824,
                                            458.808
                                        ],
                                        [
                                            289.873,
                                            399.485
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Mask 1"
                        }
                    ],
                    "w": 300,
                    "h": 460,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_5",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            17.3
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.999
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.004
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            -16.558
                                        ]
                                    },
                                    "t": 45.455,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        614.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 44.955,
                                    "s": [
                                        174.5,
                                        242.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        242.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        -273.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            17.3
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.999
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.004
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            -16.558
                                        ]
                                    },
                                    "t": 45.455,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        614.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 44.955,
                                    "s": [
                                        226.5,
                                        242.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        242.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        -273.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            17.484
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.999
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.002
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            -16.612
                                        ]
                                    },
                                    "t": 45.455,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        581.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 44.955,
                                    "s": [
                                        264,
                                        209.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        209.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        -306.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pics + Text 2",
                    "refId": "comp_6",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 149.85,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 169.83,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        530.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 44.955,
                                    "s": [
                                        132,
                                        158.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        158.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -357.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                76.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 153,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.67
                                        ],
                                        "y": [
                                            15.515
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 45.455,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 196.803,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 197.302508036296,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        530.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 45.455,
                                    "s": [
                                        131.992,
                                        159,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        158.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -357.5,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            183
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "Expanding Card BG",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 184.815,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198,
                                    "s": [
                                        99
                                    ]
                                },
                                {
                                    "t": 199.000027636687,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 45.91,
                                    "s": [
                                        132,
                                        159,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 57.942,
                                    "s": [
                                        150,
                                        227.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 184.815,
                                    "s": [
                                        150,
                                        227.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 197.802008056641,
                                    "s": [
                                        132,
                                        158.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 45.91,
                                    "s": [
                                        106.468,
                                        72.047,
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 57.942,
                                    "s": [
                                        124.378,
                                        139.37,
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ],
                                        "y": [
                                            0.833,
                                            0.833,
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ],
                                        "y": [
                                            0.167,
                                            0.167,
                                            0.167
                                        ]
                                    },
                                    "t": 184.815,
                                    "s": [
                                        124.299,
                                        139.073,
                                        100
                                    ]
                                },
                                {
                                    "t": 198.00005201002,
                                    "s": [
                                        106.468,
                                        72.047,
                                        100
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            201.047,
                                            254.258
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0.523,
                                            1.129
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0.833
                                            ],
                                            "y": [
                                                0.833
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 44,
                                        "s": [
                                            25
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": [
                                                0.833
                                            ],
                                            "y": [
                                                0.833
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 58,
                                        "s": [
                                            20
                                        ]
                                    },
                                    {
                                        "t": 198.00005201002,
                                        "s": [
                                            21
                                        ]
                                    }
                                ]
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 490.000019958109,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 4,
                    "nm": "Cover BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.388,
                                262,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                120.306,
                                103.869,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            249.992,
                                            449.703
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.188235297799,
                                            0.188235297799,
                                            0.215686276555,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 45.455,
                                                "s": [
                                                    0
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 60,
                                                "s": [
                                                    20
                                                ]
                                            },
                                            {
                                                "i": {
                                                    "x": [
                                                        0.833
                                                    ],
                                                    "y": [
                                                        0.833
                                                    ]
                                                },
                                                "o": {
                                                    "x": [
                                                        0.167
                                                    ],
                                                    "y": [
                                                        0.167
                                                    ]
                                                },
                                                "t": 185,
                                                "s": [
                                                    20
                                                ]
                                            },
                                            {
                                                "t": 198.00005201002,
                                                "s": [
                                                    0
                                                ]
                                            }
                                        ]
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            1.423,
                                            -0.342
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            99.285,
                                            106.244
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 5,
                    "nm": "UP NOW",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        126,
                                        31.52,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        126,
                                        -340.48,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        126,
                                        -340.48,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        126,
                                        -856.48,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 12,
                                        "f": "Rubik-Medium",
                                        "t": "UP NOW",
                                        "j": 0,
                                        "tr": 25,
                                        "lh": 14.4,
                                        "ls": 0,
                                        "fc": [
                                            0.698,
                                            0.698,
                                            0.698
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Pics + Text",
                    "refId": "comp_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        150,
                                        149,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        150,
                                        -223,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        150,
                                        -223,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        150,
                                        -739,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                125,
                                99,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 250,
                    "h": 198,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 10,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        401.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        174.5,
                                        29.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        29.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        -486.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 11,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        401.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        226.5,
                                        29.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        29.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        -486.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 12,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        368.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        264,
                                        -3.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        -3.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        -519.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 13,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Text",
                    "refId": "comp_9",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        336,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        -36,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -0.002,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        -36,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -552,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                58,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 116,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 14,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        336,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        -36,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        -36,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -552,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            146
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 15,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        840.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        174.5,
                                        468.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        468.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        -47.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 16,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        840.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        226.5,
                                        468.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        468.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        -47.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 17,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        807.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        264,
                                        435.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        435.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        -80.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 18,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pic + Text",
                    "refId": "comp_10",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        750,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        378,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        378,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -138,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                83,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 166,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 19,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        750,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        378,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        378,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        -138,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            196
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 20,
                    "ty": 5,
                    "nm": "UP NOW 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        126,
                                        915.52,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        126,
                                        547.52,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        126,
                                        547.52,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        126,
                                        31.52,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 12,
                                        "f": "Rubik-Medium",
                                        "t": "UP NOW",
                                        "j": 0,
                                        "tr": 25,
                                        "lh": 14.4,
                                        "ls": 0,
                                        "fc": [
                                            0.698,
                                            0.698,
                                            0.698
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 21,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Pics + Text 2",
                    "refId": "comp_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        150,
                                        1033,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        150,
                                        665,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        150,
                                        665,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        150,
                                        149,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                125,
                                99,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 250,
                    "h": 198,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 22,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn 4",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        1285.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        174.5,
                                        917.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        917.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        401.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 23,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn 4",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        1285.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        226.5,
                                        917.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        917.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        401.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 24,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn 4",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        1252.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        264,
                                        884.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        884.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        368.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 25,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Text 2",
                    "refId": "comp_9",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1220,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        852,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        852,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        336,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                58,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 116,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 26,
                    "ty": 4,
                    "nm": "Rectangle 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1220,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        852,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        852,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        336,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            146
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 27,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn 3",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        1498.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        174.5,
                                        1130.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        1130.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        614.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 28,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn 3",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        1498.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        226.5,
                                        1130.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        1130.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        614.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 29,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn 3",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        1465.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.838,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0.032,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 47.453,
                                    "s": [
                                        264,
                                        1097.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -62,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        1097.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        581.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 30,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pics + Text 3",
                    "refId": "comp_6",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1414.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        1046.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        1046.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        530.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                76.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 153,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 31,
                    "ty": 4,
                    "nm": "BG 3",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 45.4545018513997,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1414.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        -3.441,
                                        55.051,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        1046.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        1046.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        530.5,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            183
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 32,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn 2",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        174.5,
                                        1724.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        174.5,
                                        1356.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        174.5,
                                        1356.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        174.5,
                                        840.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 33,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn 2",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        226.5,
                                        1724.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        226.5,
                                        1356.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        226.5,
                                        1356.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        226.5,
                                        840.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 34,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn 2",
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        264,
                                        1691.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        264,
                                        1323.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        264,
                                        1323.5,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        264,
                                        807.5,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 35,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pic + Text 2",
                    "refId": "comp_10",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1634,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.842,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.158,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        1266,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        1266,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        750,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                83,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 166,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 36,
                    "ty": 4,
                    "nm": "BG 2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 0,
                                    "s": [
                                        132,
                                        1634,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -6.837,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        55.443,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0
                                    },
                                    "t": 44.955,
                                    "s": [
                                        132,
                                        1266.106,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -0.035,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0.035,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 45.455,
                                    "s": [
                                        132,
                                        1266,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 204.795,
                                    "s": [
                                        132,
                                        1266,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 250.249510192871,
                                    "s": [
                                        132,
                                        750,
                                        0
                                    ]
                                }
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            196
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_6",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Pic 1",
                    "refId": "image_2",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        99
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        1
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 122.877,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                65.5,
                                56,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                262,
                                224,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic 2",
                    "refId": "image_4",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        99
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        1
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 122.877,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                26,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 2,
                    "nm": "Pic 3",
                    "refId": "image_6",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.456,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        1
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 122.877,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 198.302,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                86,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 43.457,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 44.955,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 122.877,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 198.801008097331,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                90.5,
                                140,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            63,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -59,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            125,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            64.5,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            125,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            109,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            31,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_7",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 3,
                    "nm": "▽ Buttons",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188.5,
                                165.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                61.5,
                                32.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "↻ Channels/Like Btn",
                    "parent": 1,
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                22.5,
                                42.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 0,
                    "nm": "↻ Channels/Bookmark Btn",
                    "parent": 1,
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                74.5,
                                42.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                50,
                                50,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 100,
                    "h": 100,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 0,
                    "nm": "↻ Channels/Forward Btn",
                    "parent": 1,
                    "refId": "comp_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                112,
                                9.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                11,
                                9.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 22,
                    "h": 19,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 3,
                    "nm": "▽ Card Preview",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 0,
                    "nm": "↻ Channels/Card/Content/Pics + Text",
                    "parent": 5,
                    "refId": "comp_8",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                76.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 184,
                    "h": 153,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 4,
                    "nm": "BG",
                    "parent": 5,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                107,
                                91.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.760783970356,
                                            0.760783970356,
                                            0.760783970356,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 13
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            214,
                                            183
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 20
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "BG",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_8",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Pic 1",
                    "refId": "image_10",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                65.5,
                                56,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                262,
                                224,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic 2",
                    "refId": "image_11",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                26,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 2,
                    "nm": "Pic 3",
                    "refId": "image_12",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                161.5,
                                86,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                90,
                                104,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                86.5,
                                140,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            152,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -10.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            17,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            66,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -53.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            84,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            44.5,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            85,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -44,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_9",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                90.5,
                                58,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            175,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -3,
                                            -45
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            30,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            46.5,
                                            -35
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            53,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            -25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -31.5,
                                            -35
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            124,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28.5,
                                            -25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            110,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            29.5,
                                            -15
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 6",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            119,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -31,
                                            -5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 7",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            88,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -46.5,
                                            5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 8",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            61,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -60,
                                            15
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 9",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            74,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -53.5,
                                            25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 10",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            88,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -46.5,
                                            45
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 11",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            61,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -60,
                                            55
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 12",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            88,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            45.5,
                                            45
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 13",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            148,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -16.5,
                                            35
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 14",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0.5,
                                            25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 15",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            42,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            38.5,
                                            25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 16",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            27,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            77,
                                            25
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 17",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            104,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            26.5,
                                            15
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 18",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            46,
                                            5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 19",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            61,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -60,
                                            -15
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 20",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            63.5,
                                            -55
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 21",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            124,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28.5,
                                            -55
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 22",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_10",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_13",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                92,
                                55,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                368,
                                224,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                90,
                                139,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            175,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            124,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            124,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -28,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            27,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            51.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        }
    ],
    "fonts": {
        "list": [
            {
                "fName": "Rubik-Medium",
                "fFamily": "Rubik",
                "fStyle": "Medium",
                "ascent": 70.9991455078125
            },
            {
                "fName": "Rubik-Bold",
                "fFamily": "Rubik",
                "fStyle": "Bold",
                "ascent": 71.8994140625
            }
        ]
    },
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 0,
            "nm": "Tap",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        340,
                        322,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 41.0000016699642,
            "op": 103.500004215641,
            "st": 41.0000016699642,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 0,
            "nm": "Tap 2",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        426,
                        495,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 148.000006028164,
            "op": 210.500008573841,
            "st": 148.000006028164,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 0,
            "nm": "Tap 3",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        482.818,
                        521.397,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 180.00000733155,
            "op": 242.500009877227,
            "st": 180.00000733155,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 4,
            "ty": 5,
            "nm": "Channel",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        364.972,
                        82,
                        0
                    ]
                }
            },
            "ao": 0,
            "t": {
                "d": {
                    "k": [
                        {
                            "s": {
                                "s": 17,
                                "f": "Rubik-Bold",
                                "t": "Channel",
                                "j": 0,
                                "tr": 0,
                                "lh": 20.4,
                                "ls": 0,
                                "fc": [
                                    0,
                                    0,
                                    0
                                ]
                            },
                            "t": 0
                        }
                    ]
                },
                "p": {},
                "m": {
                    "g": 1,
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0
                        ]
                    }
                },
                "a": []
            },
            "ip": 0,
            "op": 490.000019958109,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 5,
            "ty": 2,
            "nm": "Channel Header.png",
            "cl": "png",
            "refId": "image_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        76,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        140.5,
                        26,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        98.932,
                        100,
                        100
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 490.000019958109,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 6,
            "ty": 5,
            "nm": "Special offer until May!",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 50,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 50,
                            "s": [
                                291,
                                304.5,
                                0
                            ],
                            "to": [
                                -0.083,
                                0,
                                0
                            ],
                            "ti": [
                                0.083,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                290.5,
                                304.5,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 60,
                            "s": [
                                290.5,
                                304.5,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 185,
                            "s": [
                                290.5,
                                304.5,
                                0
                            ],
                            "to": [
                                -0.083,
                                0,
                                0
                            ],
                            "ti": [
                                0.083,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                290,
                                304.5,
                                0
                            ]
                        }
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 50,
                            "s": [
                                80,
                                80,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100,
                                100,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100,
                                100,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                80,
                                80,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "t": {
                "d": {
                    "k": [
                        {
                            "s": {
                                "s": 19,
                                "f": "Rubik-Bold",
                                "t": "Special offer until May!",
                                "j": 0,
                                "tr": 0,
                                "lh": 22.8,
                                "ls": 0,
                                "fc": [
                                    0.212,
                                    0.208,
                                    0.216
                                ]
                            },
                            "t": 0
                        }
                    ]
                },
                "p": {},
                "m": {
                    "g": 1,
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0
                        ]
                    }
                },
                "a": []
            },
            "ip": 0,
            "op": 490.000019958109,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 7,
            "ty": 2,
            "nm": "Pic 1 new",
            "refId": "image_1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 0,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 57,
                            "s": [
                                1
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                355.25,
                                215.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                355.25,
                                215.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                355.25,
                                215.75,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 145,
                            "s": [
                                -275.262,
                                215.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                -275.262,
                                215.75,
                                0
                            ],
                            "to": [
                                105.063,
                                2.523,
                                0
                            ],
                            "ti": [
                                -105.063,
                                -2.523,
                                0
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                355.114,
                                230.886,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        224,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 8,
            "ty": 2,
            "nm": "Pic 1",
            "refId": "image_2",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 59.0000024031193,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                355.5,
                                227.5,
                                0
                            ],
                            "to": [
                                0.039,
                                -0.301,
                                0
                            ],
                            "ti": [
                                -0.295,
                                2.283,
                                0
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                355.5,
                                216,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        224,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 9,
            "ty": 2,
            "nm": "Pic 2 new",
            "refId": "image_3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 48,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 55,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 48,
                            "s": [
                                441.7,
                                187.1,
                                0
                            ],
                            "to": [
                                -0.362,
                                -0.593,
                                0
                            ],
                            "ti": [
                                1.107,
                                2.712,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 55,
                            "s": [
                                451.3,
                                187.4,
                                0
                            ],
                            "to": [
                                -1.367,
                                -3.35,
                                0
                            ],
                            "ti": [
                                -0.533,
                                0.233,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                454.5,
                                186,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                454.5,
                                186,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 145,
                            "s": [
                                -176.012,
                                186,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                -176.012,
                                186,
                                0
                            ],
                            "to": [
                                102.167,
                                0.333,
                                0
                            ],
                            "ti": [
                                -102.167,
                                -0.333,
                                0
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                436.988,
                                188,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        90,
                        104,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 48,
                            "s": [
                                12.375,
                                14.699,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 55,
                            "s": [
                                21.213,
                                21.908,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                8.5,
                                11.7,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 10,
            "ty": 2,
            "nm": "Pic 2",
            "refId": "image_4",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 59.0000024031193,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                451.5,
                                197.5,
                                0
                            ],
                            "to": [
                                7.833,
                                3.167,
                                0
                            ],
                            "ti": [
                                -7.833,
                                -3.167,
                                0
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                498.5,
                                216.5,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        90,
                        104,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                72.778,
                                53.846,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 11,
            "ty": 2,
            "nm": "Pic 3 new",
            "refId": "image_5",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 57,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                641,
                                218,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                641,
                                218,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 145,
                            "s": [
                                10.488,
                                218,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                10.488,
                                218,
                                0
                            ],
                            "to": [
                                73.5,
                                7.25,
                                0
                            ],
                            "ti": [
                                -73.5,
                                -7.25,
                                0
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                451.488,
                                261.5,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        223,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                8.7,
                                11.7,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 12,
            "ty": 2,
            "nm": "Pic 3",
            "refId": "image_6",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 59.0000024031193,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                451.5,
                                257,
                                0
                            ],
                            "to": [
                                31.5,
                                -6.583,
                                0
                            ],
                            "ti": [
                                -31.5,
                                6.583,
                                0
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                640.5,
                                217.5,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        90,
                        104,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 58.0000023623884,
                            "s": [
                                72.778,
                                53.846,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 13,
            "ty": 2,
            "nm": "Pic 4",
            "refId": "image_7",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 54,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 54,
                            "s": [
                                581.645,
                                230.438,
                                0
                            ],
                            "to": [
                                33.476,
                                -2.115,
                                0
                            ],
                            "ti": [
                                -33.476,
                                2.115,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                782.5,
                                217.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                782.5,
                                217.75,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 145,
                            "s": [
                                151.988,
                                217.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                151.988,
                                217.75,
                                0
                            ],
                            "to": [
                                31.833,
                                0,
                                0
                            ],
                            "ti": [
                                -31.833,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                342.988,
                                217.75,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        224,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 54,
                            "s": [
                                20,
                                21,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                16,
                                16,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 14,
            "ty": 2,
            "nm": "Pic 5",
            "refId": "image_8",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 57,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                928.5,
                                217.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                928.5,
                                217.75,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 145,
                            "s": [
                                297.988,
                                217.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                297.988,
                                217.75,
                                0
                            ],
                            "to": [
                                9.667,
                                0,
                                0
                            ],
                            "ti": [
                                -9.667,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                355.988,
                                217.75,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        224,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                17,
                                17,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 15,
            "ty": 2,
            "nm": "Pic 6",
            "refId": "image_9",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 57,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                99
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                1074.5,
                                217.75,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 65,
                            "s": [
                                1074.5,
                                217.75,
                                0
                            ],
                            "to": [
                                -105.085,
                                0,
                                0
                            ],
                            "ti": [
                                105.085,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 145.000005905971,
                            "s": [
                                443.988,
                                217.75,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        262,
                        224,
                        0
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                25,
                                25,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                15,
                                15,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 16,
            "ty": 0,
            "nm": "↻ Channels/Forward Btn",
            "refId": "comp_1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 0,
                            "s": [
                                239,
                                575.5,
                                0
                            ],
                            "to": [
                                0,
                                -6.664,
                                0
                            ],
                            "ti": [
                                77.359,
                                28.777,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                513.742,
                                298.246,
                                0
                            ],
                            "to": [
                                -57.555,
                                -21.41,
                                0
                            ],
                            "ti": [
                                77.359,
                                28.777,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 46,
                            "s": [
                                513.742,
                                299.246,
                                0
                            ],
                            "to": [
                                -57.555,
                                -21.41,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                497.742,
                                470.246,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.408
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                497.742,
                                470.246,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                -13.403,
                                30.6,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.704
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0.33
                            },
                            "t": 190,
                            "s": [
                                489.756,
                                415.052,
                                0
                            ],
                            "to": [
                                11.333,
                                -25.874,
                                0
                            ],
                            "ti": [
                                -0.302,
                                23.223,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.772
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0.453
                            },
                            "t": 194,
                            "s": [
                                516.66,
                                335.788,
                                0
                            ],
                            "to": [
                                0.143,
                                -11.021,
                                0
                            ],
                            "ti": [
                                3.773,
                                6.692,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0.945
                            },
                            "t": 196,
                            "s": [
                                516.256,
                                308.618,
                                0
                            ],
                            "to": [
                                -1.165,
                                -2.066,
                                0
                            ],
                            "ti": [
                                4.042,
                                1.503,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 198,
                            "s": [
                                513.742,
                                303.246,
                                0
                            ],
                            "to": [
                                -57.555,
                                -21.41,
                                0
                            ],
                            "ti": [
                                0,
                                -0.083,
                                0
                            ]
                        },
                        {
                            "t": 220.000008960784,
                            "s": [
                                513.742,
                                304.746,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        11,
                        9.5,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 22,
            "h": 19,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 17,
            "ty": 4,
            "nm": "Path",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 154,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 155.000006313279,
                            "s": [
                                100
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 157,
                            "s": [
                                427,
                                492.875,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                427,
                                492.875,
                                0
                            ],
                            "to": [
                                0.943,
                                -2.963,
                                0
                            ],
                            "ti": [
                                -0.22,
                                0.692,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.996
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 198,
                            "s": [
                                476.5,
                                337.2,
                                0
                            ],
                            "to": [
                                0.005,
                                -0.015,
                                0
                            ],
                            "ti": [
                                0.008,
                                -0.024,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.735,
                                "y": 0
                            },
                            "o": {
                                "x": 0.265,
                                "y": 1
                            },
                            "t": 199,
                            "s": [
                                476.494,
                                331.219,
                                0
                            ],
                            "to": [
                                -0.037,
                                0.116,
                                0
                            ],
                            "ti": [
                                0.008,
                                -0.024,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.584,
                                "y": 0.387
                            },
                            "o": {
                                "x": 0.238,
                                "y": 0.002
                            },
                            "t": 205,
                            "s": [
                                476.494,
                                331.219,
                                0
                            ],
                            "to": [
                                -0.008,
                                0.026,
                                0
                            ],
                            "ti": [
                                0.002,
                                11.991,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.632,
                                "y": 0.578
                            },
                            "o": {
                                "x": 0.288,
                                "y": 0.22
                            },
                            "t": 208,
                            "s": [
                                476.48,
                                311.758,
                                0
                            ],
                            "to": [
                                -0.002,
                                -13.747,
                                0
                            ],
                            "ti": [
                                -0.003,
                                26.359,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.662,
                                "y": 0.617
                            },
                            "o": {
                                "x": 0.327,
                                "y": 0.331
                            },
                            "t": 213,
                            "s": [
                                476.483,
                                249.225,
                                0
                            ],
                            "to": [
                                0.001,
                                -9.384,
                                0
                            ],
                            "ti": [
                                -0.001,
                                10.855,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.669,
                                "y": 0.609
                            },
                            "o": {
                                "x": 0.331,
                                "y": 0.347
                            },
                            "t": 215,
                            "s": [
                                476.486,
                                220.85,
                                0
                            ],
                            "to": [
                                0.003,
                                -21.267,
                                0
                            ],
                            "ti": [
                                -0.002,
                                23.933,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.669,
                                "y": 0.646
                            },
                            "o": {
                                "x": 0.335,
                                "y": 0.404
                            },
                            "t": 219,
                            "s": [
                                476.494,
                                159.623,
                                0
                            ],
                            "to": [
                                0.001,
                                -6.069,
                                0
                            ],
                            "ti": [
                                -0.001,
                                5.309,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.67,
                                "y": 0.661
                            },
                            "o": {
                                "x": 0.337,
                                "y": 0.341
                            },
                            "t": 220,
                            "s": [
                                476.496,
                                144.617,
                                0
                            ],
                            "to": [
                                0.001,
                                -5.397,
                                0
                            ],
                            "ti": [
                                0,
                                5.363,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.671,
                                "y": 0.706
                            },
                            "o": {
                                "x": 0.338,
                                "y": 0.343
                            },
                            "t": 221,
                            "s": [
                                476.498,
                                128.8,
                                0
                            ],
                            "to": [
                                0,
                                -5.491,
                                0
                            ],
                            "ti": [
                                0,
                                4.707,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.681,
                                "y": 1
                            },
                            "o": {
                                "x": 0.346,
                                "y": 0.442
                            },
                            "t": 222,
                            "s": [
                                476.499,
                                112.814,
                                0
                            ],
                            "to": [
                                0.001,
                                -9.918,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 224.000009123707,
                            "s": [
                                476.5,
                                90.375,
                                0
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            1.19,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -1.15
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -0.17,
                                            -0.24
                                        ],
                                        [
                                            -0.36,
                                            0
                                        ],
                                        [
                                            -0.33,
                                            0.28
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -0.3,
                                            0
                                        ],
                                        [
                                            -0.2,
                                            -0.17
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -0.34,
                                            0
                                        ],
                                        [
                                            0,
                                            0.98
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.19,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0.38
                                        ],
                                        [
                                            0.21,
                                            0.3
                                        ],
                                        [
                                            0.34,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0.2,
                                            -0.17
                                        ],
                                        [
                                            0.3,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0.33,
                                            0.28
                                        ],
                                        [
                                            0.57,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -1.15
                                        ]
                                    ],
                                    "v": [
                                        [
                                            7.34,
                                            -12.5
                                        ],
                                        [
                                            -7.27,
                                            -12.5
                                        ],
                                        [
                                            -9.5,
                                            -10.38
                                        ],
                                        [
                                            -9.5,
                                            11.08
                                        ],
                                        [
                                            -9.22,
                                            12.03
                                        ],
                                        [
                                            -8.32,
                                            12.5
                                        ],
                                        [
                                            -7.28,
                                            12.07
                                        ],
                                        [
                                            -0.74,
                                            6.56
                                        ],
                                        [
                                            0.05,
                                            6.29
                                        ],
                                        [
                                            0.84,
                                            6.56
                                        ],
                                        [
                                            7.36,
                                            12.07
                                        ],
                                        [
                                            8.37,
                                            12.5
                                        ],
                                        [
                                            9.5,
                                            11.08
                                        ],
                                        [
                                            9.5,
                                            -10.38
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0.833
                                            ],
                                            "y": [
                                                0.833
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 154.345,
                                        "s": [
                                            0.698038995266,
                                            0.698038995266,
                                            0.698038995266,
                                            1
                                        ]
                                    },
                                    {
                                        "t": 156.842506388326,
                                        "s": [
                                            0.337254911661,
                                            0.686274528503,
                                            0.921568632126,
                                            1
                                        ]
                                    }
                                ]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 0
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "sk": {
                                "a": 0,
                                "k": 0
                            },
                            "sa": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Преобразовать"
                        }
                    ],
                    "nm": "Path",
                    "bm": 0,
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 18,
            "ty": 0,
            "nm": "↻ Channels/Bookmark Btn",
            "refId": "comp_2",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 0,
                            "s": [
                                201.5,
                                608.5,
                                0
                            ],
                            "to": [
                                0,
                                -6.668,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 44,
                            "s": [
                                476.5,
                                339.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                476.5,
                                331.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 46,
                            "s": [
                                476.5,
                                331.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 0.667
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                427,
                                492.572,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.667,
                                "y": 1
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                427,
                                492.572,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0.006,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                476.5,
                                337.5,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        50,
                        50,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 100,
            "h": 100,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 19,
            "ty": 0,
            "nm": "↻ Channels/Like Btn",
            "refId": "comp_3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 198,
                            "s": [
                                99
                            ]
                        },
                        {
                            "t": 199.000008105436,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 0,
                            "s": [
                                149.5,
                                608.5,
                                0
                            ],
                            "to": [
                                0,
                                -6.668,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 44,
                            "s": [
                                424.5,
                                339.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 45,
                            "s": [
                                424.5,
                                331.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                8.195,
                                -25.789,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 46,
                            "s": [
                                424.5,
                                331.5,
                                0
                            ],
                            "to": [
                                -8.195,
                                25.789,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.672,
                                "y": 0.672
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                375,
                                492.572,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.671,
                                "y": 1
                            },
                            "o": {
                                "x": 0.333,
                                "y": 0
                            },
                            "t": 185,
                            "s": [
                                375,
                                492.572,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0.006,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                424.5,
                                337.5,
                                0
                            ]
                        }
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        50,
                        50,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 100,
            "h": 100,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 20,
            "ty": 4,
            "nm": "Lines",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                1
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 44,
                            "s": [
                                379,
                                330.5,
                                0
                            ],
                            "to": [
                                2.667,
                                9.333,
                                0
                            ],
                            "ti": [
                                -2.667,
                                -9.333,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 58,
                            "s": [
                                395,
                                386.5,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 185,
                            "s": [
                                395,
                                386.5,
                                0
                            ],
                            "to": [
                                -2.667,
                                -9.333,
                                0
                            ],
                            "ti": [
                                2.667,
                                9.333,
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                379,
                                330.5,
                                0
                            ]
                        }
                    ]
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                98,
                                44.084,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                114,
                                112.618,
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                "y": [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                "y": [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                114,
                                112.618,
                                100
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                98,
                                44.084,
                                100
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    175,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -3,
                                    -45
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 1",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    30,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    46.5,
                                    -35
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 2",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    53,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    64,
                                    -25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 3",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    118,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -31.5,
                                    -35
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 4",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    124,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -28.5,
                                    -25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 5",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    110,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    29.5,
                                    -15
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 6",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    119,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -31,
                                    -5
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 7",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    88,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -46.5,
                                    5
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 8",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    61,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -60,
                                    15
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 9",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    74,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -53.5,
                                    25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 10",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    88,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -46.5,
                                    45
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 11",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    61,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -60,
                                    55
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 12",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    88,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    45.5,
                                    45
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 13",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    148,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -16.5,
                                    35
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 14",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    26,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0.5,
                                    25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 15",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    42,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    38.5,
                                    25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 16",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    27,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    77,
                                    25
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 17",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    104,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    26.5,
                                    15
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 18",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    89,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    46,
                                    5
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 19",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    61,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -60,
                                    -15
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 20",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    52,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    63.5,
                                    -55
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 21",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    124,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -28.5,
                                    -55
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 22",
                            "hd": false
                        },
                        {
                            "ty": "mm",
                            "mm": 2,
                            "nm": "Merge Paths 1",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.882353007793,
                                    0.882353007793,
                                    0.890196025372,
                                    1
                                ]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 0
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "sk": {
                                "a": 0,
                                "k": 0
                            },
                            "sa": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Преобразовать"
                        }
                    ],
                    "nm": "Lines",
                    "bm": 0,
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 21,
            "ty": 4,
            "nm": "Lines old",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 44,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 45,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 52,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 185,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 198.000008064705,
                            "s": [
                                0
                            ]
                        }
                    ]
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 44,
                            "s": [
                                378.75,
                                315.125,
                                0
                            ],
                            "to": [
                                0.333,
                                0,
                                0
                            ],
                            "ti": [
                                -0.333,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 45.0000018328876,
                            "s": [
                                380.75,
                                315.125,
                                0
                            ]
                        }
                    ]
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    63,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -59,
                                    0
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 1",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    125,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -28,
                                    10
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 2",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    52,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    64.5,
                                    -10
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 3",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    125,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    -28,
                                    -10
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 4",
                            "hd": false
                        },
                        {
                            "ty": "rc",
                            "d": 1,
                            "s": {
                                "a": 0,
                                "k": [
                                    109,
                                    6
                                ]
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    31,
                                    0
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 2.438
                            },
                            "nm": "Rectangle Path 5",
                            "hd": false
                        },
                        {
                            "ty": "mm",
                            "mm": 2,
                            "nm": "Merge Paths 1",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.882353007793,
                                    0.882353007793,
                                    0.890196025372,
                                    1
                                ]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ]
                            },
                            "r": {
                                "a": 0,
                                "k": 0
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "sk": {
                                "a": 0,
                                "k": 0
                            },
                            "sa": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Преобразовать"
                        }
                    ],
                    "nm": "Lines",
                    "bm": 0,
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 22,
            "ty": 0,
            "nm": "Channels Cards",
            "refId": "comp_4",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        318.5,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        150,
                        250,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 300,
            "h": 500,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 25,
            "ty": 2,
            "nm": "Channel phone bg.png",
            "cl": "png",
            "refId": "image_14",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        192,
                        302,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 490.000019958109,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 26,
            "ty": 2,
            "nm": "bg.png",
            "cl": "png",
            "refId": "image_15",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 511.000020813457,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": [],
    "chars": [
        {
            "ch": "U",
            "size": 12,
            "style": "Medium",
            "w": 71.4,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -9.067,
                                                0
                                            ],
                                            [
                                                -4.967,
                                                4.6
                                            ],
                                            [
                                                0,
                                                9.334
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                0.467
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.5,
                                                -2.666
                                            ],
                                            [
                                                4.6,
                                                0
                                            ],
                                            [
                                                2.5,
                                                2.667
                                            ],
                                            [
                                                0,
                                                5.267
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -5,
                                                -4.6
                                            ]
                                        ],
                                        "o": [
                                            [
                                                9,
                                                0
                                            ],
                                            [
                                                4.966,
                                                -4.6
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                -0.434,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.734,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.267
                                            ],
                                            [
                                                -2.5,
                                                2.667
                                            ],
                                            [
                                                -4.6,
                                                0
                                            ],
                                            [
                                                -2.5,
                                                -2.666
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.734,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                9.334
                                            ],
                                            [
                                                5,
                                                4.6
                                            ]
                                        ],
                                        "v": [
                                            [
                                                35.8,
                                                1
                                            ],
                                            [
                                                56.75,
                                                -5.9
                                            ],
                                            [
                                                64.2,
                                                -26.8
                                            ],
                                            [
                                                64.2,
                                                -67.5
                                            ],
                                            [
                                                63.55,
                                                -69.3
                                            ],
                                            [
                                                61.8,
                                                -70
                                            ],
                                            [
                                                52.6,
                                                -70
                                            ],
                                            [
                                                50.8,
                                                -69.3
                                            ],
                                            [
                                                50.1,
                                                -67.5
                                            ],
                                            [
                                                50.1,
                                                -26.8
                                            ],
                                            [
                                                46.35,
                                                -14.9
                                            ],
                                            [
                                                35.7,
                                                -10.9
                                            ],
                                            [
                                                25.05,
                                                -14.9
                                            ],
                                            [
                                                21.3,
                                                -26.8
                                            ],
                                            [
                                                21.3,
                                                -67.5
                                            ],
                                            [
                                                20.6,
                                                -69.3
                                            ],
                                            [
                                                18.8,
                                                -70
                                            ],
                                            [
                                                9.6,
                                                -70
                                            ],
                                            [
                                                7.85,
                                                -69.3
                                            ],
                                            [
                                                7.2,
                                                -67.5
                                            ],
                                            [
                                                7.2,
                                                -26.8
                                            ],
                                            [
                                                14.7,
                                                -5.9
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "U",
                                "hd": false
                            }
                        ],
                        "nm": "U",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "P",
            "size": 12,
            "style": "Medium",
            "w": 66,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.434
                                            ],
                                            [
                                                0,
                                                0.734
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.6,
                                                3.7
                                            ],
                                            [
                                                0,
                                                7.2
                                            ],
                                            [
                                                4.6,
                                                3.8
                                            ],
                                            [
                                                8.066,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.433
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                8.066,
                                                0
                                            ],
                                            [
                                                4.6,
                                                -3.7
                                            ],
                                            [
                                                0,
                                                -7.266
                                            ],
                                            [
                                                -4.6,
                                                -3.8
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.734,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ]
                                        ],
                                        "v": [
                                            [
                                                10.2,
                                                0
                                            ],
                                            [
                                                19.8,
                                                0
                                            ],
                                            [
                                                21.6,
                                                -0.65
                                            ],
                                            [
                                                22.3,
                                                -2.4
                                            ],
                                            [
                                                22.3,
                                                -25.8
                                            ],
                                            [
                                                37,
                                                -25.8
                                            ],
                                            [
                                                56,
                                                -31.35
                                            ],
                                            [
                                                62.9,
                                                -47.7
                                            ],
                                            [
                                                56,
                                                -64.3
                                            ],
                                            [
                                                37,
                                                -70
                                            ],
                                            [
                                                10.2,
                                                -70
                                            ],
                                            [
                                                8.45,
                                                -69.3
                                            ],
                                            [
                                                7.8,
                                                -67.5
                                            ],
                                            [
                                                7.8,
                                                -2.4
                                            ],
                                            [
                                                8.5,
                                                -0.7
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "P",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.967,
                                                -1.933
                                            ],
                                            [
                                                0,
                                                -3.466
                                            ],
                                            [
                                                2.033,
                                                -1.833
                                            ],
                                            [
                                                3.866,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4,
                                                0
                                            ],
                                            [
                                                1.966,
                                                1.934
                                            ],
                                            [
                                                0,
                                                3.534
                                            ],
                                            [
                                                -2.034,
                                                1.834
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                22.1,
                                                -37
                                            ],
                                            [
                                                22.1,
                                                -58.8
                                            ],
                                            [
                                                36.5,
                                                -58.8
                                            ],
                                            [
                                                45.45,
                                                -55.9
                                            ],
                                            [
                                                48.4,
                                                -47.8
                                            ],
                                            [
                                                45.35,
                                                -39.75
                                            ],
                                            [
                                                36.5,
                                                -37
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "P",
                                "hd": false
                            }
                        ],
                        "nm": "P",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": " ",
            "size": 12,
            "style": "Medium",
            "w": 22.8,
            "data": {},
            "fFamily": "Rubik"
        },
        {
            "ch": "N",
            "size": 12,
            "style": "Medium",
            "w": 70.7,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.334,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0.734
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.266,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.666,
                                                1.134
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.666,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.734,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.667,
                                                -1.133
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.734,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ]
                                        ],
                                        "v": [
                                            [
                                                10.2,
                                                0
                                            ],
                                            [
                                                18.8,
                                                0
                                            ],
                                            [
                                                20.55,
                                                -0.7
                                            ],
                                            [
                                                21.2,
                                                -2.4
                                            ],
                                            [
                                                21.2,
                                                -45.1
                                            ],
                                            [
                                                49.7,
                                                -1.7
                                            ],
                                            [
                                                52.7,
                                                0
                                            ],
                                            [
                                                60.6,
                                                0
                                            ],
                                            [
                                                62.3,
                                                -0.7
                                            ],
                                            [
                                                63,
                                                -2.5
                                            ],
                                            [
                                                63,
                                                -67.5
                                            ],
                                            [
                                                62.3,
                                                -69.3
                                            ],
                                            [
                                                60.5,
                                                -70
                                            ],
                                            [
                                                51.9,
                                                -70
                                            ],
                                            [
                                                50.15,
                                                -69.3
                                            ],
                                            [
                                                49.5,
                                                -67.5
                                            ],
                                            [
                                                49.5,
                                                -23.9
                                            ],
                                            [
                                                20.9,
                                                -68.3
                                            ],
                                            [
                                                18,
                                                -70
                                            ],
                                            [
                                                10.2,
                                                -70
                                            ],
                                            [
                                                8.45,
                                                -69.3
                                            ],
                                            [
                                                7.8,
                                                -67.5
                                            ],
                                            [
                                                7.8,
                                                -2.4
                                            ],
                                            [
                                                8.5,
                                                -0.7
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "N",
                                "hd": false
                            }
                        ],
                        "nm": "N",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "O",
            "size": 12,
            "style": "Medium",
            "w": 69.5,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -9.134,
                                                0
                                            ],
                                            [
                                                -5.1,
                                                4.6
                                            ],
                                            [
                                                -0.267,
                                                9.067
                                            ],
                                            [
                                                0,
                                                1.8
                                            ],
                                            [
                                                0.133,
                                                3.867
                                            ],
                                            [
                                                5.233,
                                                4.734
                                            ],
                                            [
                                                8.8,
                                                0
                                            ],
                                            [
                                                5.2,
                                                -4.733
                                            ],
                                            [
                                                0.266,
                                                -8.866
                                            ],
                                            [
                                                0,
                                                -3.8
                                            ],
                                            [
                                                -0.067,
                                                -1.933
                                            ],
                                            [
                                                -5.067,
                                                -4.6
                                            ]
                                        ],
                                        "o": [
                                            [
                                                9.066,
                                                0
                                            ],
                                            [
                                                5.1,
                                                -4.6
                                            ],
                                            [
                                                0.133,
                                                -3.866
                                            ],
                                            [
                                                0,
                                                -1.866
                                            ],
                                            [
                                                -0.267,
                                                -8.866
                                            ],
                                            [
                                                -5.234,
                                                -4.733
                                            ],
                                            [
                                                -8.867,
                                                0
                                            ],
                                            [
                                                -5.2,
                                                4.734
                                            ],
                                            [
                                                -0.067,
                                                1.934
                                            ],
                                            [
                                                0,
                                                3.734
                                            ],
                                            [
                                                0.266,
                                                9.067
                                            ],
                                            [
                                                5.066,
                                                4.6
                                            ]
                                        ],
                                        "v": [
                                            [
                                                34.8,
                                                1
                                            ],
                                            [
                                                56.05,
                                                -5.9
                                            ],
                                            [
                                                64.1,
                                                -26.4
                                            ],
                                            [
                                                64.3,
                                                -34.9
                                            ],
                                            [
                                                64.1,
                                                -43.5
                                            ],
                                            [
                                                55.85,
                                                -63.9
                                            ],
                                            [
                                                34.8,
                                                -71
                                            ],
                                            [
                                                13.7,
                                                -63.9
                                            ],
                                            [
                                                5.5,
                                                -43.5
                                            ],
                                            [
                                                5.4,
                                                -34.9
                                            ],
                                            [
                                                5.5,
                                                -26.4
                                            ],
                                            [
                                                13.5,
                                                -5.9
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "O",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                4.466,
                                                0
                                            ],
                                            [
                                                2.633,
                                                2.7
                                            ],
                                            [
                                                0.133,
                                                5.467
                                            ],
                                            [
                                                0,
                                                3.4
                                            ],
                                            [
                                                -0.067,
                                                1.934
                                            ],
                                            [
                                                -2.667,
                                                2.7
                                            ],
                                            [
                                                -4.4,
                                                0
                                            ],
                                            [
                                                -2.667,
                                                -2.7
                                            ],
                                            [
                                                -0.2,
                                                -5.466
                                            ],
                                            [
                                                0,
                                                -1.533
                                            ],
                                            [
                                                0.133,
                                                -4
                                            ],
                                            [
                                                2.6,
                                                -2.666
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -4.467,
                                                0
                                            ],
                                            [
                                                -2.634,
                                                -2.7
                                            ],
                                            [
                                                -0.067,
                                                -2
                                            ],
                                            [
                                                0,
                                                -3.466
                                            ],
                                            [
                                                0.133,
                                                -5.466
                                            ],
                                            [
                                                2.666,
                                                -2.7
                                            ],
                                            [
                                                4.333,
                                                0
                                            ],
                                            [
                                                2.666,
                                                2.7
                                            ],
                                            [
                                                0.133,
                                                3.867
                                            ],
                                            [
                                                0,
                                                1.4
                                            ],
                                            [
                                                -0.2,
                                                5.534
                                            ],
                                            [
                                                -2.6,
                                                2.667
                                            ]
                                        ],
                                        "v": [
                                            [
                                                34.8,
                                                -10.6
                                            ],
                                            [
                                                24.15,
                                                -14.65
                                            ],
                                            [
                                                20,
                                                -26.9
                                            ],
                                            [
                                                19.9,
                                                -35
                                            ],
                                            [
                                                20,
                                                -43.1
                                            ],
                                            [
                                                24.2,
                                                -55.35
                                            ],
                                            [
                                                34.8,
                                                -59.4
                                            ],
                                            [
                                                45.3,
                                                -55.35
                                            ],
                                            [
                                                49.6,
                                                -43.1
                                            ],
                                            [
                                                49.8,
                                                -35
                                            ],
                                            [
                                                49.6,
                                                -26.9
                                            ],
                                            [
                                                45.4,
                                                -14.6
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "O",
                                "hd": false
                            }
                        ],
                        "nm": "O",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "W",
            "size": 12,
            "style": "Medium",
            "w": 81.6,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -1.934,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.534,
                                                1.667
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.6,
                                                -0.5
                                            ],
                                            [
                                                -0.934,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.634,
                                                0.534
                                            ],
                                            [
                                                -0.134,
                                                0.934
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.334
                                            ],
                                            [
                                                0.4,
                                                0.4
                                            ],
                                            [
                                                0.533,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.2,
                                                -1.333
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.533,
                                                0.5
                                            ],
                                            [
                                                0.866,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.666,
                                                -1.666
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.4,
                                                0.3
                                            ],
                                            [
                                                0.866,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.4,
                                                -0.4
                                            ],
                                            [
                                                0,
                                                -0.6
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.8,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.2,
                                                0.667
                                            ],
                                            [
                                                0.6,
                                                0.5
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1,
                                                0
                                            ],
                                            [
                                                0.633,
                                                -0.533
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.066,
                                                -0.2
                                            ],
                                            [
                                                0,
                                                -0.6
                                            ],
                                            [
                                                -0.4,
                                                -0.4
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.667,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.2,
                                                -0.666
                                            ],
                                            [
                                                -0.534,
                                                -0.5
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.534,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.134,
                                                -0.733
                                            ],
                                            [
                                                -0.4,
                                                -0.3
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.6,
                                                0
                                            ],
                                            [
                                                -0.4,
                                                0.4
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                2
                                            ]
                                        ],
                                        "v": [
                                            [
                                                19.3,
                                                0
                                            ],
                                            [
                                                26.1,
                                                0
                                            ],
                                            [
                                                29.6,
                                                -2.5
                                            ],
                                            [
                                                40.8,
                                                -35.7
                                            ],
                                            [
                                                52,
                                                -2.5
                                            ],
                                            [
                                                53.2,
                                                -0.75
                                            ],
                                            [
                                                55.5,
                                                0
                                            ],
                                            [
                                                62.3,
                                                0
                                            ],
                                            [
                                                64.75,
                                                -0.8
                                            ],
                                            [
                                                65.9,
                                                -3
                                            ],
                                            [
                                                77.7,
                                                -67.1
                                            ],
                                            [
                                                77.8,
                                                -67.9
                                            ],
                                            [
                                                77.2,
                                                -69.4
                                            ],
                                            [
                                                75.8,
                                                -70
                                            ],
                                            [
                                                66.8,
                                                -70
                                            ],
                                            [
                                                64,
                                                -68
                                            ],
                                            [
                                                55.8,
                                                -23
                                            ],
                                            [
                                                46.8,
                                                -52
                                            ],
                                            [
                                                45.7,
                                                -53.75
                                            ],
                                            [
                                                43.6,
                                                -54.5
                                            ],
                                            [
                                                38.1,
                                                -54.5
                                            ],
                                            [
                                                34.8,
                                                -52
                                            ],
                                            [
                                                25.9,
                                                -23
                                            ],
                                            [
                                                17.6,
                                                -68
                                            ],
                                            [
                                                16.8,
                                                -69.55
                                            ],
                                            [
                                                14.9,
                                                -70
                                            ],
                                            [
                                                5.9,
                                                -70
                                            ],
                                            [
                                                4.4,
                                                -69.4
                                            ],
                                            [
                                                3.8,
                                                -67.9
                                            ],
                                            [
                                                3.9,
                                                -67.1
                                            ],
                                            [
                                                15.7,
                                                -3
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "W",
                                "hd": false
                            }
                        ],
                        "nm": "W",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "S",
            "size": 19,
            "style": "Bold",
            "w": 66.7,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.923,
                                                -4.524
                                            ],
                                            [
                                                -8.699,
                                                0
                                            ],
                                            [
                                                -5.635,
                                                3.418
                                            ],
                                            [
                                                0,
                                                7.943
                                            ],
                                            [
                                                4.416,
                                                3.353
                                            ],
                                            [
                                                4.7,
                                                1.107
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.861,
                                                1.042
                                            ],
                                            [
                                                0,
                                                3.516
                                            ],
                                            [
                                                -2.403,
                                                2.409
                                            ],
                                            [
                                                -5.493,
                                                0
                                            ],
                                            [
                                                -2.84,
                                                -3.743
                                            ],
                                            [
                                                -0.468,
                                                -3.841
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                5.116,
                                                3.63
                                            ],
                                            [
                                                7.317,
                                                0
                                            ],
                                            [
                                                4.34,
                                                -4.134
                                            ],
                                            [
                                                0,
                                                -6.445
                                            ],
                                            [
                                                -4.415,
                                                -2.995
                                            ],
                                            [
                                                -6.149,
                                                -1.432
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.397,
                                                -1.627
                                            ],
                                            [
                                                0,
                                                -3.906
                                            ],
                                            [
                                                5.582,
                                                -2.018
                                            ],
                                            [
                                                3.512,
                                                0
                                            ],
                                            [
                                                3.104,
                                                4.753
                                            ],
                                            [
                                                0.22,
                                                4.07
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -0.098,
                                                7.748
                                            ],
                                            [
                                                4.92,
                                                4.558
                                            ],
                                            [
                                                7.46,
                                                0
                                            ],
                                            [
                                                5.635,
                                                -3.418
                                            ],
                                            [
                                                0,
                                                -6.38
                                            ],
                                            [
                                                -2.554,
                                                -1.92
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -6.561,
                                                -1.562
                                            ],
                                            [
                                                -2.839,
                                                -1.627
                                            ],
                                            [
                                                0,
                                                -3.19
                                            ],
                                            [
                                                2.403,
                                                -2.409
                                            ],
                                            [
                                                6.803,
                                                0
                                            ],
                                            [
                                                1.529,
                                                2.051
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -7.975
                                            ],
                                            [
                                                -5.116,
                                                -3.629
                                            ],
                                            [
                                                -7.951,
                                                0
                                            ],
                                            [
                                                -4.34,
                                                4.134
                                            ],
                                            [
                                                0,
                                                5.957
                                            ],
                                            [
                                                2.554,
                                                1.726
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                5.109,
                                                1.205
                                            ],
                                            [
                                                2.365,
                                                1.66
                                            ],
                                            [
                                                0,
                                                5.209
                                            ],
                                            [
                                                -2.885,
                                                1.042
                                            ],
                                            [
                                                -7.839,
                                                0
                                            ],
                                            [
                                                -1.63,
                                                -2.539
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                4.834,
                                                -23.145
                                            ],
                                            [
                                                12.072,
                                                -4.736
                                            ],
                                            [
                                                32.5,
                                                2.1
                                            ],
                                            [
                                                52.143,
                                                -3.027
                                            ],
                                            [
                                                60.596,
                                                -20.068
                                            ],
                                            [
                                                53.972,
                                                -34.668
                                            ],
                                            [
                                                43.091,
                                                -39.209
                                            ],
                                            [
                                                33.345,
                                                -41.504
                                            ],
                                            [
                                                20.713,
                                                -45.41
                                            ],
                                            [
                                                16.455,
                                                -53.125
                                            ],
                                            [
                                                20.059,
                                                -61.523
                                            ],
                                            [
                                                31.904,
                                                -65.137
                                            ],
                                            [
                                                46.369,
                                                -59.521
                                            ],
                                            [
                                                49.365,
                                                -50.684
                                            ],
                                            [
                                                58.496,
                                                -50.684
                                            ],
                                            [
                                                50.822,
                                                -68.091
                                            ],
                                            [
                                                32.172,
                                                -73.535
                                            ],
                                            [
                                                13.736,
                                                -67.334
                                            ],
                                            [
                                                7.227,
                                                -51.465
                                            ],
                                            [
                                                13.849,
                                                -38.037
                                            ],
                                            [
                                                26.904,
                                                -33.301
                                            ],
                                            [
                                                36.317,
                                                -31.104
                                            ],
                                            [
                                                47.575,
                                                -26.855
                                            ],
                                            [
                                                51.123,
                                                -18.506
                                            ],
                                            [
                                                42.751,
                                                -7.666
                                            ],
                                            [
                                                33.155,
                                                -6.104
                                            ],
                                            [
                                                16.74,
                                                -13.232
                                            ],
                                            [
                                                13.965,
                                                -23.145
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "S",
                                "hd": false
                            }
                        ],
                        "nm": "S",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "p",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                4.115,
                                                0
                                            ],
                                            [
                                                2.286,
                                                5.404
                                            ],
                                            [
                                                0,
                                                4.037
                                            ],
                                            [
                                                -1.208,
                                                3.255
                                            ],
                                            [
                                                -6.108,
                                                0
                                            ],
                                            [
                                                -2.287,
                                                -5.762
                                            ],
                                            [
                                                0,
                                                -4.166
                                            ],
                                            [
                                                2.727,
                                                -3.434
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -6.076,
                                                0
                                            ],
                                            [
                                                -1.208,
                                                -2.832
                                            ],
                                            [
                                                0,
                                                -5.013
                                            ],
                                            [
                                                2.254,
                                                -6.087
                                            ],
                                            [
                                                6.075,
                                                0
                                            ],
                                            [
                                                1.208,
                                                2.995
                                            ],
                                            [
                                                0,
                                                6.836
                                            ],
                                            [
                                                -2.728,
                                                3.435
                                            ]
                                        ],
                                        "v": [
                                            [
                                                28.516,
                                                -5.908
                                            ],
                                            [
                                                15.973,
                                                -14.014
                                            ],
                                            [
                                                14.16,
                                                -24.316
                                            ],
                                            [
                                                15.973,
                                                -36.719
                                            ],
                                            [
                                                28.516,
                                                -45.85
                                            ],
                                            [
                                                41.058,
                                                -37.207
                                            ],
                                            [
                                                42.871,
                                                -26.465
                                            ],
                                            [
                                                38.78,
                                                -11.06
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "p",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.569,
                                                -0.977
                                            ],
                                            [
                                                -3.954,
                                                0
                                            ],
                                            [
                                                -3.791,
                                                3.092
                                            ],
                                            [
                                                0,
                                                11.394
                                            ],
                                            [
                                                4.144,
                                                4.541
                                            ],
                                            [
                                                5.94,
                                                0
                                            ],
                                            [
                                                2.969,
                                                -1.953
                                            ],
                                            [
                                                1.762,
                                                -2.376
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.928,
                                                2.377
                                            ],
                                            [
                                                2.679,
                                                1.725
                                            ],
                                            [
                                                5.032,
                                                0
                                            ],
                                            [
                                                5.98,
                                                -4.883
                                            ],
                                            [
                                                0,
                                                -8.43
                                            ],
                                            [
                                                -4.145,
                                                -4.541
                                            ],
                                            [
                                                -4.015,
                                                0
                                            ],
                                            [
                                                -2.089,
                                                1.302
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                5.713,
                                                20.85
                                            ],
                                            [
                                                14.502,
                                                20.85
                                            ],
                                            [
                                                14.502,
                                                -5.859
                                            ],
                                            [
                                                19.747,
                                                -0.83
                                            ],
                                            [
                                                29.698,
                                                1.758
                                            ],
                                            [
                                                42.934,
                                                -2.881
                                            ],
                                            [
                                                51.904,
                                                -27.295
                                            ],
                                            [
                                                45.687,
                                                -46.753
                                            ],
                                            [
                                                30.56,
                                                -53.564
                                            ],
                                            [
                                                20.084,
                                                -50.635
                                            ],
                                            [
                                                14.307,
                                                -45.117
                                            ],
                                            [
                                                14.307,
                                                -52.051
                                            ],
                                            [
                                                5.713,
                                                -52.051
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "p",
                                "hd": false
                            }
                        ],
                        "nm": "p",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "e",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                3.688,
                                                0
                                            ],
                                            [
                                                4.529,
                                                -5.241
                                            ],
                                            [
                                                0,
                                                -8.398
                                            ],
                                            [
                                                -4.497,
                                                -4.736
                                            ],
                                            [
                                                -6.47,
                                                0
                                            ],
                                            [
                                                -2.1,
                                                0.52
                                            ],
                                            [
                                                -2.65,
                                                2.605
                                            ],
                                            [
                                                -1.286,
                                                2.361
                                            ],
                                            [
                                                -0.228,
                                                1.921
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.518,
                                                -1.765
                                            ],
                                            [
                                                4.554,
                                                0
                                            ],
                                            [
                                                2.325,
                                                3.215
                                            ],
                                            [
                                                0.162,
                                                5.321
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.517,
                                                2.409
                                            ],
                                            [
                                                1.747,
                                                2.637
                                            ],
                                            [
                                                3.461,
                                                1.742
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.312,
                                                0
                                            ],
                                            [
                                                -4.53,
                                                5.241
                                            ],
                                            [
                                                0,
                                                8.529
                                            ],
                                            [
                                                4.497,
                                                4.736
                                            ],
                                            [
                                                2.649,
                                                0
                                            ],
                                            [
                                                3.909,
                                                -0.912
                                            ],
                                            [
                                                1.582,
                                                -1.497
                                            ],
                                            [
                                                1.286,
                                                -2.36
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.633,
                                                2.322
                                            ],
                                            [
                                                -2.713,
                                                3.041
                                            ],
                                            [
                                                -4.877,
                                                0
                                            ],
                                            [
                                                -2.325,
                                                -3.215
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.273
                                            ],
                                            [
                                                -0.583,
                                                -3.516
                                            ],
                                            [
                                                -1.812,
                                                -2.766
                                            ],
                                            [
                                                -3.462,
                                                -1.741
                                            ]
                                        ],
                                        "v": [
                                            [
                                                28.022,
                                                -53.467
                                            ],
                                            [
                                                10.261,
                                                -45.605
                                            ],
                                            [
                                                3.467,
                                                -25.146
                                            ],
                                            [
                                                10.211,
                                                -5.249
                                            ],
                                            [
                                                26.661,
                                                1.855
                                            ],
                                            [
                                                33.784,
                                                1.074
                                            ],
                                            [
                                                43.622,
                                                -4.199
                                            ],
                                            [
                                                47.925,
                                                -9.985
                                            ],
                                            [
                                                50.195,
                                                -16.406
                                            ],
                                            [
                                                41.553,
                                                -16.406
                                            ],
                                            [
                                                38.326,
                                                -10.275
                                            ],
                                            [
                                                27.425,
                                                -5.713
                                            ],
                                            [
                                                16.621,
                                                -10.535
                                            ],
                                            [
                                                12.891,
                                                -23.34
                                            ],
                                            [
                                                50.928,
                                                -23.34
                                            ],
                                            [
                                                50.151,
                                                -34.863
                                            ],
                                            [
                                                46.657,
                                                -44.092
                                            ],
                                            [
                                                38.747,
                                                -50.854
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "e",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.711,
                                                2.914
                                            ],
                                            [
                                                -4.002,
                                                0
                                            ],
                                            [
                                                -2.389,
                                                -4.231
                                            ],
                                            [
                                                -0.356,
                                                -3.809
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0.161,
                                                -4.492
                                            ],
                                            [
                                                2.711,
                                                -2.913
                                            ],
                                            [
                                                5.584,
                                                0
                                            ],
                                            [
                                                1.291,
                                                2.279
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                13.086,
                                                -30.322
                                            ],
                                            [
                                                17.395,
                                                -41.431
                                            ],
                                            [
                                                27.466,
                                                -45.801
                                            ],
                                            [
                                                39.425,
                                                -39.453
                                            ],
                                            [
                                                41.895,
                                                -30.322
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "e",
                                "hd": false
                            }
                        ],
                        "nm": "e",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "c",
            "size": 19,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.891,
                                                0
                                            ],
                                            [
                                                4.459,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                -9.18
                                            ],
                                            [
                                                -4.07,
                                                -4.996
                                            ],
                                            [
                                                -6.836,
                                                0
                                            ],
                                            [
                                                -3.906,
                                                3.207
                                            ],
                                            [
                                                -0.977,
                                                6.934
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.132,
                                                -2.221
                                            ],
                                            [
                                                3.645,
                                                0
                                            ],
                                            [
                                                2.213,
                                                3.577
                                            ],
                                            [
                                                0,
                                                5.235
                                            ],
                                            [
                                                -1.562,
                                                3.544
                                            ],
                                            [
                                                -5.599,
                                                0
                                            ],
                                            [
                                                -1.855,
                                                -2.132
                                            ],
                                            [
                                                -0.521,
                                                -3.223
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.694,
                                                2.865
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -6.902,
                                                0
                                            ],
                                            [
                                                -4.46,
                                                5.111
                                            ],
                                            [
                                                0,
                                                7.487
                                            ],
                                            [
                                                4.069,
                                                4.997
                                            ],
                                            [
                                                6.087,
                                                0
                                            ],
                                            [
                                                3.906,
                                                -3.206
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.814,
                                                3.859
                                            ],
                                            [
                                                -2.132,
                                                2.222
                                            ],
                                            [
                                                -4.753,
                                                0
                                            ],
                                            [
                                                -2.214,
                                                -3.577
                                            ],
                                            [
                                                0,
                                                -5.202
                                            ],
                                            [
                                                2.409,
                                                -5.462
                                            ],
                                            [
                                                4.102,
                                                0
                                            ],
                                            [
                                                1.855,
                                                2.132
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.749,
                                                -6.998
                                            ],
                                            [
                                                -3.695,
                                                -2.864
                                            ]
                                        ],
                                        "v": [
                                            [
                                                26.611,
                                                -53.809
                                            ],
                                            [
                                                9.57,
                                                -46.143
                                            ],
                                            [
                                                2.881,
                                                -24.707
                                            ],
                                            [
                                                8.984,
                                                -5.981
                                            ],
                                            [
                                                25.342,
                                                1.514
                                            ],
                                            [
                                                40.332,
                                                -3.296
                                            ],
                                            [
                                                47.656,
                                                -18.506
                                            ],
                                            [
                                                39.111,
                                                -18.506
                                            ],
                                            [
                                                34.692,
                                                -9.386
                                            ],
                                            [
                                                26.025,
                                                -6.055
                                            ],
                                            [
                                                15.576,
                                                -11.419
                                            ],
                                            [
                                                12.256,
                                                -24.635
                                            ],
                                            [
                                                14.6,
                                                -37.754
                                            ],
                                            [
                                                26.611,
                                                -45.947
                                            ],
                                            [
                                                35.547,
                                                -42.749
                                            ],
                                            [
                                                39.111,
                                                -34.717
                                            ],
                                            [
                                                47.656,
                                                -34.717
                                            ],
                                            [
                                                40.991,
                                                -49.512
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "c",
                                "hd": false
                            }
                        ],
                        "nm": "c",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "i",
            "size": 19,
            "style": "Bold",
            "w": 22.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.381,
                                                0
                                            ],
                                            [
                                                15.381,
                                                -52.051
                                            ],
                                            [
                                                6.445,
                                                -52.051
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "i",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                -61.768
                                            ],
                                            [
                                                15.381,
                                                -61.768
                                            ],
                                            [
                                                15.381,
                                                -71.729
                                            ],
                                            [
                                                6.445,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "i",
                                "hd": false
                            }
                        ],
                        "nm": "i",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "a",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                2.533
                                            ],
                                            [
                                                -2.718,
                                                1.527
                                            ],
                                            [
                                                -3.184,
                                                0.423
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.689,
                                                0.458
                                            ],
                                            [
                                                -1.073,
                                                0.686
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.883,
                                                -2.373
                                            ],
                                            [
                                                3.092,
                                                0
                                            ],
                                            [
                                                1.855,
                                                1.462
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                -3.345
                                            ],
                                            [
                                                1.604,
                                                -0.909
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.624,
                                                -0.195
                                            ],
                                            [
                                                1.689,
                                                -0.457
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.396
                                            ],
                                            [
                                                -2.898,
                                                1.43
                                            ],
                                            [
                                                -2.539,
                                                0
                                            ],
                                            [
                                                -1.855,
                                                -1.462
                                            ]
                                        ],
                                        "v": [
                                            [
                                                13.184,
                                                -13.897
                                            ],
                                            [
                                                17.261,
                                                -21.204
                                            ],
                                            [
                                                24.443,
                                                -23.203
                                            ],
                                            [
                                                29.755,
                                                -23.885
                                            ],
                                            [
                                                34.725,
                                                -24.863
                                            ],
                                            [
                                                38.867,
                                                -26.578
                                            ],
                                            [
                                                38.867,
                                                -19.509
                                            ],
                                            [
                                                31.543,
                                                -7.858
                                            ],
                                            [
                                                22.559,
                                                -5.713
                                            ],
                                            [
                                                15.967,
                                                -7.905
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "a",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.027,
                                                -2.666
                                            ],
                                            [
                                                0,
                                                -4.844
                                            ],
                                            [
                                                -3.076,
                                                -2.942
                                            ],
                                            [
                                                -4.851,
                                                0
                                            ],
                                            [
                                                -3.353,
                                                1.724
                                            ],
                                            [
                                                -1.791,
                                                2.344
                                            ],
                                            [
                                                -0.716,
                                                -1.139
                                            ],
                                            [
                                                -3.027,
                                                0
                                            ],
                                            [
                                                -0.716,
                                                0.098
                                            ],
                                            [
                                                -1.367,
                                                0.391
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.488,
                                                -0.049
                                            ],
                                            [
                                                0.391,
                                                0
                                            ],
                                            [
                                                0.374,
                                                0.552
                                            ],
                                            [
                                                0,
                                                0.912
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.804,
                                                2.344
                                            ],
                                            [
                                                6.145,
                                                0
                                            ],
                                            [
                                                4.015,
                                                -2.53
                                            ],
                                            [
                                                0.163,
                                                -6.464
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.072,
                                                1.375
                                            ],
                                            [
                                                -4.581,
                                                0
                                            ],
                                            [
                                                -2.128,
                                                -1.356
                                            ],
                                            [
                                                0,
                                                -3.006
                                            ],
                                            [
                                                0.39,
                                                -0.784
                                            ],
                                            [
                                                2.018,
                                                -0.259
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -4.916,
                                                0.618
                                            ],
                                            [
                                                -3.027,
                                                2.666
                                            ],
                                            [
                                                0,
                                                4.422
                                            ],
                                            [
                                                3.076,
                                                2.942
                                            ],
                                            [
                                                4.036,
                                                0
                                            ],
                                            [
                                                3.352,
                                                -1.725
                                            ],
                                            [
                                                0.293,
                                                2.083
                                            ],
                                            [
                                                1.367,
                                                2.148
                                            ],
                                            [
                                                1.237,
                                                0
                                            ],
                                            [
                                                0.716,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.554,
                                                0.098
                                            ],
                                            [
                                                -0.488,
                                                0.049
                                            ],
                                            [
                                                -1.205,
                                                0
                                            ],
                                            [
                                                -0.375,
                                                -0.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -4.948
                                            ],
                                            [
                                                -3.837,
                                                -2.344
                                            ],
                                            [
                                                -5.299,
                                                0
                                            ],
                                            [
                                                -4.015,
                                                2.53
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.325,
                                                -2.715
                                            ],
                                            [
                                                1.917,
                                                -2.486
                                            ],
                                            [
                                                3.963,
                                                0
                                            ],
                                            [
                                                2.128,
                                                1.357
                                            ],
                                            [
                                                0,
                                                1.471
                                            ],
                                            [
                                                -0.684,
                                                1.43
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                20.459,
                                                -29.893
                                            ],
                                            [
                                                8.545,
                                                -24.966
                                            ],
                                            [
                                                4.004,
                                                -13.702
                                            ],
                                            [
                                                8.618,
                                                -2.656
                                            ],
                                            [
                                                20.508,
                                                1.758
                                            ],
                                            [
                                                31.592,
                                                -0.829
                                            ],
                                            [
                                                39.307,
                                                -6.932
                                            ],
                                            [
                                                40.82,
                                                -2.099
                                            ],
                                            [
                                                47.412,
                                                1.123
                                            ],
                                            [
                                                50.342,
                                                0.977
                                            ],
                                            [
                                                53.467,
                                                0.244
                                            ],
                                            [
                                                53.467,
                                                -6.249
                                            ],
                                            [
                                                51.904,
                                                -6.03
                                            ],
                                            [
                                                50.586,
                                                -5.957
                                            ],
                                            [
                                                48.218,
                                                -6.786
                                            ],
                                            [
                                                47.656,
                                                -8.982
                                            ],
                                            [
                                                47.656,
                                                -39.111
                                            ],
                                            [
                                                41.95,
                                                -50.049
                                            ],
                                            [
                                                26.978,
                                                -53.564
                                            ],
                                            [
                                                13.005,
                                                -49.77
                                            ],
                                            [
                                                6.738,
                                                -36.279
                                            ],
                                            [
                                                14.941,
                                                -36.279
                                            ],
                                            [
                                                17.036,
                                                -42.413
                                            ],
                                            [
                                                26.783,
                                                -46.143
                                            ],
                                            [
                                                35.919,
                                                -44.109
                                            ],
                                            [
                                                39.111,
                                                -37.565
                                            ],
                                            [
                                                38.526,
                                                -34.183
                                            ],
                                            [
                                                34.473,
                                                -31.648
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "a",
                                "hd": false
                            }
                        ],
                        "nm": "a",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "l",
            "size": 19,
            "style": "Bold",
            "w": 22.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.689,
                                                0
                                            ],
                                            [
                                                15.479,
                                                0
                                            ],
                                            [
                                                15.479,
                                                -71.729
                                            ],
                                            [
                                                6.689,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "l",
                                "hd": false
                            }
                        ],
                        "nm": "l",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": " ",
            "size": 19,
            "style": "Bold",
            "w": 27.78,
            "data": {},
            "fFamily": "Rubik"
        },
        {
            "ch": "o",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.761,
                                                0
                                            ],
                                            [
                                                2.414,
                                                3.706
                                            ],
                                            [
                                                0,
                                                5.56
                                            ],
                                            [
                                                -2.414,
                                                4.097
                                            ],
                                            [
                                                -5.312,
                                                0
                                            ],
                                            [
                                                -2.446,
                                                -4.812
                                            ],
                                            [
                                                0,
                                                -4.877
                                            ],
                                            [
                                                2.141,
                                                -4.405
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -5.247,
                                                0
                                            ],
                                            [
                                                -2.414,
                                                -3.706
                                            ],
                                            [
                                                0,
                                                -5.787
                                            ],
                                            [
                                                2.414,
                                                -4.097
                                            ],
                                            [
                                                5.987,
                                                0
                                            ],
                                            [
                                                1.545,
                                                3.056
                                            ],
                                            [
                                                0,
                                                5.397
                                            ],
                                            [
                                                -2.141,
                                                4.405
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.026,
                                                -5.713
                                            ],
                                            [
                                                15.535,
                                                -11.272
                                            ],
                                            [
                                                11.914,
                                                -25.172
                                            ],
                                            [
                                                15.535,
                                                -39.997
                                            ],
                                            [
                                                27.122,
                                                -46.143
                                            ],
                                            [
                                                39.772,
                                                -38.924
                                            ],
                                            [
                                                42.09,
                                                -27.025
                                            ],
                                            [
                                                38.879,
                                                -12.321
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                6.691,
                                                0
                                            ],
                                            [
                                                4.428,
                                                -5.203
                                            ],
                                            [
                                                0,
                                                -8.781
                                            ],
                                            [
                                                -4.202,
                                                -4.862
                                            ],
                                            [
                                                -7.144,
                                                0
                                            ],
                                            [
                                                -4.073,
                                                5.496
                                            ],
                                            [
                                                0,
                                                8.424
                                            ],
                                            [
                                                4.622,
                                                4.488
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.467,
                                                0
                                            ],
                                            [
                                                -4.428,
                                                5.204
                                            ],
                                            [
                                                0,
                                                8.196
                                            ],
                                            [
                                                4.202,
                                                4.862
                                            ],
                                            [
                                                8.566,
                                                0
                                            ],
                                            [
                                                4.073,
                                                -5.496
                                            ],
                                            [
                                                0,
                                                -8.716
                                            ],
                                            [
                                                -4.623,
                                                -4.488
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.366,
                                                -53.809
                                            ],
                                            [
                                                9.523,
                                                -46.003
                                            ],
                                            [
                                                2.881,
                                                -25.025
                                            ],
                                            [
                                                9.184,
                                                -5.437
                                            ],
                                            [
                                                26.202,
                                                1.855
                                            ],
                                            [
                                                45.16,
                                                -6.389
                                            ],
                                            [
                                                51.27,
                                                -27.27
                                            ],
                                            [
                                                44.336,
                                                -47.076
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            }
                        ],
                        "nm": "o",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "f",
            "size": 19,
            "style": "Bold",
            "w": 26.03,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.13,
                                                -3.645
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.526,
                                                1.384
                                            ],
                                            [
                                                -2.699,
                                                0
                                            ],
                                            [
                                                -0.412,
                                                -0.016
                                            ],
                                            [
                                                -0.922,
                                                -0.065
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.586,
                                                0.033
                                            ],
                                            [
                                                0.553,
                                                0
                                            ],
                                            [
                                                2.051,
                                                -2.995
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.664
                                            ],
                                            [
                                                0.526,
                                                -1.383
                                            ],
                                            [
                                                0.362,
                                                0
                                            ],
                                            [
                                                0.411,
                                                0.017
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.749,
                                                -0.065
                                            ],
                                            [
                                                -0.586,
                                                -0.032
                                            ],
                                            [
                                                -5.859,
                                                0
                                            ],
                                            [
                                                -1.14,
                                                1.693
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.643,
                                                -60.254
                                            ],
                                            [
                                                8.643,
                                                -52.051
                                            ],
                                            [
                                                1.367,
                                                -52.051
                                            ],
                                            [
                                                1.367,
                                                -45.117
                                            ],
                                            [
                                                8.643,
                                                -45.117
                                            ],
                                            [
                                                8.643,
                                                0
                                            ],
                                            [
                                                17.334,
                                                0
                                            ],
                                            [
                                                17.334,
                                                -45.117
                                            ],
                                            [
                                                26.123,
                                                -45.117
                                            ],
                                            [
                                                26.123,
                                                -52.051
                                            ],
                                            [
                                                17.334,
                                                -52.051
                                            ],
                                            [
                                                18.124,
                                                -62.622
                                            ],
                                            [
                                                22.963,
                                                -64.697
                                            ],
                                            [
                                                24.123,
                                                -64.673
                                            ],
                                            [
                                                26.123,
                                                -64.551
                                            ],
                                            [
                                                26.123,
                                                -72.559
                                            ],
                                            [
                                                24.121,
                                                -72.705
                                            ],
                                            [
                                                22.412,
                                                -72.754
                                            ],
                                            [
                                                10.547,
                                                -68.262
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "f",
                                "hd": false
                            }
                        ],
                        "nm": "f",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "r",
            "size": 19,
            "style": "Bold",
            "w": 33.3,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.377,
                                                2.849
                                            ],
                                            [
                                                -4.427,
                                                0
                                            ],
                                            [
                                                -0.439,
                                                -0.032
                                            ],
                                            [
                                                -0.521,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.391,
                                                0.033
                                            ],
                                            [
                                                0.163,
                                                0
                                            ],
                                            [
                                                2.669,
                                                -2.522
                                            ],
                                            [
                                                0.684,
                                                -1.758
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -3.711
                                            ],
                                            [
                                                2.376,
                                                -2.848
                                            ],
                                            [
                                                0.52,
                                                0
                                            ],
                                            [
                                                0.439,
                                                0.033
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.945,
                                                -0.098
                                            ],
                                            [
                                                -0.391,
                                                -0.032
                                            ],
                                            [
                                                -3.484,
                                                0
                                            ],
                                            [
                                                -2.67,
                                                2.523
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.689,
                                                0
                                            ],
                                            [
                                                15.479,
                                                0
                                            ],
                                            [
                                                15.479,
                                                -30.078
                                            ],
                                            [
                                                19.043,
                                                -39.917
                                            ],
                                            [
                                                29.248,
                                                -44.189
                                            ],
                                            [
                                                30.688,
                                                -44.141
                                            ],
                                            [
                                                32.129,
                                                -43.945
                                            ],
                                            [
                                                32.129,
                                                -53.223
                                            ],
                                            [
                                                30.127,
                                                -53.418
                                            ],
                                            [
                                                29.297,
                                                -53.467
                                            ],
                                            [
                                                20.068,
                                                -49.683
                                            ],
                                            [
                                                15.039,
                                                -43.262
                                            ],
                                            [
                                                15.039,
                                                -52.295
                                            ],
                                            [
                                                6.689,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "r",
                                "hd": false
                            }
                        ],
                        "nm": "r",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "u",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.437,
                                                -2.669
                                            ],
                                            [
                                                -7.315,
                                                0
                                            ],
                                            [
                                                -3.29,
                                                2.734
                                            ],
                                            [
                                                -1.119,
                                                1.986
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.173,
                                                -2.93
                                            ],
                                            [
                                                5.959,
                                                0
                                            ],
                                            [
                                                1.521,
                                                3.125
                                            ],
                                            [
                                                0,
                                                2.67
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                4.46
                                            ],
                                            [
                                                2.65,
                                                4.98
                                            ],
                                            [
                                                4.695,
                                                0
                                            ],
                                            [
                                                1.661,
                                                -1.367
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.111
                                            ],
                                            [
                                                -2.156,
                                                5.469
                                            ],
                                            [
                                                -4.153,
                                                0
                                            ],
                                            [
                                                -0.825,
                                                -1.692
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.25,
                                                -52.295
                                            ],
                                            [
                                                6.25,
                                                -16.748
                                            ],
                                            [
                                                8.406,
                                                -6.055
                                            ],
                                            [
                                                23.354,
                                                1.416
                                            ],
                                            [
                                                35.332,
                                                -2.686
                                            ],
                                            [
                                                39.502,
                                                -7.715
                                            ],
                                            [
                                                39.404,
                                                0
                                            ],
                                            [
                                                47.705,
                                                0
                                            ],
                                            [
                                                47.705,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -26.611
                                            ],
                                            [
                                                37.157,
                                                -14.551
                                            ],
                                            [
                                                24.983,
                                                -6.348
                                            ],
                                            [
                                                16.471,
                                                -11.035
                                            ],
                                            [
                                                15.234,
                                                -17.578
                                            ],
                                            [
                                                15.234,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "u",
                                "hd": false
                            }
                        ],
                        "nm": "u",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "n",
            "size": 19,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.439,
                                                1.839
                                            ],
                                            [
                                                -1.465,
                                                1.726
                                            ],
                                            [
                                                -2.409,
                                                0.716
                                            ],
                                            [
                                                -2.084,
                                                0
                                            ],
                                            [
                                                -1.562,
                                                -3.255
                                            ],
                                            [
                                                0,
                                                -3.19
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.432,
                                                2.832
                                            ],
                                            [
                                                7.422,
                                                0
                                            ],
                                            [
                                                2.766,
                                                -1.334
                                            ],
                                            [
                                                2.473,
                                                -3.059
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -3.418
                                            ],
                                            [
                                                0.439,
                                                -1.839
                                            ],
                                            [
                                                1.823,
                                                -2.148
                                            ],
                                            [
                                                1.334,
                                                -0.423
                                            ],
                                            [
                                                4.102,
                                                0
                                            ],
                                            [
                                                0.944,
                                                1.953
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.273
                                            ],
                                            [
                                                -2.605,
                                                -5.176
                                            ],
                                            [
                                                -3.386,
                                                0
                                            ],
                                            [
                                                -2.767,
                                                1.335
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.234,
                                                0
                                            ],
                                            [
                                                15.234,
                                                -27.393
                                            ],
                                            [
                                                15.894,
                                                -35.278
                                            ],
                                            [
                                                18.75,
                                                -40.625
                                            ],
                                            [
                                                25.098,
                                                -44.922
                                            ],
                                            [
                                                30.225,
                                                -45.557
                                            ],
                                            [
                                                38.721,
                                                -40.674
                                            ],
                                            [
                                                40.137,
                                                -32.959
                                            ],
                                            [
                                                40.137,
                                                0
                                            ],
                                            [
                                                49.072,
                                                0
                                            ],
                                            [
                                                49.072,
                                                -33.545
                                            ],
                                            [
                                                46.924,
                                                -45.703
                                            ],
                                            [
                                                31.885,
                                                -53.467
                                            ],
                                            [
                                                22.656,
                                                -51.465
                                            ],
                                            [
                                                14.795,
                                                -44.873
                                            ],
                                            [
                                                14.795,
                                                -52.295
                                            ],
                                            [
                                                6.445,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "n",
                                "hd": false
                            }
                        ],
                        "nm": "n",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "t",
            "size": 19,
            "style": "Bold",
            "w": 27.78,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.465,
                                                -2.1
                                            ],
                                            [
                                                -4.102,
                                                0
                                            ],
                                            [
                                                -1.091,
                                                0.13
                                            ],
                                            [
                                                -1.009,
                                                0.293
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.488,
                                                -0.021
                                            ],
                                            [
                                                0.423,
                                                0
                                            ],
                                            [
                                                0.684,
                                                0.359
                                            ],
                                            [
                                                0,
                                                1.823
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                3.353
                                            ],
                                            [
                                                1.465,
                                                2.1
                                            ],
                                            [
                                                1.27,
                                                0
                                            ],
                                            [
                                                1.09,
                                                -0.13
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.652,
                                                0.087
                                            ],
                                            [
                                                -0.488,
                                                0.021
                                            ],
                                            [
                                                -1.595,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                -0.618
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.203,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -10.498
                                            ],
                                            [
                                                10.4,
                                                -2.319
                                            ],
                                            [
                                                18.75,
                                                0.83
                                            ],
                                            [
                                                22.29,
                                                0.635
                                            ],
                                            [
                                                25.439,
                                                0
                                            ],
                                            [
                                                25.439,
                                                -6.982
                                            ],
                                            [
                                                23.73,
                                                -6.819
                                            ],
                                            [
                                                22.363,
                                                -6.787
                                            ],
                                            [
                                                18.945,
                                                -7.324
                                            ],
                                            [
                                                17.09,
                                                -10.986
                                            ],
                                            [
                                                17.09,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -66.895
                                            ],
                                            [
                                                8.203,
                                                -66.895
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "t",
                                "hd": false
                            }
                        ],
                        "nm": "t",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "M",
            "size": 19,
            "style": "Bold",
            "w": 83.3,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.098,
                                                3.597
                                            ],
                                            [
                                                0,
                                                1.758
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.065,
                                                -3.385
                                            ],
                                            [
                                                0,
                                                -1.465
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -1.692
                                            ],
                                            [
                                                -0.098,
                                                -3.596
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                3.874
                                            ],
                                            [
                                                -0.066,
                                                3.386
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                7.373,
                                                0
                                            ],
                                            [
                                                16.65,
                                                0
                                            ],
                                            [
                                                16.65,
                                                -42.334
                                            ],
                                            [
                                                16.504,
                                                -50.269
                                            ],
                                            [
                                                16.357,
                                                -58.301
                                            ],
                                            [
                                                16.357,
                                                -60.498
                                            ],
                                            [
                                                37.032,
                                                0
                                            ],
                                            [
                                                46.66,
                                                0
                                            ],
                                            [
                                                67.139,
                                                -60.498
                                            ],
                                            [
                                                67.041,
                                                -49.609
                                            ],
                                            [
                                                66.943,
                                                -42.334
                                            ],
                                            [
                                                66.943,
                                                0
                                            ],
                                            [
                                                76.221,
                                                0
                                            ],
                                            [
                                                76.221,
                                                -71.729
                                            ],
                                            [
                                                62.398,
                                                -71.729
                                            ],
                                            [
                                                41.919,
                                                -11.084
                                            ],
                                            [
                                                21.294,
                                                -71.729
                                            ],
                                            [
                                                7.373,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "M",
                                "hd": false
                            }
                        ],
                        "nm": "M",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "y",
            "size": 19,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.007,
                                                -2.405
                                            ],
                                            [
                                                0.162,
                                                -0.26
                                            ],
                                            [
                                                0.764,
                                                -0.536
                                            ],
                                            [
                                                1.624,
                                                0
                                            ],
                                            [
                                                0.682,
                                                0.098
                                            ],
                                            [
                                                1.527,
                                                0.423
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.667,
                                                -0.098
                                            ],
                                            [
                                                -1.236,
                                                0
                                            ],
                                            [
                                                -2.081,
                                                2.927
                                            ],
                                            [
                                                -5.074,
                                                13.336
                                            ],
                                            [
                                                -3.191,
                                                8.989
                                            ],
                                            [
                                                -1.237,
                                                3.355
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.455,
                                                1.169
                                            ],
                                            [
                                                -1.007,
                                                2.403
                                            ],
                                            [
                                                -0.52,
                                                0.78
                                            ],
                                            [
                                                -0.764,
                                                0.536
                                            ],
                                            [
                                                -0.52,
                                                0
                                            ],
                                            [
                                                -0.682,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.975,
                                                0.26
                                            ],
                                            [
                                                0.667,
                                                0.098
                                            ],
                                            [
                                                5.074,
                                                0
                                            ],
                                            [
                                                2.081,
                                                -2.928
                                            ],
                                            [
                                                2.148,
                                                -5.666
                                            ],
                                            [
                                                4.264,
                                                -11.952
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                25.024,
                                                -9.698
                                            ],
                                            [
                                                11.011,
                                                -52.295
                                            ],
                                            [
                                                1.05,
                                                -52.295
                                            ],
                                            [
                                                20.386,
                                                1.538
                                            ],
                                            [
                                                18.192,
                                                6.898
                                            ],
                                            [
                                                16.438,
                                                10.894
                                            ],
                                            [
                                                14.511,
                                                12.868
                                            ],
                                            [
                                                10.929,
                                                13.672
                                            ],
                                            [
                                                9.125,
                                                13.525
                                            ],
                                            [
                                                5.811,
                                                12.744
                                            ],
                                            [
                                                5.811,
                                                20.752
                                            ],
                                            [
                                                8.274,
                                                21.289
                                            ],
                                            [
                                                11.128,
                                                21.436
                                            ],
                                            [
                                                21.861,
                                                17.044
                                            ],
                                            [
                                                32.593,
                                                -7.352
                                            ],
                                            [
                                                40.601,
                                                -29.335
                                            ],
                                            [
                                                48.853,
                                                -52.295
                                            ],
                                            [
                                                39.136,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "y",
                                "hd": false
                            }
                        ],
                        "nm": "y",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "!",
            "size": 19,
            "style": "Bold",
            "w": 27.78,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                11.572,
                                                -48.145
                                            ],
                                            [
                                                14.111,
                                                -17.09
                                            ],
                                            [
                                                19.092,
                                                -17.09
                                            ],
                                            [
                                                21.484,
                                                -48.145
                                            ],
                                            [
                                                21.484,
                                                -71.729
                                            ],
                                            [
                                                11.572,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "!",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                11.572,
                                                0
                                            ],
                                            [
                                                21.289,
                                                0
                                            ],
                                            [
                                                21.289,
                                                -10.156
                                            ],
                                            [
                                                11.572,
                                                -10.156
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "!",
                                "hd": false
                            }
                        ],
                        "nm": "!",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "C",
            "size": 17,
            "style": "Bold",
            "w": 72.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                8.976,
                                                0
                                            ],
                                            [
                                                5.984,
                                                -7.361
                                            ],
                                            [
                                                0,
                                                -10.586
                                            ],
                                            [
                                                -7.368,
                                                -6.677
                                            ],
                                            [
                                                -8.237,
                                                0
                                            ],
                                            [
                                                -5.631,
                                                6.152
                                            ],
                                            [
                                                -0.837,
                                                7.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.95,
                                                -2.897
                                            ],
                                            [
                                                7.035,
                                                0
                                            ],
                                            [
                                                3.853,
                                                5.229
                                            ],
                                            [
                                                0,
                                                8.406
                                            ],
                                            [
                                                -4.268,
                                                4.968
                                            ],
                                            [
                                                -6.937,
                                                0
                                            ],
                                            [
                                                -3.149,
                                                -2.702
                                            ],
                                            [
                                                -1.055,
                                                -4.622
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.955,
                                                4.785
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -10.521,
                                                0
                                            ],
                                            [
                                                -5.534,
                                                6.776
                                            ],
                                            [
                                                0,
                                                13.942
                                            ],
                                            [
                                                5.598,
                                                5.049
                                            ],
                                            [
                                                9.557,
                                                0
                                            ],
                                            [
                                                4.697,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.96,
                                                4.72
                                            ],
                                            [
                                                -3.677,
                                                5.502
                                            ],
                                            [
                                                -7.642,
                                                0
                                            ],
                                            [
                                                -3.854,
                                                -5.229
                                            ],
                                            [
                                                0,
                                                -10.262
                                            ],
                                            [
                                                4.268,
                                                -4.968
                                            ],
                                            [
                                                5.69,
                                                0
                                            ],
                                            [
                                                3.149,
                                                2.702
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.547,
                                                -6.087
                                            ],
                                            [
                                                -4.955,
                                                -4.785
                                            ]
                                        ],
                                        "v": [
                                            [
                                                37.453,
                                                -73.682
                                            ],
                                            [
                                                12.695,
                                                -62.64
                                            ],
                                            [
                                                4.395,
                                                -36.597
                                            ],
                                            [
                                                15.447,
                                                -5.669
                                            ],
                                            [
                                                36.201,
                                                1.904
                                            ],
                                            [
                                                58.984,
                                                -7.324
                                            ],
                                            [
                                                67.285,
                                                -26.318
                                            ],
                                            [
                                                57.812,
                                                -26.318
                                            ],
                                            [
                                                53.448,
                                                -14.893
                                            ],
                                            [
                                                37.379,
                                                -6.641
                                            ],
                                            [
                                                20.135,
                                                -14.484
                                            ],
                                            [
                                                14.355,
                                                -34.936
                                            ],
                                            [
                                                20.757,
                                                -57.782
                                            ],
                                            [
                                                37.564,
                                                -65.234
                                            ],
                                            [
                                                50.823,
                                                -61.182
                                            ],
                                            [
                                                57.129,
                                                -50.195
                                            ],
                                            [
                                                66.602,
                                                -50.195
                                            ],
                                            [
                                                58.349,
                                                -66.504
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "C",
                                "hd": false
                            }
                        ],
                        "nm": "C",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "h",
            "size": 17,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.962,
                                                2.507
                                            ],
                                            [
                                                -3.646,
                                                0
                                            ],
                                            [
                                                -1.595,
                                                -2.864
                                            ],
                                            [
                                                0,
                                                -3.841
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.465,
                                                2.898
                                            ],
                                            [
                                                7.584,
                                                0
                                            ],
                                            [
                                                2.832,
                                                -1.855
                                            ],
                                            [
                                                2.083,
                                                -2.637
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -6.966
                                            ],
                                            [
                                                2.962,
                                                -2.506
                                            ],
                                            [
                                                4.395,
                                                0
                                            ],
                                            [
                                                0.977,
                                                1.791
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -5.143
                                            ],
                                            [
                                                -2.702,
                                                -5.305
                                            ],
                                            [
                                                -4.232,
                                                0
                                            ],
                                            [
                                                -1.66,
                                                1.074
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.445,
                                                0
                                            ],
                                            [
                                                15.234,
                                                0
                                            ],
                                            [
                                                15.234,
                                                -27.734
                                            ],
                                            [
                                                19.678,
                                                -41.943
                                            ],
                                            [
                                                29.59,
                                                -45.703
                                            ],
                                            [
                                                38.574,
                                                -41.406
                                            ],
                                            [
                                                40.039,
                                                -32.959
                                            ],
                                            [
                                                40.039,
                                                0
                                            ],
                                            [
                                                49.072,
                                                0
                                            ],
                                            [
                                                49.072,
                                                -33.545
                                            ],
                                            [
                                                46.875,
                                                -45.605
                                            ],
                                            [
                                                31.445,
                                                -53.564
                                            ],
                                            [
                                                20.85,
                                                -50.781
                                            ],
                                            [
                                                15.234,
                                                -45.215
                                            ],
                                            [
                                                15.234,
                                                -71.973
                                            ],
                                            [
                                                6.445,
                                                -71.973
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "h",
                                "hd": false
                            }
                        ],
                        "nm": "h",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        }
    ]
}