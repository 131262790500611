import React from 'react';
import { NavLink } from 'react-router-dom';

import FooterPost1 from '../../assets/img/post/footer-post1.jpg';
import FooterPost2 from '../../assets/img/post/footer-post2.jpg';
import FooterPost3 from '../../assets/img/post/footer-post3.jpg';

import FooterLogo from '../../assets/img/footer-logo.png';

class FooterComponent extends React.Component {
    render() {
        return <footer className="c-footer">
            <div className="container">
                <div className="c-footer__inner">
                    <div className="c-footer__inner__col col-list">
                        <div className="c-footer__side">
                            <span className="c-footer__side__title">INFORMATION</span>
                            <ul className="c-footer__list h-reset-list">
                                <li><NavLink to={"/imprint"}>Imprint</NavLink></li>
                                <li><a href="#">Contacts</a></li>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Become a partner</a></li>
                                <li><NavLink to={"/privacy-policy"}>Privacy Policy</NavLink></li>
                                <li><NavLink to={"/terms-and-conditions"}>Terms and Conditions</NavLink></li>
                            </ul>
                        </div>
                        <div className="c-footer__side">
                            <span className="c-footer__side__title">SHOPPING</span>
                            <ul className="c-footer__list h-reset-list">
                                <li><a href="#">Fashion</a></li>
                                <li><a href="#">Health & Beauty</a></li>
                                <li><a href="#">Electronics</a></li>
                                <li><a href="#">Sports & Leisure</a></li>
                                <li><a href="#">Cars & Motorcycles</a></li>
                                <li><a href="#" className="decor--blue">Show all</a></li>
                            </ul>
                        </div>
                        <div className="c-footer__side">
                            <span className="c-footer__side__title">CHANNELS</span>
                            <ul className="c-footer__list h-reset-list">
                                <li><a href="#">Business</a></li>
                                <li><a href="#">Beauty</a></li>
                                <li><a href="#">Technology</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="c-footer__inner__col col-contacts">
                        <div className="c-footer__side">
                            <div className="c-post-link-wrapper">
                                <span className="c-footer__side__title">RECENT POSTS</span>
                                <a href="#" className="c-post-link">
                                    <div className="c-post-link__img">
                                        <img src={FooterPost1} alt="post" />
                                    </div>
                                    <div className="c-post-link__text">
                                        <span className="c-post-link__title">Cost cutting ideas for your wedding</span>
                                        <span className="c-post-link__subtitle">by admin</span>
                                    </div>
                                </a>
                                <a href="#" className="c-post-link">
                                    <div className="c-post-link__img">
                                        <img src={FooterPost2} alt="post" />
                                    </div>
                                    <div className="c-post-link__text">
                                        <span className="c-post-link__title">Big savings on gas while you travel</span>
                                        <span className="c-post-link__subtitle">by admin</span>
                                    </div>
                                </a>
                                <a href="#" className="c-post-link">
                                    <div className="c-post-link__img">
                                        <img src={FooterPost3} alt="post" />
                                    </div>
                                    <div className="c-post-link__text">
                                        <span className="c-post-link__title">Addiction when gambling becomes a problem</span>
                                        <span className="c-post-link__subtitle">by admin</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="c-footer__side">
                            <div className="c-footer__socials">
                                <span className="c-footer__side__title">FOLLOW US</span>
                                <div className="c-footer__socials__inner">
                                    <a href="#" className="c-footer__socials__item">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9.4 19.7" style={{ enableBackground: 'new 0 0 9.4 19.7' }} xmlSpace="preserve" className="icon icon-facebook">
                                            <path fill="currentColor" d="M6.3,6.7V5c0-0.3,0-0.5,0-0.6c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.8-0.1h1.7V0 H6.6C5,0,3.8,0.4,3.1,1.2C2.4,1.9,2.1,3.1,2.1,4.6v2.1H0v3.5h2.1v9.4h4.2v-9.4h2.8l0.4-3.5H6.3z"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="c-footer__socials__item">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 14.6" style={{ enableBackground: 'new 0 0 18 14.6' }} xmlSpace="preserve" className="icon icon-twitter">
                                            <path fill="currentColor" d="M18,1.7c-0.7,0.3-1.4,0.5-2.1,0.6c0.8-0.5,1.3-1.2,1.6-2C16.8,0.7,16,1,15.2,1.2C14.5,0.4,13.5,0,12.5,0 c-2,0-3.7,1.6-3.7,3.7c0,0.3,0,0.6,0.1,0.8C5.8,4.4,3.1,2.9,1.3,0.7C0.9,1.2,0.8,1.8,0.8,2.5c0,1.3,0.7,2.4,1.6,3.1 c-0.6,0-1.2-0.2-1.7-0.5v0c0,1.8,1.3,3.3,3,3.6c-0.3,0.1-0.6,0.1-1,0.1c-0.2,0-0.5,0-0.7-0.1c0.5,1.5,1.8,2.5,3.4,2.6 c-1.3,1-2.9,1.6-4.6,1.6c-0.3,0-0.6,0-0.9-0.1c1.6,1,3.6,1.7,5.7,1.7c6.8,0,10.5-5.6,10.5-10.5l0-0.5C16.9,3.1,17.5,2.5,18,1.7z"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="c-footer__socials__item">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 980.1 980" style={{ enableBackground: 'new 0 0 980.1 980' }} xmlSpace="preserve" className="icon icon-insta">
                                            <path d="M876.6,0h-770C48.7,0,0,48.2,0,106.2v35v280v455C0,934.2,48.7,980,106.7,980h770c58,0,103.4-45.8,103.4-103.8v-455v-280v-35 C980,48.2,934.6,0,876.6,0z M491.6,346.8c79.6,0,144.4,64.8,144.4,144.4s-64.8,144.4-144.4,144.4s-144.4-64.8-144.4-144.4 C347.3,411.6,412,346.8,491.6,346.8z M839,839H139.8V419.5h80.6c-5.8,22.4-8.8,46.7-8.8,70.8c0,154.6,125.4,280.4,280,280.4 s280-126,280-280.6c0-24.2-3.1-48.2-8.8-70.6H839V839L839,839z M839,244.7c0,19.3-15.7,35-35,35h-69.8c-19.3,0-35-15.7-35-35v-69.8 c0-19.3,15.7-35,35-35H804c19.3,0,35,15.7,35,35V244.7z"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="c-footer__socials__item">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16.4 20.1" style={{ enableBackground: 'new 0 0 16.4 20.1' }} xmlSpace="preserve" className="icon icon-pinterest">
                                            <path fill="currentColor" d="M4.7,10.4c-0.2,1-0.4,1.4-0.5,2.1l0,0C3.8,14,3.6,15,3.5,15.4c-0.2,0.7-0.2,1.7-0.1,2.8 c0.1,0.7,0.1,1.2,0.2,1.7C3.6,20.2,4,20.2,4.2,20c0.2-0.2,0.6-0.8,0.9-1.4c0.6-0.9,1-1.8,1.2-2.5c0.1-0.2,0.2-0.7,0.4-1.4l0,0 c0.2-0.6,0.2-0.6,0.3-1l0,0c0.6,0.6,1.7,1.1,2.8,1.1c3.8,0,6.7-3.4,6.7-7.7c0-4.1-3.5-7.1-8-7.1C3.3,0,0,3.2,0,7.5 c0,2,1.1,4.1,2.7,4.8C3,12.4,3.3,12.3,3.4,12c0-0.1,0.1-0.3,0.2-0.8c0.1-0.3,0.1-0.5,0.2-0.6c0.1-0.2,0-0.4-0.1-0.5 C3.1,9.5,2.7,8.4,2.7,7.5c0-2.7,2.2-5,5.4-5c3,0,5,1.9,5,4.6c0,3.1-1.6,5.3-3.7,5.3c-1.1,0-1.9-0.9-1.6-1.9c0.1-0.2,0.1-0.5,0.2-0.7 c0.1-0.2,0.1-0.4,0.2-0.7c0.5-1.6,0.6-2,0.6-2.6c0-1.1-0.7-1.9-1.8-1.9C5.7,4.6,4.6,6,4.6,7.7c0,0.5,0.1,0.9,0.2,1.3 c0.1,0.2,0.1,0.3,0.2,0.5L4.7,10.4z"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-footer__app">
                                <span className="c-footer__side__title">Get the app</span>
                                <p>Enter your phone number and we will send you a link to the app!</p>
                                <div className="input-wrap">
                                    <input type="text" placeholder="Phone number here" className="form-control" />
                                    <button type="submit" className="form-submit">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19.5 17.2" style={{ enableBackground: 'new 0 0 19.5 17.2' }} xmlSpace="preserve" className="icon icon-plane">
                                            <path fill="currentColor" d="M0,16.2c0,0.7,0.7,1.2,1.4,0.9l17.5-7.5c0.8-0.3,0.8-1.5,0-1.8L0.7,0C0.4-0.1,0,0.1,0,0.5l0,5.8 c0,0.3,0.2,0.5,0.4,0.5L15,8.7L0.4,10.7c-0.2,0-0.4,0.2-0.4,0.5L0,16.2z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="c-footer__bottom">
                <div className="container">
                    <div className="c-footer__bottom__inner">
                        <a href="#" className="c-footer-logo">
                            <img src={FooterLogo} alt="logo" />
                        </a>
                        <p className="c-footer__bottom__copy">Copyright © 2019 FellowTime GmbH</p>
                    </div>
                </div>
            </div>
        </footer>
    }
}

export default FooterComponent;