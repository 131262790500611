import React, { useState, useEffect } from "react";
import axios from "axios";
import Swiper from "react-id-swiper";
import { NavLink } from "react-router-dom";

const Product = ({ data, onOpenModal }) => {
  const { CompanyId, OfferId } = data;

  const [el, setRecent] = useState(null);
  const [length, setLength] = useState(null);

  useEffect(() => {
    axios
      .get(
        `/api/v1/business/getProductsByEANummer?offerId=${OfferId}&companyId=${CompanyId}`
      )
      .then(({ data }) => {
        setRecent(data.SelectedItem);
        setLength(data.Data.length);
      });
  }, []);
  return (
    el && (
      <div className="c-shopping-grid-col">
        <div className="c-shopping-good" style={{ width: 245 + "px" }}>
          <a
            href="#"
            className="c-shopping-good__img h-object-fit"
            onClick={() => onOpenModal(el)}
          >
            <img src={el && el.Images[0]} alt="img" />
          </a>
          <div className="c-shopping-good__content">
            <a
              href="#"
              className="c-shopping-good__title bold"
              onClick={() => onOpenModal(el)}
            >
              {el && el.Title}
            </a>
            <p className="c-shopping-good__text">{el && el.Description}</p>
            <strong className="c-shopping-good__price">
              {el && el.Price} {el && el.Currency}
              {length > 1 && (
                <>
                  +{" "}
                  <NavLink to={`/offer/${el.OfferId}/${el.CompanyId}`}>
                    {length} offers
                  </NavLink>
                </>
              )}
            </strong>
          </div>
        </div>
      </div>
    )
  );
};

export default ({ onOpenModal }) => {
  const [recent, setRecent] = useState(null);
  const newId = Math.round(new Date().getTime() / 1000);
  const params = {
    slidesPerView: "auto",
    spaceBetween: 5,
    freeMode: false,
    // runCallbacksOnInit: true,
    // rebuildOnUpdate: true,
    navigation: {
      nextEl: ".nextSlide",
      prevEl: ".prevSlide",
    },
  };
  useEffect(() => {
    const recent = window.localStorage.getItem("fellowtime_recent");
    if (recent) {
      let recentData = JSON.parse(recent);
      if (recentData) {
        setRecent(recentData.reverse());
      }
    }
  }, []);

  return (
    recent && (
      <div className="container recent-block">
        <h2>Recently viewed products</h2>
        <div className="recent-slider">
          {recent && (
            <Swiper {...params}>
              {recent.map((el, key) => {
                return (
                  <div key={key}>
                    <Product data={el} onOpenModal={onOpenModal} />
                  </div>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    )
  );
};
