/** @format */

import React from 'react';
import $ from "jquery";
import HeaderComponent from '../components/header/index';
import FooterComponent from "../components/footer";

export default class Layout extends React.Component {
    constructor() {
        super();
        this.state = {
            menu: false
        }
        this.menuTrigger = this.menuTrigger.bind(this);
        this.menuClose = this.menuClose.bind(this);

    }
    componentDidMount() {
        this.menuClose();
    }
    showDialog(scrlTop) {
        var body = document.body;
        var scrollWidthCheck = document.createElement('div');
        scrollWidthCheck.style.overflowY = 'scroll';
        scrollWidthCheck.style.width = '50px';
        scrollWidthCheck.style.height = '50px';
        body.append(scrollWidthCheck);
        var scrollWidth = scrollWidthCheck.offsetWidth - scrollWidthCheck.clientWidth;
        scrollWidthCheck.remove();
        body.style.paddingRight = scrollWidth + 'px';
        body.querySelector('.c-header').style.paddingRight = scrollWidth + 'px';
        body.style.width = '100%';
        body.style.top = `-${scrlTop}`;
    };

    closeDialog() {
        var body = document.body;
        var scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.width = '';
        body.style.overflow = '';
        body.style.padding = '';
        body.querySelector('.c-header').style.padding = '';
    }
    menuTrigger() {
        this.setState({
            menu: !this.state.menu
        }, () => {
            if (this.state.menu) {
                $('body').addClass('modal-active');
                this.showDialog(this.scrlTop);
            } else {
                $('body').removeClass('modal-active');
                this.closeDialog();
            }
        });
    }
    menuClose() {
        this.setState({
            menu: false
        }, () => {
            $('body').removeClass('modal-active');
            this.closeDialog();
        });
    }
    componentWillUnmount() {
        this.menuClose();
    }
    render() {
        return (<div>
            <HeaderComponent menuTrigger={this.menuTrigger} menuClose={this.menuClose} menu={this.state.menu} lg={this.props.lg} animated={this.props.animated} />
            {this.props.children}
            <FooterComponent />
            <div className="modal-overlay" onClick={this.menuTrigger.bind(this)}></div>
        </div>)
    }
}