import React from 'react';
import Triangle from '../../assets/img/dekor/triangle.svg';
import otto from '../../assets/img/top-stores/otto.jpeg';
import bettenrid from '../../assets/img/top-stores/bettenrid.jpeg';
import fleurop from '../../assets/img/top-stores/fleurop.png';
import ffh from '../../assets/img/top-stores/ffh.jpeg';
import home24 from '../../assets/img/top-stores/home24.jpeg';
import galeria from '../../assets/img/top-stores/galeria.png';
import klingel from '../../assets/img/top-stores/klingel.jpeg';
import kare from '../../assets/img/top-stores/kare.png';
import schwab from '../../assets/img/top-stores/schwab.png';

class cTopStores extends React.Component {
    render() {
        return <section className="c-top-stores">
            <div className="c-top-stores__triangle">
                <img src={Triangle} alt="triangle" />
            </div>
            <div className="container-sm">
                <h2 className="section-title">Top stores</h2>
                <div className="c-top-stores__inner js-magic-parent">
                    <div className="c-top-stores__magic-trigger js-magic-trigger"></div>
                    <svg width="946" height="548" viewBox="0 0 946 548" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M1.96411 1.84668C106.709 29.311 192.088 21.8494 248.525 21.8494C365.425 21.8494 408.434 42.2257 449.074 41.8523C537.879 41.0364 573.637 21.8494 631.619 21.8494C742.715 21.8494 818.214 1.84668 892.683 1.84668C967.151 1.84668 947.446 105.823 911.954 126.864C876.463 147.905 853.541 153.386 800.66 161.868C753.955 169.36 590.515 161.869 539.596 181.871C515.622 191.289 393.406 181.871 357.295 181.871C341.546 181.871 233.91 161.868 171.506 161.868C144.842 161.868 25.0184 126.864 25.0184 236.879C25.0184 431.841 411.108 412.696 574.603 450.312C637.56 464.797 692.914 500.875 736 549.192" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 946 551" style={{ enableBackground: 'new 0 0 946 551' }} xmlSpace="preserve" className="animated-line">
                        <path id="c-line__4" fill="none" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M736,549.2c-43.1-48.3-98.4-84.4-161.4-98.9C411.1,412.7,25,431.8,25,236.9c0-110,119.8-75,146.5-75 c62.4,0,170,20,185.8,20c36.1,0,158.3,9.4,182.3,0c50.9-20,214.4-12.5,261.1-20c52.9-8.5,75.8-14,111.3-35 c35.5-21,55.2-125-19.3-125c-74.5,0-150,20-261.1,20c-58,0-93.7,19.2-182.5,20c-40.6,0.4-83.6-20-200.5-20 c-56.4,0-141.8,7.5-246.6-20"></path>
                    </svg>
                    <div className="c-top-stores-col">
                        <a href="#" className="c-top-stores__item">
                            <img src={otto} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col dekor--mt-20">
                        <a href="#" className="c-top-stores__item">
                            <img src={bettenrid} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col dekor--mt-40">
                        <a href="#" className="c-top-stores__item">
                            <img src={fleurop} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col dekor--mt-20">
                        <a href="#" className="c-top-stores__item">
                            <img src={ffh} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col">
                        <a href="#" className="c-top-stores__item">
                            <img src={home24} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col">
                        <a href="#" className="c-top-stores__item">
                            <img src={galeria} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col dekor--mt-20">
                        <a href="#" className="c-top-stores__item">
                            <img src={klingel} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col dekor--mt-20">
                        <a href="#" className="c-top-stores__item">
                            <img src={kare} alt="img" />
                        </a>
                    </div>
                    <div className="c-top-stores-col">
                        <a href="#" className="c-top-stores__item">
                            <img src={schwab} alt="img" />
                        </a>
                    </div>
                </div>
                <div className="c-top-stores__action">
                    <a href="#" className="btn btn--blue">
                        Show all
                </a>
                </div>
            </div>
        </section>
    }
}

export default cTopStores;