import React from 'react';
import $ from "jquery";
import axios from 'axios';
import Layout from '../containers/layout';
import QRCode from '../assets/img/qr_code.png';
import IconFire from '../assets/img/icon-fire.png';
import appleLogo from '../assets/img/apple.png';
import ShopModalImg from '../assets/img/shopping-modal/shop-modal-img.jpg';

class ChannelBiggerViewPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            menu: false,
            posts: [],
            bloggerData: null,
            load: true
        }
        this.getData = this.getData.bind(this);
        this.parseDate = this.parseDate.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getData();
        }
    }
    getData() {
        let alias = window.location.pathname.substr(1).split('channel-bigger-view/')[1];
        axios.get(`/api/v1/blogs/user/${alias}`).then((result) => {
            this.setState({
                posts: result.data.Posts,
                bloggerData: result.data,
                load: false
            })
        });
    }
    parseDate(date) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da} ${mo} ${ye}`
    }

    render() {
        const { posts, load } = this.state;
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <div className="c-search">
                    <div className="c-search-top">
                        <div className="container">
                            <div className="c-search-top__inner">
                                <form action="#" className="c-search-form">
                                    <input type="text" placeholder="Search for products, sellers, channels…" className="form-control" />
                                    <button type="submit" className="c-search-form__submit">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 24.9" style={{
                                            enableBackground: 'new 0 0 23.6 24.9'
                                        }} xmlSpace="preserve" className="icon icon-search" >
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10.3,19c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8S5.4,19,10.3,19z M15.9,17.1l6.3,6.3"></path>
                                        </svg>
                                    </button>
                                </form>
                                <button type="button" className="c-search__close">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: 'new 0 0 16 16' }} xmlSpace="preserve" className="icon-close">
                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,1.5l13,13 M14.5,1.5l-13,13"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="c-search-autocomplete">
                        <div className="container">
                            <div className="c-search-autocomplete__inner">
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                                <a href="#" className="c-search-autocomplete__link">camo</a>
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                                <a href="#" className="c-search-autocomplete__link">camo</a>
                                <a href="#" className="c-search-autocomplete__link">cardigan</a>
                            </div>
                        </div>
                    </div>
                    <div className="c-search-trendy">
                        <div className="container">
                            <div className="c-search-trendy__inner">
                                <strong className="c-search-trendy__title">Trendy searches</strong>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Laptop</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Shoes</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Bathroom Accessories</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Wall Décor</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Kitchen Storage</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Sports Equipment</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Organisers</span>
                                </a>
                                <a href="#" className="c-search-trendy__tag">
                                    <img src={IconFire} alt="fire" className="icon-fire" />
                                    <span>Headphones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <main>
                    <section className="c-channel-info">
                        <div className="container">
                            <div className="c-channel-info__inner">
                                <div className="c-channel-app">
                                    <div className="c-channel-app-about">
                                        {load && <div className="collapse_loader show"></div>}
                                        <div className="c-channel-app-about__top">
                                            <div className="c-channel-app-about__img h-object-fit">
                                                {this.state.bloggerData && <img src={this.state.bloggerData.Image} alt="img" />}
                                            </div>
                                            <div className="c-channel-app-about__main-info">
                                                <h2 className="c-channel-app-about__title">{this.state.bloggerData ? this.state.bloggerData.Name : ''}</h2>
                                                {/* <p className="c-channel-app-about__subtitle">13563 subscribers, public channel</p> */}
                                            </div>
                                        </div>
                                        <p>{this.state.bloggerData ? this.state.bloggerData.Description : ''}</p>
                                        <a href="#" className="btn btn--white">
                                            <img src={appleLogo} alt="apple" className="apple-icon" />
                                            <span>Download app on the App Store</span>
                                        </a>
                                    </div>
                                    <div className="c-channel-app-get"><strong className="c-channel-app-get__title">Get the FellowTime app</strong>
                                        <p>Enter your phone number and we will send you the link to the app!</p>
                                        <form action="#" className="c-channel-app-get__form">
                                            <div className="input-wrap">
                                                <input type="text" placeholder="Phone number here" className="form-control" />
                                                <button type="submit" className="form-submit">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19.5 17.2" style={{ enableBackground: 'new 0 0 19.5 17.2' }} xmlSpace="preserve" className="icon icon-plane">
                                                        <path fill="currentColor" d="M0,16.2c0,0.7,0.7,1.2,1.4,0.9l17.5-7.5c0.8-0.3,0.8-1.5,0-1.8L0.7,0C0.4-0.1,0,0.1,0,0.5l0,5.8 c0,0.3,0.2,0.5,0.4,0.5L15,8.7L0.4,10.7c-0.2,0-0.4,0.2-0.4,0.5L0,16.2z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </form>
                                        <p>or scan the QR code on your phone</p>
                                        <div className="c-channel-app-get__qr-code">
                                            <img src={QRCode} alt="qr-code" />
                                        </div>
                                    </div>
                                </div>
                                <div className="c-channel-list-wrap">
                                    <div className="c-channel-list__day-block">
                                        <div className="c-channel-list-inner">
                                            {
                                                posts.map((el, key) => {
                                                    return <div className="c-channel-list-block" key={key}>
                                                        <div className="c-channel-list-block__top">
                                                            <span className="c-channel-list-block__time">
                                                                {this.parseDate(el.PublishingDate)}
                                                            </span>
                                                            {/* <div className="c-channel-list-block__views">
                                                            <svg x="0" y="0" width="36" height="36" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="icon icon-view">
                                                                <defs>
                                                                    <clippath id="c_11">
                                                                        <path d="M0,0H36V36H0Z"></path>
                                                                    </clippath>
                                                                    <clippath id="c00">
                                                                        <path d="M-61,1364.3V-1645.3H284.2V1364.3Z"></path>
                                                                    </clippath>
                                                                    <clippath id="c11">
                                                                        <path d="M18.3,22.9c-1.2,0,-2.3,-.4,-3.2,-1.3c-1.8,-1.8,-1.8,-4.7,0,-6.5c.9,-.9,2,-1.3,3.2,-1.3c1.2,0,2.4,.4,3.3,1.3c1.7,1.8,1.7,4.7,0,6.5c-.9,.9,-2.1,1.3,-3.3,1.3Zm0,3.8c4.7,0,8.4,-3.7,8.4,-8.4C26.7,13.7,23,9.9,18.3,9.9c-4.6,0,-8.4,3.8,-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4Zm0,5.4C9.9,32.1,2.7,26.4,0,18.4C2.7,10.4,9.9,4.6,18.3,4.6c8.5,0,15.7,5.8,18.3,13.8C34,26.4,26.8,32.1,18.3,32.1Z"></path>
                                                                    </clippath>
                                                                </defs>
                                                                <path d="M0,0									L0,36									L36,36									L36,0 Z " fill="#FFFFFF" stroke="none"></path>
                                                                <g clipPath="url(#c_11)">
                                                                    <g clipPath="url(#c11)">
                                                                        <path d="M-7.5,-2.9H44.3V39.7H-7.5Z" fill="currentColor"></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <span>2.1K</span>
                                                        </div> */}
                                                            {/* <div className="c-channel-list-block__comments">
                                                            <svg x="0" y="0" width="36" height="36" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="icon icon-comments">
                                                                <defs>
                                                                    <clippath id="c_1">
                                                                        <path d="M0,0H36V36H0Z"></path>
                                                                    </clippath>
                                                                    <clippath id="c0">
                                                                        <path d="M-120.6,1364.3V-1645.3H224.6V1364.3Z"></path>
                                                                    </clippath>
                                                                    <clippath id="c1">
                                                                        <path d="M21.1,23c1,0,1.8,-.8,1.8,-1.7c0,-1,-.8,-1.8,-1.8,-1.8H15.2c-.9,0,-1.7,.8,-1.7,1.8c0,.9,.8,1.7,1.7,1.7Zm2.5,-7c1,0,1.8,-.8,1.8,-1.8c0,-1,-.8,-1.7,-1.8,-1.7H11.9c-1,0,-1.8,.7,-1.8,1.7c0,1,.8,1.8,1.8,1.8Zm7.7,17.6c-.2,0,-.5,0,-.8,-.1L24.6,31.4c-2.1,1,-4.3,1.5,-6.6,1.5c-3.9,0,-7.6,-1.5,-10.5,-4.2C4.7,25.9,3.1,22.2,3.1,18.2C3,14.2,4.5,10.3,7.4,7.4C10.3,4.6,14,3.1,18,3.1c.1,0,.2,0,.2,0c4,0,7.7,1.6,10.5,4.4c2.7,2.9,4.3,6.6,4.2,10.5c0,2.3,-.5,4.5,-1.5,6.6l2.1,5.9c.3,.9,.1,1.8,-.6,2.4c-.4,.5,-1,.7,-1.6,.7Z"></path>
                                                                    </clippath>
                                                                </defs>
                                                                <path d="M0,0									L0,36									L36,36									L36,0 Z " fill="#FFFFFF" stroke="none"></path>
                                                                <g clipPath="url(#c_1)">
                                                                    <g clipPath="url(#c1)">
                                                                        <path d="M-4.5,-4.5H41.3V41.3H-4.5Z" fill="currentColor"></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <span>1.8K</span>
                                                        </div> */}
                                                        </div>
                                                        <div className="c-channel-list-block__content">
                                                            {el.Images && el.Images[0] &&<div className="c-channel-list-block__photo-grid">
                                                                <div className="photo-grid-side">
                                                                    <div className="photo-grid__main h-object-fit">
                                                                        <img src={el.Images[0]} alt="img" />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="photo-grid-side">
                                                                <div className="photo-grid__small h-object-fit">
                                                                    <img src={ShopModalImg} alt="img" />
                                                                </div>
                                                                <div className="photo-grid__small photo-grid__counter h-object-fit">
                                                                    <img src={ShopModalImg} alt="img" />
                                                                    <span className="photo-grid__counter__val">10+</span>
                                                                </div>
                                                            </div> */}
                                                            </div>}
                                                            <div className="text-block-post">
                                                                {el.Description}
                                                            </div>
                                                        </div>
                                                        <div className="c-channel-list-block__footer">
                                                            <a href={el.Link} className="c-channel-list-block__footer__link" target="_blank">
                                                                <span>SEE FULL POST IN THE APP</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>


                                    {/* <div className="c-channel-list__day-block">
                                    <span className="c-channel-list__day-block__label">yesterday</span>
                                    <div className="c-channel-list-inner">
                                        <div className="c-channel-list-block">
                                            <div className="c-channel-list-block__top">
                                                <span className="c-channel-list-block__time">12:15</span>
                                                <div className="c-channel-list-block__views">
                                                    <svg x="0" y="0" width="36" height="36" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="icon icon-view">
                                                        <defs>
                                                            <clippath id="c_11">
                                                                <path d="M0,0H36V36H0Z"></path>
                                                            </clippath>
                                                            <clippath id="c00">
                                                                <path d="M-61,1364.3V-1645.3H284.2V1364.3Z"></path>
                                                            </clippath>
                                                            <clippath id="c11">
                                                                <path d="M18.3,22.9c-1.2,0,-2.3,-.4,-3.2,-1.3c-1.8,-1.8,-1.8,-4.7,0,-6.5c.9,-.9,2,-1.3,3.2,-1.3c1.2,0,2.4,.4,3.3,1.3c1.7,1.8,1.7,4.7,0,6.5c-.9,.9,-2.1,1.3,-3.3,1.3Zm0,3.8c4.7,0,8.4,-3.7,8.4,-8.4C26.7,13.7,23,9.9,18.3,9.9c-4.6,0,-8.4,3.8,-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4Zm0,5.4C9.9,32.1,2.7,26.4,0,18.4C2.7,10.4,9.9,4.6,18.3,4.6c8.5,0,15.7,5.8,18.3,13.8C34,26.4,26.8,32.1,18.3,32.1Z"></path>
                                                            </clippath>
                                                        </defs>
                                                        <path d="M0,0									L0,36									L36,36									L36,0 Z " fill="#FFFFFF" stroke="none"></path>
                                                        <g clipPath="url(#c_11)">
                                                            <g clipPath="url(#c11)">
                                                                <path d="M-7.5,-2.9H44.3V39.7H-7.5Z" fill="currentColor"></path>
                                                            </g>
                                                        </g>
                                                    </svg><span>2.1K</span>
                                                </div>
                                                <div className="c-channel-list-block__comments">
                                                    <svg x="0" y="0" width="36" height="36" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="icon icon-comments">
                                                        <defs>
                                                            <clippath id="c_1">
                                                                <path d="M0,0H36V36H0Z"></path>
                                                            </clippath>
                                                            <clippath id="c0">
                                                                <path d="M-120.6,1364.3V-1645.3H224.6V1364.3Z"></path>
                                                            </clippath>
                                                            <clippath id="c1">
                                                                <path d="M21.1,23c1,0,1.8,-.8,1.8,-1.7c0,-1,-.8,-1.8,-1.8,-1.8H15.2c-.9,0,-1.7,.8,-1.7,1.8c0,.9,.8,1.7,1.7,1.7Zm2.5,-7c1,0,1.8,-.8,1.8,-1.8c0,-1,-.8,-1.7,-1.8,-1.7H11.9c-1,0,-1.8,.7,-1.8,1.7c0,1,.8,1.8,1.8,1.8Zm7.7,17.6c-.2,0,-.5,0,-.8,-.1L24.6,31.4c-2.1,1,-4.3,1.5,-6.6,1.5c-3.9,0,-7.6,-1.5,-10.5,-4.2C4.7,25.9,3.1,22.2,3.1,18.2C3,14.2,4.5,10.3,7.4,7.4C10.3,4.6,14,3.1,18,3.1c.1,0,.2,0,.2,0c4,0,7.7,1.6,10.5,4.4c2.7,2.9,4.3,6.6,4.2,10.5c0,2.3,-.5,4.5,-1.5,6.6l2.1,5.9c.3,.9,.1,1.8,-.6,2.4c-.4,.5,-1,.7,-1.6,.7Z"></path>
                                                            </clippath>
                                                        </defs>
                                                        <path d="M0,0									L0,36									L36,36									L36,0 Z " fill="#FFFFFF" stroke="none"></path>
                                                        <g clipPath="url(#c_1)">
                                                            <g clipPath="url(#c1)">
                                                                <path d="M-4.5,-4.5H41.3V41.3H-4.5Z" fill="currentColor"></path>
                                                            </g>
                                                        </g>
                                                    </svg><span>1.8K</span>
                                                </div>
                                            </div>
                                            <div className="c-channel-list-block__content">
                                                <div className="c-channel-list-block__img h-object-fit">
                                                    <img src={ShopModalImg} alt="img" />
                                                </div>
                                            </div>
                                            <div className="c-channel-list-block__footer">
                                                <a href="#" className="c-channel-list-block__footer__link">
                                                    <span>SEE FULL POST IN THE APP</span>
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div >
                    </section >
                </main >
            </Layout>
        </div >
    }
}

export default ChannelBiggerViewPage;
