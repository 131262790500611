import React from "react";
import Slider from "../slider/slider";

class ModalShop extends React.Component {
  render() {
    const newId = Math.round(new Date().getTime()/1000);
    return (
      <div className="c-modalshop">
        <div className="c-modalshop__inner">
          <Slider images={this.props.modalData.Images} pagination={"images"} newId={newId}/>
          <div className="c-modalshop-info">
            <a href="#" className="c-modalshop-info__title">
              {this.props.modalData.Title}
            </a>
            <strong className="c-modalshop-info__price">
              {this.props.modalData.Price} {this.props.modalData.Currency}
            </strong>
            <ul className="c-modalshop-info__list h-reset-list">
              {this.props.modalData.Attributes.map((el, key) => {
                return (
                  <li key={key}>
                    <span className="c-modalshop-info__list__title">
                      {el.Key}
                    </span>
                    <span className="c-modalshop-info__list__descr">
                      {el.Value}
                    </span>
                  </li>
                );
              })}
            </ul>
            <div className="c-modalshop-info__partner">
              <strong className="c-modalshop-info__partner__name">
                Partnerangebot:
              </strong>
              <a href="#" className="c-modalshop-info__partner__logo">
                <img src={this.props.modalData.PartnerUrl} alt="home24" />
              </a>
            </div>
            <div className="c-modalshop-info__action">
              <a
                href={this.props.modalData.ProductUrl}
                target="_blank"
                className="btn btn--blue"
              >
                To the shop
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalShop;
