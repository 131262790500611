import React from 'react';
import Square from '../../assets/img/dekor/square.svg';

class Partners extends React.Component {
    render() {
        return <div className="c-partner">
            <div className="c-partner__square">
                <img src={Square} alt="square" />
            </div>
            <div className="container">
                <h2 className="section-title">Become a FellowTime partner</h2>
                <div className="c-partner__wrapper">
                    <div className="c-partner__inner">
                        <div className="c-partner__col">
                            <div className="c-partner__item">
                                <div className="c-partner__item__top"><span className="c-partner__item__title">Influencer</span>
                                    <p className="c-partner__item__text">Best for content creators, public figures, artists, bloggers, influencers.</p>
                                </div>
                                <div className="c-partner__item__content">
                                    <ul className="c-partner__item__list h-reset-list">
                                        <li>Connect to people</li>
                                        <li>List your products and services</li>
                                        <li>Engage with your audince</li>
                                    </ul><a href="#" className="btn btn--blue btn--fluid">Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div className="c-partner__col">
                            <div className="c-partner__item">
                                <div className="c-partner__item__top"><span className="c-partner__item__title">Business</span>
                                    <p className="c-partner__item__text">Best for private sellers, local shops, online shops, service providers.</p>
                                </div>
                                <div className="c-partner__item__content">
                                    <ul className="c-partner__item__list h-reset-list">
                                        <li>List ypur porducts and services</li>
                                        <li>Build Authentic Relationships</li>
                                        <li>Engage with your audince</li>
                                        <li>Sell your goods and services</li>
                                    </ul><a href="#" className="btn btn--blue btn--fluid">Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div className="c-partner__col">
                            <div className="c-partner__item">
                                <div className="c-partner__item__top"><span className="c-partner__item__title">Business Plus</span>
                                    <p className="c-partner__item__text">All the features of business and influencer plans combined together. </p>
                                </div>
                                <div className="c-partner__item__content">
                                    <ul className="c-partner__item__list h-reset-list">
                                        <li>Create channels</li>
                                        <li>List your products and services</li>
                                        <li>Engage with your audince</li>
                                        <li>Sell your goods and services</li>
                                        <li>Sell your goods and services</li>
                                    </ul><a href="#" className="btn btn--blue btn--fluid">Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Partners