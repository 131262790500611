/** @format */

import React from 'react';
import axios from 'axios';
import $ from "jquery";
import { NavLink } from 'react-router-dom';
import Layout from '../containers/layout';
import Pagination from '../components/c-pagination-2/index';

class BlogCategoryViewPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            menu: false,
            load: true,
            selectCategorie: 0,
            dataText: '',
            counts: 0,
            page: 1,
            sortType: 3,
            sortText: 'Date',
            posts: null,
            seoText: null
        }
        this.getData = this.getData.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.parseCategories = this.parseCategories.bind(this);
        this.selectCategorie = this.selectCategorie.bind(this);
        this.toggleFiltersShow = this.toggleFiltersShow.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getData();
        }
    }

    getId() {
        let url_string = window.location.href
        let url = new URL(url_string);
        return url.searchParams.get("id");
    }

    async getData() {
        const { data } = await axios.get(`/api/v1/blogs/categories`);
        let selCat = 0;
        if (this.props.match.params.id) selCat = parseInt(data.find(x => x.Title === this.props.match.params.id).Id);

        let postData = {
            Page: (this.state.page - 1),
            PageSize: 15,
            CategoryId: selCat,
            Sort: this.state.sortType
        }

        const posts = await axios.post(`/api/v1/blogs/posts`, postData);
        this.setState({
            page: parseInt(this.props.match.params.page || 0),
            posts: posts.data.Data,
            counts: posts.data.TotalCount,
            categories: data,
            selectCategorie: selCat
        }, () => {
            this.getSEOText(selCat)
        })
    }

    getSEOText(categories) {
        axios.get(`/api/v1/business/getCategoryText/${categories}`).then((result) => {
            this.setState({
                seoText: result.data
            })
        });
    }

    selectPage(page, event) {
        this.setState({
            posts: null,
            page: page
        }, () => {
            this.getData();
        })
    }

    selectCategorie(category, event) {
        this.setState({
            posts: null,
            selectCategorie: parseInt(category),
            page: 1
        }, () => {
            this.getData();
        })
    }
    parseLink(link) {
        return link.replace("/blog/", "/blog/")
    }
    parseDate(date) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da} ${mo} ${ye}`
    }
    parseCategories(categories, parent) {
        return categories.map((el, key) => {
            return <div>
                <NavLink to={`/blogs/${el.Title}`} className={`${parent && 'c-filters-cats__list__title'} ${(this.state.selectCategorie === el.Id) ? 'selected-category' : null}`} onClick={this.selectCategorie.bind(this, el.Id)}>{el.Title}</NavLink >
                {/* <ul className="c-filters-cats__list h-reset-list">
                    {el.ChildCategories && this.parseCategories(el.ChildCategories, false)}
                </ul> */}
            </div>
        })
    }
    toggleFiltersShow() {
        this.setState({
            filterVisible: !this.state.filterVisible
        }, () => {
            if (this.state.filterVisible) {
                this.showDialog(this.scrlTop);
            } else {
                this.closeDialog();
            }
        })
    }
    toggleFilter(id, event) {
        $(id).siblings('.c-filters-collapse__content').slideToggle();
        $(id).toggleClass('is-active');
    }
    changeSort(event) {
        this.setState({
            data: null,
            sortType: (this.state.sortType === 3) ? 4 : 3,
            page: 0
        }, () => {
            this.getData();
        });
    }
    render() {
        const { categories, posts, counts, selectCategorie, filterVisible, sortText, sortType, seoText } = this.state;

        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <main>
                    <div className="c-blog-category">
                        <div className="container">
                            <div className="c-blog-category-inner">
                                <div className={`c-filters-wrapper ${filterVisible ? 'is-visible' : null}`}>
                                    <div className="c-filters__header">
                                        <span>Filters</span>
                                        <button type="button" className="c-filters__close" onClick={this.toggleFiltersShow.bind(this)}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: "new 0 0 16 16" }} xmlSpace="preserve" className="icon-close">
                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.5,1.5l13,13 M14.5,1.5l-13,13"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <form action="#" className="c-filters">
                                        <div className="c-filters-box">
                                            <div className="c-filters-collapse">
                                                <button type="button" className="c-filters-collapse__trigger is-active" id={`toggle_Category`} onClick={this.toggleFilter.bind(this, `#toggle_Category`)}>
                                                    <span className="c-filters-collapse__trigger__name">Category</span>
                                                    <div className="c-filters-collapse__trigger__arr" >
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                        </svg>
                                                    </div>
                                                </button>
                                                <div className="c-filters-collapse__content is-active">
                                                    <div className="c-filters-collapse__content__inner">
                                                        <div className="c-filters-cats">
                                                            <NavLink to={`/blogs/all`} onClick={this.selectCategorie.bind(this, 0)} className="c-filters-cats__link-back">
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg>
                                                                <span>All categories</span>
                                                            </NavLink>
                                                            <div className="c-filters-cats__list-wrap">
                                                                {categories && this.parseCategories(categories, true)}
                                                                {/* <a href="#" className="c-filters-cats__list__title">Fashion</a>
                                                                <ul className="c-filters-cats__list h-reset-list">
                                                                    <li><a href="#">Truhen</a></li>
                                                                    <li><a href="#">Martinique</a></li>
                                                                    <li><a href="#">Korea</a></li>
                                                                    <li><a href="#">Solomon Islands</a></li>
                                                                    <li><a href="#">Niue</a></li>
                                                                </ul> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="c-blog-boxes-wrapper">
                                    <div className="breadcrumbs">
                                        <ul itemScope="itemscope" itemType="http://schema.org/BreadcrumbList" className="breadcrumbs__list h-reset-list">
                                            <li itemProp="itemListElement" itemScope="itemscope" itemType="http://schema.org/ListItem" className="breadcrumbs__list__item">
                                                <a href="#" itemProp="item">
                                                    <span itemProp="name">All articles</span>
                                                </a>
                                                <meta itemProp="position" content="1" />
                                            </li>
                                            <li className="breadcrumbs__list__divider">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                </svg>
                                            </li>
                                            <li itemProp="itemListElement" itemScope="itemscope" itemType="http://schema.org/ListItem" className="breadcrumbs__list__item">
                                                <a href="#" itemProp="item">
                                                    {selectCategorie > 0 && <span itemProp="name">{categories && categories.find(x => x.Id === selectCategorie).Title} {counts > 0 ? `(${counts} articles)` : ''}</span>}
                                                    {selectCategorie <= 0 && <span itemProp="name">All categories</span>}
                                                </a>
                                                <meta itemProp="position" content="1" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="c-top-info__wrapper">
                                        <div className="c-top-info__side">
                                            {selectCategorie > 0 && <h1 className="c-top-info__title">{categories && categories.find(x => x.Id === selectCategorie).Title}</h1>}
                                            {selectCategorie <= 0 && <h1 className="c-top-info__title">All categories</h1>}

                                            <button type="button" className="btn btn--blue c-top-info__filters-trigger">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                </svg>
                                                <span>Filters</span>
                                            </button>
                                        </div>
                                        <div className="c-top-info__side sort-side">
                                            <div className="c-top-info-sort-wrap">
                                                <div className="c-top-info-sort" ref={el => this.sortable = el} onClick={this.changeSort.bind(this)}>
                                                    <span className="c-top-info-sort__trigger">Sort by:</span>
                                                    <button type="button" className={`c-top-info-sort__direction ${sortType === 3 && 'reversed'}`}>
                                                        <span>{sortText}</span>
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div >


                                    </div >
                                    <div className="c-blog-boxes-inner">

                                        {posts && posts.map((post, key) => {
                                            return <div className="c-blog-boxes-col" key={key}>
                                                <div className="c-blog-boxes__card">
                                                    <a href="#" className="c-blog-boxes__card__img h-object-fit">
                                                        {post.Images && <img src={post.Images} alt="img" />}
                                                    </a>
                                                    <div className="c-blog-boxes__card__content">
                                                        <a href="#" className="c-blog-boxes__card__cat">{post.Category}</a>
                                                        <a href="#" className="c-blog-boxes__card__title">{post.Title}</a>
                                                        <div className="c-blog-boxes__card__info">
                                                            <div className="c-blog-boxes__card__info__side">
                                                                <div className="c-blog-boxes__card__info__icon">
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 24 24" }} xmlSpace="preserve" className="icon-calendar">
                                                                        <path id="SVGID_1_" d="M21,3h-1V1c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v2H8V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H3 C1.3,3,0,4.3,0,6v15c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V6C24,4.3,22.7,3,21,3z M21,20c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V10h18 V20z"></path>
                                                                    </svg>
                                                                </div>
                                                                <div className="c-blog-boxes__card__info__text">
                                                                    <span>{this.parseDate(post.PublishingDate)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="c-blog-boxes__card__info__side">
                                                                <div className="c-blog-boxes__card__info__icon">
                                                                    <svg version="1.1" id="Шар_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 23.6" style={{ enableBackground: "new 0 0 23.6 23.6" }} xmlSpace="preserve" className="icon-pensil">
                                                                        <path id="SVGID_3_" d="M11.8,6l5.7,5.7c0.4,0.4,0.4,1,0,1.4L7.4,23.3c-0.2,0.2-0.5,0.3-0.8,0.3l-5.8-0.4c-0.3,0-0.5-0.2-0.5-0.5 L0,16.9c0-0.3,0.1-0.6,0.3-0.8L10.4,6C10.8,5.6,11.4,5.6,11.8,6z M16.1,0.3l-2.5,2.5c-0.4,0.4-0.4,1,0,1.4l5.7,5.7 c0.4,0.4,1,0.4,1.4,0l2.5-2.5c0.4-0.4,0.4-1,0-1.4l-5.7-5.7C17.1-0.1,16.5-0.1,16.1,0.3z"></path>
                                                                    </svg>
                                                                </div>
                                                                <div className="c-blog-boxes__card__info__text">
                                                                    <span>By {post.Author}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="c-blog-boxes__card__text" dangerouslySetInnerHTML={{ __html: post.Description }}></div>
                                                        <div className="c-blog-boxes__card__footer">
                                                            <NavLink to={post.Link}>
                                                                <span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg>
                                                            </NavLink>
                                                        </div >
                                                    </div >
                                                </div >
                                            </div >
                                        })}

                                    </div >
                                    {counts > 20 && <Pagination
                                        totalCount={counts}
                                        currentPage={parseInt(this.props.match.params.page || 1)}
                                        currentCategory={parseInt(this.props.match.params.id || 0)}
                                        currentLink={`/blog`}
                                        functionPage={this.selectPage}
                                    />}
                                </div >
                            </div >
                            {seoText && <div className="c-seo-text" dangerouslySetInnerHTML={{ __html: seoText }}></div>}
                        </div >
                    </div >
                </main >
            </Layout>
        </div >
    }
}

export default BlogCategoryViewPage;