import React from 'react';
import ImagesLoad from '../../assets/img/preloader.gif';

class Preloader extends React.Component {
    render() {
        return <div className="preloader">
            <img src={ImagesLoad} alt="" />
        </div>
    }
}

export default Preloader