/** @format */

import React from "react";
import $ from "jquery";
import axios from "axios";
import ModalShop from "../../components/modal-shop/index";
import Modal from "react-responsive-modal";
import ShoppingFilter from "../../components/c-shopping-filters/index";
import Pagination from "../../components/c-pagination/index";
import Breadchumbs from "../../components/breadcrumbs/index";
import OfferBox from "../../components/c-offer-box/index";
import ProductBox from "../../components/c-product-box/index";

let result;
var searchMenuParams = (data, value) => {
  if (value > 0) {
    data.forEach((el) => {
      if (parseInt(el.UniqueId) === value) {
        result = el;
      } else {
        if (el.ChildCategories && el.ChildCategories.length > 0) {
          return searchMenuParams(el.ChildCategories, value);
        }
      }
    });
    return result;
  } else {
    result = null;
    return result;
  }
};

class ProductsTab extends React.Component {
  rangeSlider;
  sortable;
  scrlTop;
  constructor() {
    super();
    this.state = {
      location: "",
      data: null,
      counts: 0,
      modalData: null,
      open: false,
      page: 1,
      selectCategories: 0,
      selectCategoriesTitle: "All categories",
      seoText: "",
      categories: [],
      sortType: 0,
      MinValue: 0,
      MaxValue: 0,
      defMinVal: 0,
      defMaxVal: 0,
      filtersArray: [],
      parametrsArray: [],
      SelectedParams: [],
      sortText: "New",
      searchLine: "",
      loader: true,
      updateFilters: true,
      productsFound: 0,
      filterVisible: false,
      breadchumbs_data: [],
      activeOffer: null,
    };
    this.selectCategories = this.selectCategories.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeMinMaxPrice = this.changeMinMaxPrice.bind(this);
    this.addFilterParams = this.addFilterParams.bind(this);
    this.removeFilterParams = this.removeFilterParams.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.searchCategories = this.searchCategories.bind(this);
    this.toggleFiltersShow = this.toggleFiltersShow.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.selectOffer = this.selectOffer.bind(this);
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      $(window).on("scroll", function () {
        this.scrlTop = `${window.scrollY}px`;
      });
      $(".js-range-slider").ionRangeSlider({
        skin: "round",
        type: "double",
        prefix: "$",
        onChange: (data) => {
          $(".price-from").text(data.from);
          $(".price-to").text(data.to);
          $(".c-filters-range__from").val(data.from);
          $(".c-filters-range__to").val(data.to);
          this.changeMinMaxPrice([data.from, data.to]);
        },
      });

      this.setState({
        page: 1,
        location: window.location.pathname,
        SelectedParams:
          window.location.pathname.split("filters").length > 1
            ? JSON.parse(atob(window.location.pathname.split("filters=")[1]))
            : [],
        searchLine: decodeURIComponent(
          window.location.pathname
            .split("data=")[1]
            .split("/")[0]
            .replace(/%20/g, " ")
        ),
      });

      this.rangeSlider = $(".js-range-slider").data("ionRangeSlider");
    }
  }
  componentDidUpdate() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      let StateLoacatioin = this.state.location.split("filters")[0];
      let WindowLocation = window.location.pathname.split("filters")[0];
      let WindowData = decodeURIComponent(
        window.location.pathname
          .split("data=")[1]
          .split("/")[0]
          .replace(/%20/g, " ")
      );

      if (this.state.searchLine !== WindowData) {
        this.resetAllFilters();
      }

      if (
        StateLoacatioin !== WindowLocation ||
        this.state.searchLine !== WindowData
      ) {
        this.setState(
          {
            location: window.location.pathname,
            activeOffer: null,
            searchLine: decodeURIComponent(
              window.location.pathname
                .split("data=")[1]
                .split("/")[0]
                .replace(/%20/g, " ")
            ),
          },
          () => {
            this.getCategories();
          }
        );
      }
    }
  }

  resetAllFilters() {
    this.setState(
      {
        SelectedParams: [],
        showFilters: false,
        parametrsArray: [],
        updateFilters: true,
        page: 1,
      },
      () => {
        let valFrom = this.state.defMinVal;
        let valTo = this.state.defMaxVal;
        this.rangeSlider.update({
          from: valFrom,
          to: valTo,
        });
        $(".price-from").text(valFrom);
        $(".price-to").text(valTo);
        const baseURL = window.location.pathname.split("filters")[0];
        let filtersUrl = `${baseURL}`;
        window.history.pushState(null, null, filtersUrl);
        this.getProducts();
      }
    );
  }

  addFilterParams(key, filter, loader_a, event) {
    event.preventDefault();
    let newParams = this.state.SelectedParams;
    let getKeys = newParams.find((x) => x.key === key);

    if (getKeys) {
      let valuesParse = getKeys.value.findIndex((x) => x === filter);
      if (valuesParse > -1) {
        newParams.find((x) => x.key === key).value.splice(valuesParse, 1);
        if (newParams.find((x) => x.key === key).value.length <= 0) {
          newParams.splice(
            newParams.findIndex((x) => x.key === key),
            1
          );
        }
      } else {
        newParams.find((x) => x.key === key).value.push(filter);
      }
    } else {
      newParams.push({
        key: key,
        value: [filter],
      });
    }

    $(".c-filters__results-block").addClass("is-active");
    if (loader_a) {
      loader_a.classList.add("show");
    }
    if ($(window).width() > 1199) {
      const offset = $(event.target.parentNode).offset();
      $(".c-filters__results-block").css({ top: offset.top - 300 });
    }

    this.setState(
      {
        page: 1,
      },
      () => {
        axios
          .get(
            `/api/v1/business/search/${this.state.searchLine}?page=${
              this.state.page
            }&pageSize=${20}`
          )
          .then((result) => {
            this.setState(
              {
                productsFound: result.data.TotalCount,
              },
              () => {
                if (loader_a) {
                  loader_a.classList.remove("show");
                }
              }
            );
          });
      }
    );
    return false;
  }

  removeFilterParams(key, filter, event) {
    this.setState(
      {
        page: 1,
      },
      () => {
        let newParams = this.state.SelectedParams;
        let getKeys = newParams.find((x) => x.key === key);
        let valuesParse = getKeys.value.findIndex((x) => x === filter);
        newParams.find((x) => x.key === key).value.splice(valuesParse, 1);
        if (newParams.find((x) => x.key === key).value.length <= 0) {
          newParams.splice(
            newParams.findIndex((x) => x.key === key),
            1
          );
        }
        document.getElementById(`br_${key}_${filter}`).checked = false;
        this.getProducts();
      }
    );
  }

  selectCategories(UniqueId, name, event) {
    this.setState(
      {
        data: null,
        seoText: "",
        // categories: [],
        updateFilters: true,
        selectCategories: UniqueId,
        selectCategoriesTitle: name,
        parametrsArray: [],
        page: 1,
      },
      () => {
        this.getCategories();
      }
    );
  }

  changeMinMaxPrice(data) {
    this.setState({
      page: 1,
      MinValue: data[0],
      MaxValue: data[1],
    });
  }

  selectPage(page, event) {
    this.setState(
      {
        data: null,
        page: page,
      },
      () => {
        this.getProducts();
      }
    );
  }

  getCategories() {
    axios
      .post(`/api/v1/business/getCategoryWithTreeByAlias`, {
        Path: window.location.pathname.split("filters")[0].substr(1),
      })
      .then((result) => {
        const cid = result.data.SelectedCategoryId;
        let datatitle = searchMenuParams(result.data.Menu, cid);
        this.setState(
          {
            categories: result.data.Menu,
            selectCategories: cid,
            selectCategoriesTitle: datatitle
              ? datatitle.Name
              : "All categories",
          },
          () => {
            this.getProducts();
          }
        );
      });
  }
  changeSort(type, text, event) {
    this.setState(
      {
        data: null,
        sortType: type,
        sortText: text,
        page: 1,
      },
      () => {
        this.dropFilter();
        const path = window.location.pathname.split("/page-")[0];
        let createUrl = `${path}${
          path[path.length - 1] === "/" ? "" : "/"
        }page-1/`;
        window.history.pushState(null, null, createUrl);
        this.getProducts();
      }
    );
  }

  getProducts() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (this.state.SelectedParams.length > 0) {
      const baseURL = window.location.pathname.split("filters")[0];
      let filtersUrl = `${baseURL}${
        baseURL[baseURL.length - 1] === "/" ? "" : "/"
      }filters=${btoa(JSON.stringify(this.state.SelectedParams))}`;
      window.history.pushState(null, null, filtersUrl);
    }

    if (this.state.MinValue > 0 || this.state.MaxValue < this.state.defMaxVal) {
      const baseURL = window.location.pathname.split("price")[0];
      let filtersUrl = `${baseURL}${
        baseURL[baseURL.length - 1] === "/" ? "" : "/"
      }price=${this.state.MinValue},${this.state.MaxValue}`;
      window.history.pushState(null, null, filtersUrl);
    }

    $(".c-filters__results-block").removeClass("is-active");
    if (
      this.state.page > 1 ||
      this.state.sortType > 0 ||
      this.state.SelectedParams.length > 0 ||
      this.state.MinValue > 0 ||
      this.state.MaxValue < this.state.defMaxVal
    ) {
      this.setState({
        groups: [],
      });
    }

    axios
      .get(
        `/api/v1/business/search/${this.state.searchLine}?page=${
          this.state.page
        }&pageSize=${20}`
      )
      .then((result) => {
        this.setState(
          {
            loader: false,
            data: result.data.Data,
            counts: result.data.TotalCount,
            defMinVal: result.data.MinValue,
            defMaxVal: result.data.MaxValue,
            showFilters: this.state.SelectedParams.length > 0,
            breadchumbs_data: [
              {
                link: "/products",
                text: "All products",
              },
              {
                link: "#",
                text: `"${this.state.searchLine}" (${result.data.TotalCount} products)`,
              },
            ],
          },
          () => {
            this.props.updateState(
              this.state.searchLine,
              result.data.TotalCount
            );
            if (this.state.updateFilters) {
              $(".price-from").text(result.data.MinPrice);
              $(".price-to").text(result.data.MaxPrice);
              $(".c-filters-range__from").val(result.data.MinPrice);
              $(".c-filters-range__to").val(result.data.MaxPrice);
              this.rangeSlider.update({
                min: result.data.MinPrice,
                max: result.data.MaxPrice,
                from: result.data.MinPrice,
                to: result.data.MaxPrice,
              });
              this.setState({
                updateFilters: false,
                parametrsArray: result.data.Parameters,
              });
            }
          }
        );
      });
  }

  searchCategories(text, id) {
    let parametrs = this.state.parametrsArray;
    let selectParam = parametrs.find((x) => x.Key === id);
    if (selectParam) {
      selectParam.search = text;
    }
  }

  onOpenModal(el, event) {
    const { OfferId, CompanyId } = el;
    const recent = window.localStorage.getItem("fellowtime_recent");
    if (recent) {
      let recentData = JSON.parse(recent);
      const srch = recentData.findIndex(
        (x) => x.OfferId === OfferId && x.CompanyId === CompanyId
      );
      if (srch === -1) {
        recentData.push({ OfferId, CompanyId });

        if (recentData.length > 20) {
          recentData.splice(0, 1);
        }

        window.localStorage.setItem(
          "fellowtime_recent",
          JSON.stringify(recentData)
        );
      }
    } else {
      let recentData = [];
      recentData.push({ OfferId, CompanyId });
      window.localStorage.setItem(
        "fellowtime_recent",
        JSON.stringify(recentData)
      );
    }

    this.setState({ modalData: el }, () => {
      this.setState({ open: true });
    });
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  psrseLink(alias) {
    let splitAlias = alias.split("/");
    let createUrl = `/catalog/${splitAlias[splitAlias.length - 2]}/`;
    return createUrl;
  }

  componentWillUnmount() {
    $("body").removeClass("modal-active");
  }

  dropFilter(event) {
    var $this = $(this.sortable);
    $this
      .siblings(".c-top-info-sort__list__item.is-selected")
      .removeClass("is-selected");
    $this.addClass("is-selected");
    $this
      .closest(".c-top-info-sort__dropdown")
      .fadeOut(100)
      .removeClass("is-active");
    $($this)
      .closest(".c-top-info-sort-wrap")
      .find(".c-top-info-sort__dropdown")
      .fadeToggle(300)
      .toggleClass("is-active");
  }

  showDialog(scrlTop) {
    var body = document.body;
    var scrollWidthCheck = document.createElement("div");
    scrollWidthCheck.style.overflowY = "scroll";
    scrollWidthCheck.style.width = "50px";
    scrollWidthCheck.style.height = "50px";
    body.append(scrollWidthCheck);
    var scrollWidth =
      scrollWidthCheck.offsetWidth - scrollWidthCheck.clientWidth;
    scrollWidthCheck.remove();
    body.style.paddingRight = scrollWidth + "px";
    body.querySelector(".c-header").style.paddingRight = scrollWidth + "px";
    body.style.width = "100%";
    body.style.top = `-${scrlTop}`;
    body.classList.add("filters-active");
    $(".c-filters-wrapper").addClass("is-visible");
  }

  closeDialog() {
    var body = document.body;
    var scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    body.style.width = "";
    body.style.overflow = "";
    body.style.padding = "";
    body.querySelector(".c-header").style.padding = "";
    body.classList.remove("show");
    body.classList.remove("filters-active");
    $(".c-filters-wrapper").removeClass("is-visible");
  }

  toggleFiltersShow() {
    this.setState(
      {
        filterVisible: !this.state.filterVisible,
      },
      () => {
        if (this.state.filterVisible) {
          this.showDialog(this.scrlTop);
        } else {
          this.closeDialog();
        }
      }
    );
  }

  resetPage(f) {
    this.setState(
      {
        page: 1,
      },
      () => {
        f();
      }
    );
  }

  selectOffer(el, event) {
    this.setState({
      activeOffer: el,
    });
  }

  render() {
    const {
      activeOffer,
      open,
      counts,
      breadchumbs_data,
      data,
      modalData,
      searchLine,
      page,
      SelectedParams,
      parametrsArray,
      sortText,
      showFilters,
    } = this.state;
    return (
      <div id="products_tab" className="page-tabs-content__block is-active">
        <div className="products-tab-wrap">
          <ShoppingFilter
            getProducts={this.getProducts}
            getCategories={this.getCategories}
            changeMinMaxPrice={this.changeMinMaxPrice}
            selectCategoriesMethod={this.selectCategories}
            addFilterParams={this.addFilterParams}
            removeFilterParams={this.removeFilterParams}
            searchCategories={this.searchCategories}
            toggleFiltersShow={this.toggleFiltersShow}
            resetPage={this.resetPage}
            categories={this.state.categories}
            selectCategories={this.state.selectCategories}
            SelectedParams={SelectedParams}
            MinValue={this.state.MinValue}
            MaxValue={this.state.MaxValue}
            defMinVal={this.state.defMinVal}
            defMaxVal={this.state.defMaxVal}
            parametrsArray={parametrsArray}
            productsFound={this.state.productsFound}
            filterVisible={this.state.filterVisible}
          />

          <div className="products-tab-content">
            <div className="c-top-info">
              <Breadchumbs breadchums={breadchumbs_data} />
              <div className="c-top-info__wrapper">
                <div className="c-top-info__side">
                  <h1 className="c-top-info__title">{searchLine}</h1>
                  <button
                    type="button"
                    className="btn btn--blue c-top-info__filters-trigger"
                    onClick={this.toggleFiltersShow.bind(this)}
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 12.7 10.3"
                      style={{ enableBackground: "new 0 0 12.7 10.3" }}
                      xmlSpace="preserve"
                      className="icon-arr-sm"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"
                      ></path>
                      <span>Filters</span>
                    </svg>
                  </button>
                  <div className="c-top-info__filters">
                    {showFilters &&
                      SelectedParams.map((el, key) => {
                        return el.value.map((val, key) => {
                          return (
                            <button
                              type="button"
                              className="c-top-info__filters__item"
                              key={key}
                              onClick={this.removeFilterParams.bind(
                                this,
                                el.key,
                                val
                              )}
                            >
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 16 16"
                                style={{ enableBackground: "new 0 0 16 16" }}
                                xmlSpace="preserve"
                                className="icon-close"
                              >
                                <path
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  d="M1.5,1.5l13,13 M14.5,1.5l-13,13"
                                ></path>
                              </svg>
                              <span>{val}</span>
                            </button>
                          );
                        });
                      })}
                    {showFilters && (
                      <button
                        type="reset"
                        className="c-top-info__filters__reset"
                        onClick={this.resetAllFilters.bind(this)}
                      >
                        Reset all
                      </button>
                    )}
                  </div>
                </div>
                <div className="c-top-info__side sort-side">
                  <div className="c-top-info-sort-wrap">
                    <div
                      className="c-top-info-sort"
                      ref={(el) => (this.sortable = el)}
                      onClick={this.dropFilter.bind(this)}
                    >
                      <span className="c-top-info-sort__trigger">Sort by:</span>
                      <button
                        type="button"
                        className="c-top-info-sort__direction"
                      >
                        <span>{sortText}</span>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 12.7 10.3"
                          style={{ enableBackground: "new 0 0 12.7 10.3" }}
                          xmlSpace="preserve"
                          className="icon-arr-sm"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <div className="c-top-info-sort__dropdown">
                      <ul className="c-top-info-sort__list h-reset-list">
                        <li
                          className={`c-top-info-sort__list__item ${
                            this.state.sortType === 0 && "is-selected"
                          }`}
                          onClick={this.changeSort.bind(this, 0, "New")}
                        >
                          <span className="c-top-info-sort__list__name">
                            New
                          </span>
                          <div className="c-top-info-sort__list__circle">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 17 12"
                              style={{ enableBackground: "new 0 0 17 12" }}
                              xmlSpace="preserve"
                              className="icon-checkmark"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                d="M1.5,5.5l5,5 M15.5,1.5L7,10"
                              ></path>
                            </svg>
                          </div>
                        </li>
                        <li
                          className={`c-top-info-sort__list__item ${
                            this.state.sortType === 1 && "is-selected"
                          }`}
                          onClick={this.changeSort.bind(
                            this,
                            1,
                            "Lowest price"
                          )}
                        >
                          <span className="c-top-info-sort__list__name">
                            Lowest price
                          </span>
                          <div className="c-top-info-sort__list__circle">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 17 12"
                              style={{ enableBackground: "new 0 0 17 12" }}
                              xmlSpace="preserve"
                              className="icon-checkmark"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                d="M1.5,5.5l5,5 M15.5,1.5L7,10"
                              ></path>
                            </svg>
                          </div>
                        </li>
                        <li
                          className={`c-top-info-sort__list__item ${
                            this.state.sortType === 2 && "is-selected"
                          }`}
                          onClick={this.changeSort.bind(
                            this,
                            2,
                            "Highest price"
                          )}
                        >
                          <span className="c-top-info-sort__list__name">
                            Highest price
                          </span>
                          <div className="c-top-info-sort__list__circle">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 17 12"
                              style={{ enableBackground: "new 0 0 17 12" }}
                              xmlSpace="preserve"
                              className="icon-checkmark"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                d="M1.5,5.5l5,5 M15.5,1.5L7,10"
                              ></path>
                            </svg>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-shopping-grid-wrap">
              {data &&
                data.map((el, key) => {
                  if (data.length > 4) {
                    if (key === 4) {
                      return (
                        <>
                          {activeOffer && <OfferBox data={activeOffer} />}
                          <ProductBox
                            key={key}
                            el={el}
                            onOpenModal={this.onOpenModal}
                            selectOffer={this.selectOffer}
                          />
                        </>
                      );
                    } else {
                      return (
                        <ProductBox
                          key={key}
                          el={el}
                          onOpenModal={this.onOpenModal}
                          selectOffer={this.selectOffer}
                        />
                      );
                    }
                  } else {
                    return (
                      <>
                        <ProductBox
                          key={key}
                          el={el}
                          onOpenModal={this.onOpenModal}
                          selectOffer={this.selectOffer}
                        />
                        {activeOffer && <OfferBox data={activeOffer} />}
                      </>
                    );
                  }
                })}
            </div>
            {counts > 20 && (
              <Pagination
                totalCount={counts}
                currentPage={page}
                functionPage={this.selectPage}
              />
            )}
          </div>
        </div>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          classNames={{
            overlay: { background: "rgba(0, 0, 0, 0.2)" },
            modal: { customStyles: "100px" },
            closeButton: { customCloseButton: "1px" },
          }}
          center
        >
          <ModalShop modalData={modalData} />
        </Modal>
        <div
          className="filters-overlay"
          onClick={this.toggleFiltersShow.bind(this)}
        ></div>
      </div>
    );
  }
}

export default ProductsTab;
