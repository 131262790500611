import React from 'react';
import { Route, Switch } from "react-router-dom";
import MainPage from "./pages/main";
import ShoppingPage from "./pages/shopping";
import ChannelsPage from "./pages/channels";
import ChannelBiggerViewPage from "./pages/channel-bigger-view";
import ImpressumViewPage from "./pages/impressum";
import DataProtectionViewPage from "./pages/data-protection";
import AgbViewPage from "./pages/agb";
import SearchViewPage from "./pages/search";
import OffersViewPage from "./pages/offers";
import BlogViewPage from "./pages/blog";
import BlogCategoryViewPage from "./pages/blog-category";
import BlogAllCategoriesViewPage from "./pages/search";
import BlogArticleViewPage from "./pages/blog-article";
// import * as serviceWorker from './service-worker';
import HttpsRedirect from 'react-https-redirect';

class App extends React.Component {
  render() {
    return <div className="App">
      <HttpsRedirect>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path={['/catalog', '/catalog/:page', '/catalog/:categories/*']} component={ShoppingPage} />
        <Route exact path="/channels" component={ChannelsPage} />
        <Route exact path="/channel-bigger-view/:id" component={ChannelBiggerViewPage} />
        <Route exact path="/imprint" component={ImpressumViewPage} />
        <Route exact path="/terms-and-conditions" component={DataProtectionViewPage} />
        <Route exact path="/privacy-policy" component={AgbViewPage} />

        <Route exact path="/blog" component={BlogViewPage} />
        <Route exact path="/blog/:type/:alias" component={BlogArticleViewPage} />
        <Route exact path={['/blogs', '/blogs/:id', '/blogs/:id/:page']} component={BlogCategoryViewPage} />

        <Route exact path={['/search/:data', '/search/:data/:page', '/search/:data/*']} component={SearchViewPage} />
        <Route exact path={'/offer/:offerId/:companyId'} component={OffersViewPage} />
        <Route component={MainPage} />
      </Switch>
      </HttpsRedirect>
    </div>
  }
}

export default App;


