import React from 'react';
import FooterPost1 from '../../assets/img/post/footer-post1.jpg';
import FooterPost2 from '../../assets/img/post/footer-post2.jpg';
import FooterPost3 from '../../assets/img/post/footer-post3.jpg';
import Swiper from 'react-id-swiper';

class cJournal extends React.Component {
    swiper;
    constructor(props) {
        super(props);
        this.state = {
            paginator: false
        }
    }

    params = {
        loop: false,
        speed: 800,
        spaceBetween: 15,
        pagination: {
            el: '.c-journal-carousel__pagination',
            clickable: true
        },
        slidesPerView: 'auto',
        breakpoints: {
            320: {
                slidesPerView: 1,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
            },
            481: {
                slidesPerView: 'auto',
                slidesOffsetBefore: 10,
                slidesOffsetAfter: 10,
            }
        },
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true,
        onInit: (swiper) => {
            this.swiper = swiper
        }
    }
    render() {
        return <section className="c-journal">
            <div className="container">
                <h2 className="section-title">Check out our journal</h2>
                <div className="c-journal-carousel js-magic-parent">
                    <div className="c-journal__magic-trigger js-magic-trigger"></div>
                    <svg width="576" height="842" viewBox="0 0 576 842" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M2.07176 840C2.07176 840 2.15641 834.725 2.07176 831.803C1.27364 804.251 20.4564 778.969 59.62 755.957C124.596 717.777 357.228 684.783 385.66 581.365C439.952 383.883 380.716 427.723 466.68 271.362C510.784 191.142 564.388 89.9078 574.362 -2" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg width="576" height="846" viewBox="0 0 576 846" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path id="c-line__7" d="M2.07176 844C2.07176 844 2.15641 838.725 2.07176 835.803C1.27364 808.251 20.4564 782.969 59.62 759.957C124.596 721.777 357.228 688.783 385.66 585.365C439.952 387.883 380.716 431.723 466.68 275.362C510.784 195.142 564.388 93.9078 574.362 2" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <Swiper {...this.params}>
                        <div className="c-journal-carousel__card swiper-slide">
                            <a href="#" className="c-journal-carousel__card__category">
                                <span>Business</span>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </a>
                            <div className="c-journal-carousel__card__inner">
                                <div className="c-journal-carousel__card__label">
                                    <span>Most Popular</span>
                                </div>
                                <a href="#" className="c-journal-carousel__card__img h-object-fit">
                                    <img src="https://babeltechreviews.com/wp-content/uploads/2018/07/rendition1.img_.jpg" alt="" />
                                </a>
                                <div className="c-journal-carousel__card__content">
                                    <a href="#" className="c-journal-carousel__card__title">
                                        Cdc Issues Health Alert Notice For Travelers T…
                                        </a>
                                    <span className="c-journal-carousel__card__author">
                                        by Admin
                                        </span>
                                    <a href="#" className="c-journal-carousel__card__link">
                                        <span>
                                            READ ARTICLE
                                            </span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>

                                    <div className="c-journal-carousel__card__posts">
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost1} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Cost cutting ideas for your wedding
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost2} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Big savings on gas while you travel
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost3} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Addiction when gambling becomes a problem
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="c-journal-carousel__card swiper-slide">
                            <a href="#" className="c-journal-carousel__card__category">
                                <span>Travel</span>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </a>
                            <div className="c-journal-carousel__card__inner">
                                <div className="c-journal-carousel__card__label">
                                    <span>Most Popular</span>
                                </div>
                                <a href="#" className="c-journal-carousel__card__img h-object-fit">
                                    <img src="https://babeltechreviews.com/wp-content/uploads/2018/07/rendition1.img_.jpg" alt="" />
                                </a>
                                <div className="c-journal-carousel__card__content">
                                    <a href="#" className="c-journal-carousel__card__title">
                                        Cdc Issues Health Alert Notice For Travelers T…
                                    </a>
                                    <span className="c-journal-carousel__card__author">
                                        by Admin
                                    </span>
                                    <a href="#" className="c-journal-carousel__card__link">
                                        <span>
                                            READ ARTICLE
                                        </span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>
                                    <div className="c-journal-carousel__card__posts">
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost1} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Cost cutting ideas for your wedding
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost2} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Big savings on gas while you travel
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost3} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Addiction when gambling becomes a problem
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c-journal-carousel__card swiper-slide">
                            <a href="#" className="c-journal-carousel__card__category">
                                <span>Beauty</span>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                    <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </a>
                            <div className="c-journal-carousel__card__inner">
                                <div className="c-journal-carousel__card__label">
                                    <span>Most Popular</span>
                                </div>
                                <a href="#" className="c-journal-carousel__card__img h-object-fit">
                                    <img src="https://babeltechreviews.com/wp-content/uploads/2018/07/rendition1.img_.jpg" alt="" />
                                </a>
                                <div className="c-journal-carousel__card__content">
                                    <a href="#" className="c-journal-carousel__card__title">
                                        Do A Sporting Stag Do In Birmingham
                                    </a>
                                    <span className="c-journal-carousel__card__author">
                                        by Admin
                                    </span>
                                    <a href="#" className="c-journal-carousel__card__link">
                                        <span>READ ARTICLE</span>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                            <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                        </svg>
                                    </a>
                                    <div className="c-journal-carousel__card__posts">
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost1} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">Cost cutting ideas for your wedding</span>
                                                <span className="c-post-link__subtitle">by admin</span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost2} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Big savings on gas while you travel
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="c-post-link">
                                            <div className="c-post-link__img">
                                                <img src={FooterPost3} alt="post" />
                                            </div>
                                            <div className="c-post-link__text">
                                                <span className="c-post-link__title">
                                                    Addiction when gambling becomes a problem
                                                </span>
                                                <span className="c-post-link__subtitle">
                                                    by admin
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Swiper>
                    <div className="c-journal__actions"><a href="#" className="btn btn--blue">Show all</a></div>
                </div>
            </div>
        </section>
    }
}

export default cJournal;