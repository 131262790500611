import React from 'react';
import LottieAnimation from "../../components/lottie-animation";
import ShoppingJson from '../../assets/json/Shopping';

class cShopping extends React.Component {
    render() {
        return <section className="c-shopping">
            <div className="container-sm">
                <div className="c-shopping__inner js-magic-parent">
                    <div className="c-shopping__magic-trigger js-magic-trigger"></div>
                    <svg width="551" height="1037" viewBox="0 0 551 1037" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-line">
                        <path d="M553.295 1.09875C432.848 27.0194 367.178 225.1 283.322 287.603C159.052 380.227 96.477 422.761 49.1019 521.777C1.72682 620.793 -7.58545 729.944 10.8172 818.958C29.2199 907.973 64.461 999.842 197.964 1034.85" stroke="#C2C9D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9"></path>
                    </svg>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 555 1038" style={{ enableBackground: 'new 0 0 555 1038' }} xmlSpace="preserve" className="animated-line">
                        <path id="c-line__3" fill="none" stroke="#ffffff" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M198,1035.8C64.5,1000.8,29.2,909,10.8,820c-18.4-89-9.1-198.2,38.3-297.2s110-141.5,234.2-234.2 C367.2,226.1,432.8,28,553.3,2.1"></path>
                    </svg>
                    <div className="c-shopping-info">
                        <div className="c-shopping-info__anim">
                            <LottieAnimation data={ShoppingJson} type={'svg'} height={null} />
                        </div>
                        <div className="section-info-text c-shopping-info__text">
                            <h2 className="section-title">Shopping</h2><span className="section-subtitle">Your product search starts here  </span>
                            <p>
                                Browse & discover items from 1000s of online and offline stores. We have put a
                                lot of effort and love to make sure you always find the products that are
                                perfectly right for you. Get inspired by amazing articles and edits, and buy
                                anything you like in one tap.
                        </p>
                            <a href="#" className="btn btn--blue">
                                Explore channels
                        </a>
                        </div>
                    </div>
                    <div className="c-shopping-labels">
                        <a href="#" className="c-shopping-labels__item">
                            FOOD
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Leisure Time Entertainment
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Stores
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Beauty and Health
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Electronics & Computers
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Clothing, Shoes & Watches
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Books
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Service sector
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Cars
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Sports and tourism
                    </a>
                        <a href="#" className="c-shopping-labels__item">
                            Education
                    </a>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default cShopping;