import React from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import Layout from '../containers/layout';
import PageBannerImg from '../assets/img/svg/page-banner-img.svg';

class BlogViewPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            load: true,
            page: 0,
            categories: null,
            posts: null,
            sortType: 3,
            sortText: 'Date',
            dataText: ''
        }
        this.changeSort = this.changeSort.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getData();
        }
    }
    async getData() {
        const categories = await axios.get(`/api/v1/blogs/categories`);
        let postData = {
            Page: this.state.page,
            PageSize: 15,
            CategoryId: 0,
            Sort: this.state.sortType
        }
        const posts = await axios.post(`/api/v1/blogs/posts`, postData);
        this.setState({
            categories: categories.data,
            posts: posts.data.Data
        })
    }

    parseDate(date) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da} ${mo} ${ye}`
    }
    parseLink(link) {
        return link.replace("/blog/", "/blog/")
    }
    changeSort(event) {
        this.setState({
            data: null,
            sortType: (this.state.sortType === 3) ? 4 : 3,
            page: 0
        }, () => {
            this.getData();
        });
    }
    render() {
        const { categories, posts, sortText, sortType } = this.state;
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <main>
                    <div className="c-page-banner">
                        <div className="container">
                            <img src={PageBannerImg} alt="page-banner" className="c-page-banner__img" />
                        </div>
                    </div>
                    <div className="c-blog-content">
                        <div className="container">
                            <div className="c-blog-labels">
                                {categories && categories.map((ek, key) => {
                                    return <NavLink to={`/blogs/${ek.Title}`} className="c-blog-labels__item" key={key}>{ek.Title}</NavLink>
                                })}
                            </div>
                        </div>
                        <div className="c-blog-boxes-wrapper">
                            <div className="container">
                                <div className="c-top-info__wrapper">
                                    <div className="c-top-info__side">
                                        <h1 className="c-top-info__title">All articles</h1>
                                    </div>
                                    <div className="c-top-info__side sort-side">
                                        <div className="c-top-info-sort-wrap">
                                            <div className="c-top-info-sort" ref={el => this.sortable = el} onClick={this.changeSort.bind(this)}>
                                                <span className="c-top-info-sort__trigger">Sort by:</span>
                                                <button type="button" className={`c-top-info-sort__direction ${sortType === 3 && 'reversed'}`}>
                                                    <span>{sortText}</span>
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon-arr-sm">
                                                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="c-blog-boxes-inner" >
                                    {posts && posts.map((post, key) => {
                                        return <div className="c-blog-boxes-col" key={key}>
                                            <div className="c-blog-boxes__card">
                                                <a href="#" className="c-blog-boxes__card__img h-object-fit">
                                                    {post.Images && <img src={post.Images} alt="img" />}
                                                </a>
                                                <div className="c-blog-boxes__card__content">
                                                    <a href="#" className="c-blog-boxes__card__cat">{post.Category}</a>
                                                    <a href="#" className="c-blog-boxes__card__title">{post.Title}</a>
                                                    <div className="c-blog-boxes__card__info">
                                                        <div className="c-blog-boxes__card__info__side">
                                                            <div className="c-blog-boxes__card__info__icon">
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 24 24" }} xmlSpace="preserve" className="icon-calendar">
                                                                    <path id="SVGID_1_" d="M21,3h-1V1c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v2H8V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H3 C1.3,3,0,4.3,0,6v15c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V6C24,4.3,22.7,3,21,3z M21,20c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V10h18 V20z"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="c-blog-boxes__card__info__text">
                                                                <span>{this.parseDate(post.PublishingDate)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="c-blog-boxes__card__info__side">
                                                            <div className="c-blog-boxes__card__info__icon">
                                                                <svg version="1.1" id="Шар_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 23.6" style={{ enableBackground: "new 0 0 23.6 23.6" }} xmlSpace="preserve" className="icon-pensil">
                                                                    <path id="SVGID_3_" d="M11.8,6l5.7,5.7c0.4,0.4,0.4,1,0,1.4L7.4,23.3c-0.2,0.2-0.5,0.3-0.8,0.3l-5.8-0.4c-0.3,0-0.5-0.2-0.5-0.5 L0,16.9c0-0.3,0.1-0.6,0.3-0.8L10.4,6C10.8,5.6,11.4,5.6,11.8,6z M16.1,0.3l-2.5,2.5c-0.4,0.4-0.4,1,0,1.4l5.7,5.7 c0.4,0.4,1,0.4,1.4,0l2.5-2.5c0.4-0.4,0.4-1,0-1.4l-5.7-5.7C17.1-0.1,16.5-0.1,16.1,0.3z"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="c-blog-boxes__card__info__text">
                                                                <span>By {post.Author}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="c-blog-boxes__card__text" dangerouslySetInnerHTML={{ __html: post.Description }}></div>
                                                    <div className="c-blog-boxes__card__footer">
                                                        <NavLink to={post.Link}>
                                                            <span>Read more</span>
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: "new 0 0 12.7 10.3" }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                            </svg>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div >
                    </div >
                </main >
            </Layout>
        </div >
    }
}

export default BlogViewPage;


