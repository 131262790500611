import React from 'react'
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import Menu from '../menu/index';
import HeaderSearch from '../c-header-search/c-header-search';

import HeaderBlog from '../../assets/img/svg/header-blog.svg';
import HeaderMob from '../../assets/img/svg/header-mob.svg';

class HeaderComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      fixed: false,
      searchLine: false,
      searchTextDesc: ""
    }

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      this.setState({
        searchTextDesc: window.innerWidth > 728 ? 'Search products, sellers, channels…' : 'Search'
      })
    }
  }
  //Обработка скролла меню
  handleScroll() {
    this.setState({
      fixed: (window.scrollY > 0),
      searchLine: (window.scrollY > 20)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }
  render() {
    const { fixed, searchLine, searchTextDesc } = this.state;
    const { lg } = this.props;
    return <header className={`c-header ${lg && 'c-header--lg'} js-header ${fixed && 'is-fixed'} ${searchLine && 'search-active'}`}>
      <div className="container">
        <div className="c-header__inner">
          <div className="c-header__side side-list">
            <button type="button" className="c-header__menu-trigger js-mob-menu-open" onClick={this.props.menuTrigger.bind(this)}>
              <svg viewBox="0 0 100 100" width="60" className="ham hamRotate ham4">
                <path d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" className="line top"></path>
                <path d="m 70,50 h -40" className="line middle"></path>
                <path d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" className="line bottom"> </path>
              </svg>
            </button>

            <Link to={"/"} className="c-header__logo">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 24 24' }} xmlSpace="preserve" className="icon icon-logo-sm">
                <path fill="#56AFEB" d="M12,24c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12C5.4,0,0,5.4,0,12C0,18.6,5.4,24,12,24z"></path>
                <path fill="#FFFFFF" d="M12,4C9.6,3.6,7.7,3.8,6.5,4.5S4.4,6.3,4,7.5C3.6,8.7,3.9,10,4.6,11c0.5,0.8,1.9,1.7,2.4,1.8s0.8,0.2,1.2,0.2 c0.1,0,0.3,0,0.5,0c0.3,0,0.4-0.4,0.2-0.6c-0.5-0.5-1.1-1.2-1.2-1.8c-0.2-1,0-1.8,0.8-2.4c0.6-0.5,1.6-0.5,2.1-0.5 c0.1,0,0.2,0,0.3,0c0.3,0,1.1,0.3,1.1,0.6c0,0.2-1.2,5.3-1.4,6.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.5,0-0.8-0.1 c-0.4-0.1-1.1-0.3-1.4-0.3c0,0-0.1,0-0.1,0.1c0.1,0.2,0.5,0.8,0.9,1.1c0.3,0.2,0.8,0.3,1.1,0.4c0.1,0,0.2,0.1,0.2,0.3l-0.5,2.4 l-0.2,1.1c0,0.2,0.1,0.4,0.3,0.4h2.1c0.2,0,0.3-0.1,0.3-0.3L13,16c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.6-0.1,0.8-0.2 c0.3-0.1,0.6-0.5,0.8-0.8c0.1-0.2,0.3-0.6,0.4-0.9c0-0.1-0.1-0.2-0.2-0.1c-0.4,0.2-1.1,0.6-1.3,0.7c-0.1,0-0.2,0.1-0.3,0.1 c-0.1,0-0.2,0-0.1-0.1c0.1-0.5,0.4-2,0.6-2.6c0.2-0.6,0.4-1.9,0.6-2.6c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.3 c0-0.1,0.2-0.2,0.3-0.2c0.7,0.2,2.8,0.6,3.9,0.2C20,8.3,20.7,7.1,21,6.5c0.1-0.1,0-0.3-0.2-0.3c-0.8,0-2.8,0-4.1-0.4 C16,5.7,15.5,5.4,15,5.1C14.2,4.7,13.4,4.2,12,4z M9.5,9.5C9.2,9.5,9.1,9.5,9,9.8c-0.1,0.3,0.2,1.1,0.9,1.1c0.7,0,1-0.9,0.9-1.1 c-0.1-0.2-0.2-0.3-0.5-0.3c-0.2,0-0.3,0.1-0.4,0.1c0,0,0,0,0,0c0,0,0,0,0,0C9.8,9.6,9.7,9.5,9.5,9.5z"></path>
              </svg>
            </Link >

            <HeaderSearch placeholder={searchTextDesc} />

            <ul className="c-header__list h-reset-list">
              <li className={`js-menu-trigger ${this.props.menu ? 'is-active' : ''}`} onClick={this.props.menuTrigger.bind(this)}>
                <a href="javascript:void(0);">Shopping</a>
              </li>
              <li>
                <NavLink to={"/channels"} onClick={this.props.menuClose.bind(this)}>Channels</NavLink>
              </li>
              <li>
                <NavLink to={"/sellers"} onClick={this.props.menuClose.bind(this)}>Sellers</NavLink>
              </li>
              {/* {!lg && <li>
                <a href="#">
                  <span>Local</span>
                </a>
              </li>} */}
              {/* {!lg && <li>
                <a href="#">
                  <span>Journal</span>
                </a>
              </li>} */}
            </ul>
          </div>
          <div className="c-header__side side-search">
            <div className="c-header__search">
              <div className={`search-form-wrap show ${this.props.animated && 'true-anim'}`}>
                <HeaderSearch placeholder={searchTextDesc} />
              </div>

            </div>
            <NavLink to={"/blog"}>
              <span className="c-header__blog">
                <img src={HeaderBlog} alt="blog" className="icon-blog" />
                <span>Blog</span>
              </span>
            </NavLink>
            <a href="#" className="c-header__app">
              <img src={HeaderMob} alt="mobile-app" className="icon-get-app" />
              <span>Get the app</span>
            </a>
          </div>
        </div>
        <Menu menu={this.props.menu} menuTrigger={this.props.menuTrigger} menuClose={this.props.menuClose} />
      </div>
    </header >
  }
}

export default HeaderComponent;