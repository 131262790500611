import Img_0 from './shopping-img/img_0.webp';
import Img_1 from './shopping-img/img_1.webp';
import Img_2 from './shopping-img/img_2.webp';
import Img_3 from './shopping-img/img_3.webp';
import Img_4 from './shopping-img/img_4.webp';
import Img_5 from './shopping-img/img_5.webp';
import Img_6 from './shopping-img/img_6.webp';
import Img_7 from './shopping-img/img_7.webp';
import Img_8 from './shopping-img/img_8.webp';
import Img_9 from './shopping-img/img_9.webp';
import Img_10 from './shopping-img/img_10.webp';
import Img_11 from './shopping-img/img_11.webp';
import Img_12 from './shopping-img/img_12.webp';
import Img_13 from './shopping-img/img_13.webp';
import Img_14 from './shopping-img/img_14.webp';
import Img_15 from './shopping-img/img_15.webp';
import Img_16 from './shopping-img/img_16.webp';
import Img_17 from './shopping-img/img_17.webp';

export default {
    "v": "5.5.9",
    "fr": 29.9700012207031,
    "ip": 0,
    "op": 354.000014418716,
    "w": 800,
    "h": 600,
    "nm": "Shopping Main",
    "ddd": 0,
    "assets": [
        {
            "id": "image_0",
            "w": 472,
            "h": 320,
            "p": Img_0,
            "e": 0
        },
        {
            "id": "image_1",
            "w": 472,
            "h": 320,
            "p": Img_1,
            "e": 0
        },
        {
            "id": "image_2",
            "w": 472,
            "h": 320,
            "p": Img_2,
            "e": 0
        },
        {
            "id": "image_3",
            "w": 472,
            "h": 320,
            "p": Img_3,
            "e": 0
        },
        {
            "id": "image_4",
            "w": 472,
            "h": 320,
            "p": Img_4,
            "e": 0
        },
        {
            "id": "image_5",
            "w": 472,
            "h": 320,
            "p": Img_5,
            "e": 0
        },
        {
            "id": "image_6",
            "w": 472,
            "h": 320,
            "p": Img_6,
            "e": 0
        },
        {
            "id": "image_7",
            "w": 472,
            "h": 320,
            "p": Img_7,
            "e": 0
        },
        {
            "id": "image_8",
            "w": 472,
            "h": 320,
            "p": Img_8,
            "e": 0
        },
        {
            "id": "image_9",
            "w": 472,
            "h": 320,
            "p": Img_9,
            "e": 0
        },
        {
            "id": "image_10",
            "w": 472,
            "h": 320,
            "p": Img_10,
            "e": 0
        },
        {
            "id": "image_11",
            "w": 472,
            "h": 320,
            "p": Img_11,
            "e": 0
        },
        {
            "id": "image_12",
            "w": 472,
            "h": 320,
            "p": Img_12,
            "e": 0
        },
        {
            "id": "image_13",
            "w": 1124,
            "h": 600,
            "p": Img_13,
            "e": 0
        },
        {
            "id": "image_14",
            "w": 1124,
            "h": 600,
            "p": Img_14,
            "e": 0
        },
        {
            "id": "image_15",
            "w": 1124,
            "h": 600,
            "p": Img_15,
            "e": 0
        },
        {
            "id": "image_16",
            "w": 384,
            "h": 604,
            "p": Img_16,
            "e": 0
        },
        {
            "id": "image_17",
            "w": 800,
            "h": 600,
            "p": Img_17,
            "e": 0
        },
        {
            "id": "comp_0",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 1,
                    "nm": "Pale Gray-Royal Blue Solid 1",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 50
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                98,
                                100,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "hasMask": true,
                    "masksProperties": [
                        {
                            "inv": false,
                            "mode": "a",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ],
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ],
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ]
                                    ],
                                    "v": [
                                        [
                                            100,
                                            0
                                        ],
                                        [
                                            0,
                                            100
                                        ],
                                        [
                                            100,
                                            200
                                        ],
                                        [
                                            200,
                                            100
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0
                                            ],
                                            "y": [
                                                0.999
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 0,
                                        "s": [
                                            -100
                                        ]
                                    },
                                    {
                                        "t": 6.25000025456772,
                                        "s": [
                                            0
                                        ]
                                    }
                                ]
                            },
                            "nm": "Mask 1"
                        },
                        {
                            "inv": false,
                            "mode": "s",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ],
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            55.228
                                        ],
                                        [
                                            55.228,
                                            0
                                        ],
                                        [
                                            0,
                                            -55.228
                                        ]
                                    ],
                                    "v": [
                                        [
                                            100,
                                            0
                                        ],
                                        [
                                            0,
                                            100
                                        ],
                                        [
                                            100,
                                            200
                                        ],
                                        [
                                            200,
                                            100
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": [
                                                0
                                            ],
                                            "y": [
                                                0.985
                                            ]
                                        },
                                        "o": {
                                            "x": [
                                                0.167
                                            ],
                                            "y": [
                                                0.167
                                            ]
                                        },
                                        "t": 6.25,
                                        "s": [
                                            -100
                                        ]
                                    },
                                    {
                                        "t": 12.5000005091354,
                                        "s": [
                                            2
                                        ]
                                    }
                                ]
                            },
                            "nm": "Mask 2"
                        }
                    ],
                    "sw": 200,
                    "sh": 200,
                    "sc": "#98a1a4",
                    "ip": 0,
                    "op": 62.5000025456772,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_1",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "Shopping",
                    "refId": "comp_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 87.413,
                                    "s": [
                                        140,
                                        287,
                                        0
                                    ],
                                    "to": [
                                        -45.833,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        45.833,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 99.9,
                                    "s": [
                                        -135,
                                        287,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 179.82,
                                    "s": [
                                        -135,
                                        287,
                                        0
                                    ],
                                    "to": [
                                        45.833,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        -45.833,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 192.307507832845,
                                    "s": [
                                        140,
                                        287,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                175,
                                491,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "hasMask": true,
                    "masksProperties": [
                        {
                            "inv": false,
                            "mode": "a",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            32.348,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -18.875
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -28.35,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            19.219
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -15.4,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            14.719
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            27.273,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -5
                                        ]
                                    ],
                                    "v": [
                                        [
                                            327.759,
                                            204.254
                                        ],
                                        [
                                            21.525,
                                            204.254
                                        ],
                                        [
                                            -13.475,
                                            230.129
                                        ],
                                        [
                                            -13.65,
                                            675.656
                                        ],
                                        [
                                            25.375,
                                            704.281
                                        ],
                                        [
                                            324.302,
                                            704.281
                                        ],
                                        [
                                            364.027,
                                            674.656
                                        ],
                                        [
                                            364.115,
                                            230.254
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Mask 1"
                        }
                    ],
                    "w": 350,
                    "h": 982,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "Product Card",
                    "refId": "comp_17",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 87.413,
                                    "s": [
                                        422,
                                        250,
                                        0
                                    ],
                                    "to": [
                                        -47.028,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        47.028,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 0.667
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 99.9,
                                    "s": [
                                        139.834,
                                        250,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0
                                    },
                                    "t": 179.82,
                                    "s": [
                                        139.834,
                                        250,
                                        0
                                    ],
                                    "to": [
                                        47.028,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        -47.028,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "t": 192.307507832845,
                                    "s": [
                                        422,
                                        250,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                140.5,
                                250,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "hasMask": true,
                    "masksProperties": [
                        {
                            "inv": false,
                            "mode": "a",
                            "pt": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            23.105,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -18.875
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -20.25,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            19.219
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -11,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            14.719
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            19.48,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -5
                                        ]
                                    ],
                                    "v": [
                                        [
                                            254.738,
                                            0.137
                                        ],
                                        [
                                            25.813,
                                            0.199
                                        ],
                                        [
                                            0.813,
                                            26.074
                                        ],
                                        [
                                            0.688,
                                            471.602
                                        ],
                                        [
                                            28.563,
                                            500.227
                                        ],
                                        [
                                            252.27,
                                            500.164
                                        ],
                                        [
                                            280.645,
                                            470.539
                                        ],
                                        [
                                            280.707,
                                            26.137
                                        ]
                                    ],
                                    "c": true
                                }
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "x": {
                                "a": 0,
                                "k": 0
                            },
                            "nm": "Mask 1"
                        }
                    ],
                    "w": 281,
                    "h": 500,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_2",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/1",
                    "refId": "comp_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        305,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        -135,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        -135,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        305,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/2",
                    "refId": "comp_5",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0.5,
                                    "s": [
                                        241,
                                        305,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        241,
                                        -135,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        -135,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        305,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/3",
                    "refId": "comp_6",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        449,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        9,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        9,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        449,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/4",
                    "refId": "comp_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        241,
                                        449,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.932,
                                    "s": [
                                        241,
                                        9,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        9,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        449,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/5",
                    "refId": "comp_8",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        593,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        153,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        153,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        593,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/6",
                    "refId": "comp_9",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        241,
                                        593,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        241,
                                        153,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        153,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        593,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/7",
                    "refId": "comp_10",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        737,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        297,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        297,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        737,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/8",
                    "refId": "comp_11",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        241,
                                        737,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        241,
                                        297,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        297,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        737,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/9",
                    "refId": "comp_12",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        881,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        441,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        441,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        881,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 10,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/10",
                    "refId": "comp_13",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        241,
                                        881,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        241,
                                        441,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        441,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        881,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 11,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/11",
                    "refId": "comp_14",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        109,
                                        1025,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        585,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        585,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        1025,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 12,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/12",
                    "refId": "comp_15",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0,
                                    "s": [
                                        241,
                                        1025,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        241,
                                        585,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        241,
                                        585,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        241,
                                        1025,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 13,
                    "ty": 0,
                    "nm": "↻ Shopping/Product Preview/13",
                    "refId": "comp_16",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": 0.667,
                                        "y": 1
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 0.5,
                                    "s": [
                                        109,
                                        1169,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        -73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        73.333,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.333,
                                        "y": 0.333
                                    },
                                    "t": 67.433,
                                    "s": [
                                        109,
                                        729,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        0,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "t": 150.35,
                                    "s": [
                                        109,
                                        729,
                                        0
                                    ],
                                    "to": [
                                        0,
                                        73.333,
                                        0
                                    ],
                                    "ti": [
                                        0,
                                        -73.333,
                                        0
                                    ]
                                },
                                {
                                    "t": 156.343506368001,
                                    "s": [
                                        109,
                                        1169,
                                        0
                                    ]
                                }
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                150,
                                150,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 300,
                    "h": 300,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_3",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_0",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_4",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 3,
                    "nm": "▽ Shopping/Price",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                20.5,
                                16,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21.5,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 5,
                    "nm": "€",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                14,
                                22.2,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 20,
                                        "f": "Rubik-Bold",
                                        "t": "€",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 24,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Rectangle",
                    "parent": 1,
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                21.994,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -5.9,
                                                    0
                                                ],
                                                [
                                                    -2.33,
                                                    -2.09
                                                ],
                                                [
                                                    0,
                                                    -6.06
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    2.52,
                                                    0
                                                ],
                                                [
                                                    4.72,
                                                    3.62
                                                ],
                                                [
                                                    0.11,
                                                    9.09
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    8.58,
                                                    0
                                                ],
                                                [
                                                    2.58,
                                                    2.31
                                                ],
                                                [
                                                    0,
                                                    3.97
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -15.62,
                                                    0
                                                ],
                                                [
                                                    -4.72,
                                                    -3.62
                                                ],
                                                [
                                                    -0.02,
                                                    -2.04
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -20.996,
                                                    -16
                                                ],
                                                [
                                                    -2.396,
                                                    -16
                                                ],
                                                [
                                                    13.314,
                                                    -10.17
                                                ],
                                                [
                                                    21.004,
                                                    5.06
                                                ],
                                                [
                                                    21.004,
                                                    16
                                                ],
                                                [
                                                    11.014,
                                                    16
                                                ],
                                                [
                                                    -12.336,
                                                    11.28
                                                ],
                                                [
                                                    -20.996,
                                                    -8.5
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.396077990532,
                                            0.431373000145,
                                            0.498039007187,
                                            0.5
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 50
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_5",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_1",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_6",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_2",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_7",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_3",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_8",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_9",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_5",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_10",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_6",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_11",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_7",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_12",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_8",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_13",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_9",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_14",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_10",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_15",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_11",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_16",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 0,
                    "nm": "↻ Shopping/Price",
                    "refId": "comp_4",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                188,
                                101,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                21,
                                16,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "w": 42,
                    "h": 32,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_12",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                125,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                236,
                                160,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150,
                                190,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            89.032,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -2.484,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            52,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -21,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            26,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            34,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            64,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -15,
                                            -10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            13,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            15.5,
                                            10
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "BG",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                150.5,
                                152.031,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                101.562,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.247058823705,
                                            0.250980407,
                                            0.262745112181,
                                            1
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 77.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 181
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 2
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 20
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118.189,
                                            128.713
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -0.405,
                                            -1.644
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle 1",
                            "bm": 0,
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Round Corners 1",
                            "r": {
                                "a": 0,
                                "k": 24
                            },
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_17",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Line",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                51.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -140,
                                                    0
                                                ],
                                                [
                                                    140,
                                                    0
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.850979983807,
                                            0.850979983807,
                                            0.850979983807,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 1
                                    },
                                    "lc": 3,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Line",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Arrow Icon",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                23.25,
                                28.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    -0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0.48,
                                                    -0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -0.83
                                                ],
                                                [
                                                    0.77,
                                                    -0.07
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0.59,
                                                    0.48
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.59,
                                                    0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0.55,
                                                    0.55
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.83,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.78
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    0.59
                                                ],
                                                [
                                                    -0.55,
                                                    0.55
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    -0.59
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -0.69,
                                                    -6.56
                                                ],
                                                [
                                                    -0.59,
                                                    -4.55
                                                ],
                                                [
                                                    -0.69,
                                                    -4.44
                                                ],
                                                [
                                                    -3.63,
                                                    -1.5
                                                ],
                                                [
                                                    7.25,
                                                    -1.5
                                                ],
                                                [
                                                    8.75,
                                                    0
                                                ],
                                                [
                                                    7.39,
                                                    1.49
                                                ],
                                                [
                                                    7.25,
                                                    1.5
                                                ],
                                                [
                                                    -3.63,
                                                    1.5
                                                ],
                                                [
                                                    -0.69,
                                                    4.44
                                                ],
                                                [
                                                    -0.69,
                                                    6.56
                                                ],
                                                [
                                                    -2.7,
                                                    6.66
                                                ],
                                                [
                                                    -2.81,
                                                    6.56
                                                ],
                                                [
                                                    -8.31,
                                                    1.06
                                                ],
                                                [
                                                    -8.31,
                                                    -1.06
                                                ],
                                                [
                                                    -2.81,
                                                    -6.56
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.211765006185,
                                            0.207843005657,
                                            0.215685993433,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Arrow Icon",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 5,
                    "nm": "Product",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                106,
                                33.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Bold",
                                        "t": "Product",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Rectangle Copy 31",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                26,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            52
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 40
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle Copy 31",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                243,
                                81.75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 25,
                                        "f": "Rubik-Bold",
                                        "t": "€",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 30,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                251.483,
                                75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -8.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -8.71
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    3.54,
                                                    0
                                                ],
                                                [
                                                    6.63,
                                                    5.21
                                                ],
                                                [
                                                    0.15,
                                                    13.07
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    12.05,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    5.72
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -21.94,
                                                    0
                                                ],
                                                [
                                                    -6.62,
                                                    -5.2
                                                ],
                                                [
                                                    -0.04,
                                                    -2.93
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -29.48,
                                                    -23
                                                ],
                                                [
                                                    -3.37,
                                                    -23
                                                ],
                                                [
                                                    29.02,
                                                    -23
                                                ],
                                                [
                                                    29.5,
                                                    7.27
                                                ],
                                                [
                                                    29.5,
                                                    23
                                                ],
                                                [
                                                    15.47,
                                                    23
                                                ],
                                                [
                                                    -17.33,
                                                    16.21
                                                ],
                                                [
                                                    -29.48,
                                                    -12.22
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.396077990532,
                                            0.431373000145,
                                            0.498039007187,
                                            0.5
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 50
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_13",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                127,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                562,
                                300,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                341,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            234,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -8,
                                            -32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            40,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            66,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            71,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            158,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -46,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            147,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            43.5,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 6",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            159,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -45.5,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 7",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            50,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 8",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -66,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 9",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            77,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            44.5,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 10",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            82,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -84,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 11",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            70,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 12",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 13",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                246,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -125,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    19
                                                ],
                                                [
                                                    -125,
                                                    19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 1",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            126,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            90.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            102,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -74,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            55,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            16.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.729412019253,
                                            0.729412019253,
                                            0.729412019253,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 10,
                    "ty": 4,
                    "nm": "Combined Shape",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 124.875,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 139.860005696615,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.26,
                                                    0.43
                                                ],
                                                [
                                                    0.5,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1,
                                                    0.4
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.32,
                                                    -0.82
                                                ],
                                                [
                                                    -0.82,
                                                    -0.32
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.49,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.88
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.23,
                                                    0.42
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -0.25,
                                                    -0.43
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.16,
                                                    -1.07
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.81,
                                                    -0.32
                                                ],
                                                [
                                                    -0.32,
                                                    0.82
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.23,
                                                    1.49
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -0.89
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.48,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.24,
                                                    -0.44
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    15.8,
                                                    -7.78
                                                ],
                                                [
                                                    14.59,
                                                    -8.48
                                                ],
                                                [
                                                    -7.62,
                                                    -8.48
                                                ],
                                                [
                                                    -7.99,
                                                    -10.96
                                                ],
                                                [
                                                    -9.87,
                                                    -13.33
                                                ],
                                                [
                                                    -13.84,
                                                    -14.89
                                                ],
                                                [
                                                    -15.89,
                                                    -13.98
                                                ],
                                                [
                                                    -14.99,
                                                    -11.91
                                                ],
                                                [
                                                    -11.11,
                                                    -10.38
                                                ],
                                                [
                                                    -8.66,
                                                    6.1
                                                ],
                                                [
                                                    -5.7,
                                                    8.68
                                                ],
                                                [
                                                    8.69,
                                                    8.68
                                                ],
                                                [
                                                    10.27,
                                                    7.08
                                                ],
                                                [
                                                    8.69,
                                                    5.48
                                                ],
                                                [
                                                    -5.54,
                                                    5.48
                                                ],
                                                [
                                                    -5.7,
                                                    4.4
                                                ],
                                                [
                                                    9.18,
                                                    4.4
                                                ],
                                                [
                                                    10.33,
                                                    3.72
                                                ],
                                                [
                                                    15.83,
                                                    -6.37
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ind": 1,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -3.92,
                                                    10.297
                                                ],
                                                [
                                                    -6.24,
                                                    12.647
                                                ],
                                                [
                                                    -3.92,
                                                    14.997
                                                ],
                                                [
                                                    -1.6,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    5.36,
                                                    10.297
                                                ],
                                                [
                                                    3.04,
                                                    12.647
                                                ],
                                                [
                                                    5.36,
                                                    14.997
                                                ],
                                                [
                                                    7.68,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Combined Shape",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 11,
                    "ty": 4,
                    "nm": "Path",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 124.875,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 139.860005696615,
                                    "s": [
                                        100
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.17,
                                                    0
                                                ],
                                                [
                                                    -0.83,
                                                    0.84
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.63,
                                                    1.76
                                                ],
                                                [
                                                    1.73,
                                                    -1.79
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.71,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    -1.73
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0.82,
                                                    0.84
                                                ],
                                                [
                                                    1.16,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.68,
                                                    -1.71
                                                ],
                                                [
                                                    -1.71,
                                                    -1.86
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.7,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    1.73
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -8.28,
                                                    11.69
                                                ],
                                                [
                                                    -5.18,
                                                    13
                                                ],
                                                [
                                                    -2.08,
                                                    11.69
                                                ],
                                                [
                                                    14.67,
                                                    -5.31
                                                ],
                                                [
                                                    14.85,
                                                    -11.58
                                                ],
                                                [
                                                    8.54,
                                                    -11.68
                                                ],
                                                [
                                                    -3.35,
                                                    0.38
                                                ],
                                                [
                                                    -6.99,
                                                    0.38
                                                ],
                                                [
                                                    -8.52,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    5.1
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 12,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            250,
                                            56
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 28
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.337255001068,
                                            0.686275005341,
                                            0.921568989754,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 13,
                    "ty": 4,
                    "nm": "Channel phone bg",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                250,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            500
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 29.985
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Channel phone bg",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 14,
                    "ty": 4,
                    "nm": "Line",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                51.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -140,
                                                    0
                                                ],
                                                [
                                                    140,
                                                    0
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.850979983807,
                                            0.850979983807,
                                            0.850979983807,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 1
                                    },
                                    "lc": 3,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Line",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 15,
                    "ty": 4,
                    "nm": "Arrow Icon",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                23.25,
                                28.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    -0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0.48,
                                                    -0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -0.83
                                                ],
                                                [
                                                    0.77,
                                                    -0.07
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0.59,
                                                    0.48
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.59,
                                                    0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0.55,
                                                    0.55
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.83,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.78
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    0.59
                                                ],
                                                [
                                                    -0.55,
                                                    0.55
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.59,
                                                    -0.59
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.59,
                                                    -0.59
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -0.69,
                                                    -6.56
                                                ],
                                                [
                                                    -0.59,
                                                    -4.55
                                                ],
                                                [
                                                    -0.69,
                                                    -4.44
                                                ],
                                                [
                                                    -3.63,
                                                    -1.5
                                                ],
                                                [
                                                    7.25,
                                                    -1.5
                                                ],
                                                [
                                                    8.75,
                                                    0
                                                ],
                                                [
                                                    7.39,
                                                    1.49
                                                ],
                                                [
                                                    7.25,
                                                    1.5
                                                ],
                                                [
                                                    -3.63,
                                                    1.5
                                                ],
                                                [
                                                    -0.69,
                                                    4.44
                                                ],
                                                [
                                                    -0.69,
                                                    6.56
                                                ],
                                                [
                                                    -2.7,
                                                    6.66
                                                ],
                                                [
                                                    -2.81,
                                                    6.56
                                                ],
                                                [
                                                    -8.31,
                                                    1.06
                                                ],
                                                [
                                                    -8.31,
                                                    -1.06
                                                ],
                                                [
                                                    -2.81,
                                                    -6.56
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.211765006185,
                                            0.207843005657,
                                            0.215685993433,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Arrow Icon",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 16,
                    "ty": 5,
                    "nm": "Product",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                106,
                                33.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Bold",
                                        "t": "Product",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 17,
                    "ty": 4,
                    "nm": "Rectangle Copy 31",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                26,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            52
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 40
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle Copy 31",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 18,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                243,
                                81.75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 25,
                                        "f": "Rubik-Bold",
                                        "t": "€",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 30,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 19,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                251.483,
                                75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -8.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -8.71
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    3.54,
                                                    0
                                                ],
                                                [
                                                    6.63,
                                                    5.21
                                                ],
                                                [
                                                    0.15,
                                                    13.07
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    12.05,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    5.72
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -21.94,
                                                    0
                                                ],
                                                [
                                                    -6.62,
                                                    -5.2
                                                ],
                                                [
                                                    -0.04,
                                                    -2.93
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -29.48,
                                                    -23
                                                ],
                                                [
                                                    -3.37,
                                                    -23
                                                ],
                                                [
                                                    29.02,
                                                    -23
                                                ],
                                                [
                                                    29.5,
                                                    7.27
                                                ],
                                                [
                                                    29.5,
                                                    23
                                                ],
                                                [
                                                    15.47,
                                                    23
                                                ],
                                                [
                                                    -17.33,
                                                    16.21
                                                ],
                                                [
                                                    -29.48,
                                                    -12.22
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.396077990532,
                                            0.431373000145,
                                            0.498039007187,
                                            0.5
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 50
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 20,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_14",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                127,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                562,
                                300,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 21,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                341,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            234,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -8,
                                            -32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            40,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            66,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            71,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            158,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -46,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            147,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            43.5,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 6",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            159,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -45.5,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 7",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            50,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 8",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -66,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 9",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            77,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            44.5,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 10",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            82,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -84,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 11",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            70,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 12",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 13",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 22,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                246,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -125,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    19
                                                ],
                                                [
                                                    -125,
                                                    19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 1",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            126,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            90.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            102,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -74,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            55,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            16.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.729412019253,
                                            0.729412019253,
                                            0.729412019253,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 23,
                    "ty": 4,
                    "nm": "Combined Shape",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.26,
                                                    0.43
                                                ],
                                                [
                                                    0.5,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1,
                                                    0.4
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.32,
                                                    -0.82
                                                ],
                                                [
                                                    -0.82,
                                                    -0.32
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.49,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.88
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.23,
                                                    0.42
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -0.25,
                                                    -0.43
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.16,
                                                    -1.07
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.81,
                                                    -0.32
                                                ],
                                                [
                                                    -0.32,
                                                    0.82
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.23,
                                                    1.49
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -0.89
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.48,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.24,
                                                    -0.44
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    15.8,
                                                    -7.78
                                                ],
                                                [
                                                    14.59,
                                                    -8.48
                                                ],
                                                [
                                                    -7.62,
                                                    -8.48
                                                ],
                                                [
                                                    -7.99,
                                                    -10.96
                                                ],
                                                [
                                                    -9.87,
                                                    -13.33
                                                ],
                                                [
                                                    -13.84,
                                                    -14.89
                                                ],
                                                [
                                                    -15.89,
                                                    -13.98
                                                ],
                                                [
                                                    -14.99,
                                                    -11.91
                                                ],
                                                [
                                                    -11.11,
                                                    -10.38
                                                ],
                                                [
                                                    -8.66,
                                                    6.1
                                                ],
                                                [
                                                    -5.7,
                                                    8.68
                                                ],
                                                [
                                                    8.69,
                                                    8.68
                                                ],
                                                [
                                                    10.27,
                                                    7.08
                                                ],
                                                [
                                                    8.69,
                                                    5.48
                                                ],
                                                [
                                                    -5.54,
                                                    5.48
                                                ],
                                                [
                                                    -5.7,
                                                    4.4
                                                ],
                                                [
                                                    9.18,
                                                    4.4
                                                ],
                                                [
                                                    10.33,
                                                    3.72
                                                ],
                                                [
                                                    15.83,
                                                    -6.37
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ind": 1,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -3.92,
                                                    10.297
                                                ],
                                                [
                                                    -6.24,
                                                    12.647
                                                ],
                                                [
                                                    -3.92,
                                                    14.997
                                                ],
                                                [
                                                    -1.6,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    5.36,
                                                    10.297
                                                ],
                                                [
                                                    3.04,
                                                    12.647
                                                ],
                                                [
                                                    5.36,
                                                    14.997
                                                ],
                                                [
                                                    7.68,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Combined Shape",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 24,
                    "ty": 4,
                    "nm": "Path",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.17,
                                                    0
                                                ],
                                                [
                                                    -0.83,
                                                    0.84
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.63,
                                                    1.76
                                                ],
                                                [
                                                    1.73,
                                                    -1.79
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.71,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    -1.73
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0.82,
                                                    0.84
                                                ],
                                                [
                                                    1.16,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.68,
                                                    -1.71
                                                ],
                                                [
                                                    -1.71,
                                                    -1.86
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.7,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    1.73
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -8.28,
                                                    11.69
                                                ],
                                                [
                                                    -5.18,
                                                    13
                                                ],
                                                [
                                                    -2.08,
                                                    11.69
                                                ],
                                                [
                                                    14.67,
                                                    -5.31
                                                ],
                                                [
                                                    14.85,
                                                    -11.58
                                                ],
                                                [
                                                    8.54,
                                                    -11.68
                                                ],
                                                [
                                                    -3.35,
                                                    0.38
                                                ],
                                                [
                                                    -6.99,
                                                    0.38
                                                ],
                                                [
                                                    -8.52,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    5.1
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 25,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            250,
                                            56
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 28
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.337255001068,
                                            0.686275005341,
                                            0.921568989754,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 26,
                    "ty": 4,
                    "nm": "Channel phone bg",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                250,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            500
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 29.985
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Channel phone bg",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 27,
                    "ty": 4,
                    "nm": "Line",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                51.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -140,
                                                    0
                                                ],
                                                [
                                                    140,
                                                    0
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.850979983807,
                                            0.850979983807,
                                            0.850979983807,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 1
                                    },
                                    "lc": 3,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Line",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 28,
                    "ty": 4,
                    "nm": "Icon",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                23.25,
                                28.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -7.25,
                                                    0
                                                ],
                                                [
                                                    -1.75,
                                                    -5.5
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "gr",
                                    "it": [
                                        {
                                            "ind": 0,
                                            "ty": "sh",
                                            "ks": {
                                                "a": 0,
                                                "k": {
                                                    "i": [
                                                        [
                                                            0,
                                                            0
                                                        ],
                                                        [
                                                            0,
                                                            0
                                                        ]
                                                    ],
                                                    "o": [
                                                        [
                                                            0,
                                                            0
                                                        ],
                                                        [
                                                            0,
                                                            0
                                                        ]
                                                    ],
                                                    "v": [
                                                        [
                                                            -7.25,
                                                            5.5
                                                        ],
                                                        [
                                                            -1.75,
                                                            0
                                                        ]
                                                    ],
                                                    "c": false
                                                }
                                            },
                                            "nm": "Path 1",
                                            "hd": false
                                        },
                                        {
                                            "ty": "tr",
                                            "p": {
                                                "a": 0,
                                                "k": [
                                                    0,
                                                    0
                                                ]
                                            },
                                            "a": {
                                                "a": 0,
                                                "k": [
                                                    0,
                                                    0
                                                ]
                                            },
                                            "s": {
                                                "a": 0,
                                                "k": [
                                                    100,
                                                    -100
                                                ]
                                            },
                                            "r": {
                                                "a": 0,
                                                "k": 0
                                            },
                                            "o": {
                                                "a": 0,
                                                "k": 100
                                            },
                                            "sk": {
                                                "a": 0,
                                                "k": 0
                                            },
                                            "sa": {
                                                "a": 0,
                                                "k": 0
                                            },
                                            "nm": "Преобразовать"
                                        }
                                    ],
                                    "nm": "Group 1",
                                    "bm": 0,
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -6.75,
                                                    0
                                                ],
                                                [
                                                    7.25,
                                                    0
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.211765006185,
                                            0.207843005657,
                                            0.215685993433,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 3
                                    },
                                    "lc": 2,
                                    "lj": 2,
                                    "bm": 0,
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Icon",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 29,
                    "ty": 5,
                    "nm": "Product",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                106,
                                33.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Bold",
                                        "t": "Product",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 30,
                    "ty": 4,
                    "nm": "Rectangle Copy 31",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                26,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            52
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 40
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle Copy 31",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 31,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                243,
                                81.75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 25,
                                        "f": "Rubik-Bold",
                                        "t": "€",
                                        "j": 0,
                                        "tr": 0,
                                        "lh": 30,
                                        "ls": 0,
                                        "fc": [
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 32,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                251.483,
                                75,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -8.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -8.71
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    3.54,
                                                    0
                                                ],
                                                [
                                                    6.63,
                                                    5.21
                                                ],
                                                [
                                                    0.15,
                                                    13.07
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    12.05,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    5.72
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -21.94,
                                                    0
                                                ],
                                                [
                                                    -6.62,
                                                    -5.2
                                                ],
                                                [
                                                    -0.04,
                                                    -2.93
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -29.48,
                                                    -23
                                                ],
                                                [
                                                    -3.37,
                                                    -23
                                                ],
                                                [
                                                    29.02,
                                                    -23
                                                ],
                                                [
                                                    29.5,
                                                    7.27
                                                ],
                                                [
                                                    29.5,
                                                    23
                                                ],
                                                [
                                                    15.47,
                                                    23
                                                ],
                                                [
                                                    -17.33,
                                                    16.21
                                                ],
                                                [
                                                    -29.48,
                                                    -12.22
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.396077990532,
                                            0.431373000145,
                                            0.498039007187,
                                            0.5
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 50
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 33,
                    "ty": 2,
                    "nm": "Pic",
                    "refId": "image_15",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                140.5,
                                127,
                                0
                            ]
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                562,
                                300,
                                0
                            ]
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                25,
                                25,
                                100
                            ]
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 34,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                341,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            234,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -8,
                                            -32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            40,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            66,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            71,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89.5,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            158,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -46,
                                            -16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 5",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            147,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            43.5,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 6",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            159,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -45.5,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 7",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            50,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            32
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 8",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            118,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -66,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 9",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            77,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            44.5,
                                            48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 10",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            82,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -84,
                                            16
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 11",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            70,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            89,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 12",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            166,
                                            6
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -42,
                                            -48
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 2.438
                                    },
                                    "nm": "Rectangle Path 13",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 35,
                    "ty": 4,
                    "nm": "Lines",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                246,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -125,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    6
                                                ],
                                                [
                                                    -47,
                                                    19
                                                ],
                                                [
                                                    -125,
                                                    19
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rd",
                                    "nm": "Round Corners 1",
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            126,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            28,
                                            12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            69,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            90.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 2",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            102,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            -74,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            55,
                                            13
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            16.5,
                                            -12.5
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 3.25
                                    },
                                    "nm": "Rectangle Path 4",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.729412019253,
                                            0.729412019253,
                                            0.729412019253,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Lines",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 36,
                    "ty": 4,
                    "nm": "Combined Shape",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.26,
                                                    0.43
                                                ],
                                                [
                                                    0.5,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1,
                                                    0.4
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.32,
                                                    -0.82
                                                ],
                                                [
                                                    -0.82,
                                                    -0.32
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.49,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0.88
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.23,
                                                    0.42
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -0.25,
                                                    -0.43
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.16,
                                                    -1.07
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.81,
                                                    -0.32
                                                ],
                                                [
                                                    -0.32,
                                                    0.82
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.23,
                                                    1.49
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -0.89
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.48,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.24,
                                                    -0.44
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    15.8,
                                                    -7.78
                                                ],
                                                [
                                                    14.59,
                                                    -8.48
                                                ],
                                                [
                                                    -7.62,
                                                    -8.48
                                                ],
                                                [
                                                    -7.99,
                                                    -10.96
                                                ],
                                                [
                                                    -9.87,
                                                    -13.33
                                                ],
                                                [
                                                    -13.84,
                                                    -14.89
                                                ],
                                                [
                                                    -15.89,
                                                    -13.98
                                                ],
                                                [
                                                    -14.99,
                                                    -11.91
                                                ],
                                                [
                                                    -11.11,
                                                    -10.38
                                                ],
                                                [
                                                    -8.66,
                                                    6.1
                                                ],
                                                [
                                                    -5.7,
                                                    8.68
                                                ],
                                                [
                                                    8.69,
                                                    8.68
                                                ],
                                                [
                                                    10.27,
                                                    7.08
                                                ],
                                                [
                                                    8.69,
                                                    5.48
                                                ],
                                                [
                                                    -5.54,
                                                    5.48
                                                ],
                                                [
                                                    -5.7,
                                                    4.4
                                                ],
                                                [
                                                    9.18,
                                                    4.4
                                                ],
                                                [
                                                    10.33,
                                                    3.72
                                                ],
                                                [
                                                    15.83,
                                                    -6.37
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ind": 1,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -3.92,
                                                    10.297
                                                ],
                                                [
                                                    -6.24,
                                                    12.647
                                                ],
                                                [
                                                    -3.92,
                                                    14.997
                                                ],
                                                [
                                                    -1.6,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ],
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.3
                                                ],
                                                [
                                                    1.28,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.3
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    5.36,
                                                    10.297
                                                ],
                                                [
                                                    3.04,
                                                    12.647
                                                ],
                                                [
                                                    5.36,
                                                    14.997
                                                ],
                                                [
                                                    7.68,
                                                    12.647
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Combined Shape",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 37,
                    "ty": 4,
                    "nm": "Path",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.17,
                                                    0
                                                ],
                                                [
                                                    -0.83,
                                                    0.84
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.63,
                                                    1.76
                                                ],
                                                [
                                                    1.73,
                                                    -1.79
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.71,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    -1.73
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0.82,
                                                    0.84
                                                ],
                                                [
                                                    1.16,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.68,
                                                    -1.71
                                                ],
                                                [
                                                    -1.71,
                                                    -1.86
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.01,
                                                    1.03
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.7,
                                                    -1.73
                                                ],
                                                [
                                                    -1.71,
                                                    1.73
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -8.28,
                                                    11.69
                                                ],
                                                [
                                                    -5.18,
                                                    13
                                                ],
                                                [
                                                    -2.08,
                                                    11.69
                                                ],
                                                [
                                                    14.67,
                                                    -5.31
                                                ],
                                                [
                                                    14.85,
                                                    -11.58
                                                ],
                                                [
                                                    8.54,
                                                    -11.68
                                                ],
                                                [
                                                    -3.35,
                                                    0.38
                                                ],
                                                [
                                                    -6.99,
                                                    0.38
                                                ],
                                                [
                                                    -8.52,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    -1.19
                                                ],
                                                [
                                                    -14.72,
                                                    5.1
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Path",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 38,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                447,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            250,
                                            56
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 28
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.337255001068,
                                            0.686275005341,
                                            0.921568989754,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 39,
                    "ty": 4,
                    "nm": "Channel phone bg",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                141,
                                250,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            280,
                                            500
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 29.985
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Channel phone bg",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        },
        {
            "id": "comp_18",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Icon",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                139,
                                77.5,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0.31,
                                                    0.53
                                                ],
                                                [
                                                    0.62,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.24,
                                                    0.48
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.39,
                                                    -1.02
                                                ],
                                                [
                                                    -1.02,
                                                    -0.39
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.87,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.09
                                                ],
                                                [
                                                    1.09,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.29,
                                                    0.52
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -0.32,
                                                    -0.54
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -0.2,
                                                    -1.31
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -1.02,
                                                    -0.4
                                                ],
                                                [
                                                    -0.4,
                                                    1.01
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.28,
                                                    1.84
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    1.09,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.09
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0.3,
                                                    -0.54
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    19.76,
                                                    -9.59
                                                ],
                                                [
                                                    18.24,
                                                    -10.45
                                                ],
                                                [
                                                    -9.53,
                                                    -10.45
                                                ],
                                                [
                                                    -9.99,
                                                    -13.52
                                                ],
                                                [
                                                    -12.33,
                                                    -16.43
                                                ],
                                                [
                                                    -17.3,
                                                    -18.36
                                                ],
                                                [
                                                    -19.86,
                                                    -17.24
                                                ],
                                                [
                                                    -18.74,
                                                    -14.69
                                                ],
                                                [
                                                    -13.89,
                                                    -12.8
                                                ],
                                                [
                                                    -10.82,
                                                    7.53
                                                ],
                                                [
                                                    -7.12,
                                                    10.7
                                                ],
                                                [
                                                    10.86,
                                                    10.7
                                                ],
                                                [
                                                    12.84,
                                                    8.73
                                                ],
                                                [
                                                    10.86,
                                                    6.75
                                                ],
                                                [
                                                    -6.93,
                                                    6.75
                                                ],
                                                [
                                                    -7.13,
                                                    5.43
                                                ],
                                                [
                                                    11.47,
                                                    5.43
                                                ],
                                                [
                                                    12.91,
                                                    4.59
                                                ],
                                                [
                                                    19.78,
                                                    -7.86
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ind": 1,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.6
                                                ],
                                                [
                                                    -1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.6
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.6
                                                ],
                                                [
                                                    1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.6
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -4.9,
                                                    12.7
                                                ],
                                                [
                                                    -7.8,
                                                    15.6
                                                ],
                                                [
                                                    -4.9,
                                                    18.5
                                                ],
                                                [
                                                    -2,
                                                    15.6
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 2",
                                    "hd": false
                                },
                                {
                                    "ind": 2,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.6
                                                ],
                                                [
                                                    -1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.6
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    -1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    1.6
                                                ],
                                                [
                                                    1.6,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -1.6
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    6.7,
                                                    12.7
                                                ],
                                                [
                                                    3.8,
                                                    15.6
                                                ],
                                                [
                                                    6.7,
                                                    18.5
                                                ],
                                                [
                                                    9.6,
                                                    15.6
                                                ]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 3",
                                    "hd": false
                                },
                                {
                                    "ty": "mm",
                                    "mm": 2,
                                    "nm": "Merge Paths 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.337255001068,
                                            0.686275005341,
                                            0.921568989754,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Combined Shape",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 189.81,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 214.785,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                92,
                                126,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            8
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 6
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 189.81,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 214.785,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                226,
                                131.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Medium",
                                        "t": "€",
                                        "j": 1,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 214.785,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 239.76,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                92,
                                156,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            8
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 6
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 214.785,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 239.76,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                225,
                                161.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Medium",
                                        "t": "€",
                                        "j": 1,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 239.76,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 264.735,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                92,
                                186,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            8
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 6
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 7,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 239.76,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 264.735,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 290.21,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                226,
                                191.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Medium",
                                        "t": "€",
                                        "j": 1,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 8,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 264.735,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 289.71,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                92,
                                216,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            78,
                                            8
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 6
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.882353007793,
                                            0.882353007793,
                                            0.890196025372,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 9,
                    "ty": 5,
                    "nm": "€",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 264.735,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 289.71,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "i": {
                                        "x": [
                                            0.833
                                        ],
                                        "y": [
                                            0.833
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.167
                                        ],
                                        "y": [
                                            0.167
                                        ]
                                    },
                                    "t": 316.683,
                                    "s": [
                                        100
                                    ]
                                },
                                {
                                    "t": 337.16251373291,
                                    "s": [
                                        0
                                    ]
                                }
                            ]
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                226,
                                221.07,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 17,
                                        "f": "Rubik-Medium",
                                        "t": "€",
                                        "j": 1,
                                        "tr": 0,
                                        "lh": 20.4,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 10,
                    "ty": 4,
                    "nm": "Line",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                139.5,
                                251,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -86.5,
                                                    0
                                                ],
                                                [
                                                    86.5,
                                                    0
                                                ]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.850979983807,
                                            0.850979983807,
                                            0.850979983807,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 1
                                    },
                                    "lc": 3,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "d": [
                                        {
                                            "n": "d",
                                            "nm": "штрих",
                                            "v": {
                                                "a": 0,
                                                "k": 4
                                            }
                                        }
                                    ],
                                    "nm": "Stroke 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Line",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 11,
                    "ty": 5,
                    "nm": "Total",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                226,
                                292.33,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "t": {
                        "d": {
                            "k": [
                                {
                                    "s": {
                                        "s": 23,
                                        "f": "Rubik-Bold",
                                        "t": "Total",
                                        "j": 1,
                                        "tr": 0,
                                        "lh": 27.6,
                                        "ls": 0,
                                        "fc": [
                                            0.212,
                                            0.208,
                                            0.216
                                        ]
                                    },
                                    "t": 0
                                }
                            ]
                        },
                        "p": {},
                        "m": {
                            "g": 1,
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ]
                            }
                        },
                        "a": []
                    },
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 12,
                    "ty": 4,
                    "nm": "Rectangle",
                    "sr": 1,
                    "ks": {
                        "p": {
                            "a": 0,
                            "k": [
                                139.5,
                                179,
                                0
                            ]
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 25,
                            "nm": "Тень",
                            "np": 8,
                            "mn": "ADBE Drop Shadow",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 2,
                                    "nm": "Цвет тени",
                                    "mn": "ADBE Drop Shadow-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [
                                            0.549019992352,
                                            0.549019992352,
                                            0.603922009468,
                                            0.300000011921
                                        ]
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Непрозрачность",
                                    "mn": "ADBE Drop Shadow-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": 76.5
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Направление",
                                    "mn": "ADBE Drop Shadow-0003",
                                    "ix": 3,
                                    "v": {
                                        "a": 0,
                                        "k": 180
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Расстояние",
                                    "mn": "ADBE Drop Shadow-0004",
                                    "ix": 4,
                                    "v": {
                                        "a": 0,
                                        "k": 4
                                    }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Мягкость",
                                    "mn": "ADBE Drop Shadow-0005",
                                    "ix": 5,
                                    "v": {
                                        "a": 0,
                                        "k": 23
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Только тень",
                                    "mn": "ADBE Drop Shadow-0006",
                                    "ix": 6,
                                    "v": {
                                        "a": 0,
                                        "k": 0
                                    }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ty": "rc",
                                    "d": 1,
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            203,
                                            290
                                        ]
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 30
                                    },
                                    "nm": "Rectangle Path 1",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.952941000462,
                                            0.952941000462,
                                            0.952941000462,
                                            1
                                        ]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ]
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ]
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0
                                    },
                                    "nm": "Преобразовать"
                                }
                            ],
                            "nm": "Rectangle",
                            "bm": 0,
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 1798.20007324219,
                    "st": 0,
                    "bm": 0
                }
            ]
        }
    ],
    "fonts": {
        "list": [
            {
                "fName": "Rubik-Bold",
                "fFamily": "Rubik",
                "fStyle": "Bold",
                "ascent": 71.8994140625
            },
            {
                "fName": "Rubik-Medium",
                "fFamily": "Rubik",
                "fStyle": "Medium",
                "ascent": 70.9991455078125
            }
        ]
    },
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 0,
            "nm": "Tap",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        210,
                        322,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 78.0000031770051,
            "op": 140.500005722682,
            "st": 78.0000031770051,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 0,
            "nm": "Tap 2",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        272,
                        497,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 125.000005091354,
            "op": 187.500007637032,
            "st": 125.000005091354,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 0,
            "nm": "Tap 3",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        154.818,
                        73.397,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        0
                    ]
                },
                "s": {
                    "a": 0,
                    "k": [
                        18,
                        18,
                        100
                    ]
                }
            },
            "ao": 0,
            "w": 200,
            "h": 200,
            "ip": 174.000007087165,
            "op": 236.500009632842,
            "st": 174.000007087165,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 4,
            "ty": 0,
            "nm": "Shopping Mask",
            "refId": "comp_1",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        270,
                        296.5,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        140,
                        250,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 280,
            "h": 500,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 5,
            "ty": 2,
            "nm": "Channel phone bg.png",
            "cl": "png",
            "refId": "image_16",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        270,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        192,
                        302,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 490.000019958109,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 6,
            "ty": 0,
            "nm": "Bill Card",
            "refId": "comp_18",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        565.5,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        139.5,
                        183,
                        0
                    ]
                }
            },
            "ao": 0,
            "w": 279,
            "h": 366,
            "ip": 0,
            "op": 1798.20007324219,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 7,
            "ty": 2,
            "nm": "bg.png",
            "cl": "png",
            "refId": "image_17",
            "sr": 1,
            "ks": {
                "p": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                },
                "a": {
                    "a": 0,
                    "k": [
                        400,
                        300,
                        0
                    ]
                }
            },
            "ao": 0,
            "ip": 0,
            "op": 511.000020813457,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": [],
    "chars": [
        {
            "ch": "T",
            "size": 23,
            "style": "Bold",
            "w": 61.08,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                1.611,
                                                -71.729
                                            ],
                                            [
                                                1.611,
                                                -63.184
                                            ],
                                            [
                                                25.781,
                                                -63.184
                                            ],
                                            [
                                                25.781,
                                                0
                                            ],
                                            [
                                                35.596,
                                                0
                                            ],
                                            [
                                                35.596,
                                                -63.184
                                            ],
                                            [
                                                59.766,
                                                -63.184
                                            ],
                                            [
                                                59.766,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "T",
                                "hd": false
                            }
                        ],
                        "nm": "T",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "o",
            "size": 23,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.761,
                                                0
                                            ],
                                            [
                                                2.414,
                                                3.706
                                            ],
                                            [
                                                0,
                                                5.56
                                            ],
                                            [
                                                -2.414,
                                                4.097
                                            ],
                                            [
                                                -5.312,
                                                0
                                            ],
                                            [
                                                -2.446,
                                                -4.812
                                            ],
                                            [
                                                0,
                                                -4.877
                                            ],
                                            [
                                                2.141,
                                                -4.405
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -5.247,
                                                0
                                            ],
                                            [
                                                -2.414,
                                                -3.706
                                            ],
                                            [
                                                0,
                                                -5.787
                                            ],
                                            [
                                                2.414,
                                                -4.097
                                            ],
                                            [
                                                5.987,
                                                0
                                            ],
                                            [
                                                1.545,
                                                3.056
                                            ],
                                            [
                                                0,
                                                5.397
                                            ],
                                            [
                                                -2.141,
                                                4.405
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.026,
                                                -5.713
                                            ],
                                            [
                                                15.535,
                                                -11.272
                                            ],
                                            [
                                                11.914,
                                                -25.172
                                            ],
                                            [
                                                15.535,
                                                -39.997
                                            ],
                                            [
                                                27.122,
                                                -46.143
                                            ],
                                            [
                                                39.772,
                                                -38.924
                                            ],
                                            [
                                                42.09,
                                                -27.025
                                            ],
                                            [
                                                38.879,
                                                -12.321
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                6.691,
                                                0
                                            ],
                                            [
                                                4.428,
                                                -5.203
                                            ],
                                            [
                                                0,
                                                -8.781
                                            ],
                                            [
                                                -4.202,
                                                -4.862
                                            ],
                                            [
                                                -7.144,
                                                0
                                            ],
                                            [
                                                -4.073,
                                                5.496
                                            ],
                                            [
                                                0,
                                                8.424
                                            ],
                                            [
                                                4.622,
                                                4.488
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.467,
                                                0
                                            ],
                                            [
                                                -4.428,
                                                5.204
                                            ],
                                            [
                                                0,
                                                8.196
                                            ],
                                            [
                                                4.202,
                                                4.862
                                            ],
                                            [
                                                8.566,
                                                0
                                            ],
                                            [
                                                4.073,
                                                -5.496
                                            ],
                                            [
                                                0,
                                                -8.716
                                            ],
                                            [
                                                -4.623,
                                                -4.488
                                            ]
                                        ],
                                        "v": [
                                            [
                                                27.366,
                                                -53.809
                                            ],
                                            [
                                                9.523,
                                                -46.003
                                            ],
                                            [
                                                2.881,
                                                -25.025
                                            ],
                                            [
                                                9.184,
                                                -5.437
                                            ],
                                            [
                                                26.202,
                                                1.855
                                            ],
                                            [
                                                45.16,
                                                -6.389
                                            ],
                                            [
                                                51.27,
                                                -27.27
                                            ],
                                            [
                                                44.336,
                                                -47.076
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "o",
                                "hd": false
                            }
                        ],
                        "nm": "o",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "t",
            "size": 23,
            "style": "Bold",
            "w": 27.78,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.465,
                                                -2.1
                                            ],
                                            [
                                                -4.102,
                                                0
                                            ],
                                            [
                                                -1.091,
                                                0.13
                                            ],
                                            [
                                                -1.009,
                                                0.293
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.488,
                                                -0.021
                                            ],
                                            [
                                                0.423,
                                                0
                                            ],
                                            [
                                                0.684,
                                                0.359
                                            ],
                                            [
                                                0,
                                                1.823
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                3.353
                                            ],
                                            [
                                                1.465,
                                                2.1
                                            ],
                                            [
                                                1.27,
                                                0
                                            ],
                                            [
                                                1.09,
                                                -0.13
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.652,
                                                0.087
                                            ],
                                            [
                                                -0.488,
                                                0.021
                                            ],
                                            [
                                                -1.595,
                                                0
                                            ],
                                            [
                                                -1.237,
                                                -0.618
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.203,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -52.295
                                            ],
                                            [
                                                1.123,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -45.117
                                            ],
                                            [
                                                8.203,
                                                -10.498
                                            ],
                                            [
                                                10.4,
                                                -2.319
                                            ],
                                            [
                                                18.75,
                                                0.83
                                            ],
                                            [
                                                22.29,
                                                0.635
                                            ],
                                            [
                                                25.439,
                                                0
                                            ],
                                            [
                                                25.439,
                                                -6.982
                                            ],
                                            [
                                                23.73,
                                                -6.819
                                            ],
                                            [
                                                22.363,
                                                -6.787
                                            ],
                                            [
                                                18.945,
                                                -7.324
                                            ],
                                            [
                                                17.09,
                                                -10.986
                                            ],
                                            [
                                                17.09,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -45.117
                                            ],
                                            [
                                                25.439,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -52.295
                                            ],
                                            [
                                                17.09,
                                                -66.895
                                            ],
                                            [
                                                8.203,
                                                -66.895
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "t",
                                "hd": false
                            }
                        ],
                        "nm": "t",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "a",
            "size": 23,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                2.533
                                            ],
                                            [
                                                -2.718,
                                                1.527
                                            ],
                                            [
                                                -3.184,
                                                0.423
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.689,
                                                0.458
                                            ],
                                            [
                                                -1.073,
                                                0.686
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.883,
                                                -2.373
                                            ],
                                            [
                                                3.092,
                                                0
                                            ],
                                            [
                                                1.855,
                                                1.462
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                -3.345
                                            ],
                                            [
                                                1.604,
                                                -0.909
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.624,
                                                -0.195
                                            ],
                                            [
                                                1.689,
                                                -0.457
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.396
                                            ],
                                            [
                                                -2.898,
                                                1.43
                                            ],
                                            [
                                                -2.539,
                                                0
                                            ],
                                            [
                                                -1.855,
                                                -1.462
                                            ]
                                        ],
                                        "v": [
                                            [
                                                13.184,
                                                -13.897
                                            ],
                                            [
                                                17.261,
                                                -21.204
                                            ],
                                            [
                                                24.443,
                                                -23.203
                                            ],
                                            [
                                                29.755,
                                                -23.885
                                            ],
                                            [
                                                34.725,
                                                -24.863
                                            ],
                                            [
                                                38.867,
                                                -26.578
                                            ],
                                            [
                                                38.867,
                                                -19.509
                                            ],
                                            [
                                                31.543,
                                                -7.858
                                            ],
                                            [
                                                22.559,
                                                -5.713
                                            ],
                                            [
                                                15.967,
                                                -7.905
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "a",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.027,
                                                -2.666
                                            ],
                                            [
                                                0,
                                                -4.844
                                            ],
                                            [
                                                -3.076,
                                                -2.942
                                            ],
                                            [
                                                -4.851,
                                                0
                                            ],
                                            [
                                                -3.353,
                                                1.724
                                            ],
                                            [
                                                -1.791,
                                                2.344
                                            ],
                                            [
                                                -0.716,
                                                -1.139
                                            ],
                                            [
                                                -3.027,
                                                0
                                            ],
                                            [
                                                -0.716,
                                                0.098
                                            ],
                                            [
                                                -1.367,
                                                0.391
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.488,
                                                -0.049
                                            ],
                                            [
                                                0.391,
                                                0
                                            ],
                                            [
                                                0.374,
                                                0.552
                                            ],
                                            [
                                                0,
                                                0.912
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.804,
                                                2.344
                                            ],
                                            [
                                                6.145,
                                                0
                                            ],
                                            [
                                                4.015,
                                                -2.53
                                            ],
                                            [
                                                0.163,
                                                -6.464
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.072,
                                                1.375
                                            ],
                                            [
                                                -4.581,
                                                0
                                            ],
                                            [
                                                -2.128,
                                                -1.356
                                            ],
                                            [
                                                0,
                                                -3.006
                                            ],
                                            [
                                                0.39,
                                                -0.784
                                            ],
                                            [
                                                2.018,
                                                -0.259
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -4.916,
                                                0.618
                                            ],
                                            [
                                                -3.027,
                                                2.666
                                            ],
                                            [
                                                0,
                                                4.422
                                            ],
                                            [
                                                3.076,
                                                2.942
                                            ],
                                            [
                                                4.036,
                                                0
                                            ],
                                            [
                                                3.352,
                                                -1.725
                                            ],
                                            [
                                                0.293,
                                                2.083
                                            ],
                                            [
                                                1.367,
                                                2.148
                                            ],
                                            [
                                                1.237,
                                                0
                                            ],
                                            [
                                                0.716,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.554,
                                                0.098
                                            ],
                                            [
                                                -0.488,
                                                0.049
                                            ],
                                            [
                                                -1.205,
                                                0
                                            ],
                                            [
                                                -0.375,
                                                -0.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -4.948
                                            ],
                                            [
                                                -3.837,
                                                -2.344
                                            ],
                                            [
                                                -5.299,
                                                0
                                            ],
                                            [
                                                -4.015,
                                                2.53
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.325,
                                                -2.715
                                            ],
                                            [
                                                1.917,
                                                -2.486
                                            ],
                                            [
                                                3.963,
                                                0
                                            ],
                                            [
                                                2.128,
                                                1.357
                                            ],
                                            [
                                                0,
                                                1.471
                                            ],
                                            [
                                                -0.684,
                                                1.43
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                20.459,
                                                -29.893
                                            ],
                                            [
                                                8.545,
                                                -24.966
                                            ],
                                            [
                                                4.004,
                                                -13.702
                                            ],
                                            [
                                                8.618,
                                                -2.656
                                            ],
                                            [
                                                20.508,
                                                1.758
                                            ],
                                            [
                                                31.592,
                                                -0.829
                                            ],
                                            [
                                                39.307,
                                                -6.932
                                            ],
                                            [
                                                40.82,
                                                -2.099
                                            ],
                                            [
                                                47.412,
                                                1.123
                                            ],
                                            [
                                                50.342,
                                                0.977
                                            ],
                                            [
                                                53.467,
                                                0.244
                                            ],
                                            [
                                                53.467,
                                                -6.249
                                            ],
                                            [
                                                51.904,
                                                -6.03
                                            ],
                                            [
                                                50.586,
                                                -5.957
                                            ],
                                            [
                                                48.218,
                                                -6.786
                                            ],
                                            [
                                                47.656,
                                                -8.982
                                            ],
                                            [
                                                47.656,
                                                -39.111
                                            ],
                                            [
                                                41.95,
                                                -50.049
                                            ],
                                            [
                                                26.978,
                                                -53.564
                                            ],
                                            [
                                                13.005,
                                                -49.77
                                            ],
                                            [
                                                6.738,
                                                -36.279
                                            ],
                                            [
                                                14.941,
                                                -36.279
                                            ],
                                            [
                                                17.036,
                                                -42.413
                                            ],
                                            [
                                                26.783,
                                                -46.143
                                            ],
                                            [
                                                35.919,
                                                -44.109
                                            ],
                                            [
                                                39.111,
                                                -37.565
                                            ],
                                            [
                                                38.526,
                                                -34.183
                                            ],
                                            [
                                                34.473,
                                                -31.648
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "a",
                                "hd": false
                            }
                        ],
                        "nm": "a",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "l",
            "size": 23,
            "style": "Bold",
            "w": 22.22,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.689,
                                                0
                                            ],
                                            [
                                                15.479,
                                                0
                                            ],
                                            [
                                                15.479,
                                                -71.729
                                            ],
                                            [
                                                6.689,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "l",
                                "hd": false
                            }
                        ],
                        "nm": "l",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "€",
            "size": 17,
            "style": "Medium",
            "w": 76.5,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.6,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.366
                                            ],
                                            [
                                                0.266,
                                                -0.933
                                            ],
                                            [
                                                2.466,
                                                -1.533
                                            ],
                                            [
                                                4,
                                                0
                                            ],
                                            [
                                                1.333,
                                                7.867
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                0.434
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.433,
                                                0.434
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -8.267,
                                                0
                                            ],
                                            [
                                                -2.467,
                                                -1.533
                                            ],
                                            [
                                                -1,
                                                -3.4
                                            ],
                                            [
                                                -0.467,
                                                -0.366
                                            ],
                                            [
                                                -0.8,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.434,
                                                0.367
                                            ],
                                            [
                                                0,
                                                0.534
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.366,
                                                3.3
                                            ],
                                            [
                                                4.466,
                                                1.967
                                            ],
                                            [
                                                6,
                                                0
                                            ],
                                            [
                                                4.966,
                                                -4.066
                                            ],
                                            [
                                                0.866,
                                                -7.6
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.433
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ],
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.433
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                -0.466
                                            ],
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.967,
                                                -4.033
                                            ],
                                            [
                                                -8.534,
                                                0
                                            ],
                                            [
                                                -4.467,
                                                1.966
                                            ],
                                            [
                                                -2.367,
                                                3.3
                                            ],
                                            [
                                                -0.134,
                                                3.934
                                            ],
                                            [
                                                0.433,
                                                0.4
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.8,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.367
                                            ],
                                            [
                                                -1,
                                                3.4
                                            ],
                                            [
                                                -2.467,
                                                1.534
                                            ],
                                            [
                                                -8.267,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.733,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                -0.434,
                                                -0.433
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.666,
                                                0
                                            ],
                                            [
                                                0.466,
                                                -0.466
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -0.733
                                            ],
                                            [
                                                -0.434,
                                                -0.433
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.333,
                                                -8
                                            ],
                                            [
                                                4,
                                                0
                                            ],
                                            [
                                                2.466,
                                                1.534
                                            ],
                                            [
                                                0.266,
                                                0.934
                                            ],
                                            [
                                                0.466,
                                                0.367
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.533,
                                                0
                                            ],
                                            [
                                                0.433,
                                                -0.366
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.134,
                                                -3.933
                                            ],
                                            [
                                                -2.367,
                                                -3.3
                                            ],
                                            [
                                                -4.467,
                                                -1.966
                                            ],
                                            [
                                                -8.6,
                                                0
                                            ],
                                            [
                                                -4.967,
                                                4.067
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.434
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.667,
                                                0
                                            ],
                                            [
                                                -0.467,
                                                0.434
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.667
                                            ],
                                            [
                                                0.466,
                                                0.467
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.933,
                                                7.534
                                            ],
                                            [
                                                4.966,
                                                4.034
                                            ],
                                            [
                                                6,
                                                0
                                            ],
                                            [
                                                4.466,
                                                -1.966
                                            ],
                                            [
                                                2.366,
                                                -3.3
                                            ],
                                            [
                                                0.066,
                                                -0.6
                                            ],
                                            [
                                                -0.434,
                                                -0.4
                                            ]
                                        ],
                                        "v": [
                                            [
                                                69.4,
                                                -22.8
                                            ],
                                            [
                                                59.7,
                                                -22.8
                                            ],
                                            [
                                                57.8,
                                                -22.25
                                            ],
                                            [
                                                56.7,
                                                -20.3
                                            ],
                                            [
                                                51.5,
                                                -12.9
                                            ],
                                            [
                                                41.8,
                                                -10.6
                                            ],
                                            [
                                                27.4,
                                                -22.4
                                            ],
                                            [
                                                43.9,
                                                -22.4
                                            ],
                                            [
                                                45.65,
                                                -23.1
                                            ],
                                            [
                                                46.3,
                                                -24.8
                                            ],
                                            [
                                                46.3,
                                                -30.6
                                            ],
                                            [
                                                45.65,
                                                -32.35
                                            ],
                                            [
                                                43.9,
                                                -33
                                            ],
                                            [
                                                27,
                                                -33
                                            ],
                                            [
                                                27,
                                                -36.8
                                            ],
                                            [
                                                43.9,
                                                -36.8
                                            ],
                                            [
                                                45.6,
                                                -37.5
                                            ],
                                            [
                                                46.3,
                                                -39.2
                                            ],
                                            [
                                                46.3,
                                                -45
                                            ],
                                            [
                                                45.65,
                                                -46.75
                                            ],
                                            [
                                                43.9,
                                                -47.4
                                            ],
                                            [
                                                27.4,
                                                -47.4
                                            ],
                                            [
                                                41.8,
                                                -59.4
                                            ],
                                            [
                                                51.5,
                                                -57.1
                                            ],
                                            [
                                                56.7,
                                                -49.7
                                            ],
                                            [
                                                57.8,
                                                -47.75
                                            ],
                                            [
                                                59.7,
                                                -47.2
                                            ],
                                            [
                                                69.4,
                                                -47.2
                                            ],
                                            [
                                                70.85,
                                                -47.75
                                            ],
                                            [
                                                71.5,
                                                -49.1
                                            ],
                                            [
                                                71.5,
                                                -49.3
                                            ],
                                            [
                                                67.75,
                                                -60.15
                                            ],
                                            [
                                                57.5,
                                                -68.05
                                            ],
                                            [
                                                41.8,
                                                -71
                                            ],
                                            [
                                                21.45,
                                                -64.9
                                            ],
                                            [
                                                12.7,
                                                -47.4
                                            ],
                                            [
                                                6.2,
                                                -47.4
                                            ],
                                            [
                                                4.5,
                                                -46.75
                                            ],
                                            [
                                                3.8,
                                                -45
                                            ],
                                            [
                                                3.8,
                                                -39.2
                                            ],
                                            [
                                                4.5,
                                                -37.5
                                            ],
                                            [
                                                6.2,
                                                -36.8
                                            ],
                                            [
                                                12.4,
                                                -36.8
                                            ],
                                            [
                                                12.4,
                                                -33
                                            ],
                                            [
                                                6.2,
                                                -33
                                            ],
                                            [
                                                4.5,
                                                -32.35
                                            ],
                                            [
                                                3.8,
                                                -30.6
                                            ],
                                            [
                                                3.8,
                                                -24.8
                                            ],
                                            [
                                                4.5,
                                                -23.1
                                            ],
                                            [
                                                6.2,
                                                -22.4
                                            ],
                                            [
                                                12.7,
                                                -22.4
                                            ],
                                            [
                                                21.55,
                                                -5.05
                                            ],
                                            [
                                                41.8,
                                                1
                                            ],
                                            [
                                                57.5,
                                                -1.95
                                            ],
                                            [
                                                67.75,
                                                -9.85
                                            ],
                                            [
                                                71.5,
                                                -20.7
                                            ],
                                            [
                                                70.95,
                                                -22.2
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "€",
                                "hd": false
                            }
                        ],
                        "nm": "€",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "€",
            "size": 25,
            "style": "Bold",
            "w": 74.41,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -4.492,
                                                -4.752
                                            ],
                                            [
                                                -6.999,
                                                0
                                            ],
                                            [
                                                -4.785,
                                                6.152
                                            ],
                                            [
                                                -0.716,
                                                7.552
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.66,
                                                -2.897
                                            ],
                                            [
                                                6.022,
                                                0
                                            ],
                                            [
                                                3.288,
                                                5.209
                                            ],
                                            [
                                                0.781,
                                                4.265
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0.847
                                            ],
                                            [
                                                -0.098,
                                                1.66
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.019,
                                                2.734
                                            ],
                                            [
                                                -5.957,
                                                0
                                            ],
                                            [
                                                -2.702,
                                                -2.702
                                            ],
                                            [
                                                -0.879,
                                                -4.622
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                4.215,
                                                4.785
                                            ],
                                            [
                                                7.617,
                                                0
                                            ],
                                            [
                                                5.078,
                                                -7.356
                                            ],
                                            [
                                                1.106,
                                                -5.957
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -1.204
                                            ],
                                            [
                                                -0.066,
                                                -1.334
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                1.27,
                                                8.496
                                            ],
                                            [
                                                4.752,
                                                5.046
                                            ],
                                            [
                                                8.105,
                                                0
                                            ],
                                            [
                                                4.004,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.814,
                                                4.72
                                            ],
                                            [
                                                -3.158,
                                                5.502
                                            ],
                                            [
                                                -6.543,
                                                0
                                            ],
                                            [
                                                -1.986,
                                                -3.125
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.033,
                                                -0.781
                                            ],
                                            [
                                                0,
                                                -1.855
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.879,
                                                -4.329
                                            ],
                                            [
                                                3.645,
                                                -4.98
                                            ],
                                            [
                                                4.85,
                                                0
                                            ],
                                            [
                                                2.702,
                                                2.702
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.456,
                                                -6.087
                                            ],
                                            [
                                                -4.216,
                                                -4.785
                                            ],
                                            [
                                                -8.952,
                                                0
                                            ],
                                            [
                                                -3.027,
                                                4.362
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.066,
                                                1.205
                                            ],
                                            [
                                                0,
                                                1.4
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                16.992,
                                                -25.586
                                            ],
                                            [
                                                25.635,
                                                -5.713
                                            ],
                                            [
                                                43.262,
                                                1.855
                                            ],
                                            [
                                                62.598,
                                                -7.373
                                            ],
                                            [
                                                69.678,
                                                -26.367
                                            ],
                                            [
                                                61.719,
                                                -26.367
                                            ],
                                            [
                                                58.008,
                                                -14.941
                                            ],
                                            [
                                                44.238,
                                                -6.689
                                            ],
                                            [
                                                29.492,
                                                -14.502
                                            ],
                                            [
                                                25.342,
                                                -25.586
                                            ],
                                            [
                                                50.879,
                                                -25.586
                                            ],
                                            [
                                                52.93,
                                                -32.52
                                            ],
                                            [
                                                24.609,
                                                -32.52
                                            ],
                                            [
                                                24.561,
                                                -34.961
                                            ],
                                            [
                                                24.707,
                                                -40.234
                                            ],
                                            [
                                                50.879,
                                                -40.234
                                            ],
                                            [
                                                52.93,
                                                -47.168
                                            ],
                                            [
                                                25.684,
                                                -47.168
                                            ],
                                            [
                                                30.029,
                                                -57.764
                                            ],
                                            [
                                                44.434,
                                                -65.234
                                            ],
                                            [
                                                55.762,
                                                -61.182
                                            ],
                                            [
                                                61.133,
                                                -50.195
                                            ],
                                            [
                                                69.092,
                                                -50.195
                                            ],
                                            [
                                                62.085,
                                                -66.504
                                            ],
                                            [
                                                44.336,
                                                -73.682
                                            ],
                                            [
                                                23.291,
                                                -62.646
                                            ],
                                            [
                                                17.09,
                                                -47.168
                                            ],
                                            [
                                                6.738,
                                                -47.168
                                            ],
                                            [
                                                4.688,
                                                -40.234
                                            ],
                                            [
                                                16.309,
                                                -40.234
                                            ],
                                            [
                                                16.211,
                                                -36.621
                                            ],
                                            [
                                                16.309,
                                                -32.52
                                            ],
                                            [
                                                6.738,
                                                -32.52
                                            ],
                                            [
                                                4.688,
                                                -25.586
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "€",
                                "hd": false
                            }
                        ],
                        "nm": "€",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "P",
            "size": 17,
            "style": "Bold",
            "w": 66.7,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -3.482,
                                                4.15
                                            ],
                                            [
                                                0,
                                                5.599
                                            ],
                                            [
                                                3.902,
                                                3.597
                                            ],
                                            [
                                                6.345,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                7.175,
                                                0
                                            ],
                                            [
                                                3.481,
                                                -4.15
                                            ],
                                            [
                                                0,
                                                -6.51
                                            ],
                                            [
                                                -3.902,
                                                -3.596
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                8.545,
                                                0
                                            ],
                                            [
                                                18.262,
                                                0
                                            ],
                                            [
                                                18.262,
                                                -30.322
                                            ],
                                            [
                                                40.659,
                                                -30.322
                                            ],
                                            [
                                                56.643,
                                                -36.548
                                            ],
                                            [
                                                61.865,
                                                -51.172
                                            ],
                                            [
                                                56.013,
                                                -66.333
                                            ],
                                            [
                                                40.643,
                                                -71.729
                                            ],
                                            [
                                                8.545,
                                                -71.729
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "P",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                -5.273
                                            ],
                                            [
                                                2.699,
                                                -1.855
                                            ],
                                            [
                                                4.33,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.133,
                                                -1.009
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                4.688
                                            ],
                                            [
                                                -2.699,
                                                1.855
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.716,
                                                0
                                            ],
                                            [
                                                3.878,
                                                1.888
                                            ]
                                        ],
                                        "v": [
                                            [
                                                52.051,
                                                -51.123
                                            ],
                                            [
                                                48.003,
                                                -41.309
                                            ],
                                            [
                                                37.459,
                                                -38.525
                                            ],
                                            [
                                                18.262,
                                                -38.525
                                            ],
                                            [
                                                18.262,
                                                -63.379
                                            ],
                                            [
                                                37.459,
                                                -63.379
                                            ],
                                            [
                                                46.233,
                                                -61.865
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "P",
                                "hd": false
                            }
                        ],
                        "nm": "P",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "r",
            "size": 17,
            "style": "Bold",
            "w": 33.3,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -2.377,
                                                2.849
                                            ],
                                            [
                                                -4.427,
                                                0
                                            ],
                                            [
                                                -0.439,
                                                -0.032
                                            ],
                                            [
                                                -0.521,
                                                -0.098
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.391,
                                                0.033
                                            ],
                                            [
                                                0.163,
                                                0
                                            ],
                                            [
                                                2.669,
                                                -2.522
                                            ],
                                            [
                                                0.684,
                                                -1.758
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                -3.711
                                            ],
                                            [
                                                2.376,
                                                -2.848
                                            ],
                                            [
                                                0.52,
                                                0
                                            ],
                                            [
                                                0.439,
                                                0.033
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.945,
                                                -0.098
                                            ],
                                            [
                                                -0.391,
                                                -0.032
                                            ],
                                            [
                                                -3.484,
                                                0
                                            ],
                                            [
                                                -2.67,
                                                2.523
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.689,
                                                0
                                            ],
                                            [
                                                15.479,
                                                0
                                            ],
                                            [
                                                15.479,
                                                -30.078
                                            ],
                                            [
                                                19.043,
                                                -39.917
                                            ],
                                            [
                                                29.248,
                                                -44.189
                                            ],
                                            [
                                                30.688,
                                                -44.141
                                            ],
                                            [
                                                32.129,
                                                -43.945
                                            ],
                                            [
                                                32.129,
                                                -53.223
                                            ],
                                            [
                                                30.127,
                                                -53.418
                                            ],
                                            [
                                                29.297,
                                                -53.467
                                            ],
                                            [
                                                20.068,
                                                -49.683
                                            ],
                                            [
                                                15.039,
                                                -43.262
                                            ],
                                            [
                                                15.039,
                                                -52.295
                                            ],
                                            [
                                                6.689,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "r",
                                "hd": false
                            }
                        ],
                        "nm": "r",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "d",
            "size": 17,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                5.599
                                            ],
                                            [
                                                -2.737,
                                                3.386
                                            ],
                                            [
                                                -4.405,
                                                0
                                            ],
                                            [
                                                -2.689,
                                                -3.174
                                            ],
                                            [
                                                0,
                                                -6.608
                                            ],
                                            [
                                                2.607,
                                                -3.499
                                            ],
                                            [
                                                4.048,
                                                0
                                            ],
                                            [
                                                2.364,
                                                3.777
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                -6.575
                                            ],
                                            [
                                                2.737,
                                                -3.385
                                            ],
                                            [
                                                3.951,
                                                0
                                            ],
                                            [
                                                2.688,
                                                3.174
                                            ],
                                            [
                                                0,
                                                6.543
                                            ],
                                            [
                                                -2.608,
                                                3.5
                                            ],
                                            [
                                                -5.215,
                                                0
                                            ],
                                            [
                                                -2.364,
                                                -3.776
                                            ]
                                        ],
                                        "v": [
                                            [
                                                12.012,
                                                -25.537
                                            ],
                                            [
                                                16.116,
                                                -40.479
                                            ],
                                            [
                                                26.829,
                                                -45.557
                                            ],
                                            [
                                                36.788,
                                                -40.796
                                            ],
                                            [
                                                40.82,
                                                -26.123
                                            ],
                                            [
                                                36.909,
                                                -11.06
                                            ],
                                            [
                                                26.926,
                                                -5.811
                                            ],
                                            [
                                                15.558,
                                                -11.475
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "d",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                3.989,
                                                0
                                            ],
                                            [
                                                3.875,
                                                -5.582
                                            ],
                                            [
                                                0,
                                                -7.617
                                            ],
                                            [
                                                -4.248,
                                                -4.899
                                            ],
                                            [
                                                -5.804,
                                                0
                                            ],
                                            [
                                                -2.789,
                                                1.432
                                            ],
                                            [
                                                -2.043,
                                                3.223
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.556,
                                                0.977
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -7.199,
                                                0
                                            ],
                                            [
                                                -3.875,
                                                5.583
                                            ],
                                            [
                                                0,
                                                8.138
                                            ],
                                            [
                                                4.248,
                                                4.9
                                            ],
                                            [
                                                3.6,
                                                0
                                            ],
                                            [
                                                2.789,
                                                -1.432
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.978,
                                                -2.441
                                            ],
                                            [
                                                -2.692,
                                                -1.692
                                            ]
                                        ],
                                        "v": [
                                            [
                                                25.157,
                                                -53.223
                                            ],
                                            [
                                                8.546,
                                                -44.849
                                            ],
                                            [
                                                2.734,
                                                -25.049
                                            ],
                                            [
                                                9.106,
                                                -5.493
                                            ],
                                            [
                                                24.185,
                                                1.855
                                            ],
                                            [
                                                33.768,
                                                -0.293
                                            ],
                                            [
                                                41.016,
                                                -7.275
                                            ],
                                            [
                                                41.016,
                                                0
                                            ],
                                            [
                                                48.926,
                                                0
                                            ],
                                            [
                                                48.926,
                                                -71.973
                                            ],
                                            [
                                                40.479,
                                                -71.973
                                            ],
                                            [
                                                40.479,
                                                -45.557
                                            ],
                                            [
                                                35.177,
                                                -50.684
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "d",
                                "hd": false
                            }
                        ],
                        "nm": "d",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "u",
            "size": 17,
            "style": "Bold",
            "w": 55.62,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.437,
                                                -2.669
                                            ],
                                            [
                                                -7.315,
                                                0
                                            ],
                                            [
                                                -3.29,
                                                2.734
                                            ],
                                            [
                                                -1.119,
                                                1.986
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.173,
                                                -2.93
                                            ],
                                            [
                                                5.959,
                                                0
                                            ],
                                            [
                                                1.521,
                                                3.125
                                            ],
                                            [
                                                0,
                                                2.67
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                4.46
                                            ],
                                            [
                                                2.65,
                                                4.98
                                            ],
                                            [
                                                4.695,
                                                0
                                            ],
                                            [
                                                1.661,
                                                -1.367
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                5.111
                                            ],
                                            [
                                                -2.156,
                                                5.469
                                            ],
                                            [
                                                -4.153,
                                                0
                                            ],
                                            [
                                                -0.825,
                                                -1.692
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                6.25,
                                                -52.295
                                            ],
                                            [
                                                6.25,
                                                -16.748
                                            ],
                                            [
                                                8.406,
                                                -6.055
                                            ],
                                            [
                                                23.354,
                                                1.416
                                            ],
                                            [
                                                35.332,
                                                -2.686
                                            ],
                                            [
                                                39.502,
                                                -7.715
                                            ],
                                            [
                                                39.404,
                                                0
                                            ],
                                            [
                                                47.705,
                                                0
                                            ],
                                            [
                                                47.705,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -52.295
                                            ],
                                            [
                                                38.916,
                                                -26.611
                                            ],
                                            [
                                                37.157,
                                                -14.551
                                            ],
                                            [
                                                24.983,
                                                -6.348
                                            ],
                                            [
                                                16.471,
                                                -11.035
                                            ],
                                            [
                                                15.234,
                                                -17.578
                                            ],
                                            [
                                                15.234,
                                                -52.295
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "u",
                                "hd": false
                            }
                        ],
                        "nm": "u",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        },
        {
            "ch": "c",
            "size": 17,
            "style": "Bold",
            "w": 50,
            "data": {
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                5.891,
                                                0
                                            ],
                                            [
                                                4.459,
                                                -5.11
                                            ],
                                            [
                                                0,
                                                -9.18
                                            ],
                                            [
                                                -4.07,
                                                -4.996
                                            ],
                                            [
                                                -6.836,
                                                0
                                            ],
                                            [
                                                -3.906,
                                                3.207
                                            ],
                                            [
                                                -0.977,
                                                6.934
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                2.132,
                                                -2.221
                                            ],
                                            [
                                                3.645,
                                                0
                                            ],
                                            [
                                                2.213,
                                                3.577
                                            ],
                                            [
                                                0,
                                                5.235
                                            ],
                                            [
                                                -1.562,
                                                3.544
                                            ],
                                            [
                                                -5.599,
                                                0
                                            ],
                                            [
                                                -1.855,
                                                -2.132
                                            ],
                                            [
                                                -0.521,
                                                -3.223
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                3.694,
                                                2.865
                                            ]
                                        ],
                                        "o": [
                                            [
                                                -6.902,
                                                0
                                            ],
                                            [
                                                -4.46,
                                                5.111
                                            ],
                                            [
                                                0,
                                                7.487
                                            ],
                                            [
                                                4.069,
                                                4.997
                                            ],
                                            [
                                                6.087,
                                                0
                                            ],
                                            [
                                                3.906,
                                                -3.206
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.814,
                                                3.859
                                            ],
                                            [
                                                -2.132,
                                                2.222
                                            ],
                                            [
                                                -4.753,
                                                0
                                            ],
                                            [
                                                -2.214,
                                                -3.577
                                            ],
                                            [
                                                0,
                                                -5.202
                                            ],
                                            [
                                                2.409,
                                                -5.462
                                            ],
                                            [
                                                4.102,
                                                0
                                            ],
                                            [
                                                1.855,
                                                2.132
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.749,
                                                -6.998
                                            ],
                                            [
                                                -3.695,
                                                -2.864
                                            ]
                                        ],
                                        "v": [
                                            [
                                                26.611,
                                                -53.809
                                            ],
                                            [
                                                9.57,
                                                -46.143
                                            ],
                                            [
                                                2.881,
                                                -24.707
                                            ],
                                            [
                                                8.984,
                                                -5.981
                                            ],
                                            [
                                                25.342,
                                                1.514
                                            ],
                                            [
                                                40.332,
                                                -3.296
                                            ],
                                            [
                                                47.656,
                                                -18.506
                                            ],
                                            [
                                                39.111,
                                                -18.506
                                            ],
                                            [
                                                34.692,
                                                -9.386
                                            ],
                                            [
                                                26.025,
                                                -6.055
                                            ],
                                            [
                                                15.576,
                                                -11.419
                                            ],
                                            [
                                                12.256,
                                                -24.635
                                            ],
                                            [
                                                14.6,
                                                -37.754
                                            ],
                                            [
                                                26.611,
                                                -45.947
                                            ],
                                            [
                                                35.547,
                                                -42.749
                                            ],
                                            [
                                                39.111,
                                                -34.717
                                            ],
                                            [
                                                47.656,
                                                -34.717
                                            ],
                                            [
                                                40.991,
                                                -49.512
                                            ]
                                        ],
                                        "c": true
                                    }
                                },
                                "nm": "c",
                                "hd": false
                            }
                        ],
                        "nm": "c",
                        "bm": 0,
                        "hd": false
                    }
                ]
            },
            "fFamily": "Rubik"
        }
    ]
}