import React, { useEffect } from 'react';
import Swiper from 'swiper';

/* Pagination types */
// images
// points
// none
export default ({ images, pagination, newId = 'dest' }) => {
    const onLoadFunction = () => {
        let shopSliderThumbs = new Swiper(`#sibikid_${newId}`, {
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            speed: 600,
            breakpoints: {
                320: {
                    spaceBetween: 7,
                    slidesPerView: 3,
                },
                480: {
                    spaceBetween: 7,
                    slidesPerView: 4,
                },
                768: {
                    spaceBetween: 10,
                    slidesPerView: 3,
                    centeredSlides: false,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                },
                992: {
                    spaceBetween: 15,
                    slidesPerView: 4,
                    centeredSlides: false,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                }
            }
        });
        new Swiper(`#gallery_${newId}`, {
            spaceBetween: 10,
            slidesPerView: 1,
            loop: false,
            speed: 800,
            navigation: {
                nextEl: '.c-shop-carousel-next',
                prevEl: '.c-shop-carousel-prev',
            },
            thumbs: {
                swiper: pagination === "images" ? shopSliderThumbs : null
            },
            pagination: {
                el: '#gallery-pag',
                type: 'bullets',
            },
        });
    }
    useEffect(() => {
        onLoadFunction();
    }, []);
    return (
        <div className="c-shop-carousel">
            <div className="swiper-container c-shop-carousel__container" id={`gallery_${newId}`}>
                <div className="swiper-wrapper">
                    {
                        images.map((img, key) => {
                            return <div className="swiper-slide c-shop-carousel-slide" key={key}>
                                <div className="c-shop-carousel-slide__img h-object-fit">
                                    <img src={img} alt="shop-modal-img" />
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="c-shop-carousel__bottom">
                <button type="button" className="c-shop-carousel-nav c-shop-carousel-prev">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" stroke-linejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                    </svg>
                </button>
                {pagination === "images" && <div className="swiper-container c-shop-carousel__thumbs-container" id={`sibikid_${newId}`}>
                    <div className="swiper-wrapper">
                        {
                            images.map((img, key) => {
                                return <div className="swiper-slide c-shop-carousel__thumbs-slide" key={key}>
                                    <div className="c-shop-carousel__thumbs-slide__img h-object-fit">
                                        <img src={img} alt="shop-modal-img" />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>}

                {pagination === "points" && <div className="swiper-pagination" id="gallery-pag"></div>}

                <button type="button" className="c-shop-carousel-nav c-shop-carousel-next">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}