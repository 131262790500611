import React from 'react';
import $ from "jquery";
import Layout from '../containers/layout';
import ProductsTab from '../containers/search/products';

class ImpressumViewPage extends React.Component {
    scrlTop;
    constructor() {
        super();
        this.state = {
            menu: false,
            count: 0,
            text: ''
        }
        this.updateState = this.updateState.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
        }
    }

    updateState(a, b) {
        this.setState({
            count: b,
            text: decodeURIComponent(a)
        });
    }

    render() {
        const { count, text } = this.state;
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <main>
                    <section className="search-results">
                        <div className="search-results-wrapper">
                            <div className="search-results-top">
                                <div className="container">
                                    <h1 className="main-title">Showing {count} results for "{text}"</h1>
                                </div>
                            </div>
                            <div className="page-tabs-content">
                                <div className="container">
                                    <div className="page-tabs-content__inner">
                                        <ProductsTab updateState={this.updateState} menuTrigger={this.menuTrigger} />
                                        <div id="channels_tab" className="page-tabs-content__block">
                                            <div className="c-channels-boxes-wrapper">
                                                <div className="c-channels-boxes-inner">
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content">
                                                                <a href="#" className="c-channels-carousel__card__img h-object-fit">
                                                                    <img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a>
                                                                <a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content">
                                                                <a href="#" className="c-channels-carousel__card__img h-object-fit"><img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a><a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content"><a href="#" className="c-channels-carousel__card__img h-object-fit"><img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a><a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content"><a href="#" className="c-channels-carousel__card__img h-object-fit"><img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a><a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div >
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content"><a href="#" className="c-channels-carousel__card__img h-object-fit"><img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a><a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div >
                                                    <div className="c-channels-boxes-col">
                                                        <div className="c-channels-carousel__card">
                                                            <div className="c-channels-carousel__card__content"><a href="#" className="c-channels-carousel__card__img h-object-fit"><img src="s/images/useful/shopping-modal/shop-modal-img.jpg" alt="img" /></a><a href="#" className="c-channels-carousel__card__title">Travel Prudently Lug</a>
                                                                <p className="c-channels-carousel__card__text">Hey guys! We are eyeslovetosee - a group of 6 people who report…</p>
                                                            </div>
                                                            <div className="c-channels-carousel__card__footer"><a href="#"><span>Read more</span>
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon icon-arr-sm">
                                                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                                                </svg></a></div>
                                                        </div>
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                        <div id="online_sellers_tab" className="page-tabs-content__block"></div>
                                        <div id="local_tab" className="page-tabs-content__block"></div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </section >
                </main >
            </Layout>
        </div >
    }
}

export default ImpressumViewPage;
