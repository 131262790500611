import React from 'react';
import { NavLink } from 'react-router-dom';

class HeaderSearch extends React.Component {
    searchBTN;
    constructor() {
        super();
        this.state = {
            searchLine: ''
        }
        this.changeSearch = this.changeSearch.bind(this);
        this.bindEnter = this.bindEnter.bind(this);
    }

    bindEnter() {
        window.addEventListener('keyup', (event) => {
            if (event.keyCode === 13) {
                this.searchBTN.click();
            }
        });
    }

    changeSearch(event) {
        this.setState({
            searchLine: event.target.value
        })
    }

    render() {
        const { placeholder } = this.props;
        return <div className="search-form">
            <div className="input-wrap">
                <input type="search" name="search" placeholder={placeholder} onChange={this.changeSearch.bind(this)} onFocus={this.bindEnter.bind(this)} value={this.state.searchLine} value={this.state.searchLine} className="form-control header-search" />
                <NavLink to={`/search/data=${this.state.searchLine}`} className="form-submit" ref={el => this.searchBTN = el}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.6 24.9" style={{ enableBackground: 'new 0 0 23.6 24.9' }} xmlSpace="preserve" className="icon icon-search">
                        <path fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10.3,19c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8S5.4,19,10.3,19z M15.9,17.1l6.3,6.3"></path>
                    </svg>
                </NavLink>
            </div>
        </div>
    }
}

export default HeaderSearch