import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default (props) => {
    return (
        <div className="breadcrumbs">
            <ul itemscope="itemscope" itemType="http://schema.org/BreadcrumbList" className="breadcrumbs__list h-reset-list">
                {props.breadchums && props.breadchums.map((el, key) => {
                    return <>
                        <li itemProp="itemListElement" key={key} itemscope="itemscope" itemType="http://schema.org/ListItem" className="breadcrumbs__list__item">
                            <NavLink to={el.link} itemProp="item">
                                <span itemProp="name">{el.text}</span>
                            </NavLink>
                            <meta itemProp="position" content="1" />
                        </li>
                        {key < props.breadchums.length - 1 &&
                            <li className="breadcrumbs__list__divider">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.7 10.3" style={{ enableBackground: 'new 0 0 12.7 10.3' }} xmlSpace="preserve" className="icon-arr-sm">
                                    <path fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M11.2,5.2L7.5,1.5 M11.2,5.2L7.5,8.8 M10.8,5.2H1.5"></path>
                                </svg>
                            </li>
                        }
                    </>
                })}
            </ul>
        </div>
    )
};