import React from 'react';
import axios from 'axios';
import $ from "jquery";
import Layout from '../containers/layout';

import PageBannerImg from '../assets/img/svg/static-page-banner.svg';

class ImpressumViewPage extends React.Component {
    scrlTop;
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            load: true,
            dataText: ''
        }
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            $(window).on('scroll', function () {
                this.scrlTop = `${window.scrollY}px`;
            });
            this.getData();
        }
    }
    getData() {
        axios.get(`/api/v1/page/impressum`).then((result) => {
            this.setState({
                dataText: result.data.HtmlText,
                load: false
            })
        });
    }
    render() {
        return <div className="l-wrapper">
            <Layout lg={true} animated={false}>
                <main>
                    <div className="c-page-banner">
                        <div className="container">
                            <img src={PageBannerImg} alt="page-banner" className="c-page-banner__img" />
                        </div>
                    </div>
                    <div className="static-page-content">
                        <div className="container">
                            <h1 className="section-title">Impressum</h1>
                            <div className="text-box" dangerouslySetInnerHTML={{ __html: this.state.dataText }}>

                            </div>
                        </div>
                    </div>
                </main >
            </Layout>
        </div >
    }
}

export default ImpressumViewPage;
