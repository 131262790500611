import React from "react";

export default ({key, el, onOpenModal, selectOffer}) => {
    const checkMarke = (el) => {
        const data = el.Attributes.find(x => x.Key === "Marke");
        if(data){
            return data.Value
        }else{
            return null
        }
    };
    return(<div className="c-shopping-grid-col" key={key}>
        <div className="c-shopping-good">
            <a href="#" className="c-shopping-good__img h-object-fit" onClick={() => onOpenModal(el)}>
                <img src={el.Images[0]} alt="img" />
            </a>
            <div className="c-shopping-good__content">
                <a href="#" className="c-shopping-good__title" onClick={() => onOpenModal(el)}>{el.Title}</a>
                {/* <p className="c-shopping-good__text">{el.Description}</p> */}
                <p className="c-shopping-good__text">{el.TotalCount > 1 
                    ? <button onClick={ () => selectOffer(el)}>{el.TotalCount} offers</button>
                    : <b>{checkMarke(el)}</b>
                }</p>
                <strong className="c-shopping-good__price">
                    {el.MinPrice} {el.Currency}
                    {el.TotalCount > 1 && <>- {el.MaxPrice} {el.Currency} </>}
                </strong>
            </div>
        </div>
    </div>)
}