import React from "react";
import CarIcon from "../../assets/img/car.png";
import LinkImage from "../../assets/img/link.png";

export default ({ list }) => {
  const checkPresis = (attr) => {
      if (attr) {
        const ret = attr.find((x) => x.Key === "Preis Alt"),
          result = ret ? ret.Value : null;
        return result;
      } else {
        return null;
      }
    },
    checkLieferzeit = (attr) => {
      if (attr) {
        const ret = attr.find((x) => x.Key === "Lieferzeit"),
          result = ret ? ret.Value : null;
        return result;
      } else {
        return null;
      }
    };
  return (
    <>
      {list.map((line, key) => (
        <div className="offer-line" key={key}>
          <div className="offer-line-image">
            <img src={line.PartnerUrl} alt="" />
          </div>
          <div className="offer-line-title">{line.Title}</div>
          <div className="offer-line-delivery">
            <img src={CarIcon} alt="" />
            <span>Versandfertig in {checkLieferzeit(line.Attributes)}</span>
          </div>
          <div className="offer-line-price">
            <div className="price-data">
              {checkPresis() && (
                <div className="old-price">
                  {checkPresis(line.Attributes)}
                  {line.Currency}
                </div>
              )}
              <div className="current-price">
                {line.Price}
                {line.Currency}
              </div>
            </div>
            <div className="price-line">
              {line.Price}
              {line.Currency} including shipping
            </div>
          </div>
          <div className="offer-line-button">
            <a href={line.ProductUrl} target="_blank">
              To the shop
              <img src={LinkImage} alt="" />
            </a>
          </div>
        </div>
      ))}
    </>
  );
};
